import React from 'react';
import { Card, Row, Col, Form, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import './DebtPortalCustomerAccountFiltersBar.css'; // Add necessary custom styles

function DebtPortalCustomerAccountFiltersBar({ debtPortalData, filterProps, uniqueDocTypes, handleClearFilters }) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedDocTypes,
    setSelectedDocTypes,
    docNumberFilter,
    setDocNumberFilter,
    invoiceRefFilter,
    setInvoiceRefFilter,
    clearingDocFilter,
    setClearingDocFilter,
    setAssignmentFilter,
    setReferenceFilter,
    referenceFilter,
    amountFilter,
    setAmountFilter,
    assignmentFilter
  } = filterProps;

  const handleDocTypeSelect = (docType) => {
    if (selectedDocTypes.includes(docType)) {
      setSelectedDocTypes(selectedDocTypes.filter(type => type !== docType));
    } else {
      setSelectedDocTypes([...selectedDocTypes, docType]);
    }
  };

  return (
    <Card className="dark-card" style={{ width: '96%', marginTop: '5px', padding: '10px' }}>
      <Form>
        <Row style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', alignItems: 'center', justifyContent: "space-evenly" }}>
          {/* Date Filters */}
          <Col style={{ flex: '2' }}>
            <Form.Group controlId="filterStartDate" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Start Date</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ flex: '2' }}
              />
            </Form.Group>
          </Col>

          <Col style={{ flex: '2' }}>
            <Form.Group controlId="filterEndDate" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>End Date</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ flex: '2' }}
              />
            </Form.Group>
          </Col>

          {/* Amount Filter */}
          <Col style={{ flex: '2' }}>
            <Form.Group controlId="filterAmount" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Amount</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={amountFilter}
                onChange={(e) => setAmountFilter(e.target.value)}
                style={{ flex: '2' }}
              />
            </Form.Group>
          </Col>

          {/* Document Type Filter */}
          <Col md="2" >
            <Form.Group controlId="filterDocType" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px', textWrap: "nowrap" }}>Document Type</Form.Label>
              <DropdownButton
                size="sm"
                id="dropdown-doc-type"
                title={selectedDocTypes.length > 0 ? `${selectedDocTypes.length} Selected` : 'Select Document Types'}
                variant="outline-warning"
                style={{ flex: '2', marginBottom: "12px" }}
              >
                {uniqueDocTypes.map((docType, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={docType}
                    checked={selectedDocTypes.includes(docType)}
                    onChange={() => handleDocTypeSelect(docType)}
                    className="mx-2 my-1"
                  />
                ))}
              </DropdownButton>
            </Form.Group>
          </Col>

          {/* Clear Filters Button */}
          <Col style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='danger' size="sm" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Col>
        </Row>

        {/* Second Row */}
        <Row style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', alignItems: 'center', marginTop: '5px', marginRight: "90px" }}>
          <Col style={{ flex: '1' }}>
            <Form.Group controlId="filterDocNumber" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Doc Number</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={docNumberFilter}
                onChange={(e) => setDocNumberFilter(e.target.value)}
                style={{ flex: '1' }}
              />
            </Form.Group>
          </Col>

          <Col style={{ flex: '1' }}>
            <Form.Group controlId="filterAssignment" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Assignment</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={assignmentFilter}
                onChange={(e) => setAssignmentFilter(e.target.value)}
                style={{ flex: '1' }}
              />
            </Form.Group>
          </Col>

          <Col style={{ flex: '1' }}>
            <Form.Group controlId="filterInvoiceRef" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Invoice Ref</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={invoiceRefFilter}
                onChange={(e) => setInvoiceRefFilter(e.target.value)}
                style={{ flex: '1' }}
              />
            </Form.Group>
          </Col>

          <Col style={{ flex: '1' }}>
            <Form.Group controlId="filterClearingDoc" style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Label className="compact-label" style={{ fontSize: '12px', marginRight: '10px' }}>Clearing Doc</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={clearingDocFilter}
                onChange={(e) => setClearingDocFilter(e.target.value)}
                style={{ flex: '1' }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default DebtPortalCustomerAccountFiltersBar;
