import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { convertExcelDateToUKDate } from '../../../../Utils/PlanItFunctions';
import { CalculateRacksOfSelectedRows, CalculateSlabsOfSelectedRows, CalculateTotalWeightOfSelectedRows } from '../../../../Utils/PlanItFunctions';
import TableOptionsRow from './TableOptionsRow';
import FrameConversionSettings from './FrameConversionSettings';

const SlabWeights = {
    3: 40,
    6: 80,
    12: 160,
    20: 250,
}

const omittedColumns = [
    'Deleted?',
    "Purchasing Doc. Type",
    "Purch. Doc. Category",
    "Purchasing Group",
    "Item Category",
    "Acct Assignment Cat.",
    "Supplier/Supplying Plant",
    "PO history/release documentation",
    "Still to be invoiced (qty)",
    "Still to be invoiced (val.)",
    "Still to be delivered (value)",
    "Deletion Indicator",
    "Currency",
    "Order Unit",
    "Price unit",
    "Target Quantity",
    "Open Target Quantity",
    "Tipo de posición",
    "Indicador de borrado",
    "Historial pedido/Docu.orden entrega",
    "Grupo de compras",
    "Tipo doc.compras",
    "Cl.documento compras",
    "Tipo de imputación",
    "Moneda",
];

const columnLabels = {
    "Still to be delivered (qty)": "Still to Deliv",
    "Deletion Indicator": "Deleted?",
    "Purchasing Document" : "PO Number",
    "Short Text" : "Description",

};

function PlanningTable({ planItProps, manipulatedME2LData, setManipulatedME2LData, selectedRows, setSelectedRows, setTotalWeightSelected, 
        setTotalRacksOccupied, setTotalSlabsSelected, preparedData, setPreparedData, handleExportToSpreadsheet, handleCopyToClipboard, divisionIndicatorProps}) {
    
    const { ME2LData } = planItProps;
    const {divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm} = divisionIndicatorProps;

    const [showAdditionalPOColumns, setShowAdditionalPOColumns] = useState(false);
    const [showOnlySlabs, setShowOnlySlabs] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [headers, setHeaders] = useState([]);
    const [textFilter, setTextFilter] = useState('');

    // For Frame Conversion Seettings        
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
    setShowModal(true);
    };

    const handleCloseModal = () => {
    setShowModal(false);
    };

    const handleRowSelect = (index) => {
        const selectedRow = preparedData[index];
        const isAlreadySelected = selectedRows.includes(selectedRow);

        let updatedSelectedRows;
        if (isAlreadySelected) {
            // If the row is already selected, remove it
            updatedSelectedRows = selectedRows.filter(row => row !== selectedRow);
        } else {
            // If the row is not selected, add it
            updatedSelectedRows = [...selectedRows, selectedRow];
        }
        setSelectedRows(updatedSelectedRows);
    };

    useEffect(() => { 
        if (selectedRows.length > 0) {
            const totalWeight = CalculateTotalWeightOfSelectedRows(selectedRows);
            const totalRacks = CalculateRacksOfSelectedRows(selectedRows, divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm);
            const totalSlabs = CalculateSlabsOfSelectedRows(selectedRows);
            setTotalWeightSelected(totalWeight);
            setTotalRacksOccupied(totalRacks);
            setTotalSlabsSelected(totalSlabs);
        }
        console.log(selectedRows) 
    }, [selectedRows, setTotalRacksOccupied, setTotalSlabsSelected, setTotalWeightSelected, divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm]);

    const handlePlanTruck = () => {
        // Logic for planning truck with selected rows
        console.log("Planning truck with selected rows:", selectedRows);
        alert("This feature is not yet implemented.");
    };

    const handleResetSelection = () => {
        setSelectedRows([]);
        console.log("Selection reset");
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const toggleAdditionalPOColumns = () => {
        setShowAdditionalPOColumns(!showAdditionalPOColumns);
    };

    const handleFrameSettings = () => {
        console.log("Frame settings clicked");
        handleShowModal();
    };

    useEffect(() => {
        const sortedData = [...ME2LData[0]].sort((a, b) => {
            if (sortConfig.key !== null) {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
            }
            return 0;
        });

        const data = showAdditionalPOColumns ? sortedData : sortedData.map(entry =>
            Object.fromEntries(Object.entries(entry).filter(([key]) => !omittedColumns.includes(key)))
        );

        let data2 = showOnlySlabs ? data.filter(entry => entry["Material"].substring(0, 2) === "TB") : data;
        
        if (textFilter !== '') {
            const filteredData = data2.filter(entry => Object.values(entry).some(value => value.toString().toLowerCase().includes(textFilter.toLowerCase())));
            data2 = filteredData;
        }

        const headers = Object.keys(data[0]);
        setHeaders(headers);
        setPreparedData(data2);

    }, [showAdditionalPOColumns, showOnlySlabs, ME2LData, setPreparedData, sortConfig.direction, sortConfig.key, textFilter]);

    const handleQtyAdjust = (rowIndex, increment) => {
        const updatedData = [...preparedData];
        // Check for the existing quantity field name and use the appropriate one
        const qtyFieldName = updatedData[rowIndex]['Still to be delivered (qty)'] !== undefined ? 'Still to be delivered (qty)' : 'Por entregar (cantidad)';
        const currentQty = updatedData[rowIndex][qtyFieldName];
        const newQty = currentQty + increment;
        
        if (newQty >= 0) {
            // Update the quantity
            updatedData[rowIndex][qtyFieldName] = newQty;
            
            // Recalculate total weight and racks consumption
            const material = updatedData[rowIndex]["Material"];
            const starting2Characters = material.substring(0, 2);
            const thickness = material.substring(9, 11);
            let totalWeight = 0;
            let formattedConsumption = 0;
    
            if (starting2Characters === "TB") {
                totalWeight = newQty * (SlabWeights[parseInt(thickness)] || 0);
                let racksConsumption = 0;
                if (thickness === "20") {
                    racksConsumption = newQty / divisionIndicator20mm;
                } else if (thickness === "12") {
                    racksConsumption = newQty / divisionIndicator12mm;
                } else if (thickness === "06") {
                    racksConsumption = newQty / divisionIndicator6mm;
                } else if (thickness === "03") {
                    racksConsumption = newQty / divisionIndicator3mm;
                }
                formattedConsumption = racksConsumption.toFixed(2);
            }
    
            updatedData[rowIndex]["Total Weight"] = totalWeight;
            updatedData[rowIndex]["Racks Consumption"] = formattedConsumption;
    
            // Update the state with the modified data
            setPreparedData(updatedData);
    
            // Update selectedRows with the modified quantity
            const updatedSelectedRows = selectedRows.map(row => {
                if (row === selectedRows[rowIndex]) {
                    return { ...row, [qtyFieldName]: newQty };
                }
                return row;
            });
            setSelectedRows(updatedSelectedRows);
        }
    };
    

    return (
        <div>
            <TableOptionsRow showAdditionalColumns={showAdditionalPOColumns} toggleAdditionalPOColumns={toggleAdditionalPOColumns} handlePlanTruck={handlePlanTruck} handleCopyToClipboard={handleCopyToClipboard}
                handleResetSelection={handleResetSelection} showOnlySlabs={showOnlySlabs} setShowOnlySlabs={setShowOnlySlabs} handleExportToSpreadsheet={handleExportToSpreadsheet} setTextFilter={setTextFilter}
                textFilter={textFilter} handleFrameSettings={handleFrameSettings}
            />
            <FrameConversionSettings divisionIndicatorProps={divisionIndicatorProps} show={showModal} onHide={handleCloseModal} preparedData={preparedData} setPreparedData={setPreparedData} />
            <div className='scrollable-table'> 
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr className='sticky-row'>
                            {headers && headers.map((header, index) => (
                                <th key={index} onClick={() => handleSort(header)} className='sticky-row'>
                                    {columnLabels[header] || header}
                                    {sortConfig.key === header && (
                                        sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                                    )}
                                </th>
                            ))}
                            <th>Selection</th>
                        </tr>
                    </thead>
                    <tbody>
                        {preparedData && preparedData.map((entry, rowIndex) => (
                            <tr key={rowIndex} className='plan-it-table-body-row'>
                                {headers.map((header, colIndex) => (
                                    <td key={colIndex} className='plan-it-table-body-cell'>
                                        {(header === 'Document Date' || header === 'Fecha documento') ? convertExcelDateToUKDate(entry[header]) : 
                                            (header === 'Still to be delivered (qty)' || header === 'Por entregar (cantidad)') ? 
                                                <>
                                                    <span className='qty-adjust-icon' onClick={() => handleQtyAdjust(rowIndex, -1)}>-</span>
                                                    {entry[header]}
                                                    <span className='qty-adjust-icon' onClick={() => handleQtyAdjust(rowIndex, 1)}>+</span>
                                                </> 
                                                : entry[header]
                                        }
                                    </td>
                                ))}
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(entry)}
                                        onChange={() => handleRowSelect(rowIndex)}
                                        className={`plan-checkbox-${rowIndex}`}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default PlanningTable;
