import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

function MonthView({ filterState, rawData }) {
    const [monthlyData, setMonthlyData] = useState({});
    const [loading, setLoading] = useState(true);

    const filteredData = rawData.filter(item => 
        (filterState.month === '' || new Date(item.deliveryDate).getMonth() === parseInt(filterState.month)) &&
        (filterState.customer === '' || item.customer.includes(filterState.customer)) &&
        (filterState.material === '' || item.material.includes(filterState.material))
    );

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Table striped bordered hover size="sm" variant="dark">
            <thead>
                <tr>
                    <th>Month</th>
                    <th>Total Quantity</th>
                    <th>Total Net Value</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(monthlyData).map(([month, data]) => (
                    <tr key={month}>
                        <td>{month}</td>
                        <td>{data.totalQuantity}</td>
                        <td>{data.totalNetValue.toFixed(2)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default MonthView;

function groupDataByMonth(data) {
    return data.reduce((acc, order) => {
        const month = new Date(order.deliveryDate).toLocaleString('default', { month: 'long' });
        if (!acc[month]) {
            acc[month] = { totalQuantity: 0, totalNetValue: 0 };
        }
        acc[month].totalQuantity += order.quantity;
        acc[month].totalNetValue += order.netValue;
        return acc;
    }, {});
}
