import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import Card from 'react-bootstrap/Card';


function PlanItDashInsightCard({parentCssName, cardHeader, bodyValue, icon}) {
  return (
    <Card className={parentCssName}>
        <Card.Header className='plan-it-dash-insight-card-header'> 
            <FontAwesomeIcon icon={icon} className='plan-it-dash-card-icon' style={{marginLeft: "15px", marginRight: "15px"}} />
            <span className='plan-it-dash-insight-card-header'>{cardHeader}</span>
            <FontAwesomeIcon icon={icon} className='plan-it-dash-card-icon' style={{marginLeft: "15px", marginRight: "15px"}} />
        </Card.Header>
        <Card.Body className='plan-it-insight-card-body'>
            <Card.Text>
                {bodyValue}
            </Card.Text>
        </Card.Body>
    </Card>
  )
}

export default PlanItDashInsightCard