import './DebtPortalCustomerAccountSideMenu.css';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

function DebtPortalCustomerAccountSideMenu({ debtPortalData, selectedCustomer, setSelectedCustomer }) {
  const [searchQuery, setSearchQuery] = useState('');

  // Extract unique customer names
  const uniqueCustomers = [...new Set(debtPortalData.map(item => item['Name 1']))];
  const sortedAtoZ = uniqueCustomers.sort((a, b) => a.localeCompare(b));
  const filteredBlank = sortedAtoZ.filter(item => item !== '');

  // Filter customers based on search query
  const filteredCustomers = filteredBlank.filter(customer =>
    customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handler for button click
  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
  };

  // Handler to reset the selection and clear search
  const handleResetClick = () => {
    setSelectedCustomer(null);
    setSearchQuery('');
  };

  // Handler for search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='customer-account-side-menu'>
      <div className='header-container'>
        <span style={{ fontSize: "small" }}>Select Account</span>
        <Button
          variant="danger" // Red button
          size="sm" // Small size
          onClick={handleResetClick}
          style={{ fontSize: "small" }}
        >
          Clear
        </Button>
        <div className='search-container' style={{ padding: '5px' }}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search customers..."
          style={{ width: '100%', padding: '5px', fontSize: 'small' }}
        />
        </div>
      </div>

      <div className='button-holder'>
        {filteredCustomers.map((customer, index) => {
          const isSelected = customer === selectedCustomer;
          const buttonVariant = isSelected ? 'primary' : 'secondary';

          return (
            <div key={index} className='button-item'>
              <Button
                variant={buttonVariant}
                style={{ flexGrow: '1', margin: '1px', fontSize: 'small' }}
                onClick={() => handleCustomerClick(customer)}
              >
                {customer}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DebtPortalCustomerAccountSideMenu;
