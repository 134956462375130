import React from 'react'
import { Card, Button } from 'react-bootstrap'


function EmailSucessCard({setEmailStatus}) {
  return (
    <Card className="feedback-form-container" style={{flexDirection: "column"}}>
        <Card.Header>Success!</Card.Header>
        <Card.Body className='dark-card' style={{textAlign: "center", justifyContent: "center"}}>
        <p>Thank you, your claim as been submitted!</p>
        </Card.Body>
        <Button variant="warning" type="button" className="custom-button-gold" onClick={() => setEmailStatus(false)}>Send Another</Button>
  </Card>
  )
}

export default EmailSucessCard