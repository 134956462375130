import React from 'react';
import VBC_FiltersBar from './VBC_FiltersBar';
import KPIGrid from './KPIGrid';
import VBC_SalesBrowserParent from './VBC_SalesBrowserParent';
import VBC_TopMaterials from './VBC_TopMaterials';
import VBC_CreditAnalysis from './VBC_CreditAnalysis';
import ArrayDocumentor from '../../../Reusables/ArrayDocumentor/ArrayDocumentor';

function VBC_MainDisplayArea({
    startDate,
    endDate,
    currency,  // Pass currency down
    dataType,
    handleStartDateChange,
    handleEndDateChange,
    handleCurrencyChange, // Ensure currency change handler is passed
    handleDataTypeChange,
    kpis,
    filteredData,
    chartData,
    setSelectedKPI,
    selectedKPI,
    displayMode,
    handleDisplayModeChange,
    localCurrency,
}) {
    return (
        <div className='-VBC-Main-Display-Area-'>
            <div className='VBC-Main-Display-Area-Sub-Box'>
                <VBC_FiltersBar 
                    startDate={startDate}
                    endDate={endDate}
                    currency={currency}  // Ensure currency prop is passed
                    dataType={dataType}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    handleCurrencyChange={handleCurrencyChange}  // Pass currency handler
                    handleDataTypeChange={handleDataTypeChange}
                    displayMode={displayMode}
                    handleDisplayModeChange={handleDisplayModeChange} 
                />
                {displayMode === 'KPIGrid' &&                 
                    <KPIGrid 
                        kpis={kpis}
                        salesData={filteredData}
                        dataType={dataType}
                        chartData={chartData}
                        setSelectedKPI={setSelectedKPI}
                        selectedKPI={selectedKPI}
                        localCurrency={localCurrency}
                        currency={currency}  // Ensure currency prop is passed
                    />
                }
                {displayMode === 'SalesBrowser' && 
                    <VBC_SalesBrowserParent 
                        filteredData={filteredData}
                        dataType={dataType}
                        localCurrency={localCurrency}
                        currency={currency}  // Ensure currency prop is passed
                    />
                }
                {displayMode === 'TopMaterials' && 
                    <VBC_TopMaterials 
                        data={filteredData}
                        dataType={dataType}
                        localCurrency={localCurrency}
                        currency={currency}  // Ensure currency prop is passed
                    />
                }
                {displayMode === "CreditAnalysis" &&
                    <VBC_CreditAnalysis
                        filteredData={filteredData}
                        dataType={dataType}
                        localCurrency={localCurrency}
                        currency={currency}  // Ensure currency prop is passed
                    />
                }
                {displayMode === "ArrayDocumentor" &&
                    <ArrayDocumentor 
                        data={filteredData}
                        title={"Credit Analysis FiltereData"}
                    />
                }
            </div>
        </div>
    );
}

export default VBC_MainDisplayArea;
