import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';

function CustomFilter({ customFilters = [], setCustomFilters, materialColumnName, materialDescriptionColumnName }) {
  const [filterType, setFilterType] = useState('startsWith');
  const [filterValue, setFilterValue] = useState('');
  const [filterColumn, setFilterColumn] = useState(materialColumnName); // Default to materialColumnName

  console.log(materialColumnName, materialDescriptionColumnName);

  const handleAddFilter = () => {
    console.log(filterType, filterValue, filterColumn);
    setCustomFilters((prevFilters) => [...prevFilters, { type: filterType, value: filterValue, column: filterColumn }]);
    setFilterValue(''); // Clear after adding
  };

  useEffect(() => {
    if (materialColumnName) {
      setFilterColumn(materialColumnName);
    }
  }, [materialColumnName]);

  const handleRemoveFilter = (index) => {
    setCustomFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: '0.85rem', paddingBottom: '2px' }}>Filter Type</Form.Label>
            <Form.Select value={filterType} onChange={(e) => setFilterType(e.target.value)} style={{ padding: '2px' }}>
              <option value="startsWith">Starts With</option>
              <option value="endsWith">Ends With</option>
              <option value="contains">Contains</option>
              <option value="doesNotContain">Does Not Contain</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: '0.85rem', paddingBottom: '2px' }}>Filter Value</Form.Label>
            <Form.Control
              type="text"
              placeholder="Filter value"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              style={{ padding: '2px', height: "33px" }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontSize: '0.85rem', paddingBottom: '2px' }}>Column To Filter</Form.Label>
            <Form.Select
              value={filterColumn}
              onChange={(e) => {
                setFilterColumn(e.target.value);
                console.log("Selected Filter Column:", e.target.value);
              }}
              style={{ padding: '2px' }}
            >
              <option value={materialColumnName}>{materialColumnName}</option>
              <option value={materialDescriptionColumnName}>{materialDescriptionColumnName}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Button onClick={handleAddFilter} variant="primary" style={{ marginTop: '1.75rem' }}>
            Add Filter
          </Button>
        </Col>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Type</th>
              <th>Value</th>
              <th>Column</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {customFilters.map((filter, index) => (
              <tr key={index}>
                <td>{filter.type}</td>
                <td>{filter.value}</td>
                <td>{filter.column}</td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setCustomFilters((prev) =>
                      prev.filter((_, i) => i !== index))}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
}

export default CustomFilter;
