import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Accordion, Form, FormControl, FormCheck, Dropdown, Button, Table } from 'react-bootstrap';

function ExcelStockBrowser({ data, dataProperty1, dataProperty2, title, bodyTitle }) {
  const [filteredData, setFilteredData] = useState([]);
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filterWithE, setFilterWithE] = useState(false);
  const [selectedFinishes, setSelectedFinishes] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const applyFilters = useCallback(() => {
    return data.filter(
      (item) =>
        item['Description'].toLowerCase().includes(filter1.toLowerCase()) &&
        item[dataProperty2].toLowerCase().includes(filter2.toLowerCase()) &&
        (!filterWithE || (filterWithE && item['Material'].endsWith('E'))) &&
        (selectedFinishes.length === 0 || selectedFinishes.includes(item['Material'].substr(7, 2)))
    );
  }, [filter1, filter2, filterWithE, selectedFinishes, data, dataProperty2]);
  
  useEffect(() => {
    setFilteredData(applyFilters());
  }, [applyFilters]);

  const handleFilterColour = (e) => {
    setFilter1(e.target.value);
  };

  const handleFilter2 = (e) => {
    setFilter2(e.target.value);
  };

  const handleFilterWithE = (e) => {
    setFilterWithE(e.target.checked);
  };

  const handleFinishSelection = (selectedItems) => {
    setSelectedFinishes(selectedItems);
  };

  const handleClearFilters = () => {
    setFilter1('');
    setFilter2('');
    setFilterWithE(false);
    setSelectedFinishes([]);
  };

  const handleRowToggle = (rowIndex) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(rowIndex) ? prevRows.filter((row) => row !== rowIndex) : [...prevRows, rowIndex]
    );
  };

  const cardBodyStyle = {
    padding: '0.5rem',
    height: '75vh',
    fontSize: 'small',
    overflowY: 'scroll',
  };

  const tableStyle = {
    width: '90%',
    margin: "auto",
    marginBottom: '1rem',

  };

  return (
    <div className='INSIGHTSTOCKBROWSER'>
      <div className='center MarginTop'>
        <Card className='stock-checker-card'>
          <Card.Header className='dark-insight-card-header'>
            <Container className='Stock-Browser-Nav-Header'>
              <div className='flex-row' style={{width: "100%", marginLeft: "40px"}}>
                <Form.Label className='searchColourLabel'>Search Colour:</Form.Label>
                <Form.Control className='colour-search' type='text' placeholder='search' onChange={handleFilterColour} />
                <Form.Label className='searchColourLabel'>Search 2:</Form.Label>
                <Form.Control className='colour-search' type='text' placeholder='search' onChange={handleFilter2} />
                <FormCheck
                  type='checkbox'
                  id='filterWithE'
                  label="Filter with 'E'"
                  checked={filterWithE}
                  onChange={handleFilterWithE}
                  style={{ marginRight: '1vw' }}
                />
                <Dropdown className='multi-select-dropdown' onSelect={handleFinishSelection} title='Material Finishes'>
                  <Dropdown.Toggle variant='warning' id='dropdown-basic' className='custom-button-gold'>
                    Finishes
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey='KK'>Silk</Dropdown.Item>
                    <Dropdown.Item eventKey='SS'>Satin</Dropdown.Item>
                    <Dropdown.Item eventKey='DP'>Decor Polished</Dropdown.Item>
                    <Dropdown.Item eventKey='US'>Ultrasoft</Dropdown.Item>
                    <Dropdown.Item eventKey='NP'>Nano Polished</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button variant='warning' onClick={handleClearFilters} className='custom-button-gold' style={{ marginLeft: '5px' }}>
                  Clear
                </Button>
              </div>
            </Container>
          </Card.Header>
          <Card.Body className='dark-insight-card-body -excelstock-browser-card' style={cardBodyStyle}>
            <Card.Text style={{ fontSize: 'small' }}>{bodyTitle}</Card.Text>
            <Table striped bordered responsive hover variant='dark' style={tableStyle}>
              <thead>
                <tr>
                  <th className='col-70'>Material / Description</th>
                  <th className='col-30'>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleRowToggle(index)}>
                      <td className='col-70'>{item.Material + ' - ' + item.Description}</td>
                      <td className='col-30'>{item.Quantity}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr>
                        <td colSpan='2'>
                          <Table striped bordered responsive hover variant='dark'>
                            <thead>
                              <tr>
                                <th className='col-70'>Batch</th>
                                <th className='col-30'>Slabs</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.Batches.map((batch, batchIndex) => (
                                <React.Fragment key={batchIndex}>
                                  <tr onClick={() => handleRowToggle(index + batchIndex + 0.5)}>
                                    <td className='col-70'>{batch.Batch}</td>
                                    <td className='col-30'>{batch.Quantity}</td>
                                  </tr>
                                  {expandedRows.includes(index + batchIndex + 0.5) && (
                                    <tr>
                                      <td colSpan='2'>
                                        <Table striped bordered responsive hover variant='dark'>
                                          <thead>
                                            <tr>
                                              <th>Serial Number</th>
                                              <th>Tone</th>
                                              <th>Length</th>
                                              <th>Width</th>
                                              <th>Area</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {batch.SerialNumbers.map((serial, serialIndex) => (
                                              <tr key={serialIndex}>
                                                <td>{serial.SerialNumber}</td>
                                                <td>{serial.Tono}</td>
                                                <td>{serial.LargoCommercial}</td>
                                                <td>{serial.AnchoCommercial}</td>
                                                <td>{serial.AreaComercial}</td>

                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ExcelStockBrowser;
