import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function PlanItViewFunctionDropdown({ handleSelectViewClick, navBarBrandTitle, navDropDownIdAndTitle, textArray }) {
  if (!Array.isArray(textArray) || textArray.length === 0) {
    return <div>Something went wrong. Text options are not recognized correctly.</div>;
  }

  return (
    <>
      <Navbar.Brand>{navBarBrandTitle}</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-dark-example" />
      <Navbar.Collapse id="navbar-dark-example">
        <Nav>
          <NavDropdown
            style={{ fontSize: "medium" }}
            id={navDropDownIdAndTitle}
            title={navDropDownIdAndTitle}
            menuVariant="dark"
          >
            {textArray.map((item, index) => (
              <NavDropdown.Item key={index} onClick={() => handleSelectViewClick(item)}>
                {item}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </>
  );
}

export default PlanItViewFunctionDropdown;
