import React from 'react';
import { Button } from 'react-bootstrap';

function CUBOAnalyserDemandSubsidiarySelectionBox({ dataStockSubsid, setSelectedCenter, centersIncludingTransitDays, selectedCenter }) {
  
    const handleSetCenter = (event, header) => {
        console.log(header);
        setSelectedCenter(header);
    };

    if (dataStockSubsid[0] && dataStockSubsid.length > 0 && centersIncludingTransitDays.length > 0) {
        return (
            <div className='CUBO-analyser-demand-subsidiary-selection-box-main'>
                <div className='subsidiary-button-holder-main'>
                    <span style={{fontSize: "small"}}>Subsidiary Selection</span>
                    <div className='subsidiary-button-holder-sub'>
                        {centersIncludingTransitDays.map((center, index) => {
                            const isSelected = center.Number === selectedCenter;
                            const buttonVariant = isSelected ? 'primary' : 'success'; // Change 'primary' to your desired variant or style

                            return (
                                <div key={index} className='CUBO-analyser-demand-subsidiary-selection-box-item'>
                                    <Button 
                                        variant={buttonVariant} 
                                        style={{flexGrow: "1", margin: "1px", fontSize: "small"}} 
                                        size='sm' 
                                        onClick={(e) => handleSetCenter(e, center.Number)}
                                    >
                                        {`${center.Number} - ${center.Name}`}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='CUBO-analyser-demand-subsidiary-selection-box-main'>
                <span>No data available</span>
            </div>
        );
    }
}

export default CUBOAnalyserDemandSubsidiarySelectionBox;
