export const ReturnLangaugeSelection = (rawData, searchValue, expectedLang) => {
    const acceptedLangs = ['ENG', 'ESP'];
    //Test Error and stop if selected language isn't valid
    if (!acceptedLangs.includes(expectedLang)) {
        alert('Invalid expectedLang value. Please use "ENG" or "ESP"');
        return;
    }
    const headerRowKeys = Object.keys(rawData[0]);
    const langTest = headerRowKeys.filter((key) => key.includes(searchValue));
    if (langTest) {
        return expectedLang;
    }
    else {
        return console.log('Language is not Found, please try again');
    }
}

export function convertExcelDateToUKDateString(excelDate) {
    // Excel's date format starts from 1st January 1900
    const startDate = new Date('1900-01-01');
    // Calculate the number of milliseconds to add
    const millisecondsToAdd = (excelDate - 1) * 24 * 60 * 60 * 1000;
    // Add milliseconds to start date
    const targetDate = new Date(startDate.getTime() + millisecondsToAdd);
    // Get day, month, and year
    const day = targetDate.getDate();
    const month = targetDate.getMonth() + 1;
    const year = targetDate.getFullYear();
    // Format the date in DD/MM/YYYY format
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
}