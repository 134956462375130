import codexFlushed from './CodexFlushed.json';

// Helper function to test if value is a number and convert if necessary
const testNumberandConvertIfSuccessful = (value) => {
    return typeof value === "string" && !isNaN(value) ? parseFloat(value) : value;
};

// Helper function to calculate total quantity from specified fields
const calculateTotalQuantity = (materialCode, data, quantityFieldNames) => {
    return data.reduce((total, item) => {
        if (item["Material"] === materialCode) {
            const quantity = quantityFieldNames.map(field => parseFloat(item[field])).find(qty => !isNaN(qty)) || 0;
            return total + quantity;
        }
        return total;
    }, 0);
};

const calculateTotalQtyStockedByPlantHelper = (materialCode, plant, stockData) => {
    return stockData.reduce((total, item) => {
        if (item["Material"] === materialCode && item[parseInt(plant)]) {
            let numberPlant = parseInt(plant);
            let _quantity = item[numberPlant];
            let quantityParsed = parseFloat(_quantity);
            const quantity = isNaN(quantityParsed) ? 0 : quantityParsed;
            return total + quantity;
        }
        return total;
    }, 0);
};

// Reusable functions for specific quantity calculations
const calculateTotalQtySold = (materialCode, invoiceData) => calculateTotalQuantity(materialCode, invoiceData, ["Billed Quantity", "Cantidad Facturada"]);
const calculateTotalQtySoldByPlant = (materialCode, plant, invoiceData) => calculateTotalQuantityByPlant(materialCode, plant, invoiceData, ["Billed Quantity", "Cantidad Facturada"]);
const calculateTotalQtyOrdered = (materialCode, orderData) => calculateTotalQuantity(materialCode, orderData, ["Order Quantity", "Cantidad Pedido"]);
const calculateTotalQtyOrderedByPlant = (materialCode, plant, orderData) => calculateTotalQuantityByPlant(materialCode, plant, orderData, ["Order Quantity", "Cantidad Pedido"]);
const calculateTotalQtyStocked = (materialCode, stockData) => calculateTotalQuantity(materialCode, stockData, ["Stock Quantity", "Cantidad Stock"]);
const calculateTotalQtyStockedByPlant = (materialCode, plant, stockData) => calculateTotalQtyStockedByPlantHelper(materialCode, plant, stockData, ["Stock Quantity", "Cantidad Stock"]);
const calculateTotalQtyPurchasedOrdered = (materialCode, purchaseData) => calculateTotalQuantity(materialCode, purchaseData, ["Quantity Purchased", "Cantidad Compra"]);
const calculateTotalQtyPurchasedOrderedByPlant = (materialCode, plant, purchaseData) => calculateTotalQuantityByPlant(materialCode, plant, purchaseData, ["Quantity Purchased", "Cantidad Compra"]);

// Helper function to calculate total quantity by plant
const calculateTotalQuantityByPlant = (materialCode, plant, data, quantityFieldNames) => {
    return data.reduce((total, item) => {
        if (item["Material"] === materialCode && (item["Center"] === plant || item["Centro"] === plant)) {
            const quantity = quantityFieldNames.map(field => parseFloat(item[field])).find(qty => !isNaN(qty)) || 0;
            return total + quantity;
        }
        return total;
    }, 0);
};

// Helper function to extract material details from codex or other sources
const extractMaterialDetails = (materialCode, data, fallbackData) => {
    const materialData = data.find(item => item["Material"] === materialCode) || fallbackData;
    return {
        DecorName: materialData["Decor"] || fallbackData.DecorName,
        FinishDescription: materialData["Finish Description"] || fallbackData.FinishDescription,
        SizeDescription: materialData["Size Description"] || fallbackData.SizeDescription,
        MaterialDescription: materialData["Material Description"] || fallbackData.MaterialDescription,
        Size: materialData["Size"] || fallbackData.Size
    };
};

export const CUBOAnalyseDRPData = async (PurchaseOrders, SalesOrders, Invoices, StockHQ, StockSubsid, daysBuffer, includeSlabs, includeSamples, includeTiles, includeOthers, centersIncludingTransitDays) => {
    const CUBODRPData = [];
    
    const [stockSubsidData, stockHQData, purchaseOrdersData, salesOrdersData, invoicesData] = [StockSubsid[0], StockHQ[0], PurchaseOrders[0], SalesOrders[0], Invoices[0]];
    const codexData = codexFlushed;

    if (stockSubsidData.length === 0 || stockHQData.length === 0 || purchaseOrdersData.length === 0 || salesOrdersData.length === 0 || invoicesData.length === 0) {
        console.log("Datas are empty, will not proceed with CUBO Analyser DRP");
        return;
    }

    if (!daysBuffer) {
        daysBuffer = 150;
    }

    // Create a unique list of material codes
    const uniqueListOfMaterials = new Set([
        ...stockSubsidData.map(item => item["Material"]),
        ...stockHQData.map(item => item["Material"]),
        ...purchaseOrdersData.map(item => item["Material"]),
        ...salesOrdersData.map(item => item["Material"]),
        ...invoicesData.map(item => item["Material"]),
        ...codexData.map(item => item["Material"])
    ]);

    // Filter materials based on selected categories
    const filteredList = [...uniqueListOfMaterials].filter(material => {
        if (typeof material === 'string') {
            const startsWithTB = material.startsWith("TB");
            const startsWithMU = material.startsWith("MU");

            return (includeSlabs && startsWithTB) ||
                   (includeSamples && startsWithMU) ||
                   (includeTiles && !startsWithTB && !startsWithMU);
        }
        return includeOthers && typeof material === 'number';
    });

    for (const materialCode of filteredList) {
        const materialDetails = extractMaterialDetails(materialCode, codexData, { DecorName: "n/a", FinishDescription: "n/a", SizeDescription: "n/a", MaterialDescription: "n/a", Size: "n/a" });

        // Calculate quantities
        const stockHQ = calculateTotalQtyStocked(materialCode, stockHQData);
        const purchaseOrdered = calculateTotalQtyPurchasedOrdered(materialCode, purchaseOrdersData);
        const salesOrdered = calculateTotalQtyOrdered(materialCode, salesOrdersData);
        const invoiced = calculateTotalQtySold(materialCode, invoicesData);

        let stockAvailability = stockHQ - (salesOrdered + purchaseOrdered);
        let dailyRate = invoiced / 365;
        let monthlyRate = invoiced / 12;
        let SafetyStock = (dailyRate * daysBuffer);
        let needToProduce = 0;

        if (salesOrdered > 0 || purchaseOrdered > 0) {
            if (stockHQ < (salesOrdered + purchaseOrdered + SafetyStock)) {
                needToProduce = (salesOrdered + purchaseOrdered + SafetyStock) - stockHQ;
            }
        }

        const subdiaryDRPData = []; 
        centersIncludingTransitDays.forEach(center => {
            let subsidStock = calculateTotalQtyStockedByPlant(materialCode, center.Number, stockSubsidData);
            let subsidPOs = calculateTotalQtyPurchasedOrderedByPlant(materialCode, center.Number, purchaseOrdersData);
            let subsidSalesOrders = calculateTotalQtyOrderedByPlant(materialCode, center.Number, salesOrdersData);
            let subsidInvoiced = calculateTotalQtySoldByPlant(materialCode, center.Number, invoicesData);
            let subsidDailyRate = subsidInvoiced / 365;
            let subsidMonthlyRate = subsidInvoiced / 12;
            let subsidSafetyStock = 0;
            let needToOrder = 0;
            let ATPSubsid = subsidStock - subsidSalesOrders
            let _transitDays = center.transitDays || 0;
                
            if (subsidDailyRate > 0) {
                let bufferedAmountAccordingtoTransit = subsidDailyRate * _transitDays;
                let bufferedAmountAccordingToInvoice = subsidDailyRate * daysBuffer;
                subsidSafetyStock = bufferedAmountAccordingtoTransit + bufferedAmountAccordingToInvoice;
                needToOrder = subsidSafetyStock - ATPSubsid;
            };
            
            const subdiaryDRPObject = {
                Center: center.Number,
                StockSubsid: subsidStock,
                PurchaseOrderedSubsid: subsidPOs,
                SalesOrderedSubsid: subsidSalesOrders,
                InvoicedSubsid: subsidInvoiced,
                TransitDays: center.transitDays,
                ATPSubsid: ATPSubsid,
                DailyRateSubsid: testNumberandConvertIfSuccessful(subsidDailyRate.toFixed(2)),
                MonthlyRateSubsid: testNumberandConvertIfSuccessful(subsidMonthlyRate.toFixed(2)),
                SafetyStockSubsid: testNumberandConvertIfSuccessful(subsidSafetyStock.toFixed(2)),
                NeedToOrderSubsid: testNumberandConvertIfSuccessful(needToOrder.toFixed(0))
            };
            subdiaryDRPData.push(subdiaryDRPObject);
        });

        const accuracy = Math.min(
            purchaseOrdered ? salesOrdered / purchaseOrdered : 1,
            salesOrdered ? purchaseOrdered / salesOrdered : 1
        ) * 100;

        const DRPObject = {
            Material: materialCode,
            Decor: materialCode.substring(2, 7) || "n/a",
            DecorName: materialDetails.DecorName,
            MaterialDescription: materialDetails.MaterialDescription,
            Thickness: materialCode.substring(9, 11) || "n/a",
            Format: materialCode.substring(12, 14) || "n/a",
            Finish: materialCode.substring(7, 9) || "n/a",
            Mesh: materialCode.substring(11, 12) || "n/a",
            StockHQ: stockHQ,
            PurchaseOrdered: purchaseOrdered,
            SalesOrdered: salesOrdered,
            Invoiced: invoiced,
            StockAvailability: stockAvailability,
            DailyRate: testNumberandConvertIfSuccessful(dailyRate.toFixed(2)),
            MonthlyRate: testNumberandConvertIfSuccessful(monthlyRate.toFixed(2)),
            SafetyStock: testNumberandConvertIfSuccessful(SafetyStock.toFixed(2)),
            NeedToProduce: testNumberandConvertIfSuccessful(needToProduce.toFixed(0)),
            POToSaleAccuracy: testNumberandConvertIfSuccessful(accuracy.toFixed(2)),
            SubsidiaryDRP: subdiaryDRPData
        };

        CUBODRPData.push(DRPObject);
    }

    return CUBODRPData;
}
