const mainAPIEndpoint = 'https://localhost:3001';
const apiEndpointDRP = 'https://localhost:3001/DRP';
const apiEndpointSlabCodex = 'https://localhost:3001/SlabCodex';
const apiEndpointSlabCodexUniqueColours = 'https://localhost:3001/SlabCodex/Unique-Colours';
const HttpApiEndpointSlabCodexUniqueColours = 'http://localhost:3002/SlabCodex/Unique-Colours';
const apiEndpointDIOData = 'https://localhost:3001/DIO';
const HttpApiEndpointDIOData = 'http://localhost:3002/DIO';
const apiEndpointME21NData = 'https://localhost:3001/ME21N';
const HttpApiEndpointME21NData = 'http://localhost:3002/ME21N';
const apiEndpointVA05Data = 'https://localhost:3001/VA05';
const HttpApiEndpointVA05Data = 'http://localhost:3002/VA05';
const apiEndpointCUBOData = 'https://localhost:3001/CUBO';
const HttpApiEndpointCUBOData = 'http://localhost:3002/CUBO';
const apiEndpointIQ09Data = 'https://localhost:3001/IQ09';
const HttpApiEndpointIQ09Data = 'http://localhost:3002/IQ09';

// Check if the user is authenticated and has a token
const APIGetDRPRequest = async (token) => {
        fetch(apiEndpointDRP, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the bearer token in the headers
            
          },
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log('Failed to fetch data');
                throw new Error('Failed to fetch data');
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
};


const GetUniqueColourList = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointSlabCodexUniqueColours, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};


const GetDIOData = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointDIOData, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};

const GetME21NData = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointME21NData, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};

const GetVA05Data = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointVA05Data, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};

const GetCUBOData = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointCUBOData, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};


const GetIQ09Data = async (token) => {
  try {
    const response = await fetch(HttpApiEndpointIQ09Data, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
        "mode": "cors",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { response: data, error: null };
    } else {
      console.log('Failed to fetch data');
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error(error);
    return { response: null, error };
  }
};





module.exports = { APIGetDRPRequest, GetUniqueColourList, GetDIOData, GetME21NData, GetVA05Data, GetCUBOData, GetIQ09Data };