import React, { useState, useEffect } from 'react';
import VentasAnalyserConfig from './VentasAnalyserConfig';
import { GetVentasLocalData } from '../../../Utils/VentasAnalyserOps';
import Loading from '../../Reusables/Loading';
import VentasNavBar from './VentasNavBar';
import './_VentasStyles/VentasCards.css';
import SalesByMaterialParent from './VentasByMaterial/SalesByMaterialParent';
import VentasByClientParent from './VentasByClient/VentasByClientParent';
import VentasTurnoverDashMechanics from './VentasTurnoverDash/VentasTurnoverDashMechanics';
import ReactGA from 'react-ga4';

function VentasAnalyserCycler() {
    const [ventasData, setVentasData] = useState([]);
    const [dataJustStoredOrRemoved, setDataJustStoredOrRemoved] = useState(false);
    const [currentViewedComponent, setCurrentViewedComponent] = useState("VentasAnalyserConfig");

    // Fetch data on mount and if dataJustStoredOrRemoved changes
    useEffect(() => {
        const fetchData = async () => {
            setCurrentViewedComponent("Loading");
            const data = await GetVentasLocalData();
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    // Update ventasData only if it is currently empty
                    if (ventasData.length === 0) {
                        setVentasData(data);
                    }
                    setCurrentViewedComponent("VentasDash");
                } else {
                    setCurrentViewedComponent("VentasAnalyserConfig");
                    console.log("No data found in Dexie from VentasAnalyserData Dexie Collection");
                }
            }
            else {
                setCurrentViewedComponent("VentasAnalyserConfig");
                console.log("No data found in Dexie from VentasAnalyserData Dexie Collection");
            }
        };

        fetchData();
    }, [dataJustStoredOrRemoved]);

    useEffect(() => {
        // Track component views based on the currentViewedComponent
        ReactGA.event({
            category: 'Navigation',
            action: `Viewed ${currentViewedComponent}`,
            label: `User viewed ${currentViewedComponent} component`
        });
    }, [currentViewedComponent]);

    return (
        <div className='-VENTAS-Cycler'>
            <VentasNavBar setVentasData={setVentasData} setCurrentViewedComponent={setCurrentViewedComponent} />
            {currentViewedComponent === "VentasAnalyserConfig" && 
                <VentasAnalyserConfig 
                    ventasData={ventasData} 
                    setVentasData={setVentasData} 
                    setDataJustStoredOrRemoved={setDataJustStoredOrRemoved}
                />
            }
            {currentViewedComponent === "Loading" && <Loading />}
            {currentViewedComponent === "VentasDash" && 
                <VentasTurnoverDashMechanics 
                    ventasData={ventasData} 
                    setVentasData={setVentasData} 
                    setCurrentViewedComponent={setCurrentViewedComponent}
                />
            }
            {currentViewedComponent === "VentasSalesByMaterial" && 
                <SalesByMaterialParent 
                    ventasData={ventasData} 
                    setVentasData={setVentasData} 
                    setCurrentViewedComponent={setCurrentViewedComponent}
                />
            }
            {currentViewedComponent === "VentasByClient" && 
                <VentasByClientParent 
                    ventasData={ventasData} 
                />
            }
        </div>
    );
}

export default VentasAnalyserCycler;
