import React from 'react';
import { Form, Button } from 'react-bootstrap';

const SlabsBox = ({ slabs, handleSlabChange, handleFileChange, filesUploaded, addSlab }) => {
  return (
    <div className='form-row-slabs-box' style={{ display: 'flex', flexDirection: 'column' }}>
      Add Slabs
      {slabs.map((slab, index) => (
        <div key={index} className='form-row-slabs' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Form.Group controlId={`formMaterialCode${index}`} className='formGroup'>
              <Form.Label>Material Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Material Code"
                value={slab.materialCode}
                onChange={(e) => handleSlabChange(index, 'materialCode', e.target.value)}
              />
            </Form.Group>
          </div>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Form.Group controlId={`formSerialNumber${index}`} className='formGroup'>
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Serial Number"
                value={slab.serialNumber}
                onChange={(e) => handleSlabChange(index, 'serialNumber', e.target.value)}
              />
            </Form.Group>
          </div>
          <div style={{ flex: 1, marginRight: '10px'  }}>
            <Form.Group controlId={`formReason${index}`} className='formGroup'>
              <Form.Label>Reason</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select Reason"
                value={slab.reason || "broken-during-cutting"}
                onChange={(e) => handleSlabChange(index, 'reason', e.target.value)}
              >
                {/* Add options for the select input */}
                <option value="broken-during-cutting">Broken During Cutting</option>
                <option value="broken-during-transport">Broken During Transport</option>
                <option value="improper-installation">Improper Installation</option>
                <option value="material-fatigue">Material Fatigue</option>
                <option value="other-defect">Other Defect</option>
              </Form.Control>
            </Form.Group>
          </div>
          {/* Add file upload indicators */}
          <Form.Group controlId={`formFiles${index}`} className="formGroup files-box" style={{ flex: 1 }}>
            <Form.Label>Upload Files</Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={(e) => handleFileChange(e, index)}
            />
            {filesUploaded[index] && <span className="tick-mark">✓</span>}
          </Form.Group>
        </div>
      ))}
      <Button variant="warning" type="button" className="custom-button-gold"  style={{width: "150px", margin: "auto", marginTop: "10px"}} onClick={addSlab}>
        Add Slab
      </Button>
    </div>
  );
};

export default SlabsBox;
