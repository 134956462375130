import React, { useEffect, useState } from 'react';

const ProtectedRoute = ({ AppProps, DRPProps, DataProps, ExcelProps, debtPortalProps, element }) => {
  const {
    isAuthed,
    permissions,
  } = AppProps;

  const [permissionVerified, setPermissionVerified] = useState(false);

  useEffect(() => {
    const hasRequiredPermissions = permissions.includes('read:staff');
    setPermissionVerified(isAuthed && hasRequiredPermissions);
  }, [permissions, isAuthed]);

  if (!permissionVerified) {
    console.log('permissions not verified');
    return <>Permissions could not be verified. If you think you should be able to access this page and you're already logged in, please contact an Admin.</>;
  }
  if (permissionVerified) {
    return { ...element, props: { ...element.props, AppProps, DRPProps, DataProps, ExcelProps, debtPortalProps } };
  }
};

export default ProtectedRoute;
