import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavBtnColours from './NavBtnColours';
import Button from 'react-bootstrap/Button';
import '../../../Styles/NavBarStyles.css';
import NavBtnFinish from './NavBtnFinish';
import NavBtnThickness from './NavBtnThickness';
import NavBtnTools from '../NavBarCpts/NavBtnTools';


//<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>


function NavBarStock({data, handleFilterColourChange, handleFilterFinishChange, handleFilterThicknessChange, applyFilters, ResetFilters, filter, AppProps, DataProps}) {
  return (
    <Navbar variant="dark" bg="dark" expand="lg" className='navbar-parent'>
      <Container fluid>
        <Navbar.Brand href="#home">Stock Filters:</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
          <Nav>
            <NavDropdown
              id="colours"
              title="Colours"
              menuVariant="dark"
            >
              <NavBtnColours data={data} handleFilterColourChange={handleFilterColourChange} applyFilters={applyFilters} /> 
            </NavDropdown>
            <NavDropdown
              id="finish"
              title="Finish"
              menuVariant="dark"
            >
              <NavBtnFinish data={data} handleFilterFinishChange={handleFilterFinishChange} applyFilters={applyFilters} />
            </NavDropdown>
            <NavDropdown
              id="thickness"
              title="Thickness"
              menuVariant="dark"
            >
              <NavBtnThickness data={data} handleFilterThicknessChange={handleFilterThicknessChange} applyFilters={applyFilters} />
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Button variant="warning" className="custom-button-gold clearFilter" onClick={ResetFilters}>Clear Filters</Button>
        <Button variant="warning" className="custom-button-gold applyFilter" onClick={applyFilters}>Apply Filters</Button>
        <NavBtnTools AppProps={AppProps} DataProps={DataProps} />
      </Container>
      <Container fluid>
        <span style={{fontSize: "small"}}>current filters - Colour: {filter.Colour.toLowerCase()} / Finish: {filter.Finish.toLowerCase()} / Thickness: {filter.Thickness} </span>
      </Container>
    </Navbar>
  );
}

export default NavBarStock;