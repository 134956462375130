import '../CUBOAnalyserDemand/CUBOAnalyserDemand.css';
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import FileInputContainer from '../../../Reusables/FileInputContainer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import listOfCenters from '../../../../Utils/ListOfCenters.json';
import TransitDaysModal from './TransitDaysModal';

function CUBOAnalyserFileConfig({ CUBOAnalyserProps, intialDRPFilterProps }) {
  const { 
    setExcelCUBOComprasPath, setExcelCUBOVentasPath, setExcelCUBOOrdersPath, setExcelCUBOStockHQPath, setExcelCUBOStockSubsidPath, 
    handleAnalyserData, excelCUBOComprasPath, excelCUBOVentasPath, excelCUBOOrdersPath, excelCUBOStockSubsidPath, excelCUBOStockHQPath, 
    setCentersIncludingTransitDays, centersIncludingTransitDays, setDaysBuffer, daysBuffer
  } = CUBOAnalyserProps;
  
  const { includeSlabs, includeSamples, includeTiles, includeOthers, setIncludesSlabs, setIncludeSamples, setIncludeOthers, setIncludeTiles } = intialDRPFilterProps;

  const [showTransitDaysModal, setShowTransitDaysModal] = useState(false);

  useEffect(() => {
    setCentersIncludingTransitDays(listOfCenters);
  }, [setCentersIncludingTransitDays]);

  const handleAnalyserDataWithTransit = () => {
    handleAnalyserData(centersIncludingTransitDays);
  };
  
  const handleChangeDaysBuffer = (e) => {
    setDaysBuffer(e.target.value);
  };
  return (
    <Container className='data-browser-card'>
      <span>Upload the Excel data from each file</span>
      <FileInputContainer labelTitle={"Purchasing / Compras"} selectedFilePath={excelCUBOComprasPath} setSelectedFile={setExcelCUBOComprasPath} />
      <FileInputContainer labelTitle={"Ventas / Sales"} selectedFilePath={excelCUBOVentasPath} setSelectedFile={setExcelCUBOVentasPath} />
      <FileInputContainer labelTitle={"Orders / Pedidos"} selectedFilePath={excelCUBOOrdersPath} setSelectedFile={setExcelCUBOOrdersPath} />
      <FileInputContainer labelTitle={"Stock HQ"} selectedFilePath={excelCUBOStockHQPath} setSelectedFile={setExcelCUBOStockHQPath} />
      <FileInputContainer labelTitle={"Stock Subsid"} selectedFilePath={excelCUBOStockSubsidPath} setSelectedFile={setExcelCUBOStockSubsidPath} />
      <div className='check-holder-CUBO-config'>
        <Form.Check type='switch' label='Slabs' checked={includeSlabs} onChange={() => setIncludesSlabs(!includeSlabs)} /> 
        <Form.Check type='switch' label='Tiles & CTS' checked={includeTiles} onChange={() => setIncludeTiles(!includeTiles)} /> 
        <Form.Check type='switch' label='Samples' checked={includeSamples} onChange={() => setIncludeSamples(!includeSamples)} /> 
        <Form.Check type='switch' label='Others and Marketing' checked={includeOthers} onChange={() => setIncludeOthers(!includeOthers)} />
      </div>
      <Form.Group controlId="daysBuffer" style={{ margin: "auto", width: "20%", marginBottom: "15px"  }}>
        <Form.Label>Days buffer:</Form.Label>
        <Form.Control type="number" value={daysBuffer} onChange={handleChangeDaysBuffer}/>
      </Form.Group> 
      <div className='Transit-days-holder' style={{ marginBottom: "20px" }}>
        <Button variant='warning' className='custom-button-gold' onClick={() => setShowTransitDaysModal(true)}>Set Transit Days</Button>
      </div>
      <Button className='custom-button-gold' variant='warning' style={{ marginBottom: "10px" }} onClick={handleAnalyserDataWithTransit}>Go!</Button>
      <TransitDaysModal 
        show={showTransitDaysModal} 
        handleClose={() => setShowTransitDaysModal(false)} 
        centersIncludingTransitDays={centersIncludingTransitDays}
        setCentersIncludingTransitDays={setCentersIncludingTransitDays}
      />
    </Container>
  );
}

export default CUBOAnalyserFileConfig;
