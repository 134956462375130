// VBC_ClientSelectionBar.js
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

function VBC_ClientSelectionBar({ ventasData, setSelectedClient, selectedClient }) {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        // Unique set of Clients to render buttons
        const uniqueClients = [...new Set(ventasData.map(item => item['Client Description']))];
        uniqueClients.sort(); // Sort clients alphabetically

        // Safely set clients, if already there, don't duplicate:
        if (clients.length === 0) {
            setClients(uniqueClients);
        }
    }, [ventasData, clients]);

    return (
        <div className='VBC-Client-Selection-Bar'>
            <div className='client-selection-title-box'>
            <span style={{ fontSize: "small" }}>Client Selection Bar</span>
            <Button 
                    variant="danger"
                    className='VBC-Client-Clear-Button'
                    onClick={() => setSelectedClient('')}
                    style={{ fontWeight: 'bold', justifyContent: "space-between" }}
                    size='sm'
                >
                    Clear Client
            </Button>
            </div>
            <div className='VBC-Client-Selection-Bar-Button-Holder'>
                {clients.map((client, index) => (
                    <Button 
                        variant={selectedClient === client ? "primary" : "warning"} 
                        key={index} 
                        className={`VBC-Client-Selection-Button ${selectedClient === client ? 'selected' : ''}`}
                        onClick={() => setSelectedClient(client)}
                        size='sm'
                    >
                        <span className='client-selection-button-text'>{client}</span>
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default VBC_ClientSelectionBar;
