import React from 'react';
import { Form, NavDropdown, Nav } from 'react-bootstrap';

function SettingsDropdown({
  showAdditionalColumns,
  toggleColumns,
  ATPOnly,
  toggleATPOnly,
  ESlabsOnly,
  setESlabsOnly,
  optimalSizesOnly,
  handleSetOptimalSizesOnly,
  meshVisible,
  setMeshVisible,
  hideZeroLines,
  setHideZeroLines
}) {
  const preventDropdownClose = (e) => {
    // Stop the event from bubbling up, which prevents the dropdown from closing
    e.stopPropagation();
  };

  return (
    <Nav>
      <NavDropdown
        title="Settings"
        id="settings-nav-dropdown"
        className="settings-dropdown"
        style={{ fontSize: "medium", color: 'lightgrey', marginRight: "10px" }}
        menuVariant='dark'
      >
        <NavDropdown.Item as="div" onClick={preventDropdownClose}>
          <Form.Check
            type="switch"
            label="ATP Only"
            style={{ fontSize: "small" }}
            checked={ATPOnly}
            onChange={toggleATPOnly}
          />
        </NavDropdown.Item>
        <NavDropdown.Item as="div" onClick={preventDropdownClose}>
          <Form.Check
            type="switch"
            label="E Slabs Only"
            style={{ fontSize: "small" }}
            checked={ESlabsOnly}
            onChange={() => setESlabsOnly(!ESlabsOnly)}
          />
        </NavDropdown.Item>
        <NavDropdown.Item as="div" onClick={preventDropdownClose}>
          <Form.Check
            type="switch"
            label="Optimal Size Only"
            style={{ fontSize: "small" }}
            checked={optimalSizesOnly}
            onChange={handleSetOptimalSizesOnly}
          />
        </NavDropdown.Item>
        <NavDropdown.Item as="div" onClick={preventDropdownClose}>
          <Form.Check
            type="switch"
            label="Mesh Visible"
            style={{ fontSize: "small" }}
            checked={meshVisible}
            onChange={() => setMeshVisible(!meshVisible)}
          />
        </NavDropdown.Item>
        <NavDropdown.Item as="div" onClick={preventDropdownClose}>
          <Form.Check
            type="switch"
            label="No Zero Lines"
            style={{ fontSize: "small" }}
            checked={hideZeroLines}
            onChange={() => setHideZeroLines(!hideZeroLines)}
          />
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}

export default SettingsDropdown;
