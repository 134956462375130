import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Button, ButtonGroup } from 'react-bootstrap';

// DataKey values for the bars
const DATA_KEYS = {
    NET_BILLED: 'Net Billed Amount',
    INVOICED: 'Invoiced Amount',
    CREDITED: 'Credited Amount',
};

// Currency data keys
const CURRENCY_KEYS = {
    GBP: {
        netBilled: 'Net Billed Amount Local',
        invoiced: 'Invoiced Amount Local',
        credited: 'Credited Amount Local',
    },
    EUR: {
        netBilled: 'Net Billed Amount EUR',
        invoiced: 'Invoiced Amount EUR',
        credited: 'Credited Amount EUR',
    }
};

const SalesByMaterialChart2 = ({ topMaterials }) => {
    // State to manage visibility of bars
    const [visibleBars, setVisibleBars] = useState({
        [DATA_KEYS.NET_BILLED]: true,
        [DATA_KEYS.INVOICED]: true,
        [DATA_KEYS.CREDITED]: true,
    });

    // State to manage currency
    const [currency, setCurrency] = useState('GBP');

    // Function to handle the toggle
    const handleToggle = (key) => {
        setVisibleBars(prevState => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // Function to handle currency change
    const handleCurrencyChange = (newCurrency) => {
        setCurrency(newCurrency);
    };

    // Prepare chart data based on currency
    const currencyKeys = CURRENCY_KEYS[currency];
    const data = topMaterials.map(item => ({
        ...item,
        [DATA_KEYS.NET_BILLED]: item[currencyKeys.netBilled],
        [DATA_KEYS.INVOICED]: item[currencyKeys.invoiced],
        [DATA_KEYS.CREDITED]: item[currencyKeys.credited],
    }));

    return (
        <div>
            <ButtonGroup className="mb-3">
                {Object.keys(DATA_KEYS).map(key => (
                    <Button
                        key={key}
                        variant={visibleBars[DATA_KEYS[key]] ? 'warning' : 'outline-warning'}
                        onClick={() => handleToggle(DATA_KEYS[key])}
                    >
                        {DATA_KEYS[key]}
                    </Button>
                ))}
            </ButtonGroup>

            <ButtonGroup className="mb-3">
                <Button
                    variant={currency === 'GBP' ? 'warning' : 'outline-warning'}
                    onClick={() => handleCurrencyChange('GBP')}
                >
                    GBP
                </Button>
                <Button
                    variant={currency === 'EUR' ? 'warning' : 'outline-warning'}
                    onClick={() => handleCurrencyChange('EUR')}
                >
                    EUR
                </Button>
            </ButtonGroup>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Material Description"  />
                    <YAxis />
                    <Tooltip />
                    {visibleBars[DATA_KEYS.NET_BILLED] && (
                        <Bar dataKey={DATA_KEYS.NET_BILLED} fill="#ffc107" />
                    )}
                    {visibleBars[DATA_KEYS.INVOICED] && (
                        <Bar dataKey={DATA_KEYS.INVOICED} fill="#8884d8" />
                    )}
                    {visibleBars[DATA_KEYS.CREDITED] && (
                        <Bar dataKey={DATA_KEYS.CREDITED} fill="#82ca9d" />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SalesByMaterialChart2;
