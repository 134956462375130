import React, {useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'

function TurnOverBar({turnOverData}) {
    const [turnOverDataValid, setTurnOverDataValid] = useState(false);

    const formatCurrencyLocal = (value) => {
        return value.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2,
        });
      };
    
      const formatCurrencyEuro = (value) => {
        return value.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
        });
      };
    

    useEffect(() => {
        if (turnOverData !== null) {
            setTurnOverDataValid(true);
            console.log("turnOverData: ", turnOverData);
        }
    }, [turnOverData]);



  return (
    <Card className='dark-card turnover-main-card'>
        {turnOverDataValid &&
            <Card.Body className='turnover-body'>
                <FontAwesomeIcon icon={faMoneyBillTrendUp} style={{ marginRight: '10px' }} />
                <span style={{fontWeight: 600}}>Turnover: {}</span>
                <Container className='turnover-container-left'>
                    <span className='font-size-medium'>Local:</span>
                    <span className='font-size-medium'>{formatCurrencyLocal(turnOverData.totalTurnoverLocal)}</span>
                </Container>
                <hr className='line-between-2'></hr>
                <Container className='turnover-container'>
                    <span className='font-size-medium'>Euro:</span>
                    <span className='font-size-medium'>{formatCurrencyEuro(turnOverData.totalTurnoverEuro)}</span>
                </Container>
            </Card.Body>
        }
    </Card>
  )
}

export default TurnOverBar