import React, { useState } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from 'recharts';
import { Table, FormGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

function StockDistributionWidget({ data }) {
  const [category, setCategory] = useState('Thickness');
  const [selectedLegend, setSelectedLegend] = useState(null);

  // Aggregate data based on the selected category
  const aggregatedData = data.reduce((acc, item) => {
    const key = item[category];
    if (!acc[key]) acc[key] = 0;
    acc[key] += item.MaterialInStock;
    return acc;
  }, {});

  // Function to generate colors using the golden angle
  function getColor(index) {
    const goldenAngle = 137.508; // Golden angle in degrees
    const hue = (index * goldenAngle) % 360;
    return `hsl(${hue},70%,50%)`;
  }

  // Convert aggregated data into an array and sort it
  let chartData = Object.keys(aggregatedData).map((key) => ({
    name: key,
    value: aggregatedData[key],
  }));

  // Sort chartData in descending order
  chartData = chartData.sort((a, b) => b.value - a.value);

  // If category is 'Decor', limit chartData to top 6 items
  if (category === 'Decor') {
    chartData = chartData.slice(0, 6);
  }

  // Assign colors to chartData
  chartData = chartData.map((item, index) => ({
    ...item,
    color: getColor(index),
  }));

  // Build color mapping for consistency
  const colorMapping = {};
  chartData.forEach((item) => {
    colorMapping[item.name] = item.color;
  });

  // Get tableData
  let tableData;
  if (category === 'Decor') {
    // For 'Decor', get top 50 items
    tableData = Object.keys(aggregatedData).map((key) => ({
      name: key,
      value: aggregatedData[key],
    }));
    tableData = tableData.sort((a, b) => b.value - a.value).slice(0, 50);

    // Assign colors to tableData
    tableData = tableData.map((item, index) => ({
      ...item,
      color: colorMapping[item.name] || getColor(index + chartData.length),
    }));
  } else {
    // For other categories, tableData is same as chartData
    tableData = chartData;
  }

  // Get top 10 items for the selected legend
  const top10Data = data
    .filter((item) => item[category] === selectedLegend)
    .sort((a, b) => b.MaterialInStock - a.MaterialInStock)
    .slice(0, 10);

  // Handle category change
  const handleCategoryChange = (val) => {
    setCategory(val);
    setSelectedLegend(null); // Reset selection on category change
  };

  // Handle clicking on a legend item or pie slice
  const handleLegendClick = (name) => {
    setSelectedLegend(name);
  };

  // Custom label renderer for the pie chart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const RADIAN = Math.PI / 180;
    // Calculate the position of the label
    const radius = outerRadius + 10; // Adjust label distance from the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Only display labels for slices larger than a certain percentage
    if (percent < 0.05) return null;

    return (
      <text
        x={x}
        y={y}
        fill={chartData[index].color} // Use the same color as the pie slice
        fontSize={14} // Adjust font size here
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="widget-container" style={{ width: "70%", height: "72vh" }}>
      {/* Category Selection */}
      <div style={{ width: '100%', marginBottom: '20px', textAlign: 'center' }}>
        <FormGroup style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <ToggleButtonGroup
            type="radio"
            name="categories"
            value={category}
            onChange={handleCategoryChange}
            className="mb-1 toggle-button-small"
          >
            <ToggleButton
              id="Thickness"
              value="Thickness"
              variant={category === 'Thickness' ? 'info' : 'outline-info'}
            >
              Thickness
            </ToggleButton>
            <ToggleButton
              id="Finish"
              value="Finish"
              variant={category === 'Finish' ? 'info' : 'outline-info'}
            >
              Finish
            </ToggleButton>
            <ToggleButton
              id="SizeDescription"
              value="Size Description"
              variant={category === 'Size Description' ? 'info' : 'outline-info'}
            >
              Format
            </ToggleButton>
            <ToggleButton
              id="Decor"
              value="Decor"
              variant={category === 'Decor' ? 'info' : 'outline-info'}
            >
              Decor
            </ToggleButton>
            <ToggleButton
              id="Price group"
              value="Price group"
              variant={category === 'Price group' ? 'info' : 'outline-info'}
            >
              Price group
            </ToggleButton>
            <ToggleButton
              id="Portfolio"
              value="Portfolio"
              variant={category === 'Portfolio' ? 'info' : 'outline-info'}
            >
              Portfolio
            </ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
      </div>
      <h6>Stock Distribution by {category}</h6>
      {/* Content */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          height: "70%",
          alignContent: "center",
        }}
      >
        {/* Left Side: Pie Chart */}
        <div
          style={{
            flex: '0 0 60%',
            maxWidth: '60%',
            padding: '10px',
            boxSizing: 'border-box',
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={140}
                labelLine={false}
                label={renderCustomizedLabel}
                onClick={(data) => handleLegendClick(data.name)}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend
                onClick={(e) => handleLegendClick(e.value)}
                wrapperStyle={{ cursor: 'pointer', fontSize: 'medium' }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Right Side: Data Table */}
        <div
          style={{
            flex: '0 0 40%',
            maxWidth: '40%',
            padding: '10px',
            boxSizing: 'border-box',
            maxHeight: '400px', // Set a maximum height
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          <h5>Details</h5>
          <Table bordered hover size="sm" variant="dark" style={{ fontSize: 'medium' }}>
            <thead>
              <tr>
                <th>{category}</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((entry, index) => (
                <tr
                  key={index}
                  onClick={() => handleLegendClick(entry.name)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <span style={{ color: entry.color }}>{entry.name}</span>
                  </td>
                  <td>
                    <span style={{ color: entry.color }}>{entry.value}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Display Top 10 Details if an item is selected */}
          {selectedLegend && (
            <>
              <h6>Top 10 for {selectedLegend}</h6>
              <Table
                striped
                bordered
                hover
                size="sm"
                variant="dark"
                style={{ fontSize: 'medium' }}
              >
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {top10Data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Material}</td>
                      <td>{item.MaterialInStock}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StockDistributionWidget;
