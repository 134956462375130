import React, { useEffect, useState, useCallback } from 'react';
import DebtPortalConfig from './DebtPortalConfig';
import DebtPortalNavBar from './DebtPortalNavBar';
import DebtPortalOverview from './DebtPortalOverview/DebtPortalOverview';
import ArrayDocumentor from '../../Reusables/ArrayDocumentor/ArrayDocumentor';
import DebtPortalCustomerAccountParent from './DebtPortalCustomerAccount/DebtPortalCustomerAccountParent';
import ReactGA from 'react-ga4';
import { generatePDF } from '../../../Utils/PDFUtils';


function DebtPortalParent({ debtPortalProps }) {
  const {debtPortalPath, setDebtPortalPath, debtPortalData, setDebtPortalData } = debtPortalProps;
  const [debtPortalDisplayView, setDebtPortalDisplayView] = useState('Config'); // View controller
  const [debtDataPresent, setDebtDataPresent] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null); // Add state for selected client
  const [filtersBarVisible, setFiltersBarVisible] = useState(true);
  const [hideCurrencyandPostingColumns, setHideCurrencyandPostingColumns] = useState(true);
  const [overDueOnly, setOverDueOnly] = useState(false);
  const [exportData, setExportData] = useState([]);

    // Wrap setExportData in useCallback to stabilize its reference
    const memoizedSetExportData = useCallback((data) => {
      setExportData(prevData => {
        // Only update if data has actually changed
        if (prevData !== data) {
          return data;
        }
        return prevData;
      });
    }, []);
  

  //Debt Portal Props
  const [CustomerAccountShowOpenItemsOnly, setCustomerAccountShowOpenItemsOnly] = useState(true);

  const debtPortalFilterProps = {
    CustomerAccountShowOpenItemsOnly,
    setCustomerAccountShowOpenItemsOnly
  };

  useEffect(() => {
    if (debtPortalData && debtPortalData.length > 0) {
      setDebtPortalDisplayView('Overview');
    }
  }, [debtDataPresent]);

  // Function to handle client selection and navigate to Customer Account view
  const handleClientSelection = (clientNumber) => {
    setSelectedClient(clientNumber);  // Set the selected client in state
    setDebtPortalDisplayView('Customer Account');  // Navigate to the Customer Account view
  };

  useEffect(() => {
    // Track component views based on the currentViewedComponent
    ReactGA.event({
      category: 'Navigation',
      action: `Viewed ${debtPortalDisplayView}`,
      label: `User viewed ${debtPortalDisplayView} component`
    });
  }, [debtPortalDisplayView]);

  const handleExportData = () => {
    console.log(exportData, exportData[0]['Name 1'] );
    generatePDF(exportData, exportData[0]['Name 1']);
  };

  return (
    <div>
        <DebtPortalNavBar 
          setDebtPortalData={setDebtPortalData} 
          setDebtDataPresent={setDebtDataPresent} 
          setDebtPortalDisplayView={setDebtPortalDisplayView} 
          debtPortalFilterProps={debtPortalFilterProps}
          filtersBarVisible={filtersBarVisible}
          setFiltersBarVisible={setFiltersBarVisible}
          setHideCurrencyandPostingColumns={setHideCurrencyandPostingColumns}
          hideCurrencyandPostingColumns={hideCurrencyandPostingColumns}
          overDueOnly={overDueOnly}
          setOverDueOnly={setOverDueOnly}
          handleExportData={handleExportData}
        />
        {!debtDataPresent && debtPortalDisplayView === 'Config' && <DebtPortalConfig setDebtPortalPath={setDebtPortalPath} debtPortalPath={debtPortalPath} setDebtPortalData={setDebtPortalData} setDebtDataPresent={setDebtDataPresent}/>}
        {debtDataPresent && debtPortalDisplayView === 'Overview' && <DebtPortalOverview debtPortalData={debtPortalData} onClientSelect={handleClientSelection}/>}
        {debtDataPresent && debtPortalDisplayView === 'Customer Account' && 
          <DebtPortalCustomerAccountParent 
            debtPortalData={debtPortalData} 
            debtPortalFilterProps={debtPortalFilterProps} 
            selectedClient={selectedClient}
            filtersBarVisible={filtersBarVisible}
            hideCurrencyandPostingColumns={hideCurrencyandPostingColumns}
            overDueOnly={overDueOnly}
            setExportData={setExportData}
          />
        }
        
    </div>
  );
}

export default DebtPortalParent;
