import React, {useState} from 'react'
import PlanItNavBar from './PlanItNavBar/PlanItNavBar';
import PlanItViewCycler from './PlanItViewCycler';

function PlanItMain({planItProps, AppProps}) {
  const {setDataIsValid} = planItProps;
  const [selectedView, setSelectedView] = useState("PlanIt Dash");


  return (  
    <div>
      <PlanItNavBar setSelectedView={setSelectedView} setDataIsValid={setDataIsValid} />
      <PlanItViewCycler selectedView={selectedView} planItProps={planItProps} AppProps={AppProps} />
    </div>
  )
}

export default PlanItMain