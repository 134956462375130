import React, { useState, useEffect } from 'react';
import codexFlushed from '../../Utils/CodexFlushed.json';
import { Table, Navbar, Form, Button } from 'react-bootstrap';

function DynamicJSONTable() {
  const [data, setData] = useState(codexFlushed);
  const [filteredData, setFilteredData] = useState([]);

  const [isEditing, setIsEditing] = useState(null); // Index of the record being edited
  const [newRecord, setNewRecord] = useState({});   // State for the new record inputs
  const [pasteData, setPasteData] = useState('');   // State for pasted JSON data

  // Filter states
  const [decorFilter, setDecorFilter] = useState('');
  const [finishFilter, setFinishFilter] = useState('');
  const [thicknessFilter, setThicknessFilter] = useState('');
  const [sizeFilter, setSizeFilter] = useState('');

  // Unique filter options
  const [uniqueDecors, setUniqueDecors] = useState([]);
  const [uniqueFinishes, setUniqueFinishes] = useState([]);
  const [uniqueThicknesses, setUniqueThicknesses] = useState([]);
  const [uniqueSizes, setUniqueSizes] = useState([]);

  // Extract all unique keys from data for dynamic rendering
  const allKeys = Array.from(new Set(data.flatMap(record => Object.keys(record))));

  // Update unique filter options whenever data changes
  useEffect(() => {
    setUniqueDecors([...new Set(data.map(item => item.Decor).filter(Boolean))]);
    setUniqueFinishes([...new Set(data.map(item => item['Finish Description']).filter(Boolean))]);
    setUniqueThicknesses([...new Set(data.map(item => item.Thickness).filter(Boolean))]);
    setUniqueSizes([...new Set(data.map(item => item['Size Description']).filter(Boolean))]);
  }, [data]);

  // Apply filters and limit to top 100 entries
  useEffect(() => {
    let filtered = data;

    if (decorFilter) {
      filtered = filtered.filter(item => item.Decor === decorFilter);
    }

    if (finishFilter) {
      filtered = filtered.filter(item => item['Finish Description'] === finishFilter);
    }

    if (thicknessFilter) {
      filtered = filtered.filter(item => item.Thickness === Number(thicknessFilter));
    }

    if (sizeFilter) {
      filtered = filtered.filter(item => item['Size Description'] === sizeFilter);
    }

    // Limit to top 100 entries
    setFilteredData(filtered.slice(0, 100));
  }, [data, decorFilter, finishFilter, thicknessFilter, sizeFilter]);

  // Function to handle editing a record
  const handleEdit = (index) => {
    setIsEditing(index);
  };

  // Function to handle deleting a record
  const handleDelete = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
  };

  // Function to handle saving an edited record
  const handleSave = () => {
    setIsEditing(null);
  };

  // Function to handle changes in the editable fields
  const handleChange = (index, key, value) => {
    const updatedData = [...data];
    const dataIndex = data.findIndex((_, i) => i === index);
    updatedData[dataIndex] = {
      ...updatedData[dataIndex],
      [key]: value
    };
    setData(updatedData);
  };

  // Function to handle input changes for a new record
  const handleNewRecordChange = (key, value) => {
    setNewRecord({
      ...newRecord,
      [key]: value
    });
  };

  // Function to add a new record to the data
  const handleAddNewRecord = () => {
    if (Object.keys(newRecord).length > 0) {
      setData([...data, newRecord]);
      setNewRecord({});
    } else {
      alert('Please fill in at least one field to add a new record.');
    }
  };

  // Function to handle changes in the paste data textarea
  const handlePasteDataChange = (e) => {
    setPasteData(e.target.value);
  };

  // Function to parse and add pasted JSON data
  const handlePasteData = () => {
    try {
      const parsedData = JSON.parse(pasteData);
      if (Array.isArray(parsedData)) {
        setData([...data, ...parsedData]);
      } else if (typeof parsedData === 'object') {
        setData([...data, parsedData]);
      } else {
        alert('Invalid JSON data. Please ensure it is a JSON object or array of objects.');
      }
      setPasteData('');
    } catch (error) {
      alert('Invalid JSON format. Please check your pasted data.');
    }
  };

  return (
    <div>
      {/* Navigation Bar with Filters */}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand>Filters</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Form className="d-flex align-items-center flex-wrap">
            <Form.Group controlId="formDecorFilter" className="d-flex align-items-center mr-3 mb-2">
              <Form.Label className="mr-2 my-0" style={{ color: 'white' }}>Decor:</Form.Label>
              <Form.Control
                as="select"
                value={decorFilter}
                onChange={(e) => setDecorFilter(e.target.value)}
                size="sm"
              >
                <option value="">All</option>
                {uniqueDecors.map((decor) => (
                  <option key={decor} value={decor}>{decor}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formFinishFilter" className="d-flex align-items-center mr-3 mb-2">
              <Form.Label className="mr-2 my-0" style={{ color: 'white' }}>Finish:</Form.Label>
              <Form.Control
                as="select"
                value={finishFilter}
                onChange={(e) => setFinishFilter(e.target.value)}
                size="sm"
              >
                <option value="">All</option>
                {uniqueFinishes.map((finish) => (
                  <option key={finish} value={finish}>{finish}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formThicknessFilter" className="d-flex align-items-center mr-3 mb-2">
              <Form.Label className="mr-2 my-0" style={{ color: 'white' }}>Thickness:</Form.Label>
              <Form.Control
                as="select"
                value={thicknessFilter}
                onChange={(e) => setThicknessFilter(e.target.value)}
                size="sm"
              >
                <option value="">All</option>
                {uniqueThicknesses.map((thickness) => (
                  <option key={thickness} value={thickness}>{thickness}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formSizeFilter" className="d-flex align-items-center mr-3 mb-2">
              <Form.Label className="mr-2 my-0" style={{ color: 'white' }}>Size:</Form.Label>
              <Form.Control
                as="select"
                value={sizeFilter}
                onChange={(e) => setSizeFilter(e.target.value)}
                size="sm"
              >
                <option value="">All</option>
                {uniqueSizes.map((size) => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Navbar.Collapse>
      </Navbar>

      {/* Data Table */}
      <Table striped bordered hover size="sm" variant="dark" style={{ fontSize: 'small' }}>
        <thead>
          <tr>
            {allKeys.map((key) => (
              <th key={key}>{key}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((record, index) => (
            <tr key={index}>
              {allKeys.map((key) => (
                <td key={key}>
                  {isEditing === index ? (
                    <input
                      type="text"
                      value={record[key] || ''}
                      onChange={(e) => handleChange(index, key, e.target.value)}
                    />
                  ) : (
                    record[key]
                  )}
                </td>
              ))}
              <td>
                {isEditing === index ? (
                  <>
                    <Button variant="success" size="sm" onClick={handleSave}>Save</Button>{' '}
                    <Button variant="secondary" size="sm" onClick={() => setIsEditing(null)}>Cancel</Button>
                  </>
                ) : (
                  <>
                    <Button variant="primary" size="sm" onClick={() => handleEdit(index)}>Edit</Button>{' '}
                    <Button variant="danger" size="sm" onClick={() => handleDelete(index)}>Delete</Button>
                  </>
                )}
              </td>
            </tr>
          ))}
          {/* New Record Input Row */}
          <tr>
            {allKeys.map((key) => (
              <td key={key}>
                <input
                  type="text"
                  placeholder={key}
                  value={newRecord[key] || ''}
                  onChange={(e) => handleNewRecordChange(key, e.target.value)}
                />
              </td>
            ))}
            <td>
              <Button variant="success" size="sm" onClick={handleAddNewRecord}>Add</Button>
            </td>
          </tr>
        </tbody>
      </Table>

      {/* Paste JSON Data Section */}
      <h3>Paste JSON Data</h3>
      <textarea
        rows="5"
        cols="80"
        placeholder="Paste JSON data here..."
        value={pasteData}
        onChange={handlePasteDataChange}
      />
      <br />
      <Button variant="primary" onClick={handlePasteData}>Add Pasted Data</Button>
    </div>
  );
}

export default DynamicJSONTable;
