// ExcelDRP.jsx
import React, { useEffect, useState } from 'react';
import ExcelDRPMultiFunctionTable from './ExcelDRPMultiFunctionTable';
import { CalculateDRP, CalculateDRPWithSalesAndBudgetInfo } from '../../../Utils/DRPOps';
import { CalculateBudgetAnalysis, GetAndSetPlantNumber } from '../../../Utils/ExcelFileOps';
import { useNavigate } from 'react-router-dom';

function ExcelDRP({ 
  ExcelProps, 
  excelFilteredDRPData, 
  showAdditionalColumns, 
  ATPOnly, 
  setPlant, 
  setExcelDRPData, 
  excelDRPData, 
  DRPLoaded, 
  setDRPLoaded,
  visibleColumns,
  allColumns
}) {
  const [itemsPerPage] = useState(50); // Number of items to display per page
  const { excelDRPConcept, budget, lyTurnover, setBudgetAnalysisData, transitDays, setExcelDataValid } = ExcelProps;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!DRPLoaded) {
      // Check if the data is cleared or not available
      if (!excelDRPConcept || !excelDRPConcept.salesDataRaw || excelDRPConcept.salesDataRaw.length === 0) {
        // If data is cleared or invalid, set loaded and valid to false to force redirect or show no data
        setDRPLoaded(false);
        setExcelDataValid(false);
        navigate('/Excel');  // Redirect to Full Config page
      } else {
        // Proceed with calculations only if data exists
        try {
          if (budget > 0) {
            const data = CalculateDRPWithSalesAndBudgetInfo(excelDRPConcept, false, budget, lyTurnover, excelDRPConcept.salesDataRaw, transitDays);
            const _budgetAnalysisData = CalculateBudgetAnalysis(budget, excelDRPConcept.salesDataRaw);
            setBudgetAnalysisData(_budgetAnalysisData);
            GetAndSetPlantNumber(excelDRPConcept.VA05DataRaw, setPlant);
            setExcelDRPData(data);
            setDRPLoaded(true);
          } else {
            const data = CalculateDRP(excelDRPConcept, false);
            GetAndSetPlantNumber(excelDRPConcept.VA05DataRaw, setPlant);
            setExcelDRPData(data);
            setDRPLoaded(true);
          }
        } catch (error) {
          console.error("Error calculating DRP data: ", error);
          setDRPLoaded(false);
          setExcelDataValid(false);
          navigate('/Excel');  // Redirect in case of error
        }
      }
    }
  }, [DRPLoaded, budget, excelDRPConcept, lyTurnover, transitDays, setExcelDRPData, setBudgetAnalysisData, setPlant, setExcelDataValid, navigate, excelFilteredDRPData]);
  

  return (
    <div>
      {DRPLoaded && excelFilteredDRPData && excelFilteredDRPData.length > 0 ? (
        <>
          <ExcelDRPMultiFunctionTable 
            data={excelFilteredDRPData} 
            showAdditionalColumns={showAdditionalColumns} 
            recordsToShow={itemsPerPage} 
            ATPOnly={ATPOnly} 
            IQ09RawData={excelDRPConcept ? excelDRPConcept.IQO9DataRaw : []}
            visibleColumns={visibleColumns}
            allColumns={allColumns} 
          />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default ExcelDRP;
