import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatCurrency } from '../../../../Utils/FormattingFunctions'; // Adjust the path to your actual file location

// Custom Tooltip component
const CustomTooltip = ({ active, payload, selectedKPI, localCurrency }) => {
    if (active && payload && payload.length > 0) {
        const { date, amount } = payload[0].payload;

        let formattedValue;
        switch (selectedKPI) {
            case 'Net Invoiced Amount':
                formattedValue = formatCurrency(amount, "en-GB", localCurrency);
                break;
            case 'Net Invoiced Amount (Group Currency)':
                formattedValue = formatCurrency(amount, "en-GB", "EUR");
                break;
            case 'Transport Cost':
                formattedValue = formatCurrency(amount, "en-GB", localCurrency);
                break;
            case 'Billed Quantity':
                formattedValue = `${amount} Slabs`;
                break;
            case 'Área Total Real\nTabla Factura (M2)':
                formattedValue = `${amount.toFixed(2)} M2`;
                break;
            default:
                formattedValue = amount;
                break;
        }

        return (
            <div className="custom-tooltip" style={{ backgroundColor: 'black', padding: '10px', border: '1px solid #ccc' }}>
                <p className="label" style={{ margin: 0 }}>{`Date: ${date}`}</p>
                <p className="amount" style={{ margin: 0 }}>{`Amount: ${formattedValue}`}</p>
            </div>
        );
    }

    return null;
};

const VBC_DynamicClientChart = ({ chartData, dataType, selectedKPI, localCurrency }) => {
    if (!chartData || chartData.length === 0) {
        return <p>No data available to display.</p>;
    }

    // Determine the stroke color based on dataType
    let strokeColor;
    switch (dataType) {
        case 'Gross':
            strokeColor = '#00FFFF'; // Neon Baby Blue
            break;
        case 'Invoiced':
            strokeColor = '#98FB98'; // Light Pale Green
            break;
        case 'Credited':
            strokeColor = '#FF9999'; // Slightly more intense red
            break;
        default:
            strokeColor = '#8884d8'; // Default color
    }

    return (
        <ResponsiveContainer height={335} style={{ width: "100%" }}>
            <span style={{ color: 'white' }}>{selectedKPI}</span>
            <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="date"
                    tickFormatter={(tick) => {
                        const [year, month] = tick.split('-');
                        return `${month.padStart(2, '0')}/${year}`;
                    }}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip selectedKPI={selectedKPI} localCurrency={localCurrency}/>} />
                <Line type="monotone" dataKey="amount" stroke={strokeColor} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default VBC_DynamicClientChart;
