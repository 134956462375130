import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, FormGroup } from 'react-bootstrap';
import './LookupFieldWithDropdown.css'; // Import CSS file for the component

function LookupFieldWithDropdown({ data, searchByTitle, setParentState, setParentSelected }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [somethingSelected, setSomethingSelected] = useState(false);
  const formRef = useRef();

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() === '') {
      setSearchResults([]);
    } else {
      const filteredResults = data && Array.isArray(data) ? data.filter((oneData) =>
        typeof oneData === 'number'
          ? oneData.toString().includes(value)
          : oneData.toString().toLowerCase().includes(value.toLowerCase())
      ) : [];
      setSearchResults(filteredResults);
    }
  };

  const handleDropdownSelect = (selectedResult) => {
    setSearchTerm(selectedResult);
    setSomethingSelected(true);

    // Additional logic for setting parent state and selected state if needed

    setSearchResults([]);
  };

  useEffect(() => {
    if (searchTerm === '') {
      setSomethingSelected(false);
    }
  }, [searchTerm]);

  return (
    <Card className='lookup-main-card box-shadow-card'>
      <Card.Header>Lookup By: {searchByTitle}</Card.Header>
      <Card.Body>
        <Form className='lookup-main-form'>
          <FormGroup>
            <Form.Label>Search By {searchByTitle}</Form.Label>
            <Form.Control
              ref={formRef}
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {searchResults.length > 0 ? (
              <ul className='drop-down-list'>
                {searchResults.map(result => (
                  <li key={result} onClick={() => handleDropdownSelect(result)}>
                    <span>{result}</span>
                  </li>
                ))}
              </ul>
            ) : (
              data && Array.isArray(data) ? (
                null
              ) : <p>No data found in the database</p>
            )}
          </FormGroup>
        </Form>
      </Card.Body>
    </Card>
  );
}

LookupFieldWithDropdown.propTypes = {
  data: PropTypes.array,
  searchByTitle: PropTypes.string.isRequired,
  setParentState: PropTypes.func,
  setParentSelected: PropTypes.func,
};

export default LookupFieldWithDropdown;
