import React from 'react'

function FilterBar({textFilter, setTextFilter}) {

  const handleChange = (e) => {
    setTextFilter(e.target.value);
  };

  return (
    <div className='-filter-bar-'>
      <span style={{fontSize: "medium"}}>Filter:</span>
      <input type="text"
        onChange={handleChange}
        style={{width: "200px", height: "30px", marginLeft: "10px", fontSize: "medium"}}
        value={textFilter}
      />
    </div>
  )
}

export default FilterBar