import './PlanItNavBar.css'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import PlanItViewFunctionDropdown from './PlanItViewFunctionDropdown'
import {connectToDB, RemoveDataFromTable} from '../../../../Utils/DBOps'


function PlanItNavBar({setSelectedView, setDataIsValid}) {
  
  const handleSelectPlanItViewClick = (eventText) => {
    const selectedView = eventText;
    console.log(selectedView);
    setSelectedView(selectedView);
  };

  const handleClearData = async () => {
    try {
      const db = await connectToDB(); // Wait for connection
      await RemoveDataFromTable(db, 'ME2LData'); // Wait for data removal
      await RemoveDataFromTable(db, 'PlannerSheetData'); // Wait for data removal
      await RemoveDataFromTable(db, 'ProductionSheetData'); // Wait for data removal
      setDataIsValid(false);
      alert('Data cleared successfully');
    } catch (error) {
      console.log('Error clearing local Dexie:', error.message);
      alert('Error clearing local Dexie:', error.message);
      return; // Exit the function if there's an error
    }
  }

  return (
    <Navbar variant="dark" bg="dark" expand="lg" className='planIt-navbar'>
      <div className='nav-bar-holder'>
        <PlanItViewFunctionDropdown navBarBrandTitle={"Plan It"} navDropDownIdAndTitle={"Views"} textArray={["PlanIt Dash"]} handleSelectViewClick={handleSelectPlanItViewClick}/>
        <div className='planIt-navbar-right'><Button onClick={handleClearData} variant='warning' className='custom-button-gold'>Clear Data</Button></div>
      </div>
    </Navbar>
  )
}

export default PlanItNavBar