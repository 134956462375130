export const CalculateStockSubtotalHarlow = (data) => {
    let stockSubtotal = 0;
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the item has a "Harlow - UK Stock" property
      if (item["Harlow - UK Stock"] !== undefined) {
        // Add the "Harlow - UK Stock" value to the subtotal
        stockSubtotal += item["Harlow - UK Stock"];
      }
  
      // You can add similar checks for other stock properties if needed
      // For example:
      // if (item["Leeds - UK Stock"] !== undefined) {
      //   stockSubtotal += item["Leeds - UK Stock"];
      // }
    });
  
    return stockSubtotal;
  };


  export const CalculateStockSubtotaIQ09 = (data) => {
    let stockSubtotal = 0;
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the item has a "Harlow - UK Stock" property
      if (item["Material"] !== undefined) {
        // Add the "Harlow - UK Stock" value to the subtotal
        stockSubtotal += 1;
      }
  
      // You can add similar checks for other stock properties if needed
      // For example:
      // if (item["Leeds - UK Stock"] !== undefined) {
      //   stockSubtotal += item["Leeds - UK Stock"];
      // }
    });
  
    return stockSubtotal;
  };


export const CalculateSlabsOnPO = (data) => {
    let slabsOnPO = 0;
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the item has a "Slabs on PO" property
      if (item["Still to be delivered (qty)"] !== undefined) {
        // Add the "Slabs on PO" value to the subtotal
        if (typeof item["Material"] === 'string' && item["Material"].startsWith("TB")) {
          slabsOnPO += item["Still to be delivered (qty)"];
        }
      }
    });
  
    return slabsOnPO;
};

export const CalculateSalesDemandTotal = (data) => {
    let salesDemandTotal = 0;
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the item has a "Sales Demand" property
      if (item["Open Dlv Quantity"] !== undefined) {
        // Add the "Sales Demand" value to the subtotal
        salesDemandTotal += item["Open Dlv Quantity"];
      }
    });
  
    return salesDemandTotal;
};

export const CalculateATPTotal = (data) => {
    let atpTotal = 0;
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the item has a "ATP" property
      if (item["ATPNow"] !== undefined) {
        // Add the "ATP" value to the subtotal
        atpTotal += item["ATPNow"];
      }
    });
  
    return atpTotal;
};

export function groupByMaterialAndBatchSorted(stockItems) {
  const groupedMaterials = {};

  stockItems.forEach((item) => {
    const material = item.Material;
    const batch = item.Batch || 'N/A';

    if (!groupedMaterials[material]) {
      groupedMaterials[material] = {
        Description: item['Material Description'],
        Quantity: 1,
        Batches: {},
      };
    } else {
      groupedMaterials[material].Quantity += 1;
    }

    if (!groupedMaterials[material].Batches[batch]) {
      groupedMaterials[material].Batches[batch] = {
        Quantity: 1,
        SerialNumbers: [item['Serial Number']],
      };
    } else {
      groupedMaterials[material].Batches[batch].Quantity += 1;
      groupedMaterials[material].Batches[batch].SerialNumbers.push(item['Serial Number']);
    }
  });

  const sortedMaterials = Object.keys(groupedMaterials)
    .map((material) => ({
      Material: material,
      Description: groupedMaterials[material].Description,
      Quantity: groupedMaterials[material].Quantity,
      Batches: Object.keys(groupedMaterials[material].Batches).map((batch) => ({
        Batch: batch,
        Quantity: groupedMaterials[material].Batches[batch].Quantity,
        SerialNumbers: groupedMaterials[material].Batches[batch].SerialNumbers,
      })),
    }))
    .sort((a, b) => b.Quantity - a.Quantity)
    .slice(0, 20);

  return sortedMaterials;
}

export const groupByMaterialAndBatchAndSerial = (stockItems) => {
  const groupedMaterials = {};

  stockItems.forEach((item) => {
    const material = item.Material;
    const batch = item.Batch || 'N/A';

    if (!groupedMaterials[material]) {
      groupedMaterials[material] = {
        Description: item['Material Description'],
        Quantity: 1,
        Batches: {},
      };
    } else {
      groupedMaterials[material].Quantity += 1;
    }

    if (!groupedMaterials[material].Batches[batch]) {
      groupedMaterials[material].Batches[batch] = {
        Quantity: 1,
        SerialNumbers: [{
          SerialNumber: item['Serial Number'],
          Tono: item.TONO_TABLA,
          AreaComercial: item.AREA_COMERCIAL,
        }],
      };
    } else {
      groupedMaterials[material].Batches[batch].Quantity += 1;
      groupedMaterials[material].Batches[batch].SerialNumbers.push({
        SerialNumber: item['Serial Number'],
        Tono: item.TONO_TABLA,
        AreaComercial: item.AREA_COMERCIAL,
      });
    }
  });

  return Object.keys(groupedMaterials).map((material) => ({
    Material: material,
    Description: groupedMaterials[material].Description,
    Quantity: groupedMaterials[material].Quantity,
    Batches: Object.keys(groupedMaterials[material].Batches).map((batch) => ({
      Batch: batch,
      Quantity: groupedMaterials[material].Batches[batch].Quantity,
      SerialNumbers: groupedMaterials[material].Batches[batch].SerialNumbers,
    })),
  }));
};






  