import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DynamicChart from './DynamicChart';
import '../Excels/VentasAnalyser/VentasByClient/KPIGrid.css'; // Import custom CSS for KPIGrid

const KPIGrid = ({ kpis, selectedKPI, setSelectedKPI, chartData, groupByField }) => {
    // Handle KPI card click to update chart data
    const handleKPISelect = (kpi) => {
        setSelectedKPI(kpi.dataKey);    
    };

    return (
        <Card className='kpi-grid-card' style={{margin: "auto", marginTop: "10px", padding: "10px", backgroundColor: '#2e2e33'}}>
            <Card.Body>
                <Row className='kpi-grid'>
                    {kpis.map((kpi, index) => (
                        <Col key={index} xs={6} md={4} className='kpi-item-col' style={{ padding: "5px" }}>
                            <Card 
                                className={`kpi-item ${selectedKPI === kpi.dataKey ? 'selected' : ''}`}
                                onClick={() => handleKPISelect(kpi)}
                                style={{ cursor: 'pointer', borderRadius: '5px', padding: '5px', backgroundColor: '#45454f' }} // Compact card styling
                            >
                                <Card.Body style={{ padding: '5px' }}>
                                    <h6 style={{ marginBottom: '2px', fontSize: '14px', color: '#fff' }}>{kpi.label}</h6> {/* Smaller font for label */}
                                    <p style={{ fontSize: '14px', color: '#ccc', margin: 0 }}>
                                        {kpi.dataKey === "Daily Rate" ? kpi.value.toFixed(2) : kpi.value}
                                    </p> {/* Smaller font for value */}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* Render chart if a KPI is selected */}
                {selectedKPI && (
                    <DynamicChart
                        chartData={chartData}
                        _dataKeyX={groupByField} // Or any other field selected from the dropdown
                        dataKey="amount" // Pass selected data key for the chart
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default KPIGrid;
