import './PlanIt.css'
import '../../../Styles/Excels/ExcelFileConfig.css'
import React, {useEffect} from 'react'
import FileInputContainer from "../../Reusables/FileInputContainer"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { ReadExceslForPlanIt, SavePlanItDataToDexie, GetME2LDataFromDexie, GetProductionSheetDataFromDexie } from '../../../Utils/PlanItFunctions'

const PlanItConfig = ({planItProps}) => {
 const {ME2LData, setME2LData, productionData, setProductionData, setDataIsValid } = planItProps;


    const handlePlanItSetup = async () => {
        const planItData = await ReadExceslForPlanIt(ME2LData, productionData);
        //need validation here for if the save doesn't work.
        console.log("Saving data for future use");
        await SavePlanItDataToDexie(planItData);
        await GetFromDexie();
        setDataIsValid(true);
        console.log("Data saved to Dexie and ready to go");
    }

    useEffect(() => {
        const GetFromDexieForUseEffect = async () => {
            const _ME2LData = await GetME2LDataFromDexie();
            const _ProductionInfo = await GetProductionSheetDataFromDexie();
            let dataFound = false;
            
            try {
                if (_ME2LData  && _ProductionInfo) {
                    setME2LData(_ME2LData);
                    setProductionData(_ProductionInfo);
                }
                if (_ME2LData.length > 0) {
                    setDataIsValid(true);
                    dataFound = true;
                }
                else {
                    setDataIsValid(false);
                    dataFound = false;
                }
            } catch (error) {
                alert("Data not found in Dexie. Please upload the files again or consult Admin");
                setDataIsValid(false);
                dataFound = false;
            }
            finally {
                if (dataFound === false) {
                    setME2LData([]);
                    setProductionData([]);
                    alert("Data not found in Dexie. Please upload the files again or consult Admin");
                }
            }
        }
        GetFromDexieForUseEffect();
    }, [setDataIsValid, setME2LData, setProductionData])

    const GetFromDexie = async () => {
        const _ME2LData = await GetME2LDataFromDexie();
        const _ProductionInfo = await GetProductionSheetDataFromDexie();
        let dataFound = false;
        
        try {
            if (_ME2LData  && _ProductionInfo) {
                setME2LData(_ME2LData);
                setProductionData(_ProductionInfo);
            }
            if (_ME2LData.length > 0) {
                setDataIsValid(true);
                dataFound = true;
            }
            else {
                setDataIsValid(false);
                dataFound = false;
            }
        } catch (error) {
            alert("Data not found in Dexie. Please upload the files again or consult Admin");
            setDataIsValid(false);
            dataFound = false;
        }
        finally {
            if (dataFound === false) {
                setME2LData([]);
                setProductionData([]);
                alert("Data not found in Dexie. Please upload the files again or consult Admin");
            }
        }
    }

    return (
        <div className="-PlanIt-">
            <Card className='data-browser-card'>
                <Card.Header>Input Excel Spreadsheets</Card.Header>
                <Card.Body className='PlanIt-card-body'>
                    <FileInputContainer labelTitle={"ME2L Data"}  selectedFilePath={ME2LData} setSelectedFile={setME2LData}/>
                    <FileInputContainer labelTitle={"Production Info"} selectedFilePath={productionData} setSelectedFile={setProductionData} />
                    <div className='button-holder-div'>
                        <Button variant='warning' className="custom-button-gold plan-it-go-button" onClick={handlePlanItSetup}>Go</Button>    
                    </div>
                </Card.Body>
            </Card>
        </div>
    )

}
 
export default PlanItConfig 