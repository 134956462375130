import React, { useEffect, useState } from 'react';
import { Card, Table, Modal } from 'react-bootstrap';
import { connectToDB, GetData } from '../../../../Utils/DBOps';
import {convertExcelDateToUKDate} from '../../../../Utils/TestingFunctions';



// Function to group purchase orders by relevant fields
const groupByOrderAndDetails = (purchaseOrders) => {
  const groupedOrders = {};

  purchaseOrders.forEach((order) => {
    const orderNumber = order['Purchasing Document'] || 'N/A';
    const material = order.Material;
    const quantity = order['Order Quantity'] || 0;

    if (!groupedOrders[orderNumber]) {
      groupedOrders[orderNumber] = {
        OrderNumber: orderNumber,
        Supplier: order['Short Text'] || 'Unknown',
        OrderDate: order['Document Date'] || 'Unknown',
        TotalQuantity: 0,
        Items: [],
      };
    }

    groupedOrders[orderNumber].TotalQuantity += quantity;
    groupedOrders[orderNumber].Items.push({
      Material: material,
      Quantity: quantity,
      DeliveryDate: order['Still to be delivered (qty)'] || 'Unknown',
    });
  });

  return Object.keys(groupedOrders).map((orderNumber) => ({
    OrderNumber: orderNumber,
    Supplier: groupedOrders[orderNumber].Supplier,
    OrderDate: groupedOrders[orderNumber].OrderDate,
    TotalQuantity: groupedOrders[orderNumber].TotalQuantity,
    Items: groupedOrders[orderNumber].Items,
  }));
};

function PurchaseOrderCellViewCard({ cardCSSClassName, cardCSSHeaderClassName, cardCSSBodyClassName, cardHeaderText, selectedPurchaseOrderMaterial, show, onHide }) {
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const getPurchaseOrderData = async () => {
      if (selectedPurchaseOrderMaterial) {
        const db = await connectToDB();
        const localOrderData = await GetData(db, 'ME2N'); // Adjust 'PurchaseOrders' to your actual collection name
        if (localOrderData) {
          const orderData = localOrderData[0].filter(item => item.Material === selectedPurchaseOrderMaterial);
          const groupedData = groupByOrderAndDetails(orderData);
          setPurchaseOrderData(groupedData);
        }
      }
    };
    getPurchaseOrderData();
  }, [selectedPurchaseOrderMaterial]);

  const handleRowToggle = (rowIndex) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(rowIndex) ? prevRows.filter((row) => row !== rowIndex) : [...prevRows, rowIndex]
    );
  };

  const tableStyle = {
    fontSize: 'small',
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Card className={cardCSSClassName + " dark-card"} style={{width: "100%"}}>
        <Card.Header className={cardCSSHeaderClassName}>{cardHeaderText}</Card.Header>
        <Card.Body className={cardCSSBodyClassName}>
          {purchaseOrderData.length > 0 ? (
            <Table striped bordered hover size="sm" style={tableStyle} variant='dark'>
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Supplier</th>
                  <th>Order Date</th>
                  <th>Total Quantity</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrderData.map((order, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleRowToggle(index)} style={{ cursor: 'pointer' }}>
                      <td>{order.OrderNumber}</td>
                      <td>{order.Supplier}</td>
                      <td>{convertExcelDateToUKDate(order.OrderDate)}</td>
                      <td>{order.TotalQuantity}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr>
                        <td colSpan="4">
                          <Table striped bordered hover size="sm" style={tableStyle}>
                            <thead>
                              <tr>
                                <th>Material</th>
                                <th>Quantity</th>
                                <th>Delivery Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.Items.map((item, itemIndex) => (
                                <tr key={itemIndex}>
                                  <td>{item.Material}</td>
                                  <td>{item.Quantity}</td>
                                  <td>{item.DeliveryDate}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available for the selected Purchase Order.</p>
          )}
        </Card.Body>
      </Card>
    </Modal>
  );
}

export default PurchaseOrderCellViewCard;
