import React, { useState, useMemo } from 'react';
import VBC_CreditAnalysisTable from './VBC_CreditAnalysisTable';
import CreditAnalysisPieChart from './CreditAnalysisPieChart';
import { Card, Row, Col, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

const VBC_CreditAnalysis = ({ filteredData }) => {
  const customColors = [
    '#32CD32', '#DC143C', '#1E90FF', '#FF8C00', '#800080', '#FFD700',
    '#A52A2A', '#00CED1', '#FF4500', '#228B22', '#4682B4', '#8A2BE2',
    '#FF6347', '#FF1493', '#6A5ACD', '#7FFF00', '#8B0000', '#B8860B',
    '#4169E1', '#CD5C5C', '#9ACD32', '#EE82EE', '#FF69B4', '#5F9EA0',
  ];

  const creditData = filteredData.filter(
    (item) => item['Net Billed Amount'] < 0 && item['Order Reason'] !== 'Sin asignar'
  );

  const [selectedAggregation, setSelectedAggregation] = useState('Order Reason');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const aggregationKeyMapping = {
    'Order Reason': 'Order Reason',
    'Client Description': 'Client Description',
    'Material': 'Material Description',
  };

  const itemKey = aggregationKeyMapping[selectedAggregation];

  // Improved Color Mapping for Pie Chart with More Variation
  const colorMapping = useMemo(() => {
    const mapping = {};
    const groupKeys = Array.from(new Set(creditData.map((item) => item[itemKey] || 'Unassigned')));
    
    groupKeys.forEach((key, index) => {
      mapping[key] = customColors[index % customColors.length]; // Cycle through the custom color palette
    });

    return mapping;
  }, [creditData, selectedAggregation]);

  const handleAggregationChange = (aggregation) => {
    setSelectedAggregation(aggregation);
    setSelectedGroup(null);
    setShowAll(false);
  };

  const maxItems = selectedAggregation === 'Material' ? 10 : 7;

  return (
    <div className="-CreditAnalysis-" style={{ display: 'flex' }}>
      <Card
        bg="dark"
        text="white"
        className="dark-card-2"
        style={{
          width: '76vw',
          height: '69vh',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid lightgrey', // Grey border
          padding: '15px', // Padding for the Card
        }}
      >
        <Card.Body style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <ToggleButtonGroup
            type="radio"
            name="aggregationType"
            value={selectedAggregation}
            onChange={handleAggregationChange}
            className="mb-3 toggle-button-small"
            style={{
              maxWidth: '50%',
              minWidth: '30%',
              margin: 'auto',
            }}
          >
            {['Order Reason', 'Client Description', 'Material'].map((agg) => (
              <ToggleButton
                key={agg}
                id={`aggregation-${agg}`}
                value={agg}
                variant={selectedAggregation === agg ? 'warning' : 'outline-warning'}
              >
                {agg}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Row
            style={{
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'center',
              padding: '10px', // Padding around the Row
            }}
          >
            <Col
              style={{
                height: '100%',
                maxWidth: '60%',
                margin: 'auto',
                border: '1px solid lightgrey', // Grey border for PieChart container
                borderRadius: '5px',
                padding: '10px', // Padding for the PieChart container
                backgroundColor: '#343a40', // Match the card background color
              }}
            >
              <CreditAnalysisPieChart
                creditData={creditData}
                selectedAggregation={selectedAggregation}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                colorMapping={colorMapping}
                showAll={showAll}
                setShowAll={setShowAll}
                maxItems={maxItems}
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center',
                maxWidth: '40%',
                border: '1px solid lightgrey', // Grey border for Table container
                borderRadius: '5px',
                padding: '10px', // Padding for the Table container
                backgroundColor: '#343a40', // Match the card background color
              }}
            >
              <VBC_CreditAnalysisTable
                creditData={creditData}
                selectedAggregation={selectedAggregation}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                colorMapping={colorMapping}
                showAll={showAll}
                maxItems={maxItems}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default VBC_CreditAnalysis;
