import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ExcelDashCardVA05 from './VA05/ExcelDashCardVA05'
import ExcelDashCardIQ09 from './IQ09/ExcelDashCardIQ09'
import ExcelDashCardCUBO from './CUBO/ExcelDashCardCUBO'
import ExcelDashCardME22N from './ME22N/ExcelDashCardME22N'

function FourCardGrid({ExcelProps, setSelectedViewComponent}) {

  return (
    <Container fluid className='excel-dash-container'>
    <Row>
      <Col xs={12} md={6} lg={6} xl={6} className='mb-3'>
        <ExcelDashCardVA05 ExcelProps={ExcelProps}/>
      </Col>
      <Col xs={12} md={6} lg={6} xl={6} className='mb-3'>
        <ExcelDashCardIQ09 ExcelProps={ExcelProps}/>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6} lg={6} xl={6} className='mb-3'>
        <ExcelDashCardCUBO ExcelProps={ExcelProps} setSelectedViewComponent={setSelectedViewComponent}/>
      </Col>
      <Col xs={12} md={6} lg={6} xl={6} className='mb-3'>
        <ExcelDashCardME22N ExcelProps={ExcelProps}/>
      </Col>
    </Row>
  </Container>
  )
}

export default FourCardGrid