import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import {connectToDB, RemoveDataFromTable} from '../../../Utils/DBOps'

function ExcelNavBar({ ExcelProps, setDefaultView }) {
    const { excelDataValid, setExcelDataValid, setExcelDRPConcept } = ExcelProps;
    const navigate = useNavigate();  // Add navigate hook

    const handleJustStock = () => {
        setDefaultView("Just Stock");
    };

    const handleFullConfig = () => {
        setDefaultView("Full Config");
    };

    const handleClearData = async () => {
        if (excelDataValid) {
            try {
                // Clear data from IndexedDB
                const db = await connectToDB();
                await RemoveDataFromTable(db, "VA05Data");
                await RemoveDataFromTable(db, "IQ09Data");
                await RemoveDataFromTable(db, "ME2NData");
                await RemoveDataFromTable(db, "CUBOData");
                setExcelDataValid(false);
                setExcelDRPConcept(null);
                setDefaultView("Full Config");
            } catch (error) {
                console.log('Error clearing local storage:', error.message);
                setExcelDataValid(false);
                setExcelDRPConcept(null);
                setDefaultView("Full Config");
            }
        }
    };

    return (
        <div>
            <Navbar variant="dark" bg="dark" expand="lg" className='navbar-parent'>
                <Container fluid>
                    <Navbar.Brand href="#home">Excel</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-dark-example" />
                    <Navbar.Collapse id="navbar-dark-example">
                        <Nav>
                            <NavDropdown
                                style={{fontSize: "medium"}}
                                id="functions"
                                title="Functions"
                                menuVariant="dark"
                            >
                                <NavDropdown.Item onClick={handleJustStock}>Just Stock</NavDropdown.Item>
                                <NavDropdown.Item onClick={handleFullConfig}>Full Config</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Container fluid className={"nav-bar-right"}>
                        {excelDataValid && (
                            <Button variant="danger" size='sm' onClick={handleClearData}>
                                Start Over
                            </Button>
                        )}
                    </Container>
                </Container>
            </Navbar>
        </div>
    );
}

export default ExcelNavBar;
