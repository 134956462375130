import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Form,
  Row,
  Col,
  Accordion,
  Tooltip,
  OverlayTrigger,
  Table,
} from 'react-bootstrap';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import FileInputContainer from '../../Reusables/FileInputContainer';
import CustomFilter from './Dash/CustomFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ABCSalesDataConfig({
  file,
  handleFileSelect,
  buttonFunction,
  abcThresholds,
  setABCThresholds,
  xyzThresholds,
  setXYZThresholds,
  customFilters,
  setCustomFilters,
  columnOptions,
  dateColumnName,
  setDateColumnName,
  materialColumnName,
  setMaterialColumnName,
  billedAmountColumnName,
  setBilledAmountColumnName,
  materialDescriptionColumnName,
  setMaterialDescriptionColumnName,
}) {
  const [columnsValid, setColumnsValid] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);

  useEffect(() => {
    const allColumnsSelected = [
      dateColumnName,
      materialColumnName,
      billedAmountColumnName,
      materialDescriptionColumnName,
    ].every((col) => typeof col === 'string' && col.length > 0);

    setColumnsValid(allColumnsSelected);

    if (columnOptions.length > 0) {
      setActiveAccordion('0'); // Open Column Selection when columns are available
    }
  }, [
    dateColumnName,
    materialColumnName,
    billedAmountColumnName,
    materialDescriptionColumnName,
    columnOptions,
  ]);

  const handleABCThresholdChange = (e) => {
    const { name, value } = e.target;
    setABCThresholds({ ...abcThresholds, [name]: parseFloat(value) });
  };

  const handleXYZThresholdChange = (e) => {
    const { name, value } = e.target;
    setXYZThresholds({ ...xyzThresholds, [name]: parseFloat(value) });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.message}
    </Tooltip>
  );

  return (
    <Card className="data-browser-card">
      <Card.Header style={{ borderColor: 'darkslategray' }}>
        <h3>ABC XYZ Analysis</h3>
      </Card.Header>
      <Card.Body>
        <Stack gap={3}>
          <div>
            <span>Please upload your sales data file.</span>
            <span>(Drag and Drop is enabled)</span>
          </div>
          <FileInputContainer
            labelTitle="Sales Data File"
            selectedFilePath={file}
            setSelectedFile={handleFileSelect}
          />

          <Accordion activeKey={activeAccordion} onSelect={(e) => setActiveAccordion(e)}>
            {/* Column Selection Section */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Column Selection
                {columnsValid && (
                  <FontAwesomeIcon icon={faCircleCheck} className="ms-2 text-success" />
                )}
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-white">
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="dateColumnSelect">
                        <Form.Label>Date Column</Form.Label>
                        <Form.Control
                          as="select"
                          value={dateColumnName}
                          onChange={(e) => setDateColumnName(e.target.value)}
                        >
                          <option value="">Select Date Column</option>
                          {columnOptions.map((col) => (
                            <option key={col} value={col}>
                              {col}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="materialColumnSelect" className="mt-2">
                        <Form.Label>Material Column</Form.Label>
                        <Form.Control
                          as="select"
                          value={materialColumnName}
                          onChange={(e) => setMaterialColumnName(e.target.value)}
                        >
                          <option value="">Select Material Column</option>
                          {columnOptions.map((col) => (
                            <option key={col} value={col}>
                              {col}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="materialDescriptionColumnSelect" className="mt-2 mt-md-0">
                        <Form.Label>Material Description Column</Form.Label>
                        <Form.Control
                          as="select"
                          value={materialDescriptionColumnName}
                          onChange={(e) => setMaterialDescriptionColumnName(e.target.value)}
                        >
                          <option value="">Select Material Description Column</option>
                          {columnOptions.map((col) => (
                            <option key={col} value={col}>
                              {col}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="billedAmountColumnSelect" className="mt-2">
                        <Form.Label>Billed Amount Column</Form.Label>
                        <Form.Control
                          as="select"
                          value={billedAmountColumnName}
                          onChange={(e) => setBilledAmountColumnName(e.target.value)}
                        >
                          <option value="">Select Billed Amount Column</option>
                          {columnOptions.map((col) => (
                            <option key={col} value={col}>
                              {col}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            {/* Classification Thresholds Section */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Adjust Classification Thresholds</Accordion.Header>
              <Accordion.Body className="bg-dark text-white">
                <Form>
                  <h5>ABC Classification Thresholds (%)</h5>
                  <p>
                    Adjust the cumulative sales percentage thresholds for ABC classification.
                  </p>
                  <Row>
                    <Col>
                      <Form.Group controlId="abcThresholdA">
                        <Form.Label>A Threshold</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip({
                            message:
                              'Items contributing up to this percentage of cumulative sales will be classified as A.',
                          })}
                        >
                          <span className="ms-1 text-info">ⓘ</span>
                        </OverlayTrigger>
                        <Form.Control
                          type="number"
                          min="0"
                          max="100"
                          name="A"
                          value={abcThresholds.A}
                          onChange={handleABCThresholdChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="abcThresholdB">
                        <Form.Label>B Threshold</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip({
                            message:
                              'Items contributing up to this percentage (after A items) will be classified as B.',
                          })}
                        >
                          <span className="ms-1 text-info">ⓘ</span>
                        </OverlayTrigger>
                        <Form.Control
                          type="number"
                          min="0"
                          max="100"
                          name="B"
                          value={abcThresholds.B}
                          onChange={handleABCThresholdChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <h5 className="mt-4">XYZ Classification Thresholds</h5>
                  <Row>
                    <Col>
                      <Form.Group controlId="xyzThresholdX">
                        <Form.Label>X Threshold (CV ≤)</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip({
                            message:
                              'Items with a CV less than or equal to this value will be classified as X (stable demand).',
                          })}
                        >
                          <span className="ms-1 text-info">ⓘ</span>
                        </OverlayTrigger>
                        <Form.Control
                          type="number"
                          min="0"
                          max="1"
                          step="0.01"
                          name="X"
                          value={xyzThresholds.X}
                          onChange={handleXYZThresholdChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="xyzThresholdY">
                        <Form.Label>Y Threshold (CV ≤)</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip({
                            message:
                              'Items with a CV less than or equal to this value (after X items) will be classified as Y (moderate variability).',
                          })}
                        >
                          <span className="ms-1 text-info">ⓘ</span>
                        </OverlayTrigger>
                        <Form.Control
                          type="number"
                          min="0"
                          max="1"
                          step="0.01"
                          name="Y"
                          value={xyzThresholds.Y}
                          onChange={handleXYZThresholdChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            {/* Custom Filters Section */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>Custom Filters</Accordion.Header>
              <Accordion.Body className="bg-dark text-white">
                <CustomFilter
                  customFilters={customFilters}
                  setCustomFilters={setCustomFilters}
                  materialColumnName={materialColumnName}
                  materialDescriptionColumnName={materialDescriptionColumnName}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {file && (
            <Button
              className="custom-button-gold"
              variant="warning"
              onClick={buttonFunction}
              disabled={
                !dateColumnName ||
                !materialColumnName ||
                !billedAmountColumnName ||
                !materialDescriptionColumnName
              }
            >
              Run Analysis
            </Button>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default ABCSalesDataConfig;
