import CodexFlushed from '../Utils/CodexFlushed.json';
import {convertExcelDateToDate} from '../Utils/DateOps';

const returnSocietySaleValue = (sale) => {
    return sale["Net Amount Billed (Soc. Currency)"] || sale['Imp. Neto Facturado'];
};

const returnGroupSaleValue = (sale) => {
    return sale['Net Amount Billed (Group Currency)'] || sale['Imp. Neto Facturado (Mon. Grupo)'];
};

const returnDateValue = (sale) => {
    return sale["Invoice Document Date"] || sale['Fecha Doc. Factura'];
};

const returnBilledQty = (sale) => {
    return sale["Billed Quantity"] || sale['Cantidad Facturada'];
}

const returnOrderReason = (sale) => {
    return sale["Purchase Order Reason"] || sale['Motivo Pedido'];
};

const returnClientDescription = (sale) => {
    return sale["Client Description"] || sale['Payment Responsible Name'];
};

const isWithinTheLast12Months = (dateNumber) => {
    const date = convertExcelDateToDate(dateNumber);
    const today = new Date();
    const twelveMonthsAgo = new Date(today.setMonth(today.getMonth() - 12));
    return date >= twelveMonthsAgo;
}


//! This function is used to calculate the DRP WITHOUT DATA FROM SALES AND BUDGET
export const CalculateDRP = (excelDRPConcept, trimNoSalesDataResultsOut) => {
    try {
        if (excelDRPConcept) {
            // let totalSalesDataByCustomer = excelDRPConcept.totalSalesByCustomer;
            let totalSalesDataByMaterial = excelDRPConcept.totalSalesByMaterial;
            let totalStockByMaterialBatchAndSerial = excelDRPConcept.totalStockByMaterialBatchAndSerial;
            let totalOrdersByMaterial = excelDRPConcept.totalOrdersByMaterial;
            let totalPOsByMaterial = excelDRPConcept.totalPOsByMaterial;

            // Remove any code from uniqueMaterials that doesn't start with "TB"
            let DRPData = [];

            // Iterate through unique materials
            CodexFlushed.forEach(materialEntry => {
                let materialSold = 0;
                let materialOnOrder = 0;
                let materialOnPO = 0;
                let materialInStock = 0;
                let materialSoldPer3Month = 0;
                let materialSoldPerMonth = 0;
                let dailyRate = 0;
                let targetStock = 0;
                let DaysOnInventory = 0;
                let needToOrder = 0;
                let ATP = 0;
                let supplementedATP = 0;
                let noDataSales = true;
                let noDataStock = true;
                let noDataOrders = true;
                let noDataPOs = true;

                // Calculate Material Sold
                totalSalesDataByMaterial.forEach(sale => {
                    if (sale.Material === materialEntry.Material) {
                        materialSold += sale.TotalBilledQty;
                        noDataSales = false;
                    }
                });

                // Calculate Material On Order
                totalOrdersByMaterial.forEach(order => {
                    if (order.Material === materialEntry.Material) {
                        materialOnOrder += order.TotalOpenQuantity;
                        noDataOrders = false;
                    }
                });

                // Calculate Material On PO
                totalPOsByMaterial.forEach(po => {
                    if (po.material === materialEntry.Material) {
                        materialOnPO += po.totalPendingDelivery;
                        noDataPOs = false;
                    }
                });

                // Calculate Material In Stock
                totalStockByMaterialBatchAndSerial.forEach(stock => {
                    if (stock.Material === materialEntry.Material) {
                        stock.Batches.forEach(batch => {
                            materialInStock += batch.Quantity;
                            noDataStock = false;
                        });
                    }
                });

                if (noDataSales) {
                    materialSoldPer3Month = 0;
                    materialSoldPerMonth = 0;
                    dailyRate = 0;
                    targetStock = 0;
                    if (materialInStock > 0) {
                        DaysOnInventory = materialInStock * 365;
                    }
                    else {
                        DaysOnInventory = 0;
                    }
                } else {
                    let tmaterialSoldPer3Month = materialSold / 4;
                    materialSoldPer3Month = tmaterialSoldPer3Month.toFixed(2);
                    let tmaterialSoldPerMonth = materialSold / 12;
                    materialSoldPerMonth = tmaterialSoldPerMonth.toFixed(2);
                    let tdailyRate = materialSoldPerMonth / 30;
                    dailyRate = tdailyRate.toFixed(2);
                    targetStock = materialSoldPer3Month;

                    if (parseFloat(dailyRate) !== 0) {
                        DaysOnInventory = parseFloat(materialInStock) / parseFloat(dailyRate);
                    } 
                    else if (parseFloat(dailyRate) === 0 && materialInStock > 0) {
                        DaysOnInventory = materialInStock * 365;
                    }
                    else {
                        // Handle the case where DailyRate is zero
                        DaysOnInventory = 0; // Or any other appropriate value or indication
                    }
                }

                ATP = materialInStock - materialOnOrder;
                needToOrder = targetStock - ATP - materialOnPO;
                supplementedATP = ATP + materialOnPO;

                // Store the information in DRPData array
                DRPData.push({
                    ...materialEntry,
                    MaterialSold: materialSold,
                    MaterialOrdererdByClient: materialOnOrder,
                    MaterialOnPO: materialOnPO,
                    MaterialInStock: materialInStock,
                    MaterialSoldPer3Month: materialSoldPer3Month, 
                    MaterialSoldPerMonth: materialSoldPerMonth,
                    DailyRate: dailyRate,
                    TargetStock: targetStock, 
                    DaysOnInventory: DaysOnInventory,
                    NeedToOrder: needToOrder, 
                    ATP: ATP,
                    SupplementedATP: supplementedATP,
                    NoDataSales: noDataSales,
                    NoDataStock: noDataStock,
                    NoDataOrders: noDataOrders,
                    NoDataPOs: noDataPOs
                });
            });

            if (trimNoSalesDataResultsOut) {
                DRPData = DRPData.filter(material => {
                    return material.NoDataSales === false || material.NoDataStock === false || material.NoDataOrders === false || material.NoDataPOs === false;
                });
            }

            // Do something with the DRPData array, e.g., return it or use it as needed
            return DRPData;
        }
    } catch (error) {
        console.log(error);
        console.log("Error in CalculateDRP");
    }
};


export const CalculateDRPWithSalesAndBudgetInfo = (excelDRPConcept, trimNoSalesDataResultsOut, budget, lyTurnover, salesData, transitDays) => {
    try {
        if (excelDRPConcept) {
            // let totalSalesDataByCustomer = excelDRPConcept.totalSalesByCustomer;
            let totalSalesDataByMaterial = excelDRPConcept.totalSalesByMaterial;
            let totalStockByMaterialBatchAndSerial = excelDRPConcept.totalStockByMaterialBatchAndSerial;
            let totalOrdersByMaterial = excelDRPConcept.totalOrdersByMaterial;
            let totalPOsByMaterial = excelDRPConcept.totalPOsByMaterial;
            // let averageInvoiceWithoutCredits = 0;
            let salesDataRaw = excelDRPConcept.salesDataRaw;
            let overrideCodexData = excelDRPConcept.CodexData;


            // let salesTotalValueLocal = calculateTurnoverLocal(salesData);
            // let averageInvoiceValueAllCustomerLocalValue = averageInvoiceValueAllCustomerLocal(salesData);
            // let averageInvoiceValueAllCustomerLocalWithoutDisplaysOrCreditsValue = averageInvoiceValueAllCustomerLocaWithoutDisplaysOrQualityCredits(salesData);
            // let totalBilledSlabsAllCustomerValue = totalBilledSlabsAllCustomer(salesData);
            // let howManySlabsToSellToMeetBudget = budget / averageInvoiceValueAllCustomerLocalValue;
            let percentageDifference = (budget - lyTurnover) / budget;


            // Remove any code from uniqueMaterials that doesn't start with "TB"
            let DRPData = [];

            if (overrideCodexData) {
                CodexFlushed = overrideCodexData;
            }

            // Iterate through unique materials
            CodexFlushed.forEach(materialEntry => {
                let materialSold = 0;
                let materialOnOrder = 0;
                let materialOnPO = 0;
                let materialInStock = 0;
                let materialSoldPer3Month = 0;
                let materialSoldPerMonth = 0;
                let dailyRate = 0;
                let targetStock = 0;
                let DaysOnInventory = 0;
                let needToOrder = 0;
                let ATP = 0;
                let supplementedATP = 0;
                let noDataSales = true;
                let noDataStock = true;
                let noDataOrders = true;
                let noDataPOs = true;

                // Calculate Material Sold
                salesDataRaw.forEach(sale => {
                    if (sale.Material === materialEntry.Material) {
                        const billedQty = returnBilledQty(sale);
                        const dateValue = returnDateValue(sale);
                        if (billedQty > 0) {
                            if (isWithinTheLast12Months(dateValue)) {
                                materialSold += billedQty;
                            }
                            noDataSales = false;    
                        }
                    }
                });

                // Calculate Material On Order
                totalOrdersByMaterial.forEach(order => {
                    if (order.Material === materialEntry.Material) {
                        materialOnOrder += order.TotalOpenQuantity;
                        noDataOrders = false;
                    }
                });

                // Calculate Material On PO
                totalPOsByMaterial.forEach(po => {
                    if (po.material === materialEntry.Material) {
                        materialOnPO += po.totalPendingDelivery;
                        noDataPOs = false;
                    }
                });

                // Calculate Material In Stock
                totalStockByMaterialBatchAndSerial.forEach(stock => {
                    if (stock.Material === materialEntry.Material) {
                        stock.Batches.forEach(batch => {
                            materialInStock += batch.Quantity;
                            noDataStock = false;
                        });
                    }
                });

                if (noDataSales) {
                    materialSoldPer3Month = 0;
                    materialSoldPerMonth = 0;
                    dailyRate = 0;
                    targetStock = 0;
                    if (materialInStock > 0) {
                        DaysOnInventory = materialInStock * 365;
                    }
                    else {
                        DaysOnInventory = 0;
                    }
                } else {

                    if (typeof transitDays === 'string') {
                        transitDays = parseInt(transitDays);
                    }
                    if (transitDays < 0) {
                        transitDays = 0;
                    }

                    let targetStockWithPercentageIncrease = 0;

                    let tmaterialSoldPer3Month = materialSold / 4;
                    materialSoldPer3Month = tmaterialSoldPer3Month.toFixed(2);
                    let tmaterialSoldPerMonth = materialSold / 12;
                    materialSoldPerMonth = tmaterialSoldPerMonth.toFixed(2);


                    let tdailyRate = materialSoldPerMonth / 30;
                    let newDailyRate = 0;
                    let extraAccordingToTransitDays = 0;



                    newDailyRate = tdailyRate * (1 + percentageDifference);
                    dailyRate = newDailyRate.toFixed(2);
                    extraAccordingToTransitDays = newDailyRate * transitDays;

                    targetStock = tmaterialSoldPer3Month + extraAccordingToTransitDays;
                    targetStockWithPercentageIncrease = targetStock * (1 + percentageDifference);
                    targetStock = targetStockWithPercentageIncrease.toFixed(0);

                    if (parseFloat(dailyRate) !== 0) {
                        DaysOnInventory = parseFloat(materialInStock) / parseFloat(dailyRate);
                    } 
                    else if (parseFloat(dailyRate) === 0 && materialInStock > 0) {
                        DaysOnInventory = materialInStock * 365;
                    }
                    else {
                        // Handle the case where DailyRate is zero
                        DaysOnInventory = 0; // Or any other appropriate value or indication
                    }
                }

                ATP = materialInStock - materialOnOrder;
                needToOrder = targetStock - ATP - materialOnPO;
                supplementedATP = ATP + materialOnPO;

                // Store the information in DRPData array
                DRPData.push({
                    ...materialEntry,
                    MaterialSold: materialSold,
                    MaterialOrdererdByClient: materialOnOrder,
                    MaterialOnPO: materialOnPO,
                    MaterialInStock: materialInStock,
                    MaterialSoldPer3Month: materialSoldPer3Month, 
                    MaterialSoldPerMonth: materialSoldPerMonth,
                    DailyRate: dailyRate,
                    TargetStock: targetStock, 
                    DaysOnInventory: DaysOnInventory,
                    NeedToOrder: needToOrder, 
                    ATP: ATP,
                    SupplementedATP: supplementedATP,
                    NoDataSales: noDataSales,
                    NoDataStock: noDataStock,
                    NoDataOrders: noDataOrders,
                    NoDataPOs: noDataPOs
                });
            });

            // if (trimNoSalesDataResultsOut) {
            //     DRPData = DRPData.filter(material => {
            //         return material.NoDataSales === false || material.NoDataStock === false || material.NoDataOrders === false || material.NoDataPOs === false;
            //     });
            // }

            // Do something with the DRPData array, e.g., return it or use it as needed
            return DRPData;
        }
    } catch (error) {
        console.log(error);
        console.log("Error in CalculateDRP");
    }
};
