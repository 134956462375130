import React, { useEffect, useState } from 'react';
import { Card, ToggleButtonGroup, ToggleButton, Row, Col, Container, Navbar, Form, Button } from 'react-bootstrap';
import { 
    SumNetBilledAmountGBP, 
    SumPositiveNetAmountGBP, 
    SumNegativeNetAmountGBP, 
    SumNetBilledAmountEUR, 
    SumPositiveNetAmountEUR, 
    SumNegativeNetAmountEUR 
} from '../../../../Utils/VentasAnalyserOps'; // Adjust the import path as needed
import VentasTurnoverDashChart from './VentasTurnoverDashChart'; // Adjust the import path as needed
import centersAndCurrencies from '../../../../Utils/CentersAndCurrencies.json'; // Adjust the import path as needed
import { excelDateToJSDate } from '../../../../Utils/DateOps'; // Adjust the import path as needed

const VentasTurnoverDashMechanics = ({ ventasData }) => {
    const [currency, setCurrency] = useState('Local'); // Default to Local
    const [dataType, setDataType] = useState('Net'); // Default data type is Net
    const [centers, setCenters] = useState([]);
    const [turnovers, setTurnovers] = useState({});
    const [totals, setTotals] = useState({
        billedAmount: 0,
        billedPositive: 0,
        billedNegative: 0
    });
    const [startDate, setStartDate] = useState('2024-01-01');
    const [endDate, setEndDate] = useState('');
    const [NetData, setNetData] = useState([]);
    const [invoicedData, setInvoicedData] = useState([]);
    const [creditedData, setCreditedData] = useState([]);
    const [localCurrency, setLocalCurrency] = useState('EUR'); // Default to EUR
    const [defaultLocalCurrency, setDefaultLocalCurrency] = useState('EUR'); // Store default local currency
    const [tooltipToggle, setTooltipToggle] = useState(false);

    useEffect(() => {
        if (ventasData.length > 0) {
            const firstRecord = ventasData[0];
            const society = firstRecord["Society"];

            const centerInfo = centersAndCurrencies.find(
                (center) => center.Society === society
            );

            if (centerInfo) {
                const detectedCurrency = centerInfo["Display Currency"];
                setLocalCurrency(detectedCurrency);
                setDefaultLocalCurrency(detectedCurrency); // Store detected currency
                console.log(`Local currency set to ${detectedCurrency}`);
            }
        }
    }, [ventasData]);

    const handleCurrencyChange = (val) => {
        setCurrency(val);
        // When changing to "Group", set currency to EUR, otherwise revert to detected local currency
        if (val === 'Group') {
            setLocalCurrency('EUR');
        } else {
            setLocalCurrency(defaultLocalCurrency); // Revert to original local currency
        }
    };

    const handleDataTypeChange = (val) => {
        setDataType(val);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const formatCurrency = (amount, currencyCode) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    useEffect(() => {
        const centersSet = new Set(ventasData.map(row => row["Center"]));
        setCenters(Array.from(centersSet));
    }, [ventasData]);

    useEffect(() => {
        const calculateTurnovers = async () => {
            const turnoverResults = {};
            let totalBilledAmount = 0;
            let totalBilledPositive = 0;
            let totalBilledNegative = 0;
    
            const activeCurrency = currency === 'Local' ? localCurrency : 'EUR';
    
            for (const center of centers) {
                let billedAmount = 0;
                let billedPositive = 0;
                let billedNegative = 0;
    
                if (currency === 'Local') {
                    billedAmount = await SumNetBilledAmountGBP(ventasData, startDate, endDate, center);
                    billedPositive = await SumPositiveNetAmountGBP(ventasData, startDate, endDate, center);
                    billedNegative = await SumNegativeNetAmountGBP(ventasData, startDate, endDate, center);
                } else if (currency === 'Group') {
                    billedAmount = await SumNetBilledAmountEUR(ventasData, startDate, endDate, center);
                    billedPositive = await SumPositiveNetAmountEUR(ventasData, startDate, endDate, center);
                    billedNegative = await SumNegativeNetAmountEUR(ventasData, startDate, endDate, center);
                }
    
                turnoverResults[center] = {
                    billedAmount: formatCurrency(billedAmount, activeCurrency),
                    billedPositive: formatCurrency(billedPositive, activeCurrency),
                    billedNegative: formatCurrency(billedNegative, activeCurrency),
                    creditPercentage: billedPositive > 0 
                        ? ((billedNegative / billedPositive) * 100).toFixed(2) + '%' 
                        : 'N/A', // Avoid division by zero
                };
    
                totalBilledAmount += billedAmount;
                totalBilledPositive += billedPositive;
                totalBilledNegative += billedNegative;
            }
    
            const totalCreditPercentage = totalBilledPositive > 0
                ? ((totalBilledNegative / totalBilledPositive) * 100).toFixed(2) + '%'
                : 'N/A';
    
            setTurnovers(turnoverResults);
            setTotals({
                billedAmount: formatCurrency(totalBilledAmount, activeCurrency),
                billedPositive: formatCurrency(totalBilledPositive, activeCurrency),
                billedNegative: formatCurrency(totalBilledNegative, activeCurrency),
                creditPercentage: totalCreditPercentage,
            });
        };
    
        calculateTurnovers();
    }, [currency, centers, ventasData, startDate, endDate, localCurrency]);
    

    useEffect(() => {
        const start = startDate ? new Date(startDate) : new Date('1900-01-01');
        const end = endDate ? new Date(endDate) : new Date();
    
        const aggregateData = () => {
            // Initialize data containers
            const NetMonthlyData = {};
            const invoicedMonthlyData = {};
            const creditedMonthlyData = {};
    
            // Filter sales data for the selected date range
            const filteredSalesData = ventasData.filter(item => {
                const excelDate = item["Invoice Document Date"];
                const date = excelDate ? excelDateToJSDate(excelDate) : null;
                return date && date >= start && date <= end;
            });
    
            // Aggregate monthly data
            filteredSalesData.forEach(item => {
                const date = excelDateToJSDate(item["Invoice Document Date"]);
                const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    
                // Initialize if not already
                if (!NetMonthlyData[monthYear]) NetMonthlyData[monthYear] = 0;
                if (!invoicedMonthlyData[monthYear]) invoicedMonthlyData[monthYear] = 0;
                if (!creditedMonthlyData[monthYear]) creditedMonthlyData[monthYear] = 0;
    
                // Determine the amount based on currency
                const NetAmount = currency === 'Local' ? parseFloat(item["Net Billed Amount"]) || 0 : parseFloat(item["Net Billed Amount (Group Currency)"]) || 0;
                const invoicedAmount = NetAmount > 0 ? NetAmount : 0;
                const creditedAmount = NetAmount < 0 ? NetAmount : 0;
    
                // Add amounts to each type of aggregation
                NetMonthlyData[monthYear] += NetAmount;
                invoicedMonthlyData[monthYear] += invoicedAmount;
                creditedMonthlyData[monthYear] += creditedAmount;
            });
    
            // Format data for chart
            const formatData = (monthlyData) => Object.keys(monthlyData).map(monthYear => ({
                date: monthYear,
                amount: monthlyData[monthYear]
            }));
    
            setNetData(formatData(NetMonthlyData));
            setInvoicedData(formatData(invoicedMonthlyData));
            setCreditedData(formatData(creditedMonthlyData));
        };
    
        aggregateData();
    }, [ventasData, startDate, endDate, currency]);


    const getChartData = () => {
        switch (dataType) {
            case 'Net':
                return NetData;
            case 'Invoiced':
                return invoicedData;
            case 'Credited':
                return creditedData;
            default:
                return NetData; // Default to Net
        }
    };

    return (
        <>
            <Card className='dark-card-2'>
                <Card.Header className='dark-card-header'>
                    <Navbar bg="dark" variant="dark" className="ventas-date-toggle-navbar">
                        <Form className='ventas-date-toggle-turnover-form'>
                            <Form.Group controlId="startDate" className="custom-row-form-group">
                                <Form.Label className="form-label-flat">Start Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control-custom"
                                />
                            </Form.Group>
                            <Form.Group controlId="endDate" className="custom-row-form-group">
                                <Form.Label className="form-label-flat">End Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control-custom"
                                />
                            </Form.Group>
                        </Form>
                        <ToggleButtonGroup type="radio" name="dataType" value={dataType} className="mb-1 toggle-button-small" onChange={handleDataTypeChange}>
                            <ToggleButton 
                                id="Net" 
                                value="Net" 
                                variant={dataType === 'Net' ? 'warning' : 'outline-warning'}
                            >
                                Net
                            </ToggleButton>
                            <ToggleButton 
                                id="invoiced" 
                                value="Invoiced" 
                                variant={dataType === 'Invoiced' ? 'warning' : 'outline-warning'}
                            >
                                Invoiced
                            </ToggleButton>
                            <ToggleButton 
                                id="credited" 
                                value="Credited" 
                                variant={dataType === 'Credited' ? 'warning' : 'outline-warning'}
                            >
                                Credited
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Navbar>
                </Card.Header>
                <Card.Body className='body-of-toggle-ventas-card'>
                    <div className="title-and-toggle">
                        <Card.Title className="card-title">
                            Revenue by Center:
                        </Card.Title>
                        <ToggleButtonGroup type="radio" name="currency" value={currency} className="mb-1 toggle-button-small" onChange={(val) => handleCurrencyChange(val)}>
                            <ToggleButton 
                                id="local" 
                                value="Local" 
                                variant={currency === 'Local' ? 'warning' : 'outline-warning'}
                            >
                                {currency === 'Local' ? localCurrency : 'Local'}
                            </ToggleButton>
                            <ToggleButton 
                                id="group" 
                                value="Group" 
                                variant={currency === 'Group' ? 'warning' : 'outline-warning'}
                            >
                                EUR
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Container>
                        <Row>
                            <Col className="center-header"><strong>Center</strong></Col>
                            <Col className="center-header"><strong>Net Amount</strong></Col>
                            <Col className="center-header"><strong>Invoiced</strong></Col>
                            <Col className="center-header"><strong>Credited</strong></Col>
                            <Col className="center-header"><strong>Credit %</strong></Col>
                        </Row>
                        {centers.map(center => (
                            <Row key={center} className="mb-2">
                                <Col className="center-label">{`Center ${center}`}</Col>
                                <Col>{turnovers[center]?.billedAmount || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.billedPositive || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.billedNegative || 'Loading...'}</Col>
                                <Col>{turnovers[center]?.creditPercentage || 'Loading...'}</Col>
                            </Row>
                        ))}
                        <hr className="divider-line" />
                        <Row>
                            <Col className="center-header"><strong>Total</strong></Col>
                            <Col>{totals.billedAmount}</Col>
                            <Col>{totals.billedPositive}</Col>
                            <Col>{totals.billedNegative}</Col>
                            <Col>{totals.creditPercentage}</Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
            <Card className='dark-card-2 mt-4'>
                <Card.Header className='dark-card-header-tooltip'>
                <Form.Check 
                            type="switch" 
                            label="Toggle Tooltip/Label" 
                            style={{fontSize: "small", display: "flex", justifyContent: "space-evenly", alignItems: 'center', fontSize: "12px"}}
                            checked={tooltipToggle}
                            onChange={() => setTooltipToggle(!tooltipToggle)}
                            className='toggle-tooltip-label'
                        />
                    <Card.Title style={{marginTop: "7px", margin: "auto" }}>Sales Trend</Card.Title>
                </Card.Header>
                <Card.Body style={{width: "100%"}}>
                    <VentasTurnoverDashChart salesData={getChartData()} localCurrency={currency === 'Local' ? localCurrency : 'EUR'} dataType={dataType} tooltipToggle={tooltipToggle} />
                </Card.Body>
            </Card>
        </>
    );
};

export default VentasTurnoverDashMechanics;
