export function convertExcelDateToDate(excelDate) {
    // Excel's date format starts from 1st January 1900
    const startDate = new Date('1900-01-01');
    // Calculate the number of milliseconds to add
    const millisecondsToAdd = (excelDate - 1) * 24 * 60 * 60 * 1000;
    // Add milliseconds to start date
    const targetDate = new Date(startDate.getTime() + millisecondsToAdd);
    // Get day, month, and year
    return targetDate;
}

export function convertExcelDateToDateString(excelDate) {
    const date = convertExcelDateToDate(excelDate);
    return date.toISOString().split('T')[0];
}

export const excelDateToJSDate = (serial) => {
    const startDate = new Date(1899, 11, 30); // Excel dates start on Dec 30, 1899
    return new Date(startDate.getTime() + serial * 86400000); // 86400000 ms per day
};

/**
 * Converts an Excel date serial number to an ISO string date.
 * @param {number} excelDate - The Excel date serial number.
 * @returns {string} The ISO string representation of the date.
 */
export const excelDateToISO = (excelDate) => {
    const startDate = new Date(1900, 0, 1); // Excel's base date (January 1, 1900)
    const jsDate = new Date(startDate.getTime() + (excelDate - 1) * 86400000); // Adding days
    return jsDate.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
};

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};