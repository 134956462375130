// StockTable.js
import React from 'react';
import { Table } from 'react-bootstrap';
import '../../Styles/StockStyles.css';

const StockTable = ({ filteredData }) => {
  return (
    <Table striped bordered hover size='sm' variant='dark' className='stock-table' > 
      <thead>
        <tr>
          <th>SKU</th>
          <th>Colour</th>
          <th>Finish</th>
          <th>Thickness</th>
          <th>Size</th>
          <th>Mesh</th>
          <th>Segment</th>
          <th>Coverage Target</th>
          <th>Monthly Sales</th>
          <th>Stock Minimum</th>
          <th>Stock Target</th>
          <th>Current Stock</th>
          <th>Ordered Count</th>
          <th>ATP Now</th>
          <th>Ordered With HQ</th>
          <th>Supplemented ATP</th>
          <th>Need To Order</th>
          <th>Daily Usage</th>
          <th>Days On Inventory</th>
          {/* Add more table headers as needed */}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item) => (
          <tr key={item._id.$oid}>
            <td>{item.SKU}</td>
            <td>{item.Colour}</td>
            <td>{item.Finish}</td>
            <td>{item.Thickness}</td>
            <td>{item.Size}</td>
            <td>{item.Mesh}</td>
            <td>{item.Segment}</td>
            <td>{item.CoverageTarget}</td>
            <td>{item.MonthlySales}</td>
            <td>{item.StockMinimum}</td>
            <td>{item.StockTarget}</td>
            <td>{item.CurrentStock}</td>
            <td>{item.OrderedCount}</td>
            <td>{item.ATPNow}</td>
            <td>{item.OrderedWithHQ}</td>
            <td>{item.SupplementedATP}</td>
            <td>{item.NeedToOrder}</td>
            <td>{item.DailyUsage.toFixed(2)}</td>
            <td>{item.DaysOnInventory}</td>
            {/* Add more table cells as needed */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default StockTable;