import './PlanItDashStyles.css'
import React, { useEffect, useState } from 'react'
import { CalculateSlabsPending, RemoveRecordsWithEmptyInicioProduccion, AddCompletedSKUsToProductionData, 
  AddProductionDateToME2LData, AddRacksConsumptionField, ExportToSpreadsheetWithOmissions, CopyJsonToClipboardWithOmissions} from '../../../../Utils/PlanItFunctions';
// import { GetPlannedTrucksFromServer } from '../../../../Utils/APIRoutes_PlannedTrucks';
import PlanItDashInsightCard from './PlanItDashInsightCard';
import {faBoxesStacked, faCogs, faWeight, faBoxesPacking } from '@fortawesome/free-solid-svg-icons'; //faTruck,
import PlanningTable from './PlanningTable';
import Loading from '../../../Reusables/Loading'

function PlanItDash({planItProps, AppProps}) {
    const {ME2LData, productionData} = planItProps; //setPlannedTrucksData, plannedTrucksData was in here for API call.
    const [manipulatedME2LData, setManipulatedME2LData] = useState([]); 
    const {token} = AppProps;

    //calculatedStates
    const [slabsPending, setSlabsPending] = useState(0);
    const [cleansedProductionData, setCleanseProductionData] = useState();
    const [dataIsCleansed, setDataIsCleansed] = useState(false);
    const [dataIsCalculated, setDataIsCalculated] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalWeightSelected, setTotalWeightSelected] = useState(0);
    const [totalRacksOccupied, setTotalRacksOccupied] = useState(0);
    const [totalSlabsSelected, setTotalSlabsSelected] = useState(0);
    const [preparedData, setPreparedData] = useState([]);

    //Headers to Omit
    const omissions = ["Price unit, Target Quantity, Open Target Quantity"]

    //Division indicator states
    const [divisionIndicator3mm, setDivisionIndicator3mm] = useState(96);
    const [divisionIndicator6mm, setDivisionIndicator6mm] = useState(48);
    const [divisionIndicator12mm, setDivisionIndicator12mm] = useState(24);
    const [divisionIndicator20mm, setDivisionIndicator20mm] = useState(14);
    const divisionIndicatorProps = {
        divisionIndicator3mm: divisionIndicator3mm,
        divisionIndicator6mm: divisionIndicator6mm,
        divisionIndicator12mm: divisionIndicator12mm,
        divisionIndicator20mm: divisionIndicator20mm,
        setDivisionIndicator3mm: setDivisionIndicator3mm,
        setDivisionIndicator6mm: setDivisionIndicator6mm,
        setDivisionIndicator12mm: setDivisionIndicator12mm,
        setDivisionIndicator20mm: setDivisionIndicator20mm,
    };        
    

    //PlanItData Tests
    useEffect(() => {
      if (ME2LData) {

          const asyncPart = async () => {
              try {
                  const slabsPending = await CalculateSlabsPending(ME2LData[0]);
                  setSlabsPending(slabsPending);
                  // if (token) {
                  //   const plannedTrucks = await GetPlannedTrucksFromServer(token);
                  //   setPlannedTrucksData(plannedTrucks);
                  // }
              } catch (error) {
                  alert("There was an error processing the data, recommending clearing and starting again")
              }
          };

          const CleanseProductionDataAndAddSKUs = () => {
            const _cleansed = RemoveRecordsWithEmptyInicioProduccion(productionData[0]);
            const completedSKUs = AddCompletedSKUsToProductionData(_cleansed);
            setCleanseProductionData(completedSKUs);
            setDataIsCleansed(true);
          }

          asyncPart();
          CleanseProductionDataAndAddSKUs();
      }
  }, [ME2LData, token, productionData]); // Only trigger the effect when ME2LData changes


    useEffect(() => {
      if (dataIsCleansed) {
        AddProductionDateToME2LData(ME2LData[0], cleansedProductionData);
        AddRacksConsumptionField(ME2LData[0], divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm);
        setDataIsCalculated(true);
      }
    }, [dataIsCleansed, ME2LData, cleansedProductionData, divisionIndicator3mm, divisionIndicator6mm, divisionIndicator12mm, divisionIndicator20mm]);
    
    //RENDER FOR INSIGHT CARD
    const SKUsDataInsightHandler = () => {
      try {
        if (cleansedProductionData) {
          return cleansedProductionData.length;
        }
      } catch (error) {
        console.error(error)
        return 0;
      }
    }
    //RENDER FOR INSIGHT CARD
    // const UniqueTrucksDispatchedHandler = () => {
    //   try {
    //     if (plannedTrucksData) {
    //       return GetUniqueTrucksDispatched(plannedTrucksData).length;
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     return 0;
    //   }
    // };

    const handleExportToSpreadsheet = () => {
      if (selectedRows.length > 0) {
        ExportToSpreadsheetWithOmissions(selectedRows, omissions);
      } else {
        const confirmed = window.confirm("No rows selected to export, do you want to export all rows?");
        if (confirmed) {
          // Export all rows
          ExportToSpreadsheetWithOmissions(preparedData, omissions);
        }
      }
    };

    const handleCopyToClipboard = () => {
      if (selectedRows.length > 0) {
        CopyJsonToClipboardWithOmissions(selectedRows, omissions);
        alert('Copied to clipboard');
      } else {

        CopyJsonToClipboardWithOmissions(preparedData, omissions);
        alert('Copied to clipboard');
      }
    };

  return (
    <div className='-plan-it-dash-'>
      {selectedRows.length === 0 &&
        <div className="plan-it-dash-row">
          <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader='Slabs Pending to load' bodyValue={slabsPending} icon={faBoxesStacked}/>
          <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader="SKU's scheduled for production" bodyValue={SKUsDataInsightHandler()} icon={faCogs}/>
          {/* <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader='Trucks Dispatched YTD' bodyValue={UniqueTrucksDispatchedHandler()} icon={faTruck}/> */}
        </div>
      }
      {selectedRows.length > 0 &&
        <div className="plan-it-dash-row">
          <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader='Slabs selected to load' bodyValue={totalSlabsSelected} icon={faBoxesStacked}/>
          <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader="Total Weight Selected" bodyValue={totalWeightSelected} icon={faWeight}/>
          <PlanItDashInsightCard parentCssName='plan-it-dash-insight-card' cardHeader='Total Racks Occupied' bodyValue={totalRacksOccupied} icon={faBoxesPacking}/>
        </div>
      }
      <hr style={{ width: '90%', margin: '0 auto', borderColor: 'rgba(255, 255, 255, 0.5)', marginTop: "20px", marginBottom: "7px" }} />
      {dataIsCalculated &&
            <PlanningTable planItProps={planItProps} manipulatedME2LData={manipulatedME2LData} setManipulatedME2LData={setManipulatedME2LData} selectedRows={selectedRows} setSelectedRows={setSelectedRows} 
            totalWeightSelected={totalWeightSelected} setTotalWeightSelected={setTotalWeightSelected} totalRacksOccupied={totalRacksOccupied} setTotalRacksOccupied={setTotalRacksOccupied} 
            totalSlabsSelected={totalSlabsSelected} setTotalSlabsSelected={setTotalSlabsSelected} preparedData={preparedData} setPreparedData={setPreparedData} handleExportToSpreadsheet={handleExportToSpreadsheet}
            handleCopyToClipboard={handleCopyToClipboard} divisionIndicatorProps={divisionIndicatorProps}
      />
      }
      {!dataIsCalculated && <Loading />}
    </div>
  )
}

export default PlanItDash