import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

function ClassifiedDataChart({ data, classificationColors }) {
  // Prepare data structure for the chart
  const abcClasses = ['A', 'B', 'C'];
  const xyzClasses = ['X', 'Y', 'Z'];

  // Calculate total counts for percentages
  const totalMaterials = data.length;

  // Generate chart data with counts and percentages
  const chartData = abcClasses.map((abc) => {
    // Count of materials for each XYZ sub-category within the current ABC category
    const xyzCounts = xyzClasses.reduce((acc, xyz) => {
      const count = data.filter(
        (item) => item.classification === abc && item.xyzClassification === xyz
      ).length;
      acc[xyz] = count;
      return acc;
    }, {});

    // Total count for the ABC classification
    const totalCount = Object.values(xyzCounts).reduce((sum, count) => sum + count, 0);

    // Calculate percentages
    const xyzPercentages = {};
    xyzClasses.forEach((xyz) => {
      xyzPercentages[`${xyz}Percent`] = ((xyzCounts[xyz] / totalMaterials) * 100).toFixed(1);
    });

    return {
      abc,
      total: totalCount,
      ...xyzCounts,
      ...xyzPercentages,
    };
  });

  // Custom tooltip to show counts and percentages
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const totalCount = payload.reduce((sum, entry) => sum + entry.value, 0);
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '10px', border: '1px solid #ccc' }}>
          <p><strong>ABC Classification: {label}</strong></p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ color: entry.color }}>
              {entry.name}: {entry.value} ({((entry.value / totalMaterials) * 100).toFixed(1)}%)
            </p>
          ))}
          <p><strong>Total: {totalCount}</strong></p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData}>
        {/* X-axis representing ABC classifications */}
        <XAxis dataKey="abc" label={{ value: 'ABC Classification', position: 'insideBottom', offset: -5 }} />
        {/* Y-axis representing counts */}
        <YAxis allowDecimals={false} label={{ value: 'Number of Materials', angle: -90, position: 'insideLeft' }} />
        {/* Interactive tooltip */}
        <Tooltip content={<CustomTooltip />} />
        {/* Legend */}
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        {/* Stacked bars for XYZ classifications */}
        {xyzClasses.map((xyz) => (
          <Bar
            key={xyz}
            dataKey={xyz}
            stackId="a"
            name={`XYZ: ${xyz}`}
            fill={classificationColors[xyz]}
          >
            {/* Add labels to show percentages */}
            <LabelList
              dataKey={`${xyz}Percent`}
              position="insideTop"
              formatter={(value) => `${value}%`}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ClassifiedDataChart;
