import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ToggleButtonGroup, ToggleButton, FormGroup } from 'react-bootstrap';
import StockDistributionWidget from './Widgets/StockDistributionWidget';
import WorstDIOWidget from './Widgets/WorstDIOWidget';
import GettingLowIndicatorWidget from './Widgets/GettingLowIndicatorWidget';
import ExcelDRPInsightsFilterBar from './ExcelDRPInsightsFilterBar';

function ExcelDRPInsights({ excelDRPData, excelFilteredDRPData, setFilters }) {
  const [filteredData, setFilteredData] = useState([]);
  const [displayMode, setDisplayMode] = useState('Stock Distribution');

  const handleDisplayModeChange = (val) => {
    setDisplayMode(val);
  };

  useEffect(() => {
    // Initially load all data or filter data based on props
    setFilteredData(excelFilteredDRPData || excelDRPData);
  }, [excelDRPData, excelFilteredDRPData]);

  return (
    <Container fluid>
      <Row>
          <ExcelDRPInsightsFilterBar displayMode={displayMode} handleDisplayModeChange={handleDisplayModeChange}/>
      </Row>
      <div className='excel-drp-insight-display-area' style={{display: 'flex', flexGrow: "1"}}>
      <div className='excel-drp-insight-display-sub-holder' style={{display: 'flex', flexGrow: "1", height: "100%", justifyContent: 'center'}}>
        {displayMode === 'Stock Distribution' && <StockDistributionWidget data={filteredData} />}
        {displayMode === 'Worst DIO' && <WorstDIOWidget data={filteredData} />}
        {displayMode === 'Getting Low Indicator' && <GettingLowIndicatorWidget data={filteredData} />}
      </div>
      </div>
    </Container>
  );
}

export default ExcelDRPInsights;
