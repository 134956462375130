// OtherMaterialFilters.js
import React from 'react';
import { NavDropdown } from 'react-bootstrap';

const OtherMaterialFilters = ({ 
    uniqueValues, 
    setFinishFilter, 
    setThicknessFilter, 
    setFormatFilter, 
    setSegmentFilter 
}) => {
    return (
        <>
            <NavDropdown title="Finish" id="finish-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                {uniqueValues('Acabado').map((value, idx) => (
                    <NavDropdown.Item 
                        key={idx} 
                        style={{ fontSize: "medium", width: "fit-content" }}
                        onClick={() => setFinishFilter(value)}
                    >
                        {value}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
            
            <NavDropdown title="Thickness" id="thickness-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                {uniqueValues('Espesor').map((value, idx) => (
                    <NavDropdown.Item 
                        key={idx} 
                        style={{ fontSize: "medium", width: "fit-content" }}
                        onClick={() => setThicknessFilter(value)}
                    >
                        {value}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
            
            <NavDropdown title="Format" id="format-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                {uniqueValues('Medida').map((value, idx) => (
                    <NavDropdown.Item 
                        key={idx} 
                        style={{ fontSize: "medium", width: "fit-content" }}
                        onClick={() => setFormatFilter(value)}
                    >
                        {value}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
            
            <NavDropdown title="Segment" id="segment-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                {uniqueValues('Segmento').map((value, idx) => (
                    <NavDropdown.Item 
                        key={idx} 
                        style={{ fontSize: "medium", width: "fit-content" }}
                        onClick={() => setSegmentFilter(value)}
                    >
                        {value}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
        </>
    );
};

export default OtherMaterialFilters;
