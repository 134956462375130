import React, { useMemo } from 'react';
import { Table, Accordion } from 'react-bootstrap';

const ClassificationSummaryTable = React.memo(({ stats, classificationColors = {}, isXYZAvailable }) => {
  // Memoize calculated counts to avoid unnecessary recalculations
  const abcCounts = useMemo(() => stats.reduce((acc, item) => {
    const abc = item.classification;
    acc[abc] = (acc[abc] || 0) + 1;
    return acc;
  }, {}), [stats]);

  const xyzCounts = useMemo(() => stats.reduce((acc, item) => {
    const xyz = item.xyzClassification;
    acc[xyz] = (acc[xyz] || 0) + 1;
    return acc;
  }, {}), [stats]);

  const abcxyzCounts = useMemo(() => stats.reduce((acc, item) => {
    const abc = item.classification;
    const xyz = item.xyzClassification;
    if (!acc[abc]) acc[abc] = {};
    acc[abc][xyz] = (acc[abc][xyz] || 0) + 1;
    return acc;
  }, {}), [stats]);

  const abcClasses = useMemo(() => ['A', 'B', 'C'], []);
  const xyzClasses = useMemo(() => ['X', 'Y', 'Z'], []);

  return (
    <Accordion defaultActiveKey="0" className="mt-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="text-light">
          Classification Summary
        </Accordion.Header>
        <Accordion.Body style={{ backgroundColor: '#343a40', color: '#f8f9fa' }}>
          {/* ABC Classification Table */}
          <ClassificationTable title="ABC Classification Counts" data={abcClasses} counts={abcCounts} colors={classificationColors} />

          {/* XYZ Classification Table */}
          {isXYZAvailable && (
            <>
              <ClassificationTable title="XYZ Classification Counts" data={xyzClasses} counts={xyzCounts} colors={classificationColors} />
              <CombinedClassificationTable abcClasses={abcClasses} xyzClasses={xyzClasses} counts={abcxyzCounts} total={stats.length} colors={classificationColors} />
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
});

// Separate component for ABC and XYZ classification tables
const ClassificationTable = React.memo(({ title, data, counts, colors }) => (
  <>
    <h5>{title}</h5>
    <Table striped bordered hover responsive variant="dark" size="sm" className="mb-3" style={{ fontSize: '14px' }}>
      <thead>
        <tr>
          <th>Classification</th>
          <th>Number of Materials</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item}>
            <td style={{ color: colors[item] }}>{item}</td>
            <td>{counts[item] || 0}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
));

// Separate component for combined ABC-XYZ classification table
const CombinedClassificationTable = React.memo(({ abcClasses, xyzClasses, counts, total, colors }) => (
  <>
    <h5>Combined ABC-XYZ Classification Counts</h5>
    <Table striped bordered hover responsive variant="dark" size="sm" style={{ fontSize: '14px' }}>
      <thead>
        <tr>
          <th>ABC Classification</th>
          {xyzClasses.map((xyz) => (
            <th key={`header-${xyz}`}>{xyz}</th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {abcClasses.map((abc) => {
          const rowCounts = counts[abc] || {};
          const totalForRow = xyzClasses.reduce((sum, xyz) => sum + (rowCounts[xyz] || 0), 0);
          return (
            <tr key={`row-${abc}`}>
              <td style={{ color: colors[abc] }}>{abc}</td>
              {xyzClasses.map((xyz) => (
                <td key={`cell-${abc}-${xyz}`} style={{ color: colors[xyz] }}>
                  {rowCounts[xyz] || 0}
                </td>
              ))}
              <td>{totalForRow}</td>
            </tr>
          );
        })}
        <tr>
          <td>Total</td>
          {xyzClasses.map((xyz) => {
            const columnTotal = abcClasses.reduce((sum, abc) => sum + ((counts[abc] && counts[abc][xyz]) || 0), 0);
            return (
              <td key={`total-${xyz}`} style={{ color: colors[xyz] }}>
                {columnTotal}
              </td>
            );
          })}
          <td>{total}</td>
        </tr>
      </tbody>
    </Table>
  </>
));

export default ClassificationSummaryTable;
