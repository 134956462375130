import React, { useState } from 'react';
import { Navbar, NavDropdown, Nav, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { RemoveDataFromTable, connectToDB } from '../../../Utils/DBOps';
import FilterDropdown from '../../Reusables/FilterDropdown';
import OtherMaterialFilters from './SubComponents/OtherMaterialFilters';
import { exportToExcel, exportToExcelWithOmission } from '../../../Utils/ExcelExportUtils';

function JJDRPNavBar({ 
  JJDRPData, setJJDRPData, JJDRPDisplayView, setJJDRPDisplayView, setJJDRPDataValid,
  decorFilter, setDecorFilter, finishFilter, setFinishFilter, thicknessFilter, setThicknessFilter,
  formatFilter, setFormatFilter, segmentFilter, setSegmentFilter,
  selectedDecors, setSelectedDecors, multiSelectDecor, setMultiSelectDecor, 
  selectedCenters, setSelectedCenters, multiSelectCenters, setMultiSelectCenters,
  centerFilter, setCenterFilter, columns, visibleColumns, setVisibleColumns,
  recordsToShow, setRecordsToShow,
  showNeedToPlaceOnly, setShowNeedToPlaceOnly, filters
}) {
    // State for decor search input
    const [decorSearch, setDecorSearch] = useState('');
    const [centerSearch, setCenterSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState(false);
    const [columnsToOmit, setColumnsToOmit] = useState([]);
    const [clearFiltersBeforeExport, setClearFiltersBeforeExport] = useState(false); // New state for clearing filters before export

    const uniqueValues = (field) => [...new Set(JJDRPData.map(item => item[field]))];

    const handleClearData = async () => {
        const _db = await connectToDB();
        await RemoveDataFromTable(_db, "JJDRP");
        setJJDRPData([]);
        setJJDRPDisplayView('Config');
        setJJDRPDataValid(false);
    };

    // Compact label to show current selections
    const currentSelections = () => {
        const centerLabel = selectedCenters.length > 1 ? 'Multi' : selectedCenters[0] || 'None';
        const decorLabel = selectedDecors.length > 1 ? 'Multi' : selectedDecors[0] || 'None';
        const finishLabel = finishFilter || 'None';
        const thicknessLabel = thicknessFilter || 'None';
        const formatLabel = formatFilter || 'None';
        return `Center: ${centerLabel}, Decor: ${decorLabel}, Finish: ${finishLabel}, Thickness: ${thicknessLabel}, Format: ${formatLabel}`;
    };

    // Export function
    const handleExport = () => {
        // Clear filters if selected before exporting
        if (clearFiltersBeforeExport) {
            handleClearFilters();
        }
        exportToExcelWithOmission(JJDRPData, "JJDRP_Report", columnsToOmit);
        setShowExportModal(false);
    };

    const handleClearFilters = () => {
        setDecorFilter('');
        setCenterFilter('');
        setFinishFilter('');
        setThicknessFilter('');
        setFormatFilter('');
        setSegmentFilter('');
        setSelectedDecors([]);
        setSelectedCenters([]);
        setDecorSearch('');
        setCenterSearch('');
    };

    const handleModalClose = () => setShowExportModal(false);
    const handleModalShow = () => setShowExportModal(true);

    const handleColumnToggle = (column) => {
        if (visibleColumns.includes(column)) {
          setVisibleColumns(visibleColumns.filter(col => col !== column));
        } else {
          setVisibleColumns([...visibleColumns, column]);
        }
    };

    return (
        <Navbar className="JJDRP-navbar" variant="dark" bg="dark" expand="sm">
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ width: '98%', display: "flex" }}>
                    <Navbar.Brand className="navbar-brand-JJDRP" style={{ marginLeft: "15px" }}>JJDRP</Navbar.Brand>
                    <Nav className="mr-auto">
                        <NavDropdown title="Views" id="basic-nav-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                            <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={() => setJJDRPDisplayView('Table')}>Table</NavDropdown.Item>
                            <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={() => setJJDRPDisplayView('Insights')}>Insights</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Col style={{ display: "flex", justifyContent: "right", alignItems: "center", gap: "10px" }}>
                        <Button variant="warning" size='sm' style={{ color: "white", fontWeight: "bolder" }} onClick={handleClearFilters}>Clear Filters</Button>
                        <Button variant="info" size="sm" onClick={handleModalShow}>Export</Button>
                        <Button variant="danger" size='sm' onClick={handleClearData}>Clear Data</Button>
                    </Col>
                </div>

                <Row style={{ display: "flex", width: '100%', marginLeft: "25px" }}>
                    <Nav className="mr-auto">
                        <Navbar.Brand className="navbar-brand-JJDRP" style={{ marginLeft: "15px" }}>Filters</Navbar.Brand>
                        <FilterDropdown 
                            title="Center"
                            options={uniqueValues('Center').filter(value => 
                                value.toString().toLowerCase().includes(centerSearch.toLowerCase())
                            )}
                            selectedItems={selectedCenters}
                            setSelectedItems={setSelectedCenters}
                            searchValue={centerSearch}
                            setSearchValue={setCenterSearch}
                            multiSelect={multiSelectCenters}
                            setMultiSelect={setMultiSelectCenters}
                            handleSelectAll={() => setSelectedCenters(uniqueValues('Center'))}
                            setFilter={setCenterFilter}
                        />
                        <FilterDropdown 
                            title="Decor Name"
                            options={uniqueValues('Descrp. Modelo').filter(value => 
                                value.toLowerCase().includes(decorSearch.toLowerCase())
                            )}
                            selectedItems={selectedDecors}
                            setSelectedItems={setSelectedDecors}
                            searchValue={decorSearch}
                            setSearchValue={setDecorSearch}
                            multiSelect={multiSelectDecor}
                            setMultiSelect={setMultiSelectDecor}
                            handleSelectAll={() => setSelectedDecors(uniqueValues('Descrp. Modelo'))}
                            setFilter={setDecorFilter}
                        />
                        <OtherMaterialFilters 
                            uniqueValues={uniqueValues}
                            setFinishFilter={setFinishFilter}
                            setThicknessFilter={setThicknessFilter}
                            setFormatFilter={setFormatFilter}
                            setSegmentFilter={setSegmentFilter}
                        />
                    {/* Compact label to show current selections */}
                    <div style={{color: 'white', fontSize: 'small', display: "flex", justifyContent: "center", width: "44%", alignItems: "center"}}>
                        {currentSelections()}
                    </div>
                        <div className='columns-visible-dropdwon' style={{marginLeft: "auto", marginRight: "10%", display: "flex"}}>
                            <NavDropdown title="Columns" id="columns-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                                <Form className="px-3">
                                    {columns.map((column, idx) => (
                                    <Form.Check 
                                        key={idx}
                                        type="switch"
                                        id={`column-switch-${idx}`}
                                        label={column}
                                        checked={visibleColumns.includes(column)}
                                        onChange={() => handleColumnToggle(column)}
                                        style={{fontSize: "13px"}}
                                    />
                                    ))}
                                </Form>
                            </NavDropdown>
                            <NavDropdown title="Settings" id="settings-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                                <Form className="px-3">
                                    <Form.Group controlId="recordsToShowSlider">
                                        <Form.Label style={{ color: "white", fontSize: "12px" }}>Records to Show: {recordsToShow}</Form.Label>
                                        <Form.Control
                                            type="range"
                                            min="1"
                                            max="1500"
                                            value={recordsToShow}
                                            onChange={(e) => setRecordsToShow(Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Check 
                                        type="switch"
                                        id="show-need-to-place-only-switch"
                                        label="Show Need To Place Only"
                                        checked={showNeedToPlaceOnly}
                                        onChange={() => setShowNeedToPlaceOnly(!showNeedToPlaceOnly)}
                                        style={{ color: "white" , fontSize: "12px", marginTop: "15px" }}
                                    />
                                </Form>
                            </NavDropdown> 
                        </div>
                    </Nav>
                </Row>
            </div>

            {/* Export Modal */}
            <Modal show={showExportModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Options</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{color: "lightgrey"}}> 
                    <Form>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Label>Select Columns to Omit</Form.Label>
                            {columns.map((column, idx) => (
                                <Form.Check 
                                    key={idx}
                                    type="checkbox"
                                    label={column}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setColumnsToOmit([...columnsToOmit, column]);
                                        } else {
                                            setColumnsToOmit(columnsToOmit.filter(col => col !== column));
                                        }
                                    }}
                                />
                            ))}
                        </Form.Group>
                        <Form.Check 
                            type="checkbox"
                            id="clear-filters-before-export"
                            label="Clear Filters Before Export"
                            checked={clearFiltersBeforeExport}
                            onChange={() => setClearFiltersBeforeExport(!clearFiltersBeforeExport)}
                            style={{ marginTop: "15px" }}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleExport}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
        </Navbar>
    );
}

export default JJDRPNavBar;
