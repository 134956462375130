import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ToggleButtonGroup, ToggleButton, FormGroup } from 'react-bootstrap';

function ExcelDRPInsightsFilterBar({displayMode, handleDisplayModeChange}) {
  return (
    <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', width: "90%", margin: "auto" }}>
        <ToggleButtonGroup 
            type="radio" 
            name="displayMode" 
            value={displayMode} 
            onChange={handleDisplayModeChange}
            className="mb-1 toggle-button-small"
        >
            <ToggleButton 
                id="Stock-Distribution" 
                value="Stock Distribution" 
                variant={displayMode === 'Stock Distribution' ? 'warning' : 'outline-warning'}
            >
                Stock Distribution
            </ToggleButton>
            {/* <ToggleButton 
                id="sales-browser" 
                value="SalesBrowser" 
                variant={displayMode === 'SalesBrowser' ? 'warning' : 'outline-warning'}
            >
                Sales Browser
            </ToggleButton>
            <ToggleButton 
                id="top-materials" 
                value="TopMaterials" 
                variant={displayMode === 'TopMaterials' ? 'warning' : 'outline-warning'}
            >
                Top Materials
            </ToggleButton>
            <ToggleButton 
                id="credit-analysis" 
                value="CreditAnalysis" 
                variant={displayMode === 'CreditAnalysis' ? 'warning' : 'outline-warning'}
            >
                Credit Analysis
            </ToggleButton> */}
        </ToggleButtonGroup>
    </FormGroup>
  )
}

export default ExcelDRPInsightsFilterBar