import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

function ViewToggle({ view, setView }) {
  const handleViewChange = (val) => {
    console.log('View changed to:', val);
    setView(val);
  };

  return (
    <ToggleButtonGroup
      type="radio"
      name="view"
      value={view}
      onChange={handleViewChange}
      className="mb-3"
    >
      <ToggleButton id="tbg-radio-1" variant="outline-secondary" value="table">
        Table
      </ToggleButton>
      <ToggleButton id="tbg-radio-2" variant="outline-secondary" value="chart">
        Chart
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ViewToggle;