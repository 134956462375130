import React, { useEffect, useState } from 'react';
import { Card, Table, Modal } from 'react-bootstrap';
import { connectToDB, GetData } from '../../../../Utils/DBOps';
import { convertExcelDateToUKDate } from '../../../../Utils/TestingFunctions';

const groupBySalesOrder = (salesOrders) => {
  return salesOrders.map((order) => ({
    OrderNumber: order['Sales Document'] || 'N/A',
    Customer: order['Sold-To Party Name'] || 'Unknown',
    OrderDate: order['Order Date'] || 'Unknown',
    Material: order['Material Description'] || 'N/A',
    Quantity: order['Open Dlv Quantity'] || 0,
    DeliveryDate: order['Delivery Date'] || 'Unknown',
    Plant: order['Plant'] || 'Unknown',
    CustomerReference: order['Customer Reference (Header)'] || 'Unknown',
  }));
};

function SalesOrderCellViewCard({ cardCSSClassName, cardCSSHeaderClassName, cardCSSBodyClassName, cardHeaderText, selectedMaterial, show, onHide }) {
  const [salesOrderData, setSalesOrderData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const getSalesOrderData = async () => {
      if (selectedMaterial) {
        const db = await connectToDB();
        const localOrderData = await GetData(db, 'VA05'); // Adjust 'SalesOrders' to your actual collection name
        if (localOrderData) {
          const orderData = localOrderData[0].filter(item => item['Material'] === selectedMaterial);
          const groupedData = groupBySalesOrder(orderData);
          setSalesOrderData(groupedData);
        }
      }
    };
    getSalesOrderData();
  }, [selectedMaterial]);

  const handleRowToggle = (rowIndex) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(rowIndex) ? prevRows.filter((row) => row !== rowIndex) : [...prevRows, rowIndex]
    );
  };

  const tableStyle = {
    fontSize: 'small',
  };

  // Hardcoded headers
  const headers = {
    Plant: 'Plant',
    OrderNumber: 'Order Number',
    Customer: 'Customer',
    Material: 'Material',
    Quantity: 'Quantity',
    DeliveryDate: 'Delivery Date',
    CustomerReference: 'Customer Reference',
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg" variant='dark' >
      <Card className={cardCSSClassName + " dark-card"} style={{width: "100%"}}>
        <Card.Header className={cardCSSHeaderClassName}>{cardHeaderText}</Card.Header>
        <Card.Body className={cardCSSBodyClassName}>
          {salesOrderData.length > 0 ? (
            <Table striped bordered hover size="sm" style={tableStyle} variant='dark'>
              <thead>
                <tr>
                  {Object.values(headers).map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {salesOrderData.map((order, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleRowToggle(index)} style={{ cursor: 'pointer' }}>
                      {Object.keys(headers).map((key) => (
                        <td key={key}>
                          {key.toLowerCase().includes('date') ? convertExcelDateToUKDate(order[key]) : order[key]}
                        </td>
                      ))}
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr>
                        <td colSpan={Object.keys(headers).length}>
                          {/* Details for expanded rows can go here if needed */}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available for the selected Material.</p>
          )}
        </Card.Body>
      </Card>
    </Modal>
  );
}

export default SalesOrderCellViewCard;
