import React, { useState, useEffect } from 'react';
import './Styles/JJDRP.css';
import Config from './Config';
import JJDRPTable from './JJDRPTable';
import JJDRPNavBar from './Navbar';
import Insights from './Insights';

const columns = [
  'Center',
  'SKU',
  'Descrp. Modelo',
  'Acabado',
  'Espesor',
  'Medida',
  'Segmento',
  'Coverage (Months)',
  'Transit Days',
  'Invoiced 12M<',
  'Invoiced 3M<',
  'Daily Rate',
  'Transit Buffer',
  'Target Stock B4 Grwth',
  'Growth Expectation?',
  'Target Stock After Growth',
  'Mimimum Stock (RO Trigger)',
  'MSIB (Min Slabs in 1 Batch)',
  'Stock Max',
  'Current Available Stock (In Plant)',
  'Current Stock in Transit',
  'Current Purchase Orders',
  'Current Stock in HQ',
  'Supplemented ATP',
  // 'DIO',
  // 'Target',
  'N2O Raw',
  'Real Need',
  'Bookmatch SKU',
  'Bookmatch Preference',
  'Sister Code',
  'Final Orders to Place',
];

function JJDRPMain() {
  const [JJDRPFile, setJJDRPFile] = useState(null);
  const [JJDRPData, setJJDRPData] = useState([]);
  const [JJDRPDataValid, setJJDRPDataValid] = useState(false);
  const [JJDRPDisplayView, setJJDRPDisplayView] = useState('Config');

  // Filter states
  const [decorFilter, setDecorFilter] = useState('');
  const [centerFilter, setCenterFilter] = useState('');
  const [finishFilter, setFinishFilter] = useState('');
  const [thicknessFilter, setThicknessFilter] = useState('');
  const [formatFilter, setFormatFilter] = useState('');
  const [segmentFilter, setSegmentFilter] = useState('');
  
  // Additional states for Decor/Center multi-select
  const [selectedDecors, setSelectedDecors] = useState([]);
  const [multiSelectDecor, setMultiSelectDecor] = useState(false);
  
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [multiSelectCenters, setMultiSelectCenters] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(columns); 

  // New states for records to show and toggle switch
  const [recordsToShow, setRecordsToShow] = useState(150);
  const [showNeedToPlaceOnly, setShowNeedToPlaceOnly] = useState(false);

  useEffect(() => {
    if (JJDRPDataValid) {
      setJJDRPDisplayView('Table');
    }
  }, [JJDRPDataValid]);

  const applyFilters = (data) => {
    let filteredData = data.filter(item => {
      // Multi-select decor filter
      const decorMatch = multiSelectDecor
        ? (selectedDecors.length > 0 ? selectedDecors.includes(item['Descrp. Modelo']) : true)
        : (decorFilter ? item['Descrp. Modelo'] === decorFilter : true);
  
      // Multi-select center filter
      const centerMatch = multiSelectCenters
        ? (selectedCenters.length > 0 ? selectedCenters.includes(item.Center) : true)
        : (centerFilter ? item.Center === centerFilter : true);
  
      // Other filters
      const finishMatch = finishFilter ? item.Acabado === finishFilter : true;
      const thicknessMatch = thicknessFilter ? item.Espesor === thicknessFilter : true;
      const formatMatch = formatFilter ? item.Medida === formatFilter : true;
      const segmentMatch = segmentFilter ? item.Segmento === segmentFilter : true;
  
      return decorMatch && centerMatch && finishMatch && thicknessMatch && formatMatch && segmentMatch;
    });

    // Apply ShowNeedToPlaceOnly filter
    if (showNeedToPlaceOnly) {
      filteredData = filteredData.filter(item => Number(item['Orders to Place']) > 0 || Number(item['Adjusted Order']) > 0);
    }

    return filteredData;
  };

  //test

  // Filtered data
  const filteredData = applyFilters(JJDRPData);

  return (
    <div className='-JJDRP-Main-'>
      {JJDRPDataValid &&
        <JJDRPNavBar 
        JJDRPData={JJDRPData} 
        setJJDRPData={setJJDRPData} 
        setJJDRPDataValid={setJJDRPDataValid} 
        setJJDRPDisplayView={setJJDRPDisplayView} 
        JJDRPDisplayView={JJDRPDisplayView}
        // Pass filter states and setters to the navbar
        decorFilter={decorFilter}
        setDecorFilter={setDecorFilter}
        finishFilter={finishFilter}
        setFinishFilter={setFinishFilter}
        thicknessFilter={thicknessFilter}
        setThicknessFilter={setThicknessFilter}
        formatFilter={formatFilter}
        setFormatFilter={setFormatFilter}
        segmentFilter={segmentFilter}
        setSegmentFilter={setSegmentFilter}
        // Pass multi-select related states
        selectedDecors={selectedDecors}
        setSelectedDecors={setSelectedDecors}
        multiSelectDecor={multiSelectDecor}
        setMultiSelectDecor={setMultiSelectDecor}
        selectedCenters={selectedCenters}
        setSelectedCenters={setSelectedCenters}
        multiSelectCenters={multiSelectCenters}
        setMultiSelectCenters={setMultiSelectCenters}
        centerFilter={centerFilter}
        setCenterFilter={setCenterFilter}
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        // Pass new states
        recordsToShow={recordsToShow}
        setRecordsToShow={setRecordsToShow}
        showNeedToPlaceOnly={showNeedToPlaceOnly}
        setShowNeedToPlaceOnly={setShowNeedToPlaceOnly}
      />}
      {!JJDRPDataValid && JJDRPDisplayView === "Config" && 
        <Config 
          setJJDRPData={setJJDRPData} 
          setJJDRPFile={setJJDRPFile} 
          JJDRPFile={JJDRPFile} 
          JJDRPData={JJDRPData} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPDisplayView={setJJDRPDisplayView}
        />}
      {JJDRPDataValid && JJDRPDisplayView === "Table" && 
        <JJDRPTable 
          JJDRPData={filteredData} 
          JJDRPDisplayView={JJDRPDisplayView} 
          setJJDRPDisplayView={setJJDRPDisplayView} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPData={setJJDRPData}
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          recordsToShow={recordsToShow} // Pass the recordsToShow prop
        />}
      {JJDRPDataValid && JJDRPDisplayView === "Insights" && 
        <Insights 
          JJDRPData={filteredData} 
          JJDRPDisplayView={JJDRPDisplayView} 
          setJJDRPDisplayView={setJJDRPDisplayView} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPData={setJJDRPData}
        />}
    </div>
  );
}

export default JJDRPMain;
