import React, { useEffect, useState } from 'react';
import './ExcelPurchaseOrders.css';
import { Container } from 'react-bootstrap';
import ExcelPurchaseOrdersTable from './ExcelPurchaseOrdersTable';
import ExcelPurchaseOrdersHeaderCard from './ExcelPurchaseOrdersHeaderCard';

function ExcelPurchaseOrders({ ExcelProps, excelDRPData }) {
  const [FilteredDataToCopyToSAP, setFilteredDataToCopyToSAP] = useState([]);

  useEffect(() => {
    if (excelDRPData && excelDRPData.length > 0) {
      const filtered = excelDRPData.filter(item => item.NeedToOrder > 0.99);
      setFilteredDataToCopyToSAP(filtered);
    }
  }, [excelDRPData]);

  return (
    <div className='-EXCEL-PURCHASE-ORDERS-'>
      <Container fluid className='purchase-orders-body'>
        {FilteredDataToCopyToSAP && FilteredDataToCopyToSAP.length > 0 ? (
          <>
            <ExcelPurchaseOrdersHeaderCard FilteredDataToCopyToSAP={FilteredDataToCopyToSAP} excelDRPData={excelDRPData}/>
            <ExcelPurchaseOrdersTable FilteredDataToCopyToSAP={FilteredDataToCopyToSAP} />
          </>
        ) : (
          <span>Nothing to order</span>
        )}
      </Container>
    </div>
  );
}

export default ExcelPurchaseOrders;
