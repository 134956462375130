import './CUBOAnalyserDemandSubsidiary.css'
import React, {useState, useEffect} from 'react'
import CUBOAnalyserDemandSubsidiarySelectionBox from './CUBOAnalyserDemandSubsidiarySelectionBox';
import CUBOAnalyserDemandSubsidaryMainView from './CUBOAnalyserDemandSubsidaryMainView';

function CUBOAnalyserDemandSubsidiary({CUBOAnalyserProps, demandDataProps, demandFilters, selectedCenter, setSelectedCenter}) {
  const { CUBODRPData, dataStockSubsid, filteredDataCopy, setFilteredDataCopy, centersIncludingTransitDays } = CUBOAnalyserProps;
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchSubsidaryDRPData = async () => {
      if (CUBODRPData && CUBODRPData.length > 0 && dataStockSubsid && dataStockSubsid.length > 0) {
        console.log(filteredDataCopy);
        console.log(dataStockSubsid);
        console.log(demandFilters);
      }
    };
    fetchSubsidaryDRPData();
  },[CUBODRPData, dataStockSubsid, demandFilters])

  return (
    <div className='CUBO-analyser-demand-subsidiary-main'>
        <CUBOAnalyserDemandSubsidiarySelectionBox dataStockSubsid={dataStockSubsid} setSelectedCenter={setSelectedCenter} centersIncludingTransitDays={centersIncludingTransitDays} selectedCenter={selectedCenter}/>
        {demandFilters && 
        <CUBOAnalyserDemandSubsidaryMainView 
          CUBODRPData={CUBODRPData} 
          demandDataProps={demandDataProps} 
          CUBOAnalyserProps={CUBOAnalyserProps} 
          demandFilters={demandFilters} 
          setFilteredDataCopy={setFilteredDataCopy} 
          selectedCenter={selectedCenter}
          setSelectedCenter={setSelectedCenter}
          />
        }
    </div>
  )
}

export default CUBOAnalyserDemandSubsidiary