import React, { useEffect, useState } from 'react'
import { GetDIOData, GetME21NData, GetCUBOData, GetVA05Data, GetIQ09Data } from '../Auth/APIRoutes'
import Loading from '../Components/Reusables/Loading';
import InsightBrowser from '../Components/Insights/InsightBrowser';

function Insights({AppProps, DataProps}) {
  const {setDIOData, setSalesData, setStockData, setOrderData, setPOWithHQData} = DataProps;
  const {setCanUseInsights, token} = AppProps;
  const [isLoading, setIsLoading] = React.useState(false);
  const [didError, setDidError] = React.useState(false);
  const [SelectedComponent , setSelectedComponent] = useState('Stock');


  const syncLoadingTrue = () => {
    setIsLoading(true);
  };

  const syncLoadingFalse = () => {
    setIsLoading(false);
  };


  useEffect(() => {
    const handleInsightsData = async () => {
      try {
        if (token) {
          syncLoadingTrue();
          const { error: DioError, response: DIOResponse } = await GetDIOData(token);
          const { error: ME21NError, response: ME21NResponse } = await GetME21NData(token);
          const { error: CUBOError, response: CUBOResponse } = await GetCUBOData(token);
          const { error: VA05Error, response: VA05Response } = await GetVA05Data(token);
          const { error: StockError, response: StockResponse } = await GetIQ09Data(token);

          DIOResponse ? setDIOData(DIOResponse) : console.log(DioError) && setDidError(true);
          ME21NResponse ? setPOWithHQData(ME21NResponse) : console.log(ME21NError) && setDidError(true);
          CUBOResponse ? setSalesData(CUBOResponse) : console.log(CUBOError) && setDidError(true);
          VA05Response ? setOrderData(VA05Response) : console.log(VA05Error) && setDidError(true);
          StockResponse ? setStockData(StockResponse) : console.log(StockError) && setDidError(true);

          syncLoadingFalse();
        }
      } catch (error) {
        console.log(error);
        setCanUseInsights(false);
        setDidError(true);
      }
    };
    handleInsightsData();
  }, [token, setDIOData, setPOWithHQData, setSalesData, setOrderData, setStockData, setCanUseInsights]);

  return (
    <div className='/INSIGHTS'>
        {isLoading ? <Loading /> :
          <InsightBrowser AppProps={AppProps} DataProps={DataProps} setSelectedComponent={setSelectedComponent} SelectedComponent={SelectedComponent}/>
        }
    </div>
  )
}

export default Insights