import React, { useMemo, useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import VBC_TopMaterialsTable from './VBC_TopMaterialsTable';
import VBC_TopMaterialsBarChart from './VBC_TopMaterialsBarChart';
import VBC_MaterialSalesChart from './VBC_TopMaterialsSalesChart';
import { aggregateDataByMonthYear } from '../../../../Utils/AggregateFunctions';

const VBC_TopMaterials = ({ data, dataType, localCurrency }) => {
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [chartData, setChartData] = useState([]);

    // Function to filter and transform data based on dataType
    const getTopMaterials = useMemo(() => {
        let filteredData = data;

        switch (dataType) {
            case "Gross":
                // No additional filtering needed for gross, as it considers all sales
                break;
            case "Invoiced":
                // Filter for positive billed amounts (invoiced data)
                filteredData = filteredData.filter(item => item['Net Billed Amount'] > 0);
                break;
            case "Credited":
                // Filter for negative billed amounts (credited data)
                filteredData = filteredData.filter(item => item['Net Billed Amount'] < 0);
                break;
            default:
                break;
        }

        // Remove any invalid materials
        filteredData = filteredData.filter(item => item['Material'] !== "400000014");

        // Aggregate data to find top materials
        const materialTotals = filteredData.reduce((acc, item) => {
            const material = item['Material'];
            if (!acc[material]) {
                acc[material] = {
                    'Material': material,
                    'Material Description': item['Material Description'],
                    'Total Quantity': 0,
                    'Total Amount': 0
                };
            }
            acc[material]['Total Quantity'] += item['Billed Quantity'];
            acc[material]['Total Amount'] += item['Net Billed Amount'];
            return acc;
        }, {});

        // Convert object to array and sort by total quantity or amount based on dataType
        let sortedMaterials = Object.values(materialTotals);
        
        if (dataType === "Credited") {
            // Sort by total amount in ascending order for credited data
            sortedMaterials.sort((a, b) => a['Total Amount'] - b['Total Amount']);
        } else {
            // Sort by total quantity for invoiced or gross data
            sortedMaterials.sort((a, b) => b['Total Quantity'] - a['Total Quantity']);
        }
        
        return sortedMaterials;
    }, [data, dataType]);

    // Function to get chart data based on the selected material and dataType
    useEffect(() => {
        if (selectedMaterial) {
            const materialData = data.filter(item => item['Material'] === selectedMaterial);

            // Aggregate the data by month/year and filter by dataType
            const aggregatedData = aggregateDataByMonthYear(materialData, 'Billed Quantity', value => {
                if (dataType === "Invoiced") return value > 0;
                if (dataType === "Credited") return value < 0;
                return true; // For "Gross", include all data
            });

            setChartData(aggregatedData);
        } else {
            // For top 10 materials, don't aggregate by month/year, just use the filtered data
            const topMaterialsData = getTopMaterials.slice(0, 10).map((material, index) => ({
                id: index, // Unique key
                material: material['Material Description'],
                totalQuantity: material['Total Quantity'],
            }));
            setChartData(topMaterialsData);
        }
    }, [selectedMaterial, data, getTopMaterials, dataType]);

    return (
        <Card className="dark-card-2 top-materials-card">
            <Card.Body className='top-materials-body'>
                <Row className='top-material-table-row'>
                    <VBC_TopMaterialsTable topMaterials={getTopMaterials} setSelectedMaterial={setSelectedMaterial} localCurrency={localCurrency}/>
                </Row>
                <Row className='top-material-chart-row'>
                    {chartData && chartData.length > 0 && (
                        selectedMaterial !== "" ? (
                            <VBC_MaterialSalesChart materialSalesData={chartData} localCurrency={localCurrency} />
                        ) : (
                            <VBC_TopMaterialsBarChart topMaterials={chartData} localCurrency={localCurrency}/> 
                        )
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default VBC_TopMaterials;
