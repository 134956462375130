import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { faBoxes, faBoxesStacked} from '@fortawesome/free-solid-svg-icons'    
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ExcelDashCardME22N({ExcelProps}) {
    const { excelDRPConcept } = ExcelProps;
    const [ME22NData, setME22NData] = useState([]);
    const [totalSlabsOrderedPO, setTotalSlabsOrderedPO] = useState(0);
    const [totalSlabsPendingDelivery, setTotalSlabsPendingDelivery] = useState(0);
    const [top5OrderedMaterials, setTop5OrderedMaterials] = useState(null);

    useEffect(() => {
        const handleDataCheck = async () => {
            if (excelDRPConcept) {
                setME22NData(excelDRPConcept.totalPOsByMaterial);
            }
            else {
                console.log("error");
            }
        };
        handleDataCheck();
    }, [excelDRPConcept]);


    useEffect(() => {
        try {
            if (ME22NData && Array.isArray(ME22NData)) {
                // Calculate total orders and items
                const totalSlabsOrderedPO = ME22NData.reduce((acc, item) => {
                    // Assuming Material is a property of each item in ME22NData
                    if (item.material && item.material.startsWith("TB")) {
                        return acc + item.totalOrdered;
                    }
                    return acc;
                }, 0);
                const totalSlabsPendingDelivery = ME22NData.reduce((acc, item) => {
                    // Assuming Material is a property of each item in ME22NData
                    if (item.material && item.material.startsWith("TB")) {
                        return acc + item.totalPendingDelivery;
                    }
                    return acc;
                }, 0);

                setTotalSlabsOrderedPO(totalSlabsOrderedPO);
                setTotalSlabsPendingDelivery(totalSlabsPendingDelivery);

                //extract top 5 ordered materials
                const top5OrderedMaterials = ME22NData
                    ?.filter((material) => material.material.startsWith("TB"))
                    .sort((a, b) => {
                        const aQuantity = a.totalOrdered;
                        const bQuantity = b.totalOrdered;
                        return bQuantity - aQuantity;
                    })
                    .slice(0, 5)

                setTop5OrderedMaterials(top5OrderedMaterials);
            }
        } catch (error) {
            console.log(error);
        }
    }, [ME22NData]);

    function HorizontalLine() {
        return <div style={{ borderBottom: '1px solid white', margin: '5px 0', width: "50%" }} />;
        }

  return (
    <Card className='excel-dash-card dark-card'>
        <Card.Header style={{ fontWeight: "600" }}>
            <FontAwesomeIcon icon={faBoxes} style={{marginRight: "1vw"}} />ME22N Purchase Orders
        </Card.Header>
        <Card.Body className='excel-dash-card-body' style={{fontSize: "medium"}}>
            <span>
                Total slabs ordered: {totalSlabsOrderedPO}
            </span>
            {HorizontalLine()}
            <span>
              Total slabs pending delivery: {totalSlabsPendingDelivery}
            </span>
            {HorizontalLine()}
            <ResponsiveContainer width='75%' height={280} style={{marginTop: "1vh"}}>
              <BarChart data={top5OrderedMaterials} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey='materialDescription' fontSize='small' angle={-25} textAnchor='end' interval={0} tickMargin={1} height={130} />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc' }} />
                <Legend verticalAlign='top' height={36} />
                <Bar dataKey='totalOrdered' fill='rgb(243, 227, 54)'>
                  <LabelList dataKey='totalOrdered' position='top' />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
        </Card.Body>
    </Card>
  )
}

export default ExcelDashCardME22N