import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBtnThickness({data, handleFilterThicknessChange}) {
  return (
    <div className='navbtn-thickness'>
        {data[0] && (
        data
            // Extract unique color values
            .map((item) => item.Thickness)
            .filter((value, index, self) => self.indexOf(value) === index)
            // Sort the unique color values alphabetically
            .sort()
            // Render the dropdown items
            .map((thick) => (
            <NavDropdown.Item
                key={thick}
                className='navbtn-thick__item'
                onClick={handleFilterThicknessChange}
            >
                {thick}
            </NavDropdown.Item>
            ))
        )}
    </div>
  )
}

export default NavBtnThickness