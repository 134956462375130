import React from 'react';
import '../../Styles/Loading.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';

function Loading({statusMessage}) {
  return (
    <div className="loading-container">
      <div className="spinner">
          <FontAwesomeIcon icon={faHourglass} size="1x" color="white" />
      </div>
      <div style={{ margin: "1vw", display: "flex", flexDirection: "column"}}>
        <span style={{fontSize: "small"}}>Loading...</span>
        <span style={{fontSize: "small"}}>{statusMessage}</span>
      </div>

    </div>
  );
}

export default Loading;
