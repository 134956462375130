import './InsightComponentStyles.css';
import React, { useEffect } from 'react';
import { Container, Card, Accordion, Row, Form, FormControl } from 'react-bootstrap';

function InsightStockBrowser({ data, dataProperty1, dataProperty2, title, bodyTitle }) {
  const [filteredData, setFilteredData] = React.useState([]);
  const [filter1, setFilter1] = React.useState('');
  const [filter2, setFilter2] = React.useState('');
  const sortedData = data.sort((a, b) => (a[dataProperty1] < b[dataProperty1] ? 1 : -1))
  //srted data total quantity:
    const totalQuantity = sortedData.reduce((a, b) => a + (b[dataProperty1] || 0), 0);
    console.log(sortedData);

    useEffect(() => {
      setFilteredData(applyFilters());
    }, [filter1, filter2, data]);

    const applyFilters = () => {
      const filteredItems = data.filter(
        (item) =>
          item['Description'].toLowerCase().includes(filter1.toLowerCase()) &&
          item[dataProperty2].toLowerCase().includes(filter2.toLowerCase())
      );
      return filteredItems;
    };
  
    const handleFilterColour = (e) => {
      setFilter1(e.target.value);
    };
  
    const handleFilter2 = (e) => {
      setFilter2(e.target.value);
    };
  
  return (
    <div className='INSIGHTSTOCKBROWSER'>
      <div className='center MarginTop'>
        <Card>
          <Card.Header className='dark-insight-card-header'>
            <Container className='Stock-Browser-Nav-Header'>
              <span style={{fontSize: "large"}}>{title}</span>
              <div className='flex-row'>
                <Form.Label className='searchColourLabel'>Search Colour:</Form.Label>
                <Form.Control className='colour-search' type="text" placeholder="search" onChange={handleFilterColour}/>
                <Form.Label className='searchColourLabel'>Search 2:</Form.Label>
                <Form.Control className='colour-search' type="text" placeholder="search" onChange={handleFilter2}/>
              </div>
            </Container>
          </Card.Header>
          <Card.Body className='dark-insight-card-body stock-browser-card'>
            <Card.Text>{bodyTitle}</Card.Text>
            <Container className='insight-row-cards-holder'>
              {filteredData.map((item, index) => (
                <Accordion key={index}
                  defaultActiveKey="0"
                  className='stock-insight-accordion'
                >
                <Accordion.Item eventKey={index} className='stockbrowser-accordion-item'>
                  <Accordion.Header className='insight-accordion-header'>{item.Material + " / " + item.Description}{" - Quantity: " + item.Quantity}</Accordion.Header>
                    <Accordion.Body className='insight-accordion-body'>
                      {item.Batches.map((batch, batchIndex) => (
                        <Accordion key={batchIndex}>
                          <Accordion.Item eventKey={batchIndex} className='stockbrowser-accordion-item'>
                            <Accordion.Header className='insight-accordion-header'>Batch: {batch.Batch} / Slabs: {batch.Quantity}</Accordion.Header>
                            <Accordion.Body className='insight-accordion-body'>
                              {batch.SerialNumbers.map((serial, serialIndex) => (
                                <Row key={serialIndex}>{"Serial:  " + serial.SerialNumber + "  /  Tone:  " + serial.Tono + "  /  Area:  " + serial.AreaComercial}</Row>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>
              ))}
            </Container>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default InsightStockBrowser;
