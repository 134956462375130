import * as XLSX from "xlsx";
import {connectToDB, StoreData, GetData} from './DBOps'

export const SaveDebtPortalData = async (debtPortalPath) => {

    //Get References to the files and read data.
    let arrayBuffedDebtPortal = await debtPortalPath.arrayBuffer();

    let debtPortalBook = XLSX.readFile(arrayBuffedDebtPortal);

    let debtPortalSheet = debtPortalBook.Sheets["Sheet1"];

    let debtPortalData = XLSX.utils.sheet_to_json(debtPortalSheet);

    // Remove rows where Amount in Local Currency is present but Customer is missing.
    debtPortalData = debtPortalData.filter(item =>
        item["Customer"] && // Ensure Customer is not missing
        (item["Amount in Local Currency"] || item["Amount in Local Currency"] === 0) // Ensure Amount in Local Currency is present
    );


    const _db = await connectToDB();
    try {
        await StoreData(_db, "DebtPortalData", debtPortalData);
        console.log("Debt Portal Data Saved Successfully");
        const debtPortalDataFromDB = await GetData(_db, "DebtPortalData");
        return debtPortalDataFromDB;
    }   catch (error) {
        console.log(error);
    }
};

export const LoadDebtPortalData = async () => {
    const _db = await connectToDB();
    try {
        const debtPortalData = await GetData(_db, "DebtPortalData");
        return debtPortalData;
    } catch (error) {
        console.log(error);
    }
};

export const calculateOpenItems = (debtPortalData) => {
    let openItems = debtPortalData.filter(item => item["Clearing Document"] === "");
    return openItems.Length > 0 ? openItems : 0;
};

export const calculateOpenDebtValue = (debtPortalData) => {
    let openItems = calculateOpenItems(debtPortalData);
    let openDebtValue = openItems.reduce((acc, item) => acc + item["Amount in Local Currency"], 0);
    return openDebtValue;
};
