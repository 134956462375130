import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function FormGroupTwoInputsOneTextOneDate({controlID1,  label1, placeholder1, value1, setTextFieldValue1, label2, controlID2, placeholder2, value2, setTextFieldValue2}) {
    return (
        <div>
          <div className="form-row">
            <Form.Group controlId={controlID1} className="form-group">
              <Form.Label>{label1}</Form.Label>
              <Form.Control
                type="text"
                placeholder={placeholder1}
                value={value1}
                onChange={(e) => setTextFieldValue1(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId={controlID2} className="form-group">
              <Form.Label>{label2}</Form.Label>
              <Form.Control
                type="Date"
                placeholder={placeholder2}
                value={value2}
                onChange={(e) => setTextFieldValue2(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
    );
}

export default FormGroupTwoInputsOneTextOneDate


