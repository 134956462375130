import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

function ExcelDashNavBarViewFunctions({handleSelectViewClick, navBarBrandTitle, navDropDownIdAndTitle}) {
  return (
    <>
        <Navbar.Brand>{navBarBrandTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
            <Nav>
                <NavDropdown
                    style={{ fontSize: "medium" }}
                    id={navDropDownIdAndTitle}
                    title={navDropDownIdAndTitle}
                    menuVariant="dark"
                >
                    <NavDropdown.Item onClick={handleSelectViewClick}>Analytics</NavDropdown.Item>
                    <NavDropdown.Item onClick={handleSelectViewClick}>Check Stock</NavDropdown.Item>
                    <NavDropdown.Item onClick={handleSelectViewClick}>DRP Summary</NavDropdown.Item>
                    <NavDropdown.Item onClick={handleSelectViewClick}>Delivery Schedule</NavDropdown.Item>
                    <NavDropdown.Item onClick={handleSelectViewClick}>Insights</NavDropdown.Item>

                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    </>
  )
}

export default ExcelDashNavBarViewFunctions