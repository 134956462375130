import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

function TransitDaysModal({ show, handleClose, centersIncludingTransitDays, setCentersIncludingTransitDays }) {

  const handleTransitDaysChange = (index, value) => {
    const newCenters = [...centersIncludingTransitDays];
    newCenters[index].transitDays = Number(value); // Ensure the value is a number
    setCentersIncludingTransitDays(newCenters);
  };

  const renderTableRows = () => {
    const rows = [];
    for (let i = 0; i < centersIncludingTransitDays.length; i += 4) {
      rows.push(
        <tr key={i}>
          {centersIncludingTransitDays.slice(i, i + 4).map((center, index) => (
            <td key={index}>
              <Form.Group controlId={`transitDays-${i + index}`}>
                <Form.Label style={{ fontSize: "small" }}>{center.Number + " - " + center.Name}</Form.Label>
                <Form.Control
                  type="number"
                  value={center.transitDays}
                  onChange={(e) => handleTransitDaysChange(i + index, e.target.value)}
                />
              </Form.Group>
            </td>
          ))}
          {centersIncludingTransitDays.slice(i, i + 4).length < 4 &&
            Array(4 - centersIncludingTransitDays.slice(i, i + 4).length)
              .fill(null)
              .map((_, idx) => <td key={`empty-${i + idx}`} />)}
        </tr>
      );
    }
    return rows;
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Set Transit Days for Centers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Table size="sm" variant="dark" bordered hover>
            <tbody>
              {renderTableRows()}
            </tbody>
          </Table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TransitDaysModal;
