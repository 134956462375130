import './CUBOAnalyserDemandSubsidiary.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

function CUBOAnalyserDemandTableSubsidiary({ CUBODRPData, setFilteredDataCopy, demandDataProps, selectedCenter, demandFilters, setSelectedCenter }) {
  const { ESlabsOnly, OptimalSizesOnly, showDailyRates } = demandDataProps;
  const [sortColumn, setSortColumn] = useState('StockHQ');
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortedFilteredData, setSortedFilteredData] = useState([]);
  const tableRef = useRef();

  const DRPSubsidTableMap = [
    { drpPropertyName: 'Material', displayName: 'Material' },
    { drpPropertyName: 'Decor', displayName: 'Decor' },
    { drpPropertyName: 'DecorName', displayName: 'DecorName' },
    { drpPropertyName: 'MaterialDescription', displayName: 'MaterialDescription' },
    { drpPropertyName: 'Thickness', displayName: 'Thickness' },
    { drpPropertyName: 'Format', displayName: 'Format' },
    { drpPropertyName: 'Finish', displayName: 'Finish' },
    { drpPropertyName: 'Mesh', displayName: 'Mesh' },
    { drpPropertyName: 'StockHQ', displayName: 'Stock HQ', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.StockSubsid', displayName: 'Ctr Stock', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.SalesOrderedSubsid', displayName: 'S Orders', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.PurchaseOrderedSubsid', displayName: 'P Orders', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.InvoicedSubsid', displayName: 'Invoiced', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.SafetyStockSubsid', displayName: 'Sf Stock', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.NeedToOrderSubsid', displayName: 'Need To PO', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.MonthlyRateSubsid', displayName: 'Monthly Rate', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.DailyRateSubsid', displayName: 'Daily Rate', numericSort: true },
    { drpPropertyName: 'SubsidiaryDRP.ATPSubsid', displayName: 'ATP', numericSort: true }
  ];

  const handleRowClick = (event, item) => {
    console.log(event.target);
    console.log(item);
  };

  const handleSort = (column) => {
    const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const applyFiltersAndSort = useCallback(() => {
    let data = Array.isArray(CUBODRPData) ? CUBODRPData : [];
    data = data.filter(item => {
      return (!demandFilters.decor || item.DecorName === demandFilters.decor) &&
             (!demandFilters.finish || item.Finish === demandFilters.finish) &&
             (!demandFilters.size || item.Format === demandFilters.size) &&
             (!demandFilters.thickness || item.Thickness === demandFilters.thickness);
    });

    if (selectedCenter) {
      data = data.filter(item => {
        const subsidiaryData = item.SubsidiaryDRP.find(sub => sub.Center === selectedCenter);
        return subsidiaryData && (
          subsidiaryData.StockSubsid > 0 ||
          subsidiaryData.PurchaseOrderedSubsid > 0 ||
          subsidiaryData.SalesOrderedSubsid > 0 ||
          subsidiaryData.InvoicedSubsid > 0
        );
      });
    }

    const isNumericSort = DRPSubsidTableMap.find(entry => entry.drpPropertyName === sortColumn)?.numericSort || false;

    data.sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (sortColumn.startsWith('SubsidiaryDRP.')) {
        const propName = sortColumn.split('.')[1];
        aValue = a.SubsidiaryDRP.find(sub => sub.Center === selectedCenter)?.[propName];
        bValue = b.SubsidiaryDRP.find(sub => sub.Center === selectedCenter)?.[propName];
      }

      aValue = isNumericSort ? parseFloat(aValue) : aValue;
      bValue = isNumericSort ? parseFloat(bValue) : bValue;

      if (isNumericSort) {
        return sortDirection === 'asc' ? (aValue - bValue) : (bValue - aValue);
      } else {
        return sortDirection === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
      }
    });

    if (ESlabsOnly) {
      data = data.filter(item => typeof(item.Material) === 'string' && item.Material.endsWith('E'));
    }

    if (OptimalSizesOnly) {
      data = data.filter(item => item.Format === '01' || item.Format === '02');
    }

    const areFiltersActive = demandFilters.decor || demandFilters.finish || demandFilters.size || demandFilters.thickness;
    if (!areFiltersActive && data.length > 250) {
      data = data.slice(0, 250);
    } else if (areFiltersActive && data.length > 350) {
      data = data.slice(0, 350);
    }

    setSortedFilteredData(data);
    setFilteredDataCopy(data);
  }, [CUBODRPData, demandFilters, sortColumn, sortDirection, ESlabsOnly, OptimalSizesOnly, selectedCenter]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [CUBODRPData, demandFilters, sortColumn, sortDirection, ESlabsOnly, OptimalSizesOnly, selectedCenter, applyFiltersAndSort]);

  const renderSubsidiaryValue = (subsidiaryData, column) => {
    if (!subsidiaryData) return 'N/A';
    const fieldMapping = {
      'SubsidiaryDRP.StockSubsid': 'StockSubsid',
      'SubsidiaryDRP.SalesOrderedSubsid': 'SalesOrderedSubsid',
      'SubsidiaryDRP.PurchaseOrderedSubsid': 'PurchaseOrderedSubsid',
      'SubsidiaryDRP.InvoicedSubsid': 'InvoicedSubsid',
      'SubsidiaryDRP.SafetyStockSubsid': 'SafetyStockSubsid',
      'SubsidiaryDRP.NeedToOrderSubsid': 'NeedToOrderSubsid',
      'SubsidiaryDRP.MonthlyRateSubsid': 'MonthlyRateSubsid',
      'SubsidiaryDRP.DailyRateSubsid': 'DailyRateSubsid',
      'SubsidiaryDRP.ATPSubsid': 'ATPSubsid'
    };
    const field = fieldMapping[column];
    return Number(subsidiaryData[field]).toLocaleString();
  };

  return (
    <div className='CUBO-DRP-ANALYSER-TABLE'>
      <Table striped bordered hover size='sm' variant='dark' style={{ fontSize: 'small', margin: 'auto', marginTop: '5px' }} ref={tableRef}>
        <thead>
          <tr>
            {DRPSubsidTableMap.map(({ drpPropertyName, displayName }) => {
              if (drpPropertyName === 'SubsidiaryDRP.MonthlyRateSubsid' && !showDailyRates) {
                return null;
              }
              if (drpPropertyName === 'SubsidiaryDRP.DailyRateSubsid' && !showDailyRates) {
                return null;
              }
              return (
                <th key={drpPropertyName} onClick={() => handleSort(drpPropertyName)} style={{ cursor: 'pointer', backgroundColor: 'rgb(0, 123, 255)', color: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
                  {displayName} {sortColumn === drpPropertyName && <FontAwesomeIcon icon={sortDirection === 'asc' ? faSortUp : faSortDown} style={{ marginLeft: '5px' }} />}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedFilteredData.length > 0 ? sortedFilteredData.map((item, index) => {
            const subsidiaryData = selectedCenter && item.SubsidiaryDRP.find(sub => sub.Center === selectedCenter);
            return (
              <React.Fragment key={index}>
                <tr onClick={(event) => handleRowClick(event, item)}>
                  {DRPSubsidTableMap.map(({ drpPropertyName }) => {
                    let isVisibleColumn = drpPropertyName !== 'SubsidiaryDRP.MonthlyRateSubsid' || showDailyRates;
                    isVisibleColumn = isVisibleColumn && (drpPropertyName !== 'SubsidiaryDRP.DailyRateSubsid' || showDailyRates);
                    return (
                      isVisibleColumn && (
                        <td key={drpPropertyName}>
                          {['SubsidiaryDRP.StockSubsid', 'SubsidiaryDRP.SalesOrderedSubsid', 'SubsidiaryDRP.PurchaseOrderedSubsid', 'SubsidiaryDRP.InvoicedSubsid', 'SubsidiaryDRP.SafetyStockSubsid', 'SubsidiaryDRP.NeedToOrderSubsid', 'SubsidiaryDRP.MonthlyRateSubsid', 'SubsidiaryDRP.DailyRateSubsid', 'SubsidiaryDRP.ATPSubsid'].includes(drpPropertyName)
                            ? renderSubsidiaryValue(subsidiaryData, drpPropertyName)
                            : item[drpPropertyName]}
                        </td>
                      )
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          }) : <tr><td colSpan={DRPSubsidTableMap.length}>No data available</td></tr>}
        </tbody>
      </Table>
    </div>
  );
}

export default CUBOAnalyserDemandTableSubsidiary;
