import React, {useState} from 'react'
import DiscountEntryWizard from './DiscountEntryWizard'
import DiscountViewer from './DiscountViewer'
import NavBarDiscounts from '../Components/Discounts/NavBarDiscounts'

function DiscountManager({AppProps}) {
    // const [discounts, setDiscounts] = useState([])
    // const [discountsLoaded, setDiscountsLoaded] = useState(false)
    const [modeSelected, setModeSelected] = useState('')

  return (
    <div className='/DISCOUNT_MANAGER'>
        <NavBarDiscounts AppProps={AppProps} setModeSelected={setModeSelected}/>
        {modeSelected === 'New' && <DiscountEntryWizard AppProps={AppProps} />}
        {modeSelected === 'Browse' && <DiscountViewer AppProps={AppProps} />}
    </div>
  )
}

export default DiscountManager