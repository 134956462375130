import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const VBC_MaterialSalesChart = ({ materialSalesData }) => {
    // Ensure the data is sorted by date in ascending order
    const sortedData = [...materialSalesData].sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <ResponsiveContainer width="100%" height={260}>
            <LineChart data={sortedData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line 
                    type="monotone" 
                    dataKey="amount" 
                    stroke="#007bff" 
                    dot={false} 
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default VBC_MaterialSalesChart;
