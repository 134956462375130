import React, {useState} from 'react'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'


function ExcelPurchaseOrdersTable({FilteredDataToCopyToSAP}) {
    const [showAlert, setShowAlert] = useState(false);

    const timedAlertString = (message, duration) => {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, duration);
    };

    const handleCopyMaterialsToClipboard = (e) => {
        let materials = FilteredDataToCopyToSAP.map((item) => item.Material).join('\n');
        navigator.clipboard.writeText(materials);
        timedAlertString('Materials copied!', 2000);
      };
      
      const handleCopyQtysToClipboard = (e) => {
        let qtys = FilteredDataToCopyToSAP.map((item) => item.NeedToOrder.toFixed(0)).join('\n');
        navigator.clipboard.writeText(qtys);
        timedAlertString('Quantities copied!', 2000);
      };
  return (
    <div className='-EXCEL-PURCHASE-ORDERS-TABLE'>
      {showAlert && <div className="alert">Copied!</div>}
        <Table striped bordered hover responsive size='sm' variant="dark" style={{ fontSize: 'small' }} className='purchase-orders-table'>
            <thead>
            <tr>
                <th style={{margin: "3px"}}>
                    Material: 
                    <FontAwesomeIcon 
                        icon={faClipboard} 
                        onClick={(e) => handleCopyMaterialsToClipboard(e)} 
                        style={{marginLeft: "10px", fontSize: "1.3rem"}}>
                    </FontAwesomeIcon>
                </th>  
                <th>Decor:</th>
                <th>Finish:</th>
                <th>Thickness: </th>
                <th>
                    Need To PO: 
                    <FontAwesomeIcon 
                        icon={faClipboard} 
                        onClick={(e) => handleCopyQtysToClipboard(e)}
                        style={{marginLeft: "10px", fontSize: "1.3rem"}}>
                    </FontAwesomeIcon></th>
                <th>Material OBC: </th>
                <th>Current Stock:</th>
                <th>Target Stock:</th>
            </tr>
            </thead>
            <tbody>
            {FilteredDataToCopyToSAP.map((item, index) => (
                <tr key={index}>
                <td>{item.Material}</td>
                <td>{item['Decor']}</td>
                <td>{item['Finish Description']}</td>
                <td>{item['Thickness']}</td>
                <td>{item.NeedToOrder.toFixed(0)}</td>
                <td>{item.MaterialOrdererdByClient}</td>
                <td>{item.MaterialInStock}</td>
                <td>{item.TargetStock}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    </div>
  )
}

export default ExcelPurchaseOrdersTable