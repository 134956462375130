import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

function GettingLowIndicatorWidget({ data, threshold = 50 }) {
  const lowStockItems = data.filter(item => item.MaterialInStock < threshold);

  return (
    <div className="widget-container">
      <h5>Materials Getting Low</h5>
      <ListGroup variant="flush">
        {lowStockItems.length > 0 ? (
          lowStockItems.map((item, index) => (
            <ListGroup.Item key={index}>
              {item.Material} - {item.MaterialInStock} units
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>All materials above threshold</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
}

export default GettingLowIndicatorWidget;
