import * as XLSX from "xlsx";
import {connectToDB, StoreData, GetData} from './DBOps'

export const SlabWeights = {
    3: 40,
    6: 80,
    12: 160,
    20: 250,
}

export const ReadExceslForPlanIt = async (ME2LFile, ProductionSheetFile) => {
    try {
        let arrayBuffedME2L = await ME2LFile.arrayBuffer();
        let arrayBuffedProductionSheetData = await ProductionSheetFile.arrayBuffer();

        let ME2LBook = XLSX.readFile(arrayBuffedME2L);
        let ProductionSheetBook = XLSX.readFile(arrayBuffedProductionSheetData);

        let ME2LSheet = ME2LBook.Sheets["Sheet1"];
        let ProductionSheet = ProductionSheetBook.Sheets["Sheet1"];
        
        let ME2LData = XLSX.utils.sheet_to_json(ME2LSheet);
        let ProductionSheetData = XLSX.utils.sheet_to_json(ProductionSheet);

        const PlanItDatas = {
            ME2LData: ME2LData,
            ProductionSheetData: ProductionSheetData
        }

        return PlanItDatas;

    } catch (error) {
        console.log("Something went wrong")
    }

}

export const AddUniquePOAndPositionToME2L = async (ME2LData) => {
    ME2LData.forEach((data) => {
        let uniquePO = data["PO Number"] + "-" + data["Item"];
        data["UniquePO"] = uniquePO;
    });
    console.log(ME2LData);
}

export const SavePlanItDataToDexie = async (PlanItData) => {
    let db = await connectToDB();
    await StoreData(db, "ME2LData", PlanItData.ME2LData);
    await StoreData(db, "ProductionSheetData", PlanItData.ProductionSheetData);
};

export const GetPlanItDataFromDexie = async () => {
    try {
        let db = await connectToDB();
        let ME2LData = await GetData(db, "ME2LData");
        let PlannerSheetData = await GetData(db, "PlannerSheetData");
        let ProductionSheetData = await GetData(db, "ProductionSheetData");
    
        const PlanItDatas = {
            ME2LData: ME2LData,
            PlannerSheetData: PlannerSheetData,
            ProductionSheetData: ProductionSheetData
        }
    
        return PlanItDatas;
    } catch (error) {
        console.log("Something went wrong getting PlanItData from Dexie");
        return error;
    }
};

export const GetME2LDataFromDexie = async () => {
    try {
        let db = await connectToDB();
        let ME2LData = await GetData(db, "ME2LData");
        return ME2LData;
    } catch (error) {
        console.log("Something went wrong getting ME2LData from Dexie");
        return error;
    }
};

export const GetPlannerSheetDataFromDexie = async () => {
    try {
        let db = await connectToDB();
        let PlannerSheetData = await GetData(db, "PlannerSheetData");
        return PlannerSheetData;
    } catch (error) {
        console.log("Something went wrong getting PlannerSheetData from Dexie");
        return error;
    }
};

export const GetProductionSheetDataFromDexie = async () => {
    try {
        let db = await connectToDB();
        let ProductionSheetData = await GetData(db, "ProductionSheetData");
        return ProductionSheetData;
    } catch (error) {
        console.log("Something went wrong getting ProductionSheetData from Dexie");
        return error;
    }
};

export const ClearPlanItDatas = async () => {
    let db = connectToDB();
    await db.ME2LData.clear();
    await db.PlannerSheetData.clear();
    await db.ProductionSheetData.clear();
};


export const CalculateSlabsPending = async (ME2LData) => {
    let slabsPending = 0;
    try {
        ME2LData.forEach((data) => {
            // Check if the material starts with "TB"
            if (data["Material"].startsWith("TB")) {
                // Attempt to get the quantity from either of the two possible fields
                const quantity = data["Still to be delivered (qty)"] || data["Por entregar (cantidad)"];

                // Ensure the quantity is a positive number before adding to the total
                if (quantity > 0) {
                    slabsPending += quantity;
                }
            }
        });
    } catch (error) {
        console.log(error);
    }
    return slabsPending;
};


export const GetColumnNumberByHeaderValue = (sheetData, headerValue) => {
    let columnNumber = -1;
    for (let cell in sheetData) {
        if (sheetData[cell].v === headerValue) {
            columnNumber = sheetData[cell].c;
            break;
        }
    }
    return columnNumber;
}

//PRODUCTION DATA FUNCTIONS
export function RemoveRecordsWithEmptyInicioProduccion(data) {
    return data.filter(obj => obj["Inicio producción"]);
}


export const AddProductionDateToME2LData = (ME2LData, cleansedProductionSheetDataWithSKUs) => {
    ME2LData.forEach((data) => {
        let productionDate = "31/12/99"; // Initialize with a date far in the future
        let starting2Characters = data["Material"] ? data["Material"].substring(0, 2) : "";

        if (starting2Characters === "TB") {
            let ME2LMaterial = data["Material"];
            let ME2LFinish = ME2LMaterial.substring(7, 9);
            let ME2LdecorCode = ME2LMaterial.substring(2, 7);
            let ME2Lthickness = ME2LMaterial.substring(9, 11);
            let ME2LformatCode = ME2LMaterial.substring(12, 14);

            let earliestProductionDate = Infinity; // Initialize with a large value

            cleansedProductionSheetDataWithSKUs.forEach((SKU) => {
                let SKUFinish = SKU["Acabado"];
                let SKUDecorCode = SKU["Código Color"];
                let SKUThickness = SKU["Espesor"];
                let SKUFormatCode = SKU["Formato"];

                if (
                    SKUFinish && SKUDecorCode && SKUThickness && SKUFormatCode &&
                    ME2LdecorCode === SKUDecorCode &&
                    ME2Lthickness === SKUThickness &&
                    ME2LformatCode === SKUFormatCode
                ) {
                    if (ME2LFinish === SKUFinish || (SKUFinish === "GR" && (ME2LFinish === "US" || ME2LFinish === "DP"))) {
                        let productionDateValue = SKU["Inicio producción"];
                        if (typeof productionDateValue === 'number') {
                            // Convert the numeric date to a JavaScript date
                            let date = new Date((productionDateValue - (25567 + 2)) * 86400 * 1000); // Excel date conversion
                            let dateConverted = date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format for comparison
                            let currentProductionTimeStamp = Date.parse(dateConverted);

                            if (currentProductionTimeStamp < earliestProductionDate) {
                                earliestProductionDate = currentProductionTimeStamp;
                                productionDate = date.toLocaleDateString("en-GB"); // Convert to DD/MM/YYYY format for display
                            }
                        }
                    }
                }
            });

            if (productionDate === "31/12/99") {
                productionDate = "No Data";
            }

            data["Production Date"] = productionDate;
        } else {
            productionDate = "No Data";
            data["Production Date"] = productionDate;
        }
    });
};


//PLANNED TRUCK FUNCTIONS
export const GetUniqueTrucksDispatched = (plannedTrucksData) => {
    let uniqueTrucks = [];
    plannedTrucksData.forEach((truck) => {
        if (!uniqueTrucks.includes(truck["Truck Reference"])) {
            uniqueTrucks.push(truck["Truck Reference"]);
        }
    });
    return uniqueTrucks;
};

//RANDOM FUNCTIONS
export function convertExcelDateToUKDate(excelDate) {
    // Excel's date format starts from 1st January 1900
    const startDate = new Date('1900-01-01');
    // Calculate the number of milliseconds to add
    const millisecondsToAdd = (excelDate - 1) * 24 * 60 * 60 * 1000;
    // Add milliseconds to start date
    const targetDate = new Date(startDate.getTime() + millisecondsToAdd);
    // Get day, month, and year
    const day = targetDate.getDate();
    const month = targetDate.getMonth() + 1;
    const year = targetDate.getFullYear();
    // Format the date in DD/MM/YYYY format
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
}

export const AddCompletedSKUsToProductionData = (productionData) => {
    const _data = [...productionData];
    _data.forEach((data) => {
        let SKUStartTB = "TB";
        let decorCode = data["Código Color"];
        let thickness = data["Espesor"];
        let formatCode = data["Formato"];
        let finishCode = data["Acabado"];
        let mesh = "";
        if (parseInt(thickness) === 3 || parseInt(thickness) === 6) {
            mesh = "P";
        } else mesh = "N"
        
        let SKU = SKUStartTB + decorCode + finishCode + thickness + mesh + formatCode + "E";
        data["SKU"] = SKU;
    });
    return _data;
};


export const AddRacksConsumptionField = (ME2LData, divInd3, divInd6, divInd12, divInd20) => {
    ME2LData.forEach((data) => {
        let totalWeight = 0;
        let racksConsumption = 0;
        let material = data["Material"];
        let starting2Characters = material.substring(0, 2);
        let thickness = data["Material"].substring(9, 11);
        let qty = data["Still to be delivered (qty)"] || data["Por entregar (cantidad)"];
        if (starting2Characters === "TB") {
            if (qty > 0) {
                totalWeight = qty * SlabWeights[parseInt(thickness)];
            }
        }
        else {
            totalWeight = "N/A"
        }
        data["Total Weight"] = totalWeight;

        let formattedConsumption = 0;
        if (totalWeight !== "N/A") {
            
            if (thickness === "20") {
                racksConsumption = qty / divInd20;
                formattedConsumption = racksConsumption.toFixed(2);
            }
            if (thickness === "12") {
                racksConsumption = qty / divInd12;
                formattedConsumption = racksConsumption.toFixed(2);
            }
            if (thickness === "06") {
                racksConsumption = qty / divInd6;
                formattedConsumption = racksConsumption.toFixed(2);
            }
            if (thickness === "03") {
                racksConsumption = qty / divInd3;
                formattedConsumption = racksConsumption.toFixed(2);
            }
        }
        data["Racks Consumption"] = formattedConsumption;
    });
}

export const CalculateSlabsOfSelectedRows = (SelectedRows) => {
    let totalWeight = 0;
    SelectedRows.forEach((row) => {
        let qty = row["Still to be delivered (qty)"] || row["Por entregar (cantidad)"];

        totalWeight += qty;
    });
    return totalWeight;
}

export const CalculateRacksOfSelectedRows = (SelectedRows, divInd3, divInd6, divInd12, divInd20 ) => {
    let totalRacks = 0;
    SelectedRows.forEach((row) => {
        let qty = row["Still to be delivered (qty)"] || row["Por entregar (cantidad)"];
        let thickness = row["Material"].substring(9, 11);
        let racks = 0;
        if (thickness === "20") {
            racks = qty / divInd20;
        }
        if (thickness === "12") {
            racks = qty / divInd12;
        }
        if (thickness === "06") {
            racks = qty / divInd6;
        }
        if (thickness === "03") {
            racks = qty / divInd3;
        }
        totalRacks += racks;
    });
    return totalRacks.toFixed(2);
};

export const CalculateTotalWeightOfSelectedRows = (SelectedRows) => {
    let totalWeight = 0;
    SelectedRows.forEach((row) => {
        let qty = row["Still to be delivered (qty)"] || row["Por entregar (cantidad)"];
        let thickness = row["Material"].substring(9, 11);
        let itemWeight = qty * SlabWeights[parseInt(thickness)] 
        console.log(itemWeight);
        totalWeight += itemWeight;
    });
    return totalWeight;
};

export function ExportToSpreadsheet(selectedRows, totalWeightSelected, totalRacksOccupied, totalSlabsSelected) {
    // Create a new worksheet
    let selected_rows = [...selectedRows, { 'Still to be delivered (qty)': totalSlabsSelected, 'Total Weight': totalWeightSelected, 'Racks Consumption': totalRacksOccupied, 'Material': 'Total' }];
    const ws = XLSX.utils.json_to_sheet(selected_rows);
    
    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PlanIt');

    // Convert the workbook to a binary string
    const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/octet-stream' });

    // Create a download link for the blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    let date = getFileSafeDateHourString();
    a.download = 'selected_rows' + date +'.xlsx';
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
}

export function ExportToSpreadsheetWithOmissions(selectedRows, totalWeightSelected, totalRacksOccupied, totalSlabsSelected, headersToOmit = []) {
    // Create a new worksheet
    let selected_rows = [...selectedRows, { 'Still to be delivered (qty)': totalSlabsSelected, 'Total Weight': totalWeightSelected, 'Racks Consumption': totalRacksOccupied, 'Material': 'Total' }];
    const ws = XLSX.utils.json_to_sheet(selected_rows);

    // Remove omitted headers
    if (headersToOmit.length > 0) {
        ws['!cols'] = ws['!cols'].filter(col => !headersToOmit.includes(col.w));
    }
    
    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PlanIt');

    // Convert the workbook to a binary string
    const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/octet-stream' });

    // Create a download link for the blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    let date = getFileSafeDateHourString();
    a.download = 'selected_rows' + date +'.xlsx';
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
}


function getFileSafeDateHourString() {
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}-${String(now.getMonth() + 1).padStart(2, '0')}-${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
    const safeString = formattedDate.replace(/[/\\:*?"<>|]/g, '-');
    return safeString;
}

// Helper function to convert data to array buffer
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export function CopyJsonToClipboard(data) {
    // Extract headers from the properties of the first object
    const headers = Object.keys(data[0]);

    // Prepare the data rows
    const dataRows = data.map((item) => {
        return headers.map((header) => item[header]).join("\t");
    });

    // Combine headers and data rows
    const clipboardData = [headers.join("\t"), ...dataRows].join("\n");

    // Copy data to clipboard
    const el = document.createElement('textarea');
    el.value = clipboardData;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function CopyJsonToClipboardWithOmissions(data, headersToOmit = []) {
    // Extract headers from the properties of the first object
    let headers = Object.keys(data[0]);

    // Remove omitted headers
    headers = headers.filter(header => !headersToOmit.includes(header));

    // Prepare the data rows
    const dataRows = data.map((item) => {
        return headers.map((header) => item[header]).join("\t");
    });

    // Combine headers and data rows
    const clipboardData = [headers.join("\t"), ...dataRows].join("\n");

    // Copy data to clipboard
    const el = document.createElement('textarea');
    el.value = clipboardData;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

// function timestampToUKDate(timestamp) {
//     // Check if the timestamp is a valid number and not NaN
//     if (isNaN(timestamp) || timestamp < 0 || !Number.isInteger(timestamp)) {
//         return 12/31/2099;
//     }

//     // Create a new Date object using the timestamp
//     var date = new Date(timestamp);

//     return date;
// }

// function swapDayAndMonthInStringDate(dateString) {
//     // Split the date string into day, month, and year parts
//     const parts = dateString.split('/');
//     if (parts.length !== 3) {
//         // Invalid date format
//         return null;
//     }

//     // Swap the day and month parts
//     const temp = parts[0];
//     parts[0] = parts[1];
//     parts[1] = temp;

//     // Join the parts back together with '/'
//     return parts.join('/');
// }





