import React, {useState, useEffect} from 'react'
import { Container } from 'react-bootstrap'
import ChartViewer from './ChartViewer'
import ChartSelector from './ChartSelector'
import Card from 'react-bootstrap/Card'

function Top10DynamicChartCpt({ dynamicChartData, salesData, selectedChart, setSelectedChart }) {
  const [chartDataValid, setChartDataValid] = useState(false);


  useEffect(() => {
    if (dynamicChartData !== null) {
      setChartDataValid(true);
    }
  }, [dynamicChartData]);

  return (
    <Card className='-dynamic-chart-cpt- dark-card'>
      {chartDataValid && (
        <Container fluid className='dynamic-chart-container'>
          <ChartViewer dynamicChartData={dynamicChartData} selectedChart={selectedChart} salesData={salesData} />
          <ChartSelector setSelectedChart={setSelectedChart} />
        </Container>
      )}
    </Card>
  );
}


export default Top10DynamicChartCpt