import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

const FilterControls = ({ startDate, endDate, selectedCurrency, onFilterChange, onApplyFilters }) => {
    return (
        <Form className="mb-4">
            <Row>
                <Col md={3}>
                    <Form.Group controlId="startDate">
                        <Form.Label className="form-label-flat">Start Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => onFilterChange('startDate', e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="endDate">
                        <Form.Label className="form-label-flat">End Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => onFilterChange('endDate', e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Label className="form-label-flat">Currency</Form.Label>
                    <Form.Check
                        type="radio"
                        label="GBP"
                        id="currency-gbp"
                        checked={selectedCurrency === 'GBP'}
                        onChange={() => onFilterChange('currency', 'GBP')}
                    />
                    <Form.Check
                        type="radio"
                        label="EUR"
                        id="currency-eur"
                        checked={selectedCurrency === 'EUR'}
                        onChange={() => onFilterChange('currency', 'EUR')}
                    />
                </Col>
                <Col md={2} className="d-flex align-items-end">
                    <Button
                        variant="warning"
                        onClick={onApplyFilters}
                        className="mt-2"
                    >
                        Apply Filters
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default FilterControls;
