import { excelDateToJSDate } from "./DateOps";

/**
 * Aggregates and optionally transforms data by month and year based on the property.
 * @param {Array} data - The data to aggregate.
 * @param {Date} startDate - The start date for aggregation.
 * @param {Date} endDate - The end date for aggregation.
 * @param {string} property - The property to aggregate.
 * @param {Function} [transform] - Optional function to transform each entry.
 * @returns {Array} - Aggregated and optionally transformed data.
 */
export const aggregateAndTransform = (data, property, transform) => {
    const aggregatedData = aggregateDataByMonthYear(data, property);
    return transform ? aggregatedData.map(transform) : aggregatedData;
};

export const getGrossDataInvoicedAmount = (data, currency) => {
    const property = currency === 'GBP' ? "Net Billed Amount" : "Net Billed Amount (Group Currency)";
    return aggregateDataByMonthYear(data, property);
};

// Function to get gross data
export const getGrossData = (data, property) => {
    return aggregateDataByMonthYear(data, property);
};

// Function to get invoiced data (positive amounts)
export const getInvoicedDataAmount = (data, currency) => {
    const property = currency === 'GBP' ? "Net Billed Amount" : "Net Billed Amount (Group Currency)";
    return aggregateDataByMonthYear(data, property, amount => amount > 0);
};

export const getInvoicedData = (data, property) => {
    return aggregateDataByMonthYear(data, property, amount => amount > 0);
};

// Function to get credited data (negative amounts)
export const getCreditedDataAmount = (data, currency) => {
    const property = currency === 'GBP' ? "Net Billed Amount" : "Net Billed Amount (Group Currency)";
    return aggregateDataByMonthYear(data, property, amount => amount < 0);
};

export const getCreditedData = (data, property) => {
    return aggregateDataByMonthYear(data, property, amount => amount < 0);
};

export const aggregateDataByMonthYear = (data, valueField, filter = () => true) => {
    const monthlyData = {};
    data.forEach(item => {
        const value = item[valueField];
        // Apply the filter before aggregation
        if (!filter(value)) {
            return; // Skip items that don't match the filter
        }

        // Convert the Excel date to a JavaScript Date object
        const date = excelDateToJSDate(item["Invoice Document Date"]);
        const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        
        if (!monthlyData[monthYear]) {
            monthlyData[monthYear] = 0;
        }
        monthlyData[monthYear] += value || 0;
    });

    return Object.keys(monthlyData).map(monthYear => ({
        date: monthYear,
        amount: monthlyData[monthYear]
    }));
};

// eslint-disable-next-line no-unused-vars
const aggregateDataByCustomerAndMaterial = (data) => {
    const customerData = {};

    data.forEach(item => {
        const customer = item["Client Description"];
        const material = item["Material Description"];
        const netBilledAmount = item["Net Billed Amount"];

        // Initialize customer data if not present
        if (!customerData[customer]) {
            customerData[customer] = {
                totalGross: 0,
                totalInvoiced: 0,
                totalCredited: 0,
                materials: {}
            };
        }

        // Aggregate totals by customer
        customerData[customer].totalGross += netBilledAmount;
        if (netBilledAmount > 0) {
            customerData[customer].totalInvoiced += netBilledAmount;
        } else {
            customerData[customer].totalCredited += Math.abs(netBilledAmount);
        }

        // Initialize material data if not present
        if (!customerData[customer].materials[material]) {
            customerData[customer].materials[material] = {
                totalGross: 0,
                totalInvoiced: 0,
                totalCredited: 0
            };
        }

        // Aggregate totals by material for the customer
        customerData[customer].materials[material].totalGross += netBilledAmount;
        if (netBilledAmount > 0) {
            customerData[customer].materials[material].totalInvoiced += netBilledAmount;
        } else {
            customerData[customer].materials[material].totalCredited += Math.abs(netBilledAmount);
        }
    });

    // Format the data
    const formattedData = Object.keys(customerData).map(customer => {
        return {
            customerName: customer,
            totalGross: customerData[customer].totalGross,
            totalInvoiced: customerData[customer].totalInvoiced,
            totalCredited: customerData[customer].totalCredited,
            materialsSold: Object.keys(customerData[customer].materials).map(material => {
                return {
                    materialDescription: material,
                    totalGross: customerData[customer].materials[material].totalGross,
                    totalInvoiced: customerData[customer].materials[material].totalInvoiced,
                    totalCredited: customerData[customer].materials[material].totalCredited
                };
            })
        };
    });

    return formattedData;
};

export const CalculateKPIs = (data, mode, localCurrency = 'GBP', locale = 'en-GB') => {
    const filteredData = data.filter(item => {
        if (mode === 'Gross') return true;
        if (mode === 'Invoiced') return item['Net Billed Amount'] > 0;
        if (mode === 'Credited') return item['Net Billed Amount'] < 0;
        return true;
    });

    // KPI Calculations
    const totalBilledQty = filteredData.reduce((acc, item) => acc + (item['Billed Quantity'] || 0), 0);
    const totalInvoicedAmount = filteredData.reduce((acc, item) => acc + (item['Net Billed Amount'] || 0), 0);
    const totalInvoicedAmountEUR = filteredData.reduce((acc, item) => acc + (item['Net Billed Amount (Group Currency)'] || 0), 0);
    const totalM2Purchased = filteredData.reduce((acc, item) => acc + (item['Área Total Real\nTabla Factura (M2)'] || 0), 0);
    const totalTransportBilled = filteredData
        .filter(item => (item['Material Description'] || '').toLowerCase().includes('transport'))
        .reduce((acc, item) => acc + (item['Net Billed Amount'] || 0), 0);

    // Currency Formatter for Local Currency
    const currencyFormatterLocal = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: localCurrency
    });
    
    // Currency Formatter for Group Currency (EUR)
    const currencyFormatterEUR = new Intl.NumberFormat('en-EU', { // 'en-EU' for EUR
        style: 'currency',
        currency: 'EUR'
    });

    // Return KPIs
    return [
        { label: 'Net Invoiced Amount', value: currencyFormatterLocal.format(totalInvoicedAmount) },
        { label: 'Net Invoiced Amount (Group Currency)', value: currencyFormatterEUR.format(totalInvoicedAmountEUR) },
        { label: 'Billed Quantity', value: totalBilledQty },
        { label: 'Área Total Real\nTabla Factura (M2)', value: totalM2Purchased.toFixed(2) },
        { label: 'Transport Cost', value: currencyFormatterLocal.format(totalTransportBilled) },
    ];
};

