import React, {useState} from 'react'
import ExcelNavBar from '../Components/Excels/ExcelDRP/ExcelNavBar';
import ExcelFileConfig from '../Components/Excels/ExcelDRP/ExcelFileConfig';
import JustStockFileConfig from '../Components/Excels/ExcelDRP/JustStockFileConfig';
import ExcelStockChecker from '../Components/Excels/ExcelDRP/ExcelStockChecker/ExcelStockChecker';
import ExcelDRPStateManager from '../Components/Excels/ExcelDRP/ExcelDRPStateManager';

function Excel({AppProps, DataProps, ExcelProps}) {
    const [defaultView, setDefaultView] = useState("");
    let componentToRender;

    // Conditionally set the component based on the selected view
    switch (defaultView) {
      case "Just Stock":
        componentToRender = <JustStockFileConfig AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} setDefaultView={setDefaultView}/>;
        break;
      case "Stock Checker":
        componentToRender = <ExcelStockChecker ExcelProps={ExcelProps} AppProps={AppProps} />;
        break;
      case "Full Config":
        componentToRender = <ExcelFileConfig AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} />;
        break;
      // Add more cases for other components
      default:
        componentToRender = <ExcelFileConfig AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} />;
    }
  
    
  return (
    <div className='/EXCEL'>
        <ExcelNavBar AppProps={AppProps} DataProps={DataProps} ExcelProps={ExcelProps} setDefaultView={setDefaultView}/>
        <ExcelDRPStateManager ExcelProps={ExcelProps} AppProps={AppProps} DataProps={DataProps} />
        {componentToRender}
    </div>
    
  )
}

export default Excel