// import * as XLSX from "xlsx";

export function convertExcelDateToUKDate(excelDate) {
    // Excel's date format starts from 1st January 1900
    const startDate = new Date('1900-01-01');
    // Calculate the number of milliseconds to add
    const millisecondsToAdd = (excelDate - 1) * 24 * 60 * 60 * 1000;
    // Add milliseconds to start date
    const targetDate = new Date(startDate.getTime() + millisecondsToAdd);
    // Get day, month, and year
    const day = targetDate.getDate();
    const month = targetDate.getMonth() + 1;
    const year = targetDate.getFullYear();
    // Format the date in DD/MM/YYYY format
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
}