// StockFilter.js
import React, { useState } from 'react';
import NavBarStock from './NavBarCpts/NavBarStock';

const StockFilter = ({ data, updateFilteredData, AppProps, DataProps }) => {
  const [filter, setFilter] = useState({
    Colour: '',
    Thickness: '',
    Finish: ''
    // Add more filters as needed
  });

  const handleFilterColourChange = (e) => {
    const { text } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      Colour: text, // No need for ["Colour"]
    }));
    console.log(text);  
  };
  
  const handleFilterFinishChange = (e) => {
    const { text } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      Finish: text, // No need for ["Finish"]
    }));
    console.log(text);  
  };
  
  const handleFilterThicknessChange = (e) => {
    const { text } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      Thickness: text, // No need for ["Thickness"]
    }));
    console.log(text);  
  };
  

  const applyFilters = () => {
    const colourFilter = filter.Colour.toLowerCase();
    const thicknessFilter = filter.Thickness;
    const finishFilter = filter.Finish.toLowerCase();
  
    const filteredData = data.filter((item) => {
      const isColourMatch = colourFilter === '' || item.Colour.toLowerCase().includes(colourFilter);
      const isThicknessMatch = thicknessFilter === '' || item.Thickness === parseInt(thicknessFilter);
      const isFinishMatch = finishFilter === '' || item.Finish.toLowerCase().includes(finishFilter);
  
      return isColourMatch && isThicknessMatch && isFinishMatch;
    });
  
    // Update the filtered data in the parent component
    updateFilteredData(filteredData);
  };

  const ResetFilters = () => {
    setFilter({
      Colour: '',
      Thickness: '',
      Finish: ''
    });
    updateFilteredData(data);
  }

  return (
        <NavBarStock 
            data={data} 
            setFilter={setFilter} 
            updateFilteredData={updateFilteredData} 
            handleFilterColourChange={handleFilterColourChange}
            handleFilterFinishChange={handleFilterFinishChange} 
            handleFilterThicknessChange={handleFilterThicknessChange}
            applyFilters={applyFilters} 
            ResetFilters={ResetFilters}
            filter={filter}
            AppProps={AppProps}
            DataProps={DataProps}
        />
  );
};

export default StockFilter;
