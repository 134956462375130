import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { excelDateToJSDate, formatDate } from '../../../../Utils/DateOps';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';
import '../_VentasStyles/VBC_SalesBrowserTable.css'; // Import the CSS file

const VBC_SalesBrowserTable = ({ data, localCurrency }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    // Helper function to group and sort data
    const transformData = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
            return {};
        }

        // Convert and sort the data by Invoice Document Date in descending order
        const sortedData = [...data].sort((a, b) => {
            const dateA = excelDateToJSDate(a["Invoice Document Date"]).getTime();
            const dateB = excelDateToJSDate(b["Invoice Document Date"]).getTime();
            return dateB - dateA; // Descending order
        });

        // Group sorted data
        return sortedData.reduce((acc, item) => {
            const invoice = item["Invoice Document"];
            if (!acc[invoice]) {
                acc[invoice] = [];
            }
            acc[invoice].push(item);
            return acc;
        }, {});
    };

    // Transform and sort data
    const groupedData = transformData(data);

    if (Object.keys(groupedData).length === 0) {
        return <p>No Data to view</p>;
    }

    return (
        <div className='sales-browser-table-holder'>
            <Table striped bordered hover variant="dark" size='sm' className='sales-browser-table'>
                <thead>
                    <tr className='sales-browser-sticky-row'>
                        <th>Invoice Number</th>
                        <th>Invoice Document Date</th>
                        <th>Client Description</th>
                        <th>Material</th>
                        <th>Material Description</th>
                        <th>Billed Quantity</th>
                        <th>Net Billed Amount</th>
                        <th>Client Reference</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(groupedData).map(invoice => {
                        const invoiceData = groupedData[invoice];
                        const billedQuantity = invoiceData.reduce((acc, item) => acc + item["Billed Quantity"], 0);
                        const netBilledAmount = invoiceData.reduce((acc, item) => acc + item["Net Billed Amount"], 0).toFixed(2);

                        return (
                            <React.Fragment key={invoice}>
                                <tr
                                    onClick={() => setExpandedRow(expandedRow === invoice ? null : invoice)}
                                    style={{ cursor: 'pointer', backgroundColor: expandedRow === invoice ? '#343a40' : 'transparent' }}
                                >
                                    <td>{invoiceData[0]["Invoice Document"]}</td>
                                    <td>{formatDate(excelDateToJSDate(invoiceData[0]["Invoice Document Date"]))}</td>
                                    <td>{invoiceData[0]["Client Description"]}</td>
                                    <td>{invoiceData[0]["Material"]}</td>
                                    <td>{invoiceData[0]["Material Description"]}</td>
                                    <td>{billedQuantity}</td>
                                    <td>{formatCurrency(netBilledAmount, "en-GB", localCurrency)}</td>
                                    <td>{invoiceData[0]["Client Reference"]}</td>
                                </tr>
                                {expandedRow === invoice && (
                                    <tr className='sub-row-content'>
                                        <td colSpan="8">
                                            <Table bordered variant="dark" size="sm" >
                                                <thead className='expanded-table-head'>
                                                    <tr>
                                                        <th>Material</th>
                                                        <th>Material Description</th>
                                                        <th>Batch</th>
                                                        <th>Numero De Serie</th>
                                                        <th>Área Total Real↵Tabla Factura (M2)</th>
                                                        <th>Order Reason</th>
                                                        <th>Sales Order</th>
                                                        <th>Delivery Note</th>

                                                    </tr>
                                                </thead>
                                                <tbody className='expanded-table-body'>
                                                    {invoiceData.map(subItem => (
                                                        <tr key={subItem["Número de Serie"]}>
                                                            <td>{subItem["Material"]}</td>
                                                            <td>{subItem["Material Description"]}</td>
                                                            <td>{subItem["Batch"]}</td>
                                                            <td>{subItem["Número de Serie"]}</td>
                                                            <td>{subItem["Área Total Real\nTabla Factura (M2)"]}</td>
                                                            <td>{subItem["Order Reason"]}</td>
                                                            <td>{subItem["Sales Document"]}</td>
                                                            <td>{subItem["Delivery Document"]}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default VBC_SalesBrowserTable;
