import { connectToDB, StoreData, GetData } from './DBOps';
import * as XLSX from "xlsx";

export const GetJJDRPLocalData = async () => {
    try {
        const db = await connectToDB();
        const data = await GetData(db, 'JJDRP');
        return data[0];
    } catch (error) {
        console.error("Error getting Ventas Data from local storage:", error);
        return null;
    }
};

export const SaveJJDRPData = async (JJDRPPath) => {

    //Get References to the files and read data.
    let arrayBuffedJJDRP = await JJDRPPath.arrayBuffer();

    let JJDRPBook = XLSX.readFile(arrayBuffedJJDRP);

    let JJDRPSheet = JJDRPBook.Sheets["Full Matrix"];

    let JJDRPData = XLSX.utils.sheet_to_json(JJDRPSheet);

    const _db = await connectToDB();
    try {
        await StoreData(_db, "JJDRP", JJDRPData);
        console.log("JJDRP Data Saved Successfully");
        const JJDRPDataFromDB = await GetData(_db, "JJDRP");
        return JJDRPDataFromDB;
    }   catch (error) {
        console.log(error);
    }
};