import '../../../Styles/Excels/ExcelFileConfig.css';
import React, {useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FileInputContainer from '../../Reusables/FileInputContainer';
import { DRPConcept } from '../../../Utils/ExcelFileOps';
import { Button, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

function ExcelFileConfig({AppProps, DataProps, ExcelProps}) {
  const Navigate = useNavigate();
  const {
      excelIQ09Path,
      setExcelIQ09Path,
      excelVA05Path,
      setExcelVA05Path,
      excelME2NPath,
      setExcelME2NPath,
      excelCUBOPath,
      setExcelCUBOPath,
      excelCodexPath,
      setExcelCodexPath,
      excelDataValid,
      setExcelDataValid,
      excelDRPConcept,
      setExcelDRPConcept,
      budget, 
      setBudget,
      lyTurnover,
      setLyTurnover,
      transitDays,
      setTransitDays,
      transitInfoAdded,
      setTransitInfoAdded,
      budgetInfoAdded, 
      setBudgetInfoAdded,
      saveDataForFutureUse,
      setSaveDataForFutureUse,
      overrideCodexData,
      setOverrideCodexData,
    } = ExcelProps;

  const handleDRP = () => {
      DRPConcept(excelVA05Path, excelIQ09Path, excelME2NPath, excelCUBOPath, setExcelDataValid, setExcelDRPConcept, saveDataForFutureUse, overrideCodexData, excelCodexPath);
      setExcelCUBOPath(null);
      setExcelIQ09Path(null);
      setExcelME2NPath(null);
      setExcelVA05Path(null);
      setExcelCodexPath(null);
    }

    //To Loop back to Full Config once the states are set to null and has ran the setters.
  useEffect(() => {
    if (excelDRPConcept && excelDataValid) {
      Navigate("/ExcelDash");
    }
  }, [excelDataValid, excelDRPConcept]);

  //If this is selected, runs the second function CalculateDRPWithSalesAndBudget in DROOps.js
  const handleSwitchBudgetToggle = () => {
    setBudgetInfoAdded(!budgetInfoAdded);
  };

  const handleSaveDataToggle = () => {
    setSaveDataForFutureUse(!saveDataForFutureUse);
  };

  // Load budget value from localStorage on component mount
  useEffect(() => {
    const savedBudget = localStorage.getItem('budget');
    if (savedBudget !== null) {
        setBudget(savedBudget);
    }
  }, []);

  // Persist budget value to localStorage whenever it changes
  useEffect(() => {
      localStorage.setItem('budget', budget);
  }, [budget]);

  // Load lyTurnover value from localStorage on component mount
  useEffect(() => {
    const savedLyTurnover = localStorage.getItem('lyTurnover');
    if (savedLyTurnover !== null) {
        setLyTurnover(savedLyTurnover);
    }
  }, []);

  // Persist lyTurnover value to localStorage whenever it changes
  useEffect(() => {
      localStorage.setItem('lyTurnover', lyTurnover);
  }, [lyTurnover]);

  useEffect(() => {
    const savedTransitDays = localStorage.getItem('transitDays');
    if (savedTransitDays !== null) {
        setTransitDays(savedTransitDays);
    }
  }, []);

  useEffect(() => {
      localStorage.setItem('transitDays', transitDays);
  }, [transitDays]);

  const handleOverrideCodexData = () => {
    setOverrideCodexData(!overrideCodexData);
  };

    
  return (
    <div className='-EXCELFILECONFIG-'>
        <Card className='data-browser-card'>
        <Card.Header style={{fontWeight: "600"}}>Configuration:</Card.Header>
        <Card.Body>
            <Card.Text>
                In order for Excel Functions to work, you need to add the datasets for it to work on. Everything is processed in the browser for maximum security, to prevent any data from being transmitted on the web. 
            </Card.Text>
            <Card.Text>Once files are present, feel free to use the available functions</Card.Text>
            <FileInputContainer selectedFilePath={excelIQ09Path} setSelectedFile={setExcelIQ09Path} labelTitle={"IQ09"}/>
            <FileInputContainer selectedFilePath={excelVA05Path} setSelectedFile={setExcelVA05Path} labelTitle={"VA05"}/>
            <FileInputContainer selectedFilePath={excelME2NPath} setSelectedFile={setExcelME2NPath} labelTitle={"ME2N"}/>
            <FileInputContainer selectedFilePath={excelCUBOPath} setSelectedFile={setExcelCUBOPath} labelTitle={"CUBO"}/>
            <Form.Check type="switch" label="Add Budget Data" checked={budgetInfoAdded} onChange={handleSwitchBudgetToggle}/>
            <Form.Check type="switch" label="Add Transit Days" checked={transitInfoAdded} onChange={() => setTransitInfoAdded(!transitInfoAdded)}/>
            <Form.Check type="switch" label="Save Data for future" checked={saveDataForFutureUse} onChange={handleSaveDataToggle}/>
            <Form.Check type="switch" label="Override Codex Data" checked={overrideCodexData} onChange={handleOverrideCodexData}/>
            {budgetInfoAdded &&
              <Container className='custom-input-group-budget'>
                  <span>please do not add any commas, only 1 decimal. Example: 3284391.71 Is accepted. (<>Local Currency</>)</span>
                  <InputGroup className="mb-4">
                  <InputGroup.Text id="basic-addon1">LY Turnover</InputGroup.Text>
                      <Form.Control className='custom-number-input-budget' type="number" placeholder="LY Turnover" value={lyTurnover} onChange={(event) => setLyTurnover(event.target.value)} />
                  </InputGroup>
                  <InputGroup className="mb-4">
                      <InputGroup.Text id="basic-addon1">Budget</InputGroup.Text>
                      <Form.Control className='custom-number-input-budget' type="number" placeholder="Budget Amount" value={budget} onChange={(event) => setBudget(event.target.value)} />
                  </InputGroup>
              </Container>
            }
            {transitInfoAdded &&
              <Container className='custom-input-group-budget'>
                <span>For example, 30 Days Avg In Transit (<>to consider extra stock buffer</>)</span>
                <InputGroup className="mb-4">
                <InputGroup.Text id="basic-addon1">Days In Transit Average</InputGroup.Text>
                    <Form.Control className='custom-number-input-budget' type="number" placeholder="Average DIT" value={transitDays} onChange={(event) => setTransitDays(event.target.value)} />
                </InputGroup>
              </Container>
            }
            {overrideCodexData &&
              <FileInputContainer selectedFilePath={excelCodexPath} setSelectedFile={setExcelCodexPath} labelTitle={"Codex Data"}/>
            }
            <Container>
              <Button variant="warning" onClick={handleDRP}>Calculate!</Button>
            </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExcelFileConfig