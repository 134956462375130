import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../Images/NeolithLogoTransparent.png';

function excelDateToJSDate(excelDate) {
  if (!excelDate || isNaN(excelDate)) return null; // Return null for invalid dates
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
}

function formatCurrency(value, locale = 'en-GB', currency = 'GBP') {
  if (isNaN(value)) return '';
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
}

export function generatePDF(exportData, selectedClient) {
  const doc = new jsPDF('l', 'pt', 'A4'); // Landscape orientation, points unit, A4 size

  // Get current date/time for header and filename
  const currentDateTime = new Date();
  const dateTimeString = currentDateTime.toLocaleString('en-GB', { timeZone: 'UTC' });
  const filename = `ODS-${selectedClient}-${currentDateTime.toISOString().replace(/[:.-]/g, '_')}.pdf`;

  const tableColumns = [
    { header: 'Doc Type', dataKey: 'Document Type' },
    { header: 'Doc Number', dataKey: 'Document Number' },
    { header: 'Invoice Ref', dataKey: 'Invoice Reference' },
    { header: 'Assignment', dataKey: 'Assignment' },
    { header: 'Doc Date', dataKey: 'Document Date' },
    { header: 'Net Due Date', dataKey: 'Net Due Date' },
    { header: 'Clearing Date', dataKey: 'Clearing Date' },
    { header: 'Clearing Doc', dataKey: 'Clearing Document' },
    { header: 'Customer', dataKey: 'Customer' },
    { header: 'Name', dataKey: 'Name 1' },
    { header: 'Currency', dataKey: 'Local Currency' },
    { header: 'Amount', dataKey: 'Amount in Local Currency' },
    { header: 'Text', dataKey: 'Text' },
    { header: 'Terms of Payment', dataKey: 'Terms of Payment' },
    { header: 'Reference', dataKey: 'Reference' },
  ];

  const currencyCode = exportData.length > 0 ? exportData[0]['Local Currency'] : 'GBP';

  // Sort exportData by 'Net Due Date' from oldest to newest
  const sortedData = exportData.slice().sort((a, b) => {
    const dateA = excelDateToJSDate(a['Net Due Date']);
    const dateB = excelDateToJSDate(b['Net Due Date']);
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return dateA - dateB; // Oldest to newest
  });

  // Calculate the total amount
  const totalAmount = sortedData.reduce((sum, item) => {
    const amountStr = item['Amount in Local Currency'];
    const amountNum = parseFloat(amountStr);
    return sum + (isNaN(amountNum) ? 0 : amountNum);
  }, 0);

  const formattedTotalAmount = formatCurrency(totalAmount, 'en-GB', currencyCode);

  const tableRows = sortedData.map(item => ({
    'Document Type': item['Document Type'] || '',
    'Document Number': item['Document Number'] || '',
    'Invoice Reference': item['Invoice Reference'] || '',
    'Assignment': item['Assignment'] || '',
    'Document Date': excelDateToJSDate(item['Document Date']) ? excelDateToJSDate(item['Document Date']).toLocaleDateString('en-GB') : '',
    'Net Due Date': excelDateToJSDate(item['Net Due Date']) ? excelDateToJSDate(item['Net Due Date']).toLocaleDateString('en-GB') : '',
    'Clearing Date': excelDateToJSDate(item['Clearing Date']) ? excelDateToJSDate(item['Clearing Date']).toLocaleDateString('en-GB') : '',
    'Clearing Document': item['Clearing Document'] || '',
    'Customer': item['Customer'] || '',
    'Name 1': item['Name 1'] || '',
    'Local Currency': item['Local Currency'] || '',
    'Amount in Local Currency': formatCurrency(item['Amount in Local Currency'], 'en-GB', currencyCode),
    'Text': item['Text'] || '',
    'Terms of Payment': item['Terms of Payment'] || '',
    'Reference': item['Reference'] || '',
  }));

  // Create a footer row with the total amount
  const footRows = [{
    'Document Type': '', // empty
    'Document Number': '',
    'Invoice Reference': '',
    'Assignment': '',
    'Document Date': '',
    'Net Due Date': '',
    'Clearing Date': '',
    'Clearing Document': '',
    'Customer': '',
    'Name 1': '',
    'Local Currency': 'Total: ',
    'Amount in Local Currency': formattedTotalAmount,
    'Text': '',
    'Terms of Payment': '',
    'Reference': ''
  }];

  // Constants for positioning
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 14;
  const headerY = 60; // Adjusted to move header text lower

  // Header Text
  doc.setFontSize(14);

  if (selectedClient) {
    doc.text(`Overdue statement for ${selectedClient}`, margin, headerY);
  } else {
    doc.text('Overdue statement', margin, headerY);
  }

  // Logo
  const logoWidth = 140; // Adjust width as needed
  const logoHeight = 40; // Adjust height as needed
  const logoX = pageWidth - logoWidth - margin;
  const logoY = headerY - logoHeight + 10; // Adjust to align with text

  // Ensure the logo is properly loaded as a Base64 data URL
  doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

  // Date/Time
  doc.setFontSize(10);
  const dateTimeWidth = doc.getTextWidth(dateTimeString);
  const dateTimeX = logoX - dateTimeWidth - 10; // Adjust spacing as needed
  const dateTimeY = headerY;

  doc.text(dateTimeString, dateTimeX, dateTimeY);

  // Draw a thin line under header
  const lineY = headerY + 10;

  doc.setLineWidth(0.5);
  doc.line(margin, lineY, pageWidth - margin, lineY);

  // Table Start Y position
  const tableStartY = lineY + 10;

  doc.autoTable({
    columns: tableColumns,
    body: tableRows,
    foot: footRows,
    startY: tableStartY,
    styles: { fontSize: 8 },
    theme: 'grid',
    headStyles: { fillColor: [22, 160, 133], textColor: 255 },
    footStyles: { fillColor: [22, 160, 133], fontStyle: 'bold', halign: 'right', textColor: 255 },
    pageBreak: 'auto',
    didDrawPage: function (data) {
      // Footer
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(`Page ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
    },
  });

  doc.save(filename);
}
