import React, { useState, useEffect, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

function CUBOAnalyserDemandTable({ CUBODRPData, demandFilters, setFilteredDataCopy, demandDataProps }) {
  const { ESlabsOnly, OptimalSizesOnly, showDailyRates } = demandDataProps;
  
  // Initialize sortColumn and sortDirection with 'StockHQ' and 'asc'
  const [sortColumn, setSortColumn] = useState('StockHQ');
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortedFilteredData, setSortedFilteredData] = useState([]);
  const tableRef = useRef();

  const columns = [
    'Material', 'DecorName', 'MaterialDescription', 'Thickness', 'Format', 'Finish', 'Mesh',
    'StockHQ', 'PurchaseOrdered', 'SalesOrdered', 'Invoiced', 'StockAvailability',
    ...(showDailyRates ? ['DailyRate', 'MonthlyRate'] : []), // Conditionally add DailyRate and MonthlyRate columns
    'SafetyStock', 'NeedToProduce', 'POToSaleAccuracy'
  ];

  const handleRowClick = (event, item) => {
    console.log(event.target);
    console.log(item);
  };

  const handleSort = (column) => {
    const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const applyFiltersAndSort = () => {
    let data = Array.isArray(CUBODRPData) ? CUBODRPData : [];
    
    // Filter data based on the active filters
    data = data.filter(item => {
      return (!demandFilters.decor || item.DecorName === demandFilters.decor) &&
             (!demandFilters.finish || item.Finish === demandFilters.finish) &&
             (!demandFilters.size || item.Format === demandFilters.size) &&
             (!demandFilters.thickness || item.Thickness === demandFilters.thickness);
    });
  
    // Determine if the sorting should be numeric or string-based
    const isNumericSort = ['StockHQ', 'PurchaseOrdered', 'SalesOrdered', 'Invoiced', 'Thickness', 'SafetyStock', 'NeedToProduce', 'MonthlyRate', 'DailyRate'].includes(sortColumn);
  
    // Sort the data based on the column type
    data.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      
      if (isNumericSort) {
        // Parse values as floats for comparison
        return sortDirection === 'asc' ? (parseFloat(aValue) - parseFloat(bValue)) : (parseFloat(bValue) - parseFloat(aValue));
      } else {
        // Fallback to string comparison for non-numeric values
        return sortDirection === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
      }
    });

    if (ESlabsOnly) {
      data = data.filter(item => typeof(item.Material) === 'string' && item.Material.endsWith('E'));
    }

    if (OptimalSizesOnly) {
      data = data.filter(item => item.Format === '01' || item.Format === '02');
    };
  
    // Limit data to top 250 if no filters are applied
    const areFiltersActive = demandFilters.decor || demandFilters.finish || demandFilters.size || demandFilters.thickness;
    if (!areFiltersActive && data.length > 250) {
      data = data.slice(0, 250);
    }
    // if filters are applied but data length is greater than 400, limit data to 400
    else if (areFiltersActive && data.length > 350) {
      data = data.slice(0, 350);
    }
  
    setSortedFilteredData(data);
    setFilteredDataCopy(data); // Update export data whenever data changes
  };

  useEffect(() => {
    applyFiltersAndSort();
  }, [CUBODRPData, demandFilters, sortColumn, sortDirection, ESlabsOnly, OptimalSizesOnly, showDailyRates]);

  return (
    <div className='-CUBO-DRP-ANALYSER-TABLE-'>
      <Table striped bordered hover size='sm' variant='dark' style={{ fontSize: 'small', width: "95%", margin: "auto", marginTop: "10px"}} ref={tableRef}>
        <thead>
          <tr>{columns.map(column => (
            <th key={column} onClick={() => handleSort(column)} style={{ cursor: 'pointer', backgroundColor: 'rgb(0, 123, 255)', color: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
              {column} {sortColumn === column && <FontAwesomeIcon icon={sortDirection === 'asc' ? faSortUp : faSortDown} style={{ marginLeft: '5px' }} />}
            </th>
          ))}</tr>
        </thead>
        <tbody>
          {sortedFilteredData.length > 0 ? sortedFilteredData.map((item, index) => (
            <tr key={index} onClick={(event) => handleRowClick(event, item)}>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>{['StockHQ', 'PurchaseOrdered', 'SalesOrdered', 'Invoiced'].includes(column) ? Number(item[column]).toLocaleString() : item[column]}</td>
              ))}
            </tr>
          )) : <tr><td colSpan={columns.length}>No data available</td></tr>}
        </tbody>
      </Table>
    </div>
  );
}

export default CUBOAnalyserDemandTable;
