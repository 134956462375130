import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, currency }) => {
    if (active && payload && payload.length) {
        const { date, amount } = payload[0].payload;
        const formatCurrency = (amount, currencyCode) => {
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(amount);
        };
        return (
            <div className="custom-tooltip" style={{ zIndex: 0 }}>
                <p className="label">{`Date: ${date}`}</p>
                <p className="amount">{`Amount: ${formatCurrency(amount, currency)}`}</p>
            </div>
        );
    }
    return null;
};

const VentasTurnoverDashChart = ({ salesData, localCurrency, dataType, tooltipToggle }) => {
    if (!salesData || salesData.length === 0) {
        return <p>No data available to display.</p>;
    }

    let strokeColor;
    switch (dataType) {
        case 'Gross':
            strokeColor = '#00FFFF'; // Neon Baby Blue
            break;
        case 'Invoiced':
            strokeColor = '#98FB98'; // Light Pale Green
            break;
        case 'Credited':
            strokeColor = '#FF9999'; // Slightly more intense red
            break;
        default:
            strokeColor = '#8884d8'; // Default color
    }

    const formatCurrency = (amount, currencyCode) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    // Get the maximum amount to determine dynamic label positioning
    const maxAmount = Math.max(...salesData.map((d) => d.amount));

    return (
        <ResponsiveContainer width="95%" height={400} style={{ margin: "auto" }}>
            <LineChart data={salesData} margin={{ top: 30, right: 40, bottom: 20, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    tickFormatter={(tick) => {
                        const [year, month] = tick.split('-');
                        return `${month.padStart(2, '0')}/${year}`;
                    }}
                />
                <YAxis padding={{ top: 50 }} /> {/* Add padding to avoid clipping */}
                {tooltipToggle && (
                    <Tooltip content={<CustomTooltip currency={localCurrency} />} />
                )}
                <Line type="monotone" dataKey="amount" stroke={strokeColor}>
                    {!tooltipToggle && (
                        <LabelList
                            dataKey="amount"
                            position={({ value }) => (value >= maxAmount * 0.9 ? 'bottom' : 'top')} // Adjust label position
                            formatter={(value) => formatCurrency(value, localCurrency)}
                            fill="#ffbb00"
                            fontWeight="bold"
                            style={{ zIndex: 10 }}
                        />
                    )}
                </Line>
            </LineChart>
        </ResponsiveContainer>
    );
};

export default VentasTurnoverDashChart;
