import React from 'react';
import { NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

const FilterDropdown = ({ 
    title, options = [], selectedItems = [], setSelectedItems, searchValue, setSearchValue, 
    multiSelect, setMultiSelect, handleSelectAll
}) => {

    const handleItemSelect = (value, event) => {
        if (multiSelect) {
            event.preventDefault();
            event.stopPropagation();
            setSelectedItems((prev) => 
                prev?.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
            );
        } else {
            setSelectedItems([value]);
        }
    };

    const handleMultiSelectToggle = () => {
        setMultiSelect(!multiSelect);
        setSelectedItems([]);
    };

    // Custom sorting function
    const sortOptions = (options) => {
        if (title === "Thickness" || title === "Size" || title === "Price Group") {
            return options.sort((a, b) => parseFloat(a) - parseFloat(b)); // Numeric sort
        } else {
            return options.sort((a, b) => a.toString().localeCompare(b.toString())); // String sort
        }
    };

    return (
        <NavDropdown title={title} id={`${title.toLowerCase()}-dropdown`} style={{ fontSize: "medium" }} menuVariant='dark'>
            <Form className="px-3">
                <FormControl
                    type="text"
                    placeholder={`Search ${title}...`}
                    value={searchValue || ''}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="mb-2"
                />
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: "center" }}>
                    <Form.Check 
                        type="switch"
                        id={`multi-select-${title.toLowerCase()}-switch`}
                        label="Multi-select"
                        checked={multiSelect}
                        onChange={handleMultiSelectToggle}
                        style={{ fontSize: "small" }}
                    />
                    <Button size="sm" onClick={handleSelectAll} style={{ width: "65px", fontSize: "11px", textWrap: "nowrap" }}>
                        Select All
                    </Button>
                </div>
            </Form>
            {sortOptions(options).map((value, idx) => (
                <NavDropdown.Item 
                    key={idx} 
                    style={{ 
                        fontSize: "medium", 
                        width: "fit-content", 
                        color: selectedItems?.includes(value) ? 'gold' : 'lightgrey' 
                    }}
                    onClick={(e) => handleItemSelect(value, e)}
                >
                    {value}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

export default FilterDropdown;
