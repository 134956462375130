import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap'

function ExcelPurchaseOrdersHeaderCard({FilteredDataToCopyToSAP, excelDRPData}) {

    const calculateTotalNeedToOrder = (FilteredDataToCopyToSAP) => {
        let totalNeedToOrder = 0;
        FilteredDataToCopyToSAP.forEach(item => {
            totalNeedToOrder += item.NeedToOrder;
        });
        return totalNeedToOrder;
    };

    const calculateTotalAlreadyOnOrder = (excelDRPData) => {
        let totalAlreadyOnOrder = 0;
        excelDRPData.forEach(item => {
            totalAlreadyOnOrder += item.MaterialOnPO;
        });
        return totalAlreadyOnOrder;
    };

    const copyAllToClipboard = (FilteredDataToCopyToSAP) => {
        let copyText = '';
      
        // Get the header row with property names
        const headerRow = "Material\tDecor\tFinish\tThickness\tNeedToOrder\n";
        copyText += `${headerRow}`;
      
        // Append data rows
        FilteredDataToCopyToSAP.forEach(item => {
          copyText += `${item.Material}\t${item.Decor}\t${item['Finish Description']}\t${item.Thickness}\t${item.NeedToOrder}\n`;
        });
      
        navigator.clipboard.writeText(copyText);
    };
      
      

  return (
    <div className='-PURCHASE-ORDERS-HEADER-CARD-'>
        <Container fluid className='purchase-orders-header-container'>
            <Card className='dark-card po-header-card'>
                <Card.Header>
                    <Card.Title>Purchase Orders</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text className='card-small-text'>
                        Total Need to order: {calculateTotalNeedToOrder(FilteredDataToCopyToSAP).toFixed(0)}
                    </Card.Text>
                    <Card.Text className='card-small-text'>
                        Total Already on order: {calculateTotalAlreadyOnOrder(excelDRPData).toFixed(0)}
                    </Card.Text>
                    <Button variant="primary" onClick={copyAllToClipboard(FilteredDataToCopyToSAP)}>Copy Data</Button>
                </Card.Body>
            </Card>
        </Container>
    </div>
  )
}

export default ExcelPurchaseOrdersHeaderCard