// ColumnsDropdown.jsx
import React from 'react';
import { NavDropdown, Form, Nav } from 'react-bootstrap';

function ColumnsDropdown({ visibleColumns, allColumns, toggleColumnVisibility }) {
  const preventDropdownClose = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing on item click
  };

  return (
    <Nav>
      <NavDropdown
        title="Columns"
        id="columns-nav-dropdown"
        align="end"
        className="columns-dropdown"
        menuVariant="dark"
        style={{ fontSize: 'medium', color: 'lightgrey', marginRight: '10px' }}
      >
        <div onClick={preventDropdownClose}>
          {allColumns.map((column) => (
            <Form.Check
              key={column.key}
              type="switch"
              label={column.label}
              checked={visibleColumns.includes(column.key)}
              onChange={(e) => toggleColumnVisibility(column.key, e.target.checked)}
              style={{ paddingLeft: '15px', marginLeft: '50px' }}
            />
          ))}
        </div>
      </NavDropdown>
    </Nav>
  );
}

export default ColumnsDropdown;
