import React from 'react'
import StockManager from '../Components/Stock/StockManager'

function StockTools({DRPProps, AppProps, DataProps}) {
  const { filteredData, setFilteredData } = DRPProps;
  return (
    <div>
        <StockManager filteredData={filteredData} setFilteredData={setFilteredData} AppProps={AppProps} DataProps={DataProps} />
    </div>
  )
}

export default StockTools