export function convertSerialDate(serialNumber) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const referenceDate = new Date(1899, 11, 30); // Months are zero-based

    const date = new Date(referenceDate.getTime() + serialNumber * millisecondsPerDay);
    return date;
}

export const GetDeliveryScheduleMainData = (excelDRPconcept) => {
    try {
        let rawData = excelDRPconcept.VA05DataRaw;
        return rawData;
    } catch (error) {
        return error;
    }
};

export const GroupDataByMonth = (data) => {
    try {
        let monthArray = {};

        data.forEach(element => {
            let date = element["Delivery Date"];
            let year;
            let month;

            if (date) {
                date = convertSerialDate(date);
            }
            else {
                date = new Date(1970, 1, 1);
            }

            year = date.getFullYear();
            month = date.getMonth();

            // Initialize year if not present
            if (!monthArray[year]) {
                monthArray[year] = {};
            }

            // Initialize month array if not present
            if (!monthArray[year][month]) {
                monthArray[year][month] = [];
            }

            // Push element into corresponding month array
            monthArray[year][month].push(element);
        });
        return monthArray;

    } catch (error) {
        console.error("Error in GroupDataByMonth:", error);
        // You might want to handle the error further depending on your requirements
        return null; // or an empty object/array
    }
};
