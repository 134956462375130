import './DebtPortalCustomerAccount.css';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import DebtPortalCustomerAccountSideMenu from './DebtPortalCustomerAccountSideMenu/DebtPortalCustomerAccountSideMenu';
import DebtPortalCustomerAccountMainTable from './DebtPortalCustomerAccountMainTable/DebtPortalCustomerAccountMainTable';
import DebtPortalCustomerAccountFiltersBar from './DebtPortalCustomerAccountFiltersBar/DebtPortalCustomerAccountFiltersBar';
import { Collapse } from 'react-bootstrap';

// Move excelDateToJSDate outside the component
const excelDateToJSDate = (excelDate) => {
  if (!excelDate) return null;
  const baseDate = new Date(1900, 0, 1); // Excel base date
  return new Date(baseDate.getTime() + (excelDate - 2) * 86400000); // Adjust for Excel's leap year bug
};

function DebtPortalCustomerAccountParent({
  debtPortalData,
  debtPortalFilterProps,
  selectedClient,
  filtersBarVisible,
  hideCurrencyandPostingColumns,
  overDueOnly,
  setExportData, // Assumed to be a stable function from parent
}) {
  const { CustomerAccountShowOpenItemsOnly } = debtPortalFilterProps;
  const [selectedCustomer, setSelectedCustomer] = useState(selectedClient); // Initialize with the passed client

  // Filter States
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const [docNumberFilter, setDocNumberFilter] = useState('');
  const [invoiceRefFilter, setInvoiceRefFilter] = useState('');
  const [clearingDocFilter, setClearingDocFilter] = useState('');
  const [assignmentFilter, setAssignmentFilter] = useState('');
  const [referenceFilter, setReferenceFilter] = useState('');
  const [amountFilter, setAmountFilter] = useState('');

  // Update selectedCustomer when selectedClient prop changes
  useEffect(() => {
    setSelectedCustomer(selectedClient);
  }, [selectedClient]);

  // Define filterProps to pass to FiltersBar
  const filterProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedDocTypes,
    setSelectedDocTypes,
    docNumberFilter,
    setDocNumberFilter,
    invoiceRefFilter,
    setInvoiceRefFilter,
    clearingDocFilter,
    setClearingDocFilter,
    assignmentFilter,
    setAssignmentFilter,
    referenceFilter,
    setReferenceFilter,
    amountFilter,
    setAmountFilter,
  };

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setSelectedDocTypes([]);
    setDocNumberFilter('');
    setInvoiceRefFilter('');
    setClearingDocFilter('');
    setAssignmentFilter('');
    setReferenceFilter('');
    setAmountFilter('');
  };

  // Memoize unique values to optimize performance
  const uniqueDocTypes = useMemo(() => {
    return [...new Set(debtPortalData.map(item => item['Document Type']).filter(v => v))];
  }, [debtPortalData]);

  const uniqueCustomers = useMemo(() => {
    return [...new Set(debtPortalData.map(item => item['Name 1']).filter(v => v))];
  }, [debtPortalData]);

  // Apply all filters to the data
  const filteredData = useMemo(() => {
    return debtPortalData.filter(item => {
      // Filter by selected customer from side menu
      const isSelectedCustomer = selectedCustomer ? item['Name 1'] === selectedCustomer : true;

      // Open items filter
      const isOpenItem = CustomerAccountShowOpenItemsOnly ? !item['Clearing Document'] : true;

      const overDueFilter = overDueOnly ? 
        (excelDateToJSDate(item['Net Due Date']) < new Date()) : true;

      // Date Filters (assuming 'Document Date')
      const documentDate = excelDateToJSDate(item['Document Date']);
      const matchesStartDate = startDate ? (documentDate && documentDate >= new Date(startDate)) : true;
      const matchesEndDate = endDate ? (documentDate && documentDate <= new Date(endDate)) : true;

      // Document Type Filter
      const matchesDocType = selectedDocTypes.length > 0 ? selectedDocTypes.includes(item['Document Type']) : true;

      // Document Number Filter
      const matchesDocNumber = docNumberFilter
        ? item['Document Number'].toString().toLowerCase().includes(docNumberFilter.toLowerCase())
        : true;

      // Invoice Reference Filter
      const matchesInvoiceRef = invoiceRefFilter
        ? item['Invoice Reference'].toString().toLowerCase().includes(invoiceRefFilter.toLowerCase())
        : true;

      // Clearing Document Filter
      const matchesClearingDoc = clearingDocFilter
        ? item['Clearing Document'].toString().toLowerCase().includes(clearingDocFilter.toLowerCase())
        : true;

      const matchesAssignment = assignmentFilter
        ? item['Assignment'].toString().toLowerCase().includes(assignmentFilter.toLowerCase())
        : true;
        
      const matchesReference = referenceFilter
        ? item['Reference'].toString().toLowerCase().includes(referenceFilter.toLowerCase())
        : true;
        
      const matchesAmount = amountFilter
        ? item['Amount in Local Currency'].toString().toLowerCase().includes(amountFilter.toLowerCase())
        : true;

      return (
        isSelectedCustomer &&
        isOpenItem &&
        matchesStartDate &&
        matchesEndDate &&
        matchesDocType &&
        matchesDocNumber &&
        matchesInvoiceRef &&
        matchesAssignment &&
        matchesReference &&
        matchesAmount &&
        overDueFilter &&
        matchesClearingDoc
      );
    });
  }, [
    debtPortalData,
    selectedCustomer,
    CustomerAccountShowOpenItemsOnly,
    startDate,
    endDate,
    selectedDocTypes,
    docNumberFilter,
    invoiceRefFilter,
    clearingDocFilter,
    assignmentFilter,
    referenceFilter,
    amountFilter,
    overDueOnly, // Removed excelDateToJSDate from dependencies
  ]);

  // If no customer is selected, show the top 250 most recent records
  const top250Records = useMemo(() => {
    return debtPortalData
      .filter(item => (CustomerAccountShowOpenItemsOnly ? !item['Clearing Document'] : true))
      .sort((a, b) => {
        const dateA = excelDateToJSDate(a['Net Due Date']);
        const dateB = excelDateToJSDate(b['Net Due Date']);
        return dateB - dateA; // Most recent first
      })
      .slice(0, 250);
  }, [debtPortalData, CustomerAccountShowOpenItemsOnly]);

  // Memoize dataToDisplay
  const dataToDisplay = useMemo(() => {
    let data;

    if (selectedCustomer && filteredData.length === 0) {
      data = null; // Set to null to handle the "No data found" case
    } else {
      data = filteredData.length > 0 ? filteredData : top250Records;
    }

    if (data && data.length > 300) {
      data = data.slice(0, 300);
    }

    return data;
  }, [selectedCustomer, filteredData, top250Records]);

  // Use a ref to store the previous dataToDisplay
  const prevDataToDisplayRef = useRef();

  // Update the export data when dataToDisplay changes
  useEffect(() => {
    // Only update if dataToDisplay has changed
    if (prevDataToDisplayRef.current !== dataToDisplay) {
      setExportData(dataToDisplay || []);
      prevDataToDisplayRef.current = dataToDisplay;
    }
  }, [dataToDisplay, setExportData]);

  return (
    <div className='debt-portal-customer-account-main d-flex flex-column'>
      <div className='d-flex'>
        <DebtPortalCustomerAccountSideMenu
          debtPortalData={debtPortalData}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
        <div className='flex-grow-1'>
          {/* Filters Bar with Collapse */}
          <Collapse in={filtersBarVisible}>
            <div>
              <DebtPortalCustomerAccountFiltersBar
                debtPortalData={debtPortalData}
                filterProps={filterProps}
                uniqueDocTypes={uniqueDocTypes}
                uniqueCustomers={uniqueCustomers}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </Collapse>

          {/* Check if there is data to display */}
          {dataToDisplay === null ? (
            <div className="no-data-message">
              <p>No data found</p>
            </div>
          ) : (
            <DebtPortalCustomerAccountMainTable
              debtPortalData={dataToDisplay}
              filtersBarVisible={filtersBarVisible}
              hideCurrencyandPostingColumns={hideCurrencyandPostingColumns}
              CustomerAccountShowOpenItemsOnly={CustomerAccountShowOpenItemsOnly}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DebtPortalCustomerAccountParent;
