import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCoffee } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/HomeBar.css'
import logo from '../../Images/NeolithLogoTransparent.png'
import { useNavigate } from 'react-router-dom';
import Login from '../../Auth/Login';
import { useAuth0 } from "@auth0/auth0-react";
import Profile from '../../Auth/Profile';


function HomeBar({setSideMenuVisible }) {
    let navigate = useNavigate();
    const { user, isAuthenticated } = useAuth0();

    const navigateToHome = () => {
      navigate('/');
    };
  
    const showSideMenu = () => {
      // Add your logic for showing the side menu here
      setSideMenuVisible(s => !s);
    };


  return (
    <div className='homebar-main'>
        <div className='left-bar-section'>
            <FontAwesomeIcon icon={faBars} className='menu-icon' onClick={showSideMenu}/>
            <img src={logo} className='homebar-logo' onClick={navigateToHome}/> <div style={{fontSize: "small", marginLeft: ".2vw"}} >UK</div>
        </div>
        <div className='right-bar-section'>
            {isAuthenticated ? <span className='login-text'>Staff Login</span> && <Profile/> : <Login />}
        </div>
    </div>
  )
}

export default HomeBar