import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import '../../Styles/NavBarStyles.css';

//<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>


function NavBarDiscounts({AppProps, setModeSelected}) {

    const handleSelectNewDiscount = () => {
        setModeSelected('New')
    }

    const handleSelectBrowseDiscounts = () => {
        setModeSelected('Browse')
    }

  return (
    <Navbar variant="dark" bg="dark" expand="lg" className='navbar-parent-discounts'>
      <Container fluid className='nav-discounts-top-container'>
        <Navbar.Brand href="#home">Discount Manager:</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Button variant="warning" className="custom-button-gold clearFilter" onClick={handleSelectNewDiscount} >Add New Discount</Button>
        <Button variant="warning" className="custom-button-gold applyFilter" onClick={handleSelectBrowseDiscounts} >Discount Browser</Button>
      </Container>
      <Container fluid>
        <span style={{fontSize: "small"}}>Navigate throughout Discounts here. </span>
      </Container>
    </Navbar>
  );
}

export default NavBarDiscounts;