import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DynamicChart = ({ chartData, _dataKeyX, selectedKPI }) => {
  if (!chartData || chartData.length === 0) {
    return <p>No data available to display.</p>;
  }

  return (
    <ResponsiveContainer height={335} style={{ width: "100%" }}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        {/* The XAxis is dynamic based on the grouping field */}
        <XAxis dataKey={_dataKeyX} />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="amount"
          stroke="#ffc400"
          label={({ x, y, value }) => (
            <text x={x} y={y - 10} fill="#ffc400" textAnchor="middle">
              {value}
            </text>
          )}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DynamicChart;
