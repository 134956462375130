import React, {useState, useEffect} from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';


function RolesChecker({ AppProps }) {
  const { token, setToken, isAuthed, setIsAuthed, loggedInUser, setLoggedInUser, permissions, setPermissions } = AppProps;
  const { user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const silentToken = await getAccessTokenSilently({
          audience: "https://localhost:3001",
        });

        setToken(silentToken);
        const decoded = jwtDecode(silentToken);
        setPermissions(decoded.permissions);
      } catch (error) {
        // If silent authentication fails, use popup
        try {
          const popupToken = await getAccessTokenWithPopup({
            audience: "https://localhost:3001",
          });

          setToken(popupToken);
          const decoded = jwtDecode(popupToken);
          setPermissions(decoded.permissions);
        } catch (popupError) {
          console.error("Error getting token with popup:", popupError);
        }
      }
    };

    if (isAuthenticated) {
      setIsAuthed(true);
      setLoggedInUser(user);
      fetchToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Additional logic if needed
  }, [token]);

  return <div style={{ display: "none" }}></div>;
}

export default RolesChecker;
