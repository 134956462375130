import React, {useState, useEffect} from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

function ChartViewer({dynamicChartData, selectedChart}) {
    const [chartDataValid, setChartDataValid] = useState([]);
    const [chartData, setChartData] = useState([]);
    const currencyFormatter = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(value);

    const tooltipFormatter = (value, name, props) => {
        const { payload } = props;
      
        // Check the payload and payload[0] to see their values
        try {
            const slabName = payload.name || payload.materialDescription;
            return [`${slabName} - NetBilledSum: ${currencyFormatter(value)}`];
        } catch (error) {
           console.log("couldnt access payload.Description"); 
           return [`NetBilledSum: ${currencyFormatter(value)}`];
        }

    };

    useEffect(() => {
        if (dynamicChartData !== null) {
            setChartDataValid(true);
            console.log("dynmaicChartData: ", dynamicChartData);
        }
    }, [dynamicChartData]);

    const DynamicChartDataObj = (selectedChart) => {
        const _selectedChart = selectedChart.selectedChart;
        console.log(selectedChart);
        if (_selectedChart == 'Best Customers') {
            return (
                <ResponsiveContainer width='75%' height={400} style={{ marginTop: "1vh" }}>
                    <BarChart data={dynamicChartData.top10Customers} margin={{ top: 50, right: 30, left: 50, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                        <XAxis dataKey="name" angle={-30} textAnchor="end" interval={0} height={140} fontSize={"small"} />
                        <YAxis fontSize={"small"} color='white' />
                        <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc', fontSize: "small" }} />
                        <Bar dataKey="totalValue" fill="#37c45d" />
                        {/* Chart Title */}
                        <text x="50%" y="30" textAnchor="middle" fill="#ffffff" fontSize="16" fontWeight="bold">
                            Top 10 Customers - Total Value
                        </text>
                    </BarChart>
                </ResponsiveContainer>
            )
        }
        if (_selectedChart == 'Best Materials') {
            return (
                <ResponsiveContainer width='75%' height={400} style={{ marginTop: "1vh" }}>
                    <BarChart data={dynamicChartData.top10Materials} margin={{ top: 50, right: 30, left: 50, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                        <XAxis dataKey="materialDescription" angle={-30} textAnchor="end" interval={0} height={200} fontSize={"small"} color='white' />
                        <YAxis fontSize={"Small"} color='white' />
                        <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc', fontSize: "small" }}/>
                        <Bar dataKey="totalValue" fill="skyblue" />
                        {/* Chart Title */}
                        <text x="50%" y="30" textAnchor="middle" fill="#ffffff" fontSize="16" fontWeight="bold">
                            Top 10 Materials - Total Value
                        </text>
                    </BarChart>
                </ResponsiveContainer>
            )
        }
        if (_selectedChart === 'Worst Customers') {
            return (
                <ResponsiveContainer width='75%' height={400} style={{ marginTop: "1vh" }}>
                    <BarChart data={dynamicChartData.top10WorstCustomers} margin={{ top: 50, right: 30, left: 50, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                        <XAxis dataKey="name" angle={-30} textAnchor="end" interval={0} height={140} fontSize={"small"} />
                        <YAxis fontSize={"small"} color='white' />
                        <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc', fontSize: "small" }} />
                        <Bar dataKey="totalValue" fill="crimson" />
                        {/* Chart Title */}
                        <text x="50%" y="30" textAnchor="middle" fill="#ffffff" fontSize="16" fontWeight="bold">
                            Bottom 10 Customers - Total Value
                        </text>
                    </BarChart>
                </ResponsiveContainer>
            );
        }
        
        if (_selectedChart === 'Worst Materials') {
            return (
                <ResponsiveContainer width='75%' height={400} style={{ marginTop: "1vh" }}>
                    <BarChart data={dynamicChartData.top10WorstMaterials} margin={{ top: 50, right: 30, left: 50, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                        <XAxis dataKey="materialDescription" angle={-30} textAnchor="end" interval={0} height={200} fontSize={"small"} color='white' />
                        <YAxis fontSize={"Small"} color='white' />
                        <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc', fontSize: "small" }}/>
                        <Bar dataKey="totalValue" fill="orange" />
                        {/* Chart Title */}
                        <text x="50%" y="30" textAnchor="middle" fill="#ffffff" fontSize="16" fontWeight="bold">
                            Bottom 10 Materials - Total Value
                        </text>
                    </BarChart>
                </ResponsiveContainer>
            );
        }
        return <>Nothing Selected</>;
    };

  return (
    <div className='chart-viewer'>
        {chartDataValid &&
            <DynamicChartDataObj selectedChart={selectedChart}/>
        }
    </div>
  )
}

export default ChartViewer