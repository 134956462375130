import React, { useState } from 'react';
import { Card, Container, Row, Col, Table } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import '../DebtPortalStyles.css';

// Function to convert Excel date number to JavaScript Date
const excelDateToJSDate = (excelDate) => {
  const baseDate = new Date(1900, 0, 1); // Excel base date
  return new Date(baseDate.getTime() + (excelDate - 2) * 86400000); // Adjust for Excel's leap year bug
};

// Function to format numbers as currency
const formatCurrency = (value, currencyCode) => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode
    }).format(value);
  } catch (error) {
    console.error('Error formatting currency:', error);
    return value; // Fallback in case of error
  }
};

// DebtPortalOverview component
const DebtPortalOverview = ({ debtPortalData, onClientSelect }) => {
  const [activeTab, setActiveTab] = useState('amountDue'); // Default to showing "Total Amount Due"

  // Extract unique currency codes from the data
  const uniqueCurrencies = [...new Set(debtPortalData.map(item => item['Local Currency']))];
// Calculate total amount due and other metrics, excluding items with a clearing document
  const totalAmountDue = debtPortalData
    .filter(item => !item['Clearing Document'] && new Date() > excelDateToJSDate(item['Net Due Date']) && item['Amount in Local Currency'] > 0)
    .reduce((sum, item) => sum + item['Amount in Local Currency'], 0);

  const totalDueMoreThan30Days = debtPortalData
    .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 30 && item['Amount in Local Currency'] > 0)
    .reduce((sum, item) => sum + item['Amount in Local Currency'], 0);

  const totalDueMoreThan60Days = debtPortalData
    .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 60 && item['Amount in Local Currency'] > 0)
    .reduce((sum, item) => sum + item['Amount in Local Currency'], 0);

  const totalDueMoreThan90Days = debtPortalData
    .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 90 && item['Amount in Local Currency'] > 0)
    .reduce((sum, item) => sum + item['Amount in Local Currency'], 0);

  // Calculate total documents to allocate, excluding items with a clearing document
  const totalDocumentsToAllocate = debtPortalData
    .filter(item => !item['Clearing Document'] || item['Clearing Document'] === '')
    .length;

  // Calculate number of clients in debt, excluding items with a clearing document
  const clientsInDebt = new Set(
    debtPortalData
      .filter(item => (!item['Clearing Document'] || item['Clearing Document'] === '') && item['Amount in Local Currency'] < 0)
      .map(item => item['Customer'])
  ).size;

// Prepare data for charts and tables
const chartData = [
  { 
    day: 30, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 30 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 60, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 60 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 90, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 90 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 120, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 120 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 150, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 150 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 180, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 180 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  },
  { 
    day: 360, 
    amount: debtPortalData
      .filter(item => !item['Clearing Document'] && (new Date() > excelDateToJSDate(item['Net Due Date'])) && (new Date() - excelDateToJSDate(item['Net Due Date'])) / (1000 * 60 * 60 * 24) > 360 && item['Amount in Local Currency'] > 0)
      .reduce((sum, item) => sum + item['Amount in Local Currency'], 0) 
  }
];

// Calculate documents by client, excluding items with a clearing document
const documentsByClient = debtPortalData
  .filter(item => !item['Clearing Document'] || item['Clearing Document'] === '')
  .reduce((acc, item) => {
    const clientNumber = item['Customer'];
    const clientName = item['Name 1'];

    if (!acc[clientNumber]) {
      acc[clientNumber] = { name: clientName, documents: 0 };
    }
    acc[clientNumber].documents += 1; // Increment the document count
    
    return acc;
  }, {});
  
// Convert the accumulator object to an array
const documentsByClientArray = Object.entries(documentsByClient).map(([clientNumber, details]) => ({
  clientNumber,
  name: details.name,
  documents: details.documents,
})).sort((a, b) => b.documents - a.documents); // Sort by document count in descending order

// Calculate worst clients, excluding items with a clearing document
const worstClients = debtPortalData
  .filter(item => 
    (!item['Clearing Document'] || item['Clearing Document'] === '') &&  // Only consider documents not cleared
    (new Date() > excelDateToJSDate(item['Net Due Date']))  // Only include overdue items
  )
  .reduce((acc, item) => {
    const client = acc.find(c => c.client === item['Customer']);
    if (client) {
      client.amount += item['Amount in Local Currency'];
      client.documents += 1; // Assuming each entry is a separate document
    } else {
      acc.push({
        client: item['Customer'],
        'Name 1': item['Name 1'],
        amount: item['Amount in Local Currency'],
        documents: 1 // Assuming each entry is a separate document
      });
    }
    return acc;
  }, [])
  .sort((a, b) => b.amount - a.amount); // Sort by amount in descending order



  return (
    <Container>
      <Row className="mt-4">
        <Col md={6} lg={4} onClick={() => setActiveTab('amountDue')} className={`card-clickable ${activeTab === 'amountDue' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Total Amount OverDue</Card.Title>
              <Card.Text>{formatCurrency(totalAmountDue, debtPortalData[0]['Local Currency'])}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} onClick={() => setActiveTab('documentsToAllocate')} className={`card-clickable ${activeTab === 'documentsToAllocate' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Total Documents to Allocate</Card.Title>
              <Card.Text>{totalDocumentsToAllocate}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} onClick={() => setActiveTab('clientsInDebt')} className={`card-clickable ${activeTab === 'clientsInDebt' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Number of Clients in Debt</Card.Title>
              <Card.Text>{clientsInDebt}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} onClick={() => setActiveTab('amountDue')} className={`card-clickable ${activeTab === 'dueMoreThan30Days' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Total Due More Than 30 Days</Card.Title>
              <Card.Text>{formatCurrency(totalDueMoreThan30Days, debtPortalData[0]['Local Currency'])}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} onClick={() => setActiveTab('amountDue')} className={`card-clickable ${activeTab === 'dueMoreThan60Days' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Total Due More Than 60 Days</Card.Title>
              <Card.Text>{formatCurrency(totalDueMoreThan60Days, debtPortalData[0]['Local Currency'])}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} onClick={() => setActiveTab('amountDue')} className={`card-clickable ${activeTab === 'dueMoreThan90Days' ? 'active' : ''}`}>
          <Card className="dark-card mb-4">
            <Card.Body>
              <Card.Title className="gold-title">Total Due More Than 90 Days</Card.Title>
              <Card.Text>{formatCurrency(totalDueMoreThan90Days, debtPortalData[0]['Local Currency'])}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} className="mt-4" style={{ height: '60vh' }}>
        {activeTab === 'amountDue' && (
          <ResponsiveContainer width="100%" height={350} style={{ margin: "auto" }}> {/* Adjust the width and height as needed */}
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}> {/* Adjust the top margin */}
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis 
                dataKey="day" 
                tick={{ fontSize: 12 }} 
                tickFormatter={(value) => `${value} Days`} // Custom label for X-axis ticks
              />
              <YAxis 
                tick={{ fontSize: 12 }}
                tickFormatter={(value) => formatCurrency(value, debtPortalData[0]['Local Currency'])} // Format Y-axis labels
              />
              <Tooltip 
                formatter={(value, name) => [formatCurrency(value, debtPortalData[0]['Local Currency'])]} 
                labelFormatter={(label) => `Greater than ${label} Days`} // Custom label for tooltip
                contentStyle={{ backgroundColor: '#333', borderColor: '#333',  fontSize: '14px' }} // Dark background
                itemStyle={{ color: '#fff' }} // White text and smaller font size
              />
              <Line type="monotone" dataKey="amount" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        )}
        {activeTab === 'documentsToAllocate' && (
          <div className="table-responsive" style={{ maxHeight: '400px' }}>
            <Table variant="dark" striped bordered hover className="mb-0" size='sm' style={{fontSize: "14px"}}>
              <thead>
                <tr>
                  <th>Client Number</th>
                  <th>Client Name</th>
                  <th>Documents</th>
                </tr>
              </thead>
              <tbody>
                {documentsByClientArray.map((doc, index) => (
                  <tr key={index} onClick={() => onClientSelect(doc.name)} style={{ cursor: 'pointer' }}>
                    <td>{doc.clientNumber}</td>
                    <td>{doc.name}</td>
                    <td>{doc.documents}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {activeTab === 'clientsInDebt' && (
          <div className="table-responsive" style={{ maxHeight: '400px' }}> {/* Adjust height as needed */}
            <Table variant="dark" striped bordered hover className="mb-0" size='sm' style={{fontSize: "small"}}>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Client Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
              {worstClients.map((client, index) => (
                <tr key={index} onClick={() => onClientSelect(client['Name 1'])} style={{ cursor: 'pointer' }}>
                  <td>{client.client}</td>
                  <td>{client['Name 1']}</td>
                  <td>{formatCurrency(client.amount, debtPortalData[0]['Local Currency'])}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
        )}
        </Col>
      </Row>
    </Container>
  );
};

export default DebtPortalOverview;
