import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = 'dev-pinboards.eu.auth0.com';
  const clientId = 'Vw3F9A8ICDTTIkaD8OauggRpVX2C33gK';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: window.location.origin , audience: "https://localhost:3001" }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;