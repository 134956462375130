import React from 'react'
import Button from 'react-bootstrap/Button'
import FilterBar from './FilterBar'

function TableOptionsRow({showAdditionalColumns, toggleAdditionalPOColumns, handlePlanTruck, handleResetSelection, showOnlySlabs, 
    setShowOnlySlabs, handleExportToSpreadsheet, handleCopyToClipboard, textFilter, setTextFilter, handleFrameSettings  }) 
{
  return (
    <div className='plan-it-dash-row-sb'>
        <div className='checkbox-area'>
            <input
                type="checkbox"
                checked={showAdditionalColumns}
                onChange={toggleAdditionalPOColumns}
                className='checkbox-input'
            />
            <label style={{fontSize: "small", marginLeft: "8px"}}>Show PO Columns</label>
            <input
                type="checkbox"
                checked={showOnlySlabs}
                onChange={() => setShowOnlySlabs(!showOnlySlabs)}
                className='checkbox-input'
            />
            <label style={{fontSize: "small", marginLeft: "8px"}}>Only Slabs</label>
        </div>
        <div className='filter-bar'>
            <FilterBar textFilter={textFilter} setTextFilter={setTextFilter}/>
        </div>
        <div className="button-container">
            <Button variant="primary" onClick={handlePlanTruck}>Plan Truck</Button>{' '}
            <Button variant="primary" onClick={handleExportToSpreadsheet}>Export Selection</Button>{' '}
            <Button variant="primary" onClick={handleCopyToClipboard}>Copy Selection</Button>{' '}
            <Button variant="info" style={{color: "white"}} onClick={handleFrameSettings}>Frame Conversion Settings</Button>{' '}
            <Button variant="danger" onClick={handleResetSelection}>Reset Selection</Button>
        </div>
    </div>
  )
}

export default TableOptionsRow