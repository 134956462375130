import './CUBOAnalyserNavbar.css'
import React from 'react'
import {Nav, Navbar, NavDropdown, Button} from 'react-bootstrap'
import {RemoveDataFromTable, connectToDB} from '../../../../Utils/DBOps'


const CUBOAnalyserNavbar = ({CUBOAnalyserProps}) => {
  const {setExcelCUBOComprasPath, setExcelCUBOVentasPath, setExcelCUBOOrdersPath, setExcelCUBOStockHQPath, setExcelCUBOStockSubsidPath, CUBODRPData, setDRPDataLoaded, setDataIsStoredToDexie,
    setDataCompras, setDataVentas, setDataOrders, setDataStockHQ, setDataStockSubsid, setSelectedView, setReadyToAnalyse, dataCompras, setFilters, setCUBODRPData} = CUBOAnalyserProps;

  const resetData = async () => {
    console.log("Resetting data");
    setSelectedView('Config');
    setExcelCUBOComprasPath(null);
    setExcelCUBOVentasPath(null);
    setExcelCUBOOrdersPath(null);
    setExcelCUBOStockHQPath(null);
    setExcelCUBOStockSubsidPath(null);
    setDataCompras([]);
    setDataVentas([]);
    setDataStockHQ([]);
    setDataOrders([]);
    setDataStockSubsid([]);
    setCUBODRPData([]);
    const db = await connectToDB();
    await RemoveDataFromTable(db, 'CUBOCompras');
    await RemoveDataFromTable(db, 'CUBOVentas');
    await RemoveDataFromTable(db, 'CUBOOrders');
    await RemoveDataFromTable(db, 'CUBOStockHQ');
    await RemoveDataFromTable(db, 'CUBOStockSubsid');
    await RemoveDataFromTable(db, 'CUBOAnalyserDRP');
    setReadyToAnalyse(false);
    setDRPDataLoaded(false);
    setDataIsStoredToDexie(false);
  };

  const handleDemandView = (e) => {
    if (CUBODRPData && CUBODRPData.length > 0) {
      setSelectedView(e.target.innerText);
    } else {
      alert('No data to display. Please upload the Excel files and click Go! to proceed.');
    }
  };
    
  return (
    <Navbar className="-CUBOAnalyser-navbar" variant="dark" bg="dark" expand="lg">
        <Navbar.Brand className="navbar-brand-cubo-analyser">CUBO Analyser</Navbar.Brand>
        <Nav className="mr-auto">
            <NavDropdown title="Views" id="basic-nav-dropdown" style={{fontSize: "medium"}} menuVariant='dark'>
                <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={() => setSelectedView('Main')}>Main</NavDropdown.Item>
                <NavDropdown.Item  style={{ fontSize: "medium", width: "fit-content" }} onClick={() => setSelectedView('Documentor')}>Documentor</NavDropdown.Item>
                <NavDropdown.Item  style={{ fontSize: "medium", width: "fit-content" }} onClick={handleDemandView}>Demand</NavDropdown.Item>
            </NavDropdown>
        </Nav>
        <Nav className="mr-auto flex-end">
          <Button variant="danger" size='sm' onClick={resetData}>Reset Data</Button>
        </Nav>

    </Navbar>
  )
}

export default CUBOAnalyserNavbar