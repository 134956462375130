import '../Dash/ExcelDash.css';
import '../ExcelDashNavBar/ExcelDashNavBar.css'   
import React, { useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ExcelDashNavBarViewFunctions from './ExcelDashNavBarViewFunctions'
import ExcelDashNavBarDRPFunctions from './ExcelDashNavBarDRPFunctions'
import ExcelDashNavBarFiltersFunctions from './ExcelDashNavBarFiltersFunctions'
import {RemoveDataFromTable, connectToDB} from '../../../../Utils/DBOps'

function ExcelDashNavBar({
  ExcelProps,
  setSelectedViewComponent,
  selectedViewComponent,
  selectedDecors,
  setSelectedDecors,
  selectedFinishes,
  setSelectedFinishes,
  selectedThicknesses,
  setSelectedThicknesses,
  selectedSizes,
  setSelectedSizes,
  selectedPriceGroups,
  setSelectedPriceGroups,
  excelDRPData,
  filterStatusLabelText,
  toggleColumns,
  showAdditionalColumns,
  excelFilteredDRPData,
  plant,
  setATPOnly,
  ATPOnly,
  setESlabsOnly,
  ESlabsOnly,
  optimalSizesOnly,
  setOptimalSizesOnly,
  setDRPLoaded,
  meshVisible,
  setMeshVisible,
  hideZeroLines,
  setHideZeroLines,
  visibleColumns,
  setVisibleColumns,
  allColumns,
  toggleColumnVisibility,
}) {
  const { excelDataValid, setExcelDataValid, setExcelDRPConcept, excelDRPConcept } = ExcelProps;


  const handleSelectViewClick = (event) => {
    const selectedItemText = event.target.innerHTML;
    setSelectedViewComponent(selectedItemText);
  };

  const handleClearData = async () => {
    if (excelDataValid) {
        try {
            setDRPLoaded(false);
            const db = await connectToDB(); // Wait for connection

            // Define the names of the first five tables you want to clear
            const tablesToClear = ['IQ09', 'ME2N', 'VA05', 'CUBO'];

            // Remove data from the specified tables
            await Promise.all(tablesToClear.map(table => RemoveDataFromTable(db, table)));

            setExcelDataValid(false);
            setExcelDRPConcept(null);
            alert('Data cleared successfully');
        } catch (error) {
            console.log('Error clearing local Dexie:', error.message);
            alert('Error clearing local Dexie:', error.message);
            return; // Exit the function if there's an error
        }

    }
};


  useEffect(() => {
    if (excelDRPConcept === null && !excelDataValid) {
      setSelectedViewComponent("Full Config");
    }
  },[excelDRPConcept,excelDataValid, setSelectedViewComponent]);

  const handleCopyToClipboard = () => {
    // Prepare the headers
    const headers = [
      "Material",
      "Material Description",
      "Decor",
      "Finish Description",
      "Thickness",
      "ATP",
    ];
  
    // Prepare the data rows
    const dataRows = excelFilteredDRPData.map((item) => {
      // Replace dash with 0 for non-negative numbers
      const replaceDash = (value) => (value === "-" || parseFloat(value) ? value : "0");
  
      return [
        item.Material,
        item["Material Description"],
        item.Decor,
        item["Finish Description"],
        item.Thickness,
        replaceDash(item.ATP),
      ].join("\t");
    });
  
    // Combine headers and data rows
    const tsvData = [headers.join("\t"), ...dataRows].join("\n");
  
    // Copy the data to the clipboard
    navigator.clipboard.writeText(tsvData).then(() => {
      // Handle success if needed
      console.log('Data copied to clipboard:', tsvData);
    }).catch((error) => {
      // Handle error if needed
      console.error('Failed to copy data to clipboard:', error);
    });
  };
  
  

  return (
    <Navbar variant="dark" bg="dark" expand="lg" className='navbar-parent'>
      <Container fluid className='flex-text'>
            <ExcelDashNavBarViewFunctions handleSelectViewClick={handleSelectViewClick} navBarBrandTitle={"Functions"} navDropDownIdAndTitle={"Select View"}/>
            {selectedViewComponent === "DRP Summary" && 
            <div className={"drp-function-bar"}>
              {excelDataValid && <ExcelDashNavBarDRPFunctions handleSelectViewClick={handleSelectViewClick} navBarIdAndTitle={"DRP Functions"} excelDRPData={excelDRPData}/>}
            </div>}
            <Container className={"nav-bar-right"}>
              {excelDataValid && <Button variant="danger" size='sm' onClick={handleClearData}>Start Over</Button>}
            </Container>
      </Container>   
      {selectedViewComponent === "DRP Summary" && 
            <ExcelDashNavBarFiltersFunctions 
              selectedDecors={selectedDecors}
              setSelectedDecors={setSelectedDecors}
              selectedFinishes={selectedFinishes}
              setSelectedFinishes={setSelectedFinishes}
              selectedThicknesses={selectedThicknesses}
              setSelectedThicknesses={setSelectedThicknesses}
              selectedSizes={selectedSizes}
              setSelectedSizes={setSelectedSizes}
              selectedPriceGroups={selectedPriceGroups}
              setSelectedPriceGroups={setSelectedPriceGroups}
              ExcelProps={ExcelProps}
              excelDRPData={excelDRPData}
              filterStatusLabelText={filterStatusLabelText}
              toggleColumns={toggleColumns}
              showAdditionalColumns={showAdditionalColumns}
              handleCopyToClipboard={handleCopyToClipboard}
              excelFilteredDRPData={excelFilteredDRPData}
              plant={plant}
              setATPOnly={setATPOnly}
              ATPOnly={ATPOnly}
              setESlabsOnly={setESlabsOnly}
              ESlabsOnly={ESlabsOnly}
              optimalSizesOnly={optimalSizesOnly}
              setOptimalSizesOnly={setOptimalSizesOnly}
              meshVisible={meshVisible}
              setMeshVisible={setMeshVisible}
              hideZeroLines={hideZeroLines}
              setHideZeroLines={setHideZeroLines}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              allColumns={allColumns}
              toggleColumnVisibility={toggleColumnVisibility}
            />
      }
    </Navbar>
  );
}   

export default ExcelDashNavBar;
