import React from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';

const VBC_TopMaterialsTable = ({ topMaterials, setSelectedMaterial, localCurrency }) => {
    return (
        <Table striped bordered hover variant="dark" size='sm' className='top-materials-table'>
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Material Description</th>
                    <th>Total Quantity</th>
                    <th>Total Amount</th>
                </tr>
            </thead>
            <tbody className='sales-browser-table-body'>
                {topMaterials.length > 0 ? (
                    topMaterials.map((material, index) => (
                        <tr key={index} onClick={() => setSelectedMaterial(material["Material"])}>
                            <td>{material['Material']}</td>
                            <td>{material['Material Description']}</td>
                            <td>{material['Total Quantity']}</td>
                            <td>{formatCurrency(material['Total Amount'], 'en-GB', localCurrency)}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" className="text-center">No Data Available</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default VBC_TopMaterialsTable;
