import React from 'react'
import './ExcelStockCheckerStyles.css';
import ExcelStockBrowser from './ExcelStockBroswer';


function ExcelStockChecker({AppProps, ExcelProps}) {
    const {excelDRPConcept} = ExcelProps;
  return (
    <div>
        <ExcelStockBrowser 
            data={excelDRPConcept.totalStockByMaterialBatchAndSerial} 
            dataProperty1={"Quantity"}
            dataProperty2={"Description"}
            title={"Excel Stock Checker"}
            bodyTitle={"Total SKU's in Stock: " + excelDRPConcept.totalStockByMaterialBatchAndSerial.length}
        />
    </div>
  )
}

export default ExcelStockChecker