/**
 * Formats a number as a localized currency string.
 * 
 * @param {number} value - The numeric value to format.
 * @param {string} locale - The locale string (e.g., 'en-US', 'de-DE').
 * @param {string} currency - The currency code (e.g., 'USD', 'EUR').
 * @returns {string} - The formatted currency string.
 */
export const formatCurrency = (value, locale = 'en-GB', currency = 'GBP') => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
};