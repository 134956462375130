import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'


function ExcelDashNavBarDRPFunctions({navBarIdAndTitle, handleSelectViewClick}) {
 
  const itemClick = (event) => {
    const selectedItemText = event.target.innerHTML;
    // Use the selectedItemText as needed
    console.log(selectedItemText);
    handleSelectViewClick(event);
    // Example: setComponent(selectedItemText);
  };
 
 
  return (
    <>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example narrow">
            <Nav>
                <NavDropdown
                    style={{ fontSize: "medium", width: "fit-content" }}
                    id={navBarIdAndTitle}
                    title={navBarIdAndTitle}
                    menuVariant="dark"
                >
                <NavDropdown.Item onClick={(event) => itemClick(event)}>Purchase Order Proposal</NavDropdown.Item>
                <NavDropdown.Item onClick={(event) => itemClick(event)}>Calculative Summaray</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    </>
  )
}

export default ExcelDashNavBarDRPFunctions