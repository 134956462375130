import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

function XYZClassificationFilter({ xyzFilter, setXYZFilter }) {
  const handleFilterChange = (val) => {
    console.log('XYZClassificationFilter changed to:', val);
    setXYZFilter(val);
  };

  return (
    <ToggleButtonGroup
      type="checkbox"
      name="xyzClassification"
      value={xyzFilter}
      onChange={handleFilterChange}
      className="mb-3"
    >
      <ToggleButton id="tbg-btn-x" variant="outline-primary" value="X">
        X
      </ToggleButton>
      <ToggleButton id="tbg-btn-y" variant="outline-primary" value="Y">
        Y
      </ToggleButton>
      <ToggleButton id="tbg-btn-z" variant="outline-primary" value="Z">
        Z
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default XYZClassificationFilter;
