import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

function ClassificationFilter({ classificationFilter, setClassificationFilter }) {
  const handleFilterChange = (val) => {
    console.log('ClassificationFilter changed to:', val);
    setClassificationFilter(val);
  };

  return (
    <ToggleButtonGroup
      type="checkbox"
      name="classification"
      value={classificationFilter}
      onChange={handleFilterChange}
      className="mb-3"
    >
      <ToggleButton id="tbg-btn-1" variant="outline-primary" value="A">
        A
      </ToggleButton>
      <ToggleButton id="tbg-btn-2" variant="outline-primary" value="B">
        B
      </ToggleButton>
      <ToggleButton id="tbg-btn-3" variant="outline-primary" value="C">
        C
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ClassificationFilter;
