export const SubmitClaimForBreakage = async (
    accountNumber,
    accountName,
    InvoiceNumber,
    invoiceDate,
    comment,
    slabs,
    files,
    setIsLoading,
    setEmailStatus
  ) => {
    try {
      let testMail = "j.smith@neolith.com";
      // eslint-disable-next-line no-unused-vars
      let liveMail = "j.smith@neolith.com, s.woods@neolith.com";  
      const formData = new FormData();
      formData.append('slabs', JSON.stringify(slabs));
      formData.append('accountNumber', accountNumber);
      formData.append('accountName', accountName);
      formData.append('InvoiceNumber', InvoiceNumber);
      formData.append('invoiceDate', invoiceDate);
      formData.append('comment', comment);
      formData.append('to', testMail);
      formData.append('subject', `Neolution.uk - Claim for Breakage - ${invoiceDate} - ${accountNumber} - ${accountName} - ${InvoiceNumber}`);
  
      const summaryText = `
        *Sent from the Neolith UK Claim Form*
        Claim for Breakage:
        Account Number: ${accountNumber}
        Account Name: ${accountName}
        Invoice Number: ${InvoiceNumber}
        Invoice Date: ${invoiceDate}
        Comments: ${comment}
        Number of Slabs: ${slabs.length}
        ${slabs.map((slab, index) => `
          Slab ${index + 1}:
          - Material Code: ${slab.materialCode}
          - Serial Number: ${slab.serialNumber}
          - Reason: ${slab.reason}
        `).join('')}
      `;
  
      formData.append('text', summaryText);
  
      files.forEach((file, index) => {
        file.forEach((fileItem, fileItemIndex) => {
          formData.append(`slabFiles[${index}]`, fileItem);
        });
      });
  
      const response = await fetch('https://api.neolution.uk/mailer/send-claim', {
        method: 'POST',
        mode: 'cors',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setEmailStatus(true);
        alert("Email Sent");
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  export const validateClaimBreakageFormData = (accountNumber, InvoiceNumber, invoiceDate, slabs) => {
    if (!accountNumber) {
      alert('Account Number is required.');
      return false;
    }
  
    if (!InvoiceNumber) {
      alert('Invoice Number is required.');
      return false;
    }
  
    if (!invoiceDate) {
      alert('Invoice Date is required.');
      return false;
    }
  
    if (!slabs.length) {
      alert('At least one slab is required.');
      return false;
    }
  
    return true;
  };