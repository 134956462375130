import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import '../Styles/Profile.css';


function Profile() {
    const { user, isAuthenticated, logout } = useAuth0();
  return (
    <div className='profile'>
        <img className='profile-img' src={user.picture} alt={user.name} />
        <span className='profile-text'>{user.name}</span>
        <Button variant="warning" className="custom-button-gold" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>
    </div>
  )
}

export default Profile