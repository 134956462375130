import React, {useState} from "react"
import PlanItConfig from "../Components/Excels/PlanIt/PlanItConfig"
import PlanItMain from "../Components/Excels/PlanIt/PlanItMain"

const PlanItParent = ({AppProps}) => {
    const [savePlanItDataForFutureUse, setSavePlanItDataForFutureUse] = useState(false);
    const [ME2LData, setME2LData] = useState();
    const [plannerSheetData, setPlannerSheetData] = useState();
    const [productionData, setProductionData] = useState();
    const [plannedTrucksData, setPlannedTrucksData] = useState();
    const [dataIsValid, setDataIsValid] = useState(false);

    const planItProps = {
        savePlanItDataForFutureUse: savePlanItDataForFutureUse,
        setSavePlanItDataForFutureUse: setSavePlanItDataForFutureUse,
        ME2LData: ME2LData,
        setME2LData: setME2LData,
        plannerSheetData: plannerSheetData,
        setPlannerSheetData: setPlannerSheetData,
        productionData: productionData,
        setProductionData: setProductionData,
        dataIsValid: dataIsValid,
        setDataIsValid: setDataIsValid,
        plannedTrucksData: plannedTrucksData,
        setPlannedTrucksData: setPlannedTrucksData,
    };

 return (
    <div className="-Test-Ground-">
        {!dataIsValid && <PlanItConfig planItProps={planItProps} />}
        {dataIsValid && <PlanItMain planItProps={planItProps} AppProps={AppProps} />}
    </div>
 )
}

export default PlanItParent