import '../../../../Styles/Excels/ExcelFileConfig.css';
import React, {useState, useEffect} from 'react'
import FileInputContainer from '../../../Reusables/FileInputContainer'
import {Card, Container, Row, Col, Button, InputGroup} from 'react-bootstrap'
import { TransformExcelToDataArray } from '../../../../Utils/CUBOAnalyserFunctions';
import {GetData, StoreData, connectToDB } from '../../../../Utils/DBOps'
import CUBOAnalyserFileConfig from './CUBOAnalyserFileConfig';
import CUBOAnalyserNavbar from '../CUBOAnalyserNavbar/CUBOAnalyserNavbar';
import CUBOAnalyserDash from '../CUBOAnalyserDash';
import ArrayDocumentor from '../../../Reusables/ArrayDocumentor/ArrayDocumentor';
import CUBOAnalyserDemand from '../CUBOAnalyserDemand/CUBOAnalyserDemand';
import Loading from '../../../Reusables/Loading';
import {CUBOAnalyseDRPData} from '../../../../Utils/CUBOAnalyserDRP';

function CUBOAnalyserParent() {
    const [excelCUBOComprasPath, setExcelCUBOComprasPath] = useState(null);
    const [excelCUBOVentasPath, setExcelCUBOVentasPath] = useState(null);
    const [excelCUBOOrdersPath, setExcelCUBOOrdersPath] = useState(null);
    const [excelCUBOStockHQPath, setExcelCUBOStockHQPath] = useState(null);
    const [excelCUBOStockSubsidPath, setExcelCUBOStockSubsidPath] = useState(null);
    const [dataCompras, setDataCompras] = useState([]);
    const [dataVentas, setDataVentas] = useState([]);
    const [dataStockHQ, setDataStockHQ] = useState([]);
    const [dataStockSubsid, setDataStockSubsid] = useState([]);
    const [dataOrders, setDataOrders] = useState([]);
    const [CUBODRPData, setCUBODRPData] = useState([]);
    const [filters, setFilters] = useState({material: "", finish: "", size: "", thickness: ""});
    const [filteredDataCopy, setFilteredDataCopy] = useState([{}]);

    
    const [selectedView, setSelectedView] = useState('Config');
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [dataIsStoredToDexie, setDataIsStoredToDexie] = useState(false);
    const [readyToAnalyse, setReadyToAnalyse] = useState(false);
    const [DRPDataLoaded, setDRPDataLoaded] = useState(false);

    //Demand Data Props
    const [ESlabsOnly, setESlabsOnly] = useState(true);
    const [OptimalSizesOnly, setOptimalSizesOnly] = useState(true);
    const [showDailyRates, setShowDailyRates] = useState(true);

    //Initial DRP Fiilers for FileConfigCpt
    const [includeSlabs, setIncludeSlabs] = useState(true);
    const [includeSamples, setIncludeSamples] = useState(false);
    const [includeTiles, setIncludeTiles] = useState(false);
    const [includeOthers, setIncludeOthers] = useState(false);
    const [centersIncludingTransitDays, setCentersIncludingTransitDays] = useState([]);
    const [daysBuffer, setDaysBuffer] = useState(150);

    //Process the data and save to dexieDB
    const handleAnalyserData = async () => {
        setIsLoading(true);
        setStatusMessage("Processing Excels To Array's");    
        const arrayDataCompras = await TransformExcelToDataArray(excelCUBOComprasPath);
        const arrayDataVentas = await TransformExcelToDataArray(excelCUBOVentasPath);
        const arrayDataOrders = await TransformExcelToDataArray(excelCUBOOrdersPath);
        const arrayDataStock = await TransformExcelToDataArray(excelCUBOStockHQPath);
        const arrayDataStockSubsid = await TransformExcelToDataArray(excelCUBOStockSubsidPath);
        if (!arrayDataCompras || !arrayDataVentas || !arrayDataStock || !arrayDataOrders || !arrayDataStockSubsid) {
            alert("Please upload both CUBO Compras and CUBO Ventas files.");
            return;
        }

        setStatusMessage("Connecting to DexieDB and Storing Data");  
        const db = await connectToDB();
        await StoreData(db,'CUBOCompras', arrayDataCompras);
        await StoreData(db ,'CUBOVentas', arrayDataVentas);
        await StoreData(db ,'CUBOOrders', arrayDataOrders);
        await StoreData(db ,'CUBOStockHQ', arrayDataStock);
        await StoreData(db ,'CUBOStockSubsid', arrayDataStockSubsid);
        setStatusMessage("Setting Data to get ready for DRP Analysis");
        setExcelCUBOComprasPath(null);
        setExcelCUBOVentasPath(null);
        setExcelCUBOStockHQPath(null);
        setExcelCUBOOrdersPath(null);
        setExcelCUBOStockSubsidPath(null);
        setDataIsStoredToDexie(true);
        setIsLoading(false);
    };
    //GetDataFromDexie
    useEffect(() => {
        const fetchDataFromDexie = async () => {
            setIsLoading(true);
            try {
              const db = await connectToDB();
              const _dataCompras = await GetData(db, 'CUBOCompras');
              const _dataVentas = await GetData(db, 'CUBOVentas');
              const _dataOrders = await GetData(db, 'CUBOOrders');
              const _dataStockHQ = await GetData(db, 'CUBOStockHQ');
              const _dataStockSubsid = await GetData(db, 'CUBOStockSubsid');
              const _dataCUBODRP = await GetData(db, 'CUBOAnalyserDRP');
      
              // Set the fetched data to states
              setDataCompras(_dataCompras[0]);
              setDataVentas(_dataVentas[0]);
              setDataStockHQ(_dataStockHQ[0]);
              setDataOrders(_dataOrders[0]);
              setDataStockSubsid(_dataStockSubsid[0]);
      
              if (_dataCUBODRP && _dataCUBODRP.length > 0) {
                setCUBODRPData(_dataCUBODRP[0]);
                setDRPDataLoaded(true);
                setReadyToAnalyse(true);
                setSelectedView('Main');
                console.log("DRP Data Loaded from DexieDB");
              } else {
                // If DRP data is not found, calculate and set it
                console.log("Calculating DRP Data");
                const newData = await CUBOAnalyseDRPData(_dataCompras, _dataOrders, _dataVentas, _dataStockHQ, _dataStockSubsid, daysBuffer, includeSlabs, includeSamples, includeTiles, includeOthers, centersIncludingTransitDays);
                await StoreData(db, 'CUBOAnalyserDRP', newData);
                setCUBODRPData(newData);
                setDRPDataLoaded(true);
                setReadyToAnalyse(true);
                setSelectedView('Main');
              }
              setIsLoading(false);
            } catch (error) {
              console.error("Error fetching data from DexieDB:", error);
              setIsLoading(false);
              // Handle error here, e.g., show an error message
            }
          };
      
        fetchDataFromDexie();
      }, [dataIsStoredToDexie, ]);
      

    const CUBOAnalyserProps = {
        setExcelCUBOComprasPath: setExcelCUBOComprasPath,
        setExcelCUBOVentasPath: setExcelCUBOVentasPath,
        setExcelCUBOOrdersPath: setExcelCUBOOrdersPath,
        setExcelCUBOStockHQPath: setExcelCUBOStockHQPath,
        setExcelCUBOStockSubsidPath: setExcelCUBOStockSubsidPath,
        setDataCompras: setDataCompras,
        setDataVentas: setDataVentas,
        setDataStockHQ: setDataStockHQ,
        setDataStockSubsid: setDataStockSubsid,
        setDataOrders: setDataOrders,
        setCUBODRPData: setCUBODRPData,
        setSelectedView: setSelectedView,
        handleAnalyserData: handleAnalyserData,
        setStatusMessage: setStatusMessage,
        setReadyToAnalyse: setReadyToAnalyse,
        setFilters: setFilters,
        setDRPDataLoaded: setDRPDataLoaded,
        setDataIsStoredToDexie: setDataIsStoredToDexie,
        setFilteredDataCopy: setFilteredDataCopy,
        setCentersIncludingTransitDays: setCentersIncludingTransitDays,
        setDaysBuffer: setDaysBuffer,
        filteredDataCopy: filteredDataCopy,
        DRPDataLoaded: DRPDataLoaded,
        dataCompras: dataCompras,
        dataVentas: dataVentas,
        dataOrders: dataOrders,
        dataStockSubsid: dataStockSubsid,
        dataStockHQ: dataStockHQ,
        CUBODRPData: CUBODRPData,
        selectedView: selectedView,
        isLoading: isLoading,
        statusMessage: statusMessage,
        readyToAnalyse: readyToAnalyse,
        filters: filters,
        centersIncludingTransitDays: centersIncludingTransitDays,
        daysBuffer: daysBuffer,
    };

    const demandDataProps = {
        ESlabsOnly: ESlabsOnly,
        OptimalSizesOnly: OptimalSizesOnly,
        showDailyRates: showDailyRates,
        setESlabsOnly: setESlabsOnly,
        setOptimalSizesOnly: setOptimalSizesOnly,
        setShowDailyRates: setShowDailyRates,
    };

    const intialDRPFilterProps = {
        includeSlabs: includeSlabs,
        includeSamples: includeSamples,
        includeTiles: includeTiles,
        includeOthers: includeOthers,
        setIncludeSlabs: setIncludeSlabs,
        setIncludeSamples: setIncludeSamples,
        setIncludeTiles: setIncludeTiles,
        setIncludeOthers: setIncludeOthers,
    };

  return (
    <div className='-CUBOAnalyser-Parent-'>
        <CUBOAnalyserNavbar CUBOAnalyserProps={CUBOAnalyserProps} />
        {isLoading && <Loading statusMessage={statusMessage} />}
        {!isLoading && selectedView === "Config" && 
            <CUBOAnalyserFileConfig 
                CUBOAnalyserProps={CUBOAnalyserProps}
                intialDRPFilterProps={intialDRPFilterProps}
            />
        }
        {!isLoading && selectedView === "Main" && readyToAnalyse &&<CUBOAnalyserDash CUBOAnalyserProps={CUBOAnalyserProps}/>}
        {!isLoading && selectedView === "Documentor" && 
            <div className='documentor-window-main'>
              <ArrayDocumentor data={dataCompras} title={"dataCompras"} />,
              <ArrayDocumentor data={dataVentas} title={"dataVentas"} />,
              <ArrayDocumentor data={dataOrders} title={"dataOrders"} />,
              <ArrayDocumentor data={dataStockSubsid} title={"dataStockSubsid"} />,
              <ArrayDocumentor data={dataStockHQ} title={"dataStockHQ"} />
              {CUBODRPData && CUBODRPData.length > 0 && <ArrayDocumentor data={CUBODRPData} title={"CUBODRPData"} />}
            </div>
        }
        {!isLoading && selectedView === "Demand" && CUBODRPData && CUBODRPData.length > 0 && DRPDataLoaded && <CUBOAnalyserDemand CUBOAnalyserProps={CUBOAnalyserProps} demandDataProps={demandDataProps}/>}
    </div>
  )
}

export default CUBOAnalyserParent