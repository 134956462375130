import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Container, Row, Card } from 'react-bootstrap';
import '../Styles/DiscountEntry.css';
import MaterialWizardModal from '../Components/Reusables/MaterialWizardModal';

function DiscountEntryWizard({AppProps}) {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [discountStartDate, setDiscountStartDate] = useState('');
  const [discountExpiryDate, setDiscountExpiryDate] = useState('');
  const [comment, setComment] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState(Array(discounts.length).fill(false));
  const [emailStatus, setEmailStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [materialModalVisible, setMaterialModalVisible] = useState(false);
  const [selectedParams, setSelectedParams] = useState({});

  const handleFileChange = (e, DiscountIndex) => {
    const fileList = Array.from(e.target.files);
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[DiscountIndex] = fileList;
      return updatedFiles;
    });

    // Mark the corresponding Discount as having files uploaded
    setFilesUploaded((prevFilesUploaded) => {
      const updatedFilesUploaded = [...prevFilesUploaded];
      updatedFilesUploaded[DiscountIndex] = true;
      return updatedFilesUploaded;
    });
  };

  const addDiscount = () => {
    setDiscounts((prevDiscounts) => [
      ...prevDiscounts,
      { materialCode: '', percentageOff: '', expectedPrice: '' },
    ]);
  };

  const handleDiscountChange = (index, field, value) => {
    setDiscounts((prevDiscounts) => {
      const updatedDiscounts = [...prevDiscounts];
      updatedDiscounts[index][field] = value;
      return updatedDiscounts;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate required fields
    if (!accountNumber || !discountStartDate || !discountExpiryDate) {
      alert('Please fill in all required fields.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();

    formData.append('AccountNumber', accountNumber);
    formData.append('AccountName', accountName);
    formData.append('DiscountStartDate', discountStartDate);
    formData.append('DiscountExpiryDate', discountExpiryDate);
    formData.append('Discounts', JSON.stringify(discounts));

    // Append files to FormData
    files.forEach((file, index) => {
      file.forEach((fileItem, fileItemIndex) => {
        formData.append(`DiscountFiles[${index}]`, fileItem);
      });
    });

    try {
      const response = await fetch('http://localhost:3001/Discounts/Add', {
        method: 'POST',
        mode: 'cors',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setIsLoading(false);
        setEmailStatus(true);
        alert("Email Sent");
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleOpenMaterialWizard = () => {
    console.log('Material Wizard clicked');
    setMaterialModalVisible(true);
  };

  const handleCloseModal = () => {
    setMaterialModalVisible(false);
  };

  const handleLookup = (params) => {
    // Handle the selected parameters
    console.log('Selected Parameters:', params);
    setSelectedParams(params);
  };

  useEffect(() => {
    if (materialModalVisible) {
      console.log('Material Wizard opened');
    }
  }, [materialModalVisible]);

  if (isLoading) {
    // Render loading state
    return (
      <div className="feedback-form-container" style={{ marginTop: "2vh" }}>
        <p>Loading...</p>
      </div>
    );
  }

  if (!emailStatus && !isLoading) {
    return (
      
      <div className="feedback-form-container" style={{marginTop: "2vh"}}>
        {materialModalVisible && 
          <MaterialWizardModal 
            show={materialModalVisible} 
            onHide={handleCloseModal} 
            onLookup={handleLookup}
            AppProps={AppProps}
        />}
        <Container>
            <Row>
              <Form onSubmit={handleSubmit} className='the-form'>
                <div className="form-row">
                  <p style={{ margin: "auto", fontWeight: "700" }}>Discount Entry</p>
                </div>
                <div className="form-row">
                  <p style={{ margin: "auto", fontWeight: "600", fontSize: "medium" }}>Please enter discount details here.</p>
                </div>
                <div className="form-row">
                  <Form.Group controlId="accountNumber" className="form-group discount-frm-grp">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="accountName" className="form-group discount-frm-grp">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Automatically populated"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </div>

                <div className='form-row'>
                <Form.Group controlId="discountStartDate" className="form-group discount-frm-grp">
                    <Form.Label>Discount Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={discountStartDate}
                      onChange={(e) => setDiscountStartDate(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="discountExpiryDate" className="form-group discount-frm-grp">
                    <Form.Label>Discount Expiry Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={discountExpiryDate}
                      onChange={(e) => setDiscountExpiryDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="form-row-comments">
                  <Form.Group controlId="formComments" className='formGroup-comments discount-frm-grp'>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder="Add additional comments..."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <Row className='discounts-filebox'>
                    {/* Add file upload indicators */}
                        <Form.Group controlId='discountFile' className="formGroup files-box discount-frm-grp ">
                            <Form.Label>Upload Files</Form.Label>
                            <p className="file-upload-text"></p>
                            <Form.Control
                                type="file"
                                multiple
                                onChange={(e) => handleFileChange(e)}
                            />
                        </Form.Group>
                </Row>
              </Form>

              {/* Add the Discounts box content here */}
              <div className='form-row-Discounts-box'>
                <p style={{fontSize: "medium", fontWeight: "700"}}>Add Discounts</p>
                {discounts.map((Discount, index) => (
                  <div key={index} className='form-row discounts-child-info'>
                    <Row className='materials-row'>
                        <Col>
                            <Form.Group controlId={`formMaterialCode${index}`} className='formGroup discount-frm-grp'>
                                <Form.Label>Material Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Material Code"
                                    value={Discount.materialCode}
                                    onClick={handleOpenMaterialWizard}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={`formSerialNumber${index}`} className='formGroup discount-frm-grp'>
                                <Form.Label>% Off</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter number between 0 and 100"
                                    value={Discount.serialNumber}
                                    onChange={(e) => handleDiscountChange(index, 'percentageOff', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={`formSerialNumber${index}`} className='formGroup discount-frm-grp'>
                                <Form.Label>Expected Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Serial Number"
                                    value={Discount.serialNumber}
                                    onChange={(e) => handleDiscountChange(index, 'expectedPrice', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                  </div>
                ))}
                <Button variant="primary" type="button" className="button" onClick={addDiscount}>
                  Add Discount
                </Button>
              </div>
            </Row>
            <Row>
            <Button variant="primary" type="submit" className="button" onClick={handleSubmit}>
                Submit
            </Button>
          </Row>
        </Container>
      </div>
    );
  }

  if (emailStatus) {
    return (
      <Card className="feedback-form-container" style={{ flexDirection: "column" }}>
        <Card.Header>Success!</Card.Header>
        <Card.Body className='formGroup' style={{ textAlign: "center", justifyContent: "center" }}>
          <p>Thank you, your discount entry has been submitted!</p>
        </Card.Body>
        <Button variant="primary" type="button" className="button" onClick={() => setEmailStatus(false)}>Send Another</Button>
      </Card>
    );
  }


}

export default DiscountEntryWizard;
