import React, { useState, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';
import codexFlushed from '../../../../Utils/CodexFlushed.json';

const VBC_CreditAnalysisTable = ({
  creditData,
  selectedAggregation,
  selectedGroup,
  setSelectedGroup,
  colorMapping,
  showAll,
}) => {
  const [expandedGroups, setExpandedGroups] = useState([]);

  const aggregationKeyMapping = {
    'Order Reason': 'Order Reason',
    'Client Description': 'Client Description',
    'Material': 'Material Description',
  };

  const groupKeyName = aggregationKeyMapping[selectedAggregation];

  // Create a lookup map for Half Code to Decor
  const halfCodeToDecorMap = useMemo(() => {
    const map = {};
    codexFlushed.forEach((entry) => {
      map[entry['Half Code']] = entry['Decor'];
    });
    return map;
  }, []);

  // Group data based on selectedAggregation
  const groupedData = creditData.reduce((groups, item) => {
    let groupKey = item[groupKeyName] || 'Unassigned';

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(item);

    return groups;
  }, {});

  // Calculate total 'Net Billed Amount' for each group
  let groupTotals = Object.keys(groupedData).map((groupKey) => {
    const totalAmount = groupedData[groupKey].reduce(
      (sum, item) => sum + item['Net Billed Amount'],
      0
    );
    return {
      groupKey,
      totalAmount,
    };
  });

  // Sort groups by totalAmount descending
  groupTotals.sort((a, b) => Math.abs(b.totalAmount) - Math.abs(a.totalAmount));

  // Limit to the same groups shown in the pie chart
  if (!showAll) {
    groupTotals = groupTotals.filter((group) => colorMapping[group.groupKey]);
  }

  const toggleGroup = (groupKey) => {
    setExpandedGroups((prev) =>
      prev.includes(groupKey)
        ? prev.filter((key) => key !== groupKey)
        : [...prev, groupKey]
    );
  };

  return (
    <div
      className="-Credit-Analysis-Table-"
      style={{ overflowY: 'auto', maxHeight: '500px' }}
    >
      <Table
        striped
        bordered
        hover
        variant="dark"
        size="sm"
        style={{ fontSize: 'medium' }}
      >
        <thead>
          <tr>
            <th>{selectedAggregation}</th>
            <th>Total Net Billed Amount</th>
          </tr>
        </thead>
        <tbody>
          {groupTotals.map(({ groupKey, totalAmount }) => (
            <React.Fragment key={groupKey}>
              <tr
                onClick={() => {
                  toggleGroup(groupKey);
                  setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
                }}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    selectedGroup === groupKey ? '#343a40' : 'transparent',
                }}
              >
                <td>
                  <span
                    style={{
                      color: colorMapping[groupKey] || '#FFFFFF',
                      fontWeight: 'bold',
                    }}
                  >
                    ■{' '}
                  </span>
                  {groupKey}
                </td>
                <td>{formatCurrency(totalAmount)}</td>
              </tr>
              {expandedGroups.includes(groupKey) && (
                <tr>
                  <td colSpan="2" style={{ padding: 0 }}>
                    <Table
                      bordered
                      variant="dark"
                      size="sm"
                      style={{ margin: 0 }}
                    >
                      <thead>
                        <tr>
                          <th>Invoice</th>
                          {selectedAggregation === 'Client Description' ? (
                            <>
                              <th>Order Reason</th>
                              <th>Material</th>
                            </>
                          ) : selectedAggregation === 'Material' ? (
                            <>
                              <th>Reason</th>
                              <th>Client</th>
                            </>
                          ) : selectedAggregation === 'Order Reason' ? (
                            <>
                              <th>Client</th>
                              <th>Material</th>
                            </>
                          ) : null}
                          <th>Net Billed Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedData[groupKey].map((item, index) => (
                          <tr key={index}>
                            <td>{item['Invoice Document']}</td>
                            {selectedAggregation === 'Client Description' ? (
                              <>
                                <td>{item['Order Reason']}</td>
                                <td>{item['Material Description']}</td>
                              </>
                            ) : selectedAggregation === 'Material' ? (
                              <>
                                <td>{item['Order Reason']}</td>
                                <td>{item['Client Description']}</td>
                              </>
                            ) : selectedAggregation === 'Order Reason' ? (
                              <>
                                <td>{item['Client Description']}</td>
                                <td>{item['Material Description']}</td>
                              </>
                            ) : null}
                            <td>{formatCurrency(item['Net Billed Amount'])}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default VBC_CreditAnalysisTable;
