import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SlabWeights = {
    3: 40,
    6: 80,
    12: 160,
    20: 250,
}

function FrameConversionSettings({ show, onHide, divisionIndicatorProps, preparedData, setPreparedData }) {
  const {
    setDivisionIndicator3mm,
    setDivisionIndicator6mm,
    setDivisionIndicator12mm,
    setDivisionIndicator20mm
  } = divisionIndicatorProps;

  const [localDivisionIndicators, setLocalDivisionIndicators] = useState({
    divisionIndicator3mm: divisionIndicatorProps.divisionIndicator3mm,
    divisionIndicator6mm: divisionIndicatorProps.divisionIndicator6mm,
    divisionIndicator12mm: divisionIndicatorProps.divisionIndicator12mm,
    divisionIndicator20mm: divisionIndicatorProps.divisionIndicator20mm
  });

  const handleInputChange = (e, key) => {
    const value = parseInt(e.target.value);
    setLocalDivisionIndicators(prevState => ({
      ...prevState,
      [key]: isNaN(value) ? '' : value // Clear the input if not a number
    }));
  };

  const handleSave = () => {
    setDivisionIndicator3mm(localDivisionIndicators.divisionIndicator3mm);
    setDivisionIndicator6mm(localDivisionIndicators.divisionIndicator6mm);
    setDivisionIndicator12mm(localDivisionIndicators.divisionIndicator12mm);
    setDivisionIndicator20mm(localDivisionIndicators.divisionIndicator20mm);

    // Recalculate total weight and racks consumption with updated division indicators
    const updatedData = [...preparedData].map(entry => {
        const material = entry["Material"];
        const starting2Characters = material.substring(0, 2);
        const thickness = material.substring(9, 11);
        let totalWeight = 0;
        let formattedConsumption = 0;

        if (starting2Characters === "TB") {
            const newQty = entry['Still to be delivered (qty)'];
            totalWeight = newQty * SlabWeights[parseInt(thickness)];
            let racksConsumption = 0;
            if (thickness === "20") {
                racksConsumption = newQty / localDivisionIndicators.divisionIndicator20mm;
            } else if (thickness === "12") {
                racksConsumption = newQty / localDivisionIndicators.divisionIndicator12mm ;
            } else if (thickness === "06") {
                racksConsumption = newQty / localDivisionIndicators.divisionIndicator6mm;
            } else if (thickness === "03") {
                racksConsumption = newQty / localDivisionIndicators.divisionIndicator3mm;
            }
            formattedConsumption = racksConsumption.toFixed(2);
        }

        return {
            ...entry,
            "Total Weight": totalWeight,
            "Racks Consumption": formattedConsumption
        };
    });

    // Update the state with the modified data
    setPreparedData(updatedData);

    onHide(); // Close modal after saving
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Frame Conversion Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="thickness3">
            <Form.Label>3mm Slabs To a Frame:</Form.Label>
            <Form.Control
              type="number"
              value={localDivisionIndicators.divisionIndicator3mm}
              onChange={(e) => handleInputChange(e, 'divisionIndicator3mm')}
              className='form-control-modal'
              min={0}
              step={1}
            />
          </Form.Group>
          <Form.Group controlId="thickness6">
            <Form.Label>6mm Slabs To a Frame:</Form.Label>
            <Form.Control
              type="number"
              value={localDivisionIndicators.divisionIndicator6mm}
              onChange={(e) => handleInputChange(e, 'divisionIndicator6mm')}
              className='form-control-modal'
              min={0}
              step={1}
            />
          </Form.Group>
          <Form.Group controlId="thickness12">
            <Form.Label>12mm Slabs To a Frame:</Form.Label>
            <Form.Control
              type="number"
              value={localDivisionIndicators.divisionIndicator12mm}
              onChange={(e) => handleInputChange(e, 'divisionIndicator12mm')}
              className='form-control-modal'
              min={0}
              step={1}
            />
          </Form.Group>
          <Form.Group controlId="thickness20">
            <Form.Label>20mm Slabs To a Frame:</Form.Label>
            <Form.Control
              type="number"
              value={localDivisionIndicators.divisionIndicator20mm}
              onChange={(e) => handleInputChange(e, 'divisionIndicator20mm')}
              className='form-control-modal'
              min={0}
              step={1}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FrameConversionSettings;
