import React from 'react';
import Table from 'react-bootstrap/Table';
import './ArrayDocumentor.css';

const renderValue = value => {
  if (Array.isArray(value)) {
    // If the value is an array, stringify it
    return JSON.stringify(value);
  } else {
    // Otherwise, render the value as is
    return value;
  }
};

const renderType = value => {
  return typeof value;
};

const ArrayDocumentor = ({ data, title }) => {
  const Styler = {
    width: '35%',
    fontSize: 'small',
    margin: 'auto',
  };

  // If data is not defined or empty, or it's not an array, display "No data"
  if (!data || data.length === 0 || !Array.isArray(data)) return <div>No data</div>;

  // If data is an array but its length is 0, display "No data"
  if (data.length === 0) return <div>No data</div>;

  // Assuming the first item of the array represents the structure of subsequent items
  const singleRecord = data[0];

  return (
    <div className='-array-documentor-main-'>
      <Table striped bordered hover size="sm" className='object-documenter-table' style={Styler}>
        <thead>
          <tr>
            <th colSpan={3}>{title}</th>
          </tr>
          <tr>
            <th key={1}>Header</th>
            <th key={2}>Value</th>
            <th key={3}>Type</th>
          </tr>
        </thead>
        <tbody>
          {/* Iterate over the keys of the first record */}
          {Object.keys(singleRecord).map((key, index) => (
            <tr key={index}>
              {/* Display key */}
              <td style={{ width: "33%" }}>{key}</td>
              {/* Check if the value is an array and render it accordingly */}
              <td style={{ width: "33%" }}>{renderValue(singleRecord[key])}</td>
              {/* Render the data type of the value */}
              <td style={{ width: "33%" }}>{renderType(singleRecord[key])}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ArrayDocumentor;
