import React, {useState, useEffect} from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1Wave} from '@fortawesome/free-solid-svg-icons';

function CUBOCardSlabsCredits({ creditsData }) {
    const [creditsDataValid, setCreditsDataValid] = useState(false);
  const formatCurrencyLocal = (value) => {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
    });
  };

  const formatCurrencyEuro = (value) => {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });
  };

    useEffect(() => {
        if (creditsData !== null) {
            setCreditsDataValid(true);
        }
    }, [creditsData]);

  return (
    <Card className='cubo-card-sales-data dark-card font-lemonchiffon' >
      <Card.Header style={{ fontWeight: '600', display: 'flex', alignItems: 'center' }} className='medium-card-text'>
        <FontAwesomeIcon icon={faMoneyBill1Wave} style={{ marginRight: '10px' }} />
        <span>Credits:</span>
        <FontAwesomeIcon icon={faMoneyBill1Wave} style={{ marginLeft: '10px' }} />
      </Card.Header>
      <Card.Body className='cubo-card-body-transport'>
        {creditsDataValid &&
            <> 
            <Row className='cubo-card-transport-data-text'>
                <Col className="custom-col bord-bottom bord-right">
                <span style={{fontWeight: 600}}>Credits £:</span>
                <span className='revenue-result'>{formatCurrencyLocal(creditsData.totalCreditsLocal)}</span>
                </Col>
                <Col className="custom-col bord-bottom">
                <span style={{fontWeight: 600}}>Credits E:</span>
                <span className='revenue-result'>{formatCurrencyEuro(creditsData.totalCreditsEuro)}</span>
                </Col>
                <></>
            </Row>
            <Row className='cubo-card-transport-data-text'>
                <Col className="custom-col bord-right">
                <span style={{fontWeight: 600}}>Average: Cr £</span>
                <span className='revenue-result'>{formatCurrencyLocal(creditsData.averageCreditsLocal)}</span>
                </Col>
                <Col className="custom-col">
                <span style={{fontWeight: 600}}>Avg Cr £:</span>
                <span className='revenue-result'>{formatCurrencyEuro(creditsData.averageCreditsEuro)}</span>
                </Col>
            </Row>
            </>
        }
        </Card.Body>
    </Card>
  );
}

export default CUBOCardSlabsCredits;
