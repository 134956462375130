import React, { useEffect } from 'react'
import FileInputContainer from '../../Reusables/FileInputContainer'
import { Button, Container } from 'react-bootstrap'
import { GetJJDRPLocalData, SaveJJDRPData } from '../../../Utils/JJDRPOps'

function Config({JJDRPData, setJJDRPData, JJDRPFile, setJJDRPFile, setJJDRPDataValid}) {

  const handleJJDRPDataSave = async () => {
    const data = await SaveJJDRPData(JJDRPFile);
    if (data && data.length > 0) {
      setJJDRPData(data[0]);
      setJJDRPDataValid(true);
    }
  };

  const handleJJDRPLoad = async () => {
    const JJDRPData = await GetJJDRPLocalData();
    if (JJDRPData && JJDRPData.length > 0) {
      setJJDRPData(JJDRPData);
      setJJDRPDataValid(true);
      setJJDRPFile(null);
    } else {
      alert("No Debt Portal Data Found, please extract society FBL5N and upload.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      //retrieves data from JJDRP table in IndexedDB
      await handleJJDRPLoad();
    };
    fetchData();
  }, [GetJJDRPLocalData, SaveJJDRPData, setJJDRPData, setJJDRPDataValid]);

  return (
    <Container className='data-browser-card'>
      <span>Upload the Excel data file</span>
      <FileInputContainer labelTitle={"JJDRP Data"} selectedFilePath={JJDRPFile} setSelectedFile={setJJDRPFile} />
      <Button className='custom-button-gold' variant='warning' style={{ marginBottom: "10px" }} onClick={handleJJDRPDataSave}> Go!</Button>
    </Container>
  );
}

export default Config
