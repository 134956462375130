import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

function ClassifiedDataTable({ data, classificationColors = {} }) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const userCurrencyFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'GBP', // Default fallback currency
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const renderSortIcon = (column) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? (
        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: '5px' }} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: '5px' }} />
      );
    }
    return null;
  };

  const sortedData = data.slice().sort((a, b) => {
    const aValue = sortColumn ? a[sortColumn] : null;
    const bValue = sortColumn ? b[sortColumn] : null;

    if (aValue == null || bValue == null) return 0;

    if (sortDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  return (
    <div style={{ height: "76vh", overflowY: "scroll", marginBottom: "5px" }}>
      <Table striped bordered hover responsive size="sm" variant="dark" style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(14, 81, 99)', cursor: 'pointer' }} onClick={() => handleSort('material')}>
              Material {renderSortIcon('material')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(9, 100, 125)', cursor: 'pointer' }} onClick={() => handleSort('materialDescription')}>
              Material Description {renderSortIcon('materialDescription')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(14, 81, 99)', cursor: 'pointer' }} onClick={() => handleSort('totalSales')}>
              Total Sales {renderSortIcon('totalSales')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(9, 100, 125)', cursor: 'pointer' }} onClick={() => handleSort('cumulativePercentage')}>
              Cumulative % of Sales {renderSortIcon('cumulativePercentage')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(14, 81, 99)', cursor: 'pointer' }} onClick={() => handleSort('mean')}>
              Mean {renderSortIcon('mean')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(9, 100, 125)', cursor: 'pointer' }} onClick={() => handleSort('stdDev')}>
              Std Dev {renderSortIcon('stdDev')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(14, 81, 99)', cursor: 'pointer' }} onClick={() => handleSort('coeffVar')}>
              Coeff Var {renderSortIcon('coeffVar')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(9, 100, 125)', cursor: 'pointer' }} onClick={() => handleSort('classification')}>
              ABC Classification {renderSortIcon('classification')}
            </th>
            <th style={{ position: 'sticky', top: 0, zIndex: 3, backgroundColor: 'rgb(14, 81, 99)', cursor: 'pointer' }} onClick={() => handleSort('xyzClassification')}>
              XYZ Classification {renderSortIcon('xyzClassification')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.material}>
              <td>{item.material}</td>
              <td>{item.materialDescription}</td>
              <td>{userCurrencyFormatter.format(item.totalSales)}</td>
              <td>{item.cumulativePercentage.toFixed(2)}%</td>
              <td>{userCurrencyFormatter.format(item.mean)}</td>
              <td>{item.stdDev.toFixed(2)}</td>
              <td>{item.coeffVar.toFixed(2)}</td>
              <td>{item.classification}</td>
              <td>{item.xyzClassification}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ClassifiedDataTable;
