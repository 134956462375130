import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { GetUniqueColourList } from '../../Auth/APIRoutes';
import LookupDropdownBasic from './LookupDropdownBasic';
import { type } from '@testing-library/user-event/dist/type';
import LookupFieldWithDropdown from './LookupFieldWithDropdown';

function MaterialWizardModal({ show, onHide, onLookup, AppProps}) {
  const {token} = AppProps;
  const [color, setColor] = useState('');
  const [uniqueLists, setUniqueLists] = useState({});
  const [thickness, setThickness] = useState('');
  const [finish, setFinish] = useState('');

  const handleLookup = () => {
    // Pass the selected values to the parent component
    onLookup({ color, thickness, finish });
    // Close the modal
    onHide();
  };

  useEffect(() => {
    async function getLists() {
      const {response , error} = await GetUniqueColourList(token);
      if (error)
      {
        alert(error);
        return;
      }
      else {
          if (response) {
            setUniqueLists(response);
            console.log("Colors set");
            console.log(response.uniqueColors);
            console.log(typeof(response.uniqueColors)) 
          }
          console.log("Colors are: ", response);
      }
    }
    getLists();
  }, [show, token]);

  

  return (
    <Modal show={show} onHide={onHide} className='/MODAL MODAL' centered size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Material Wizard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            <span>Miniumum of Color and Thickness needs to be supplied before Search </span>
            {uniqueLists && 
            <LookupFieldWithDropdown data={uniqueLists.uniqueColors} searchByTitle='Colour' dataProperty='uniqueColors' setColor={setColor}  />}
            <LookupFieldWithDropdown data={uniqueLists.uniqueThicknesses} searchByTitle='Thickness' dataProperty='uniqueThicknesses' setThickness={setThickness}  />
            <LookupFieldWithDropdown data={uniqueLists.uniqueFinishes} searchByTitle='Finish' dataProperty='uniqueFinishes' setFinish={setFinish}  />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleLookup}>
          Lookup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MaterialWizardModal;
