// KPIGrid.js
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import './KPIGrid.css'; // Import the custom CSS for KPIGrid
import VBC_DynamicClientChart from './VBC_DynamicClientChart';

const KPIGrid = ({ kpis, dataType, selectedKPI, setSelectedKPI, chartData, localCurrency }) => {

    // Separate the KPIs into top and bottom rows
    const topKPIs = kpis.slice(0, 2);
    const bottomKPIs = kpis.slice(2, 5);

    // Handle KPI card click to update chart data
    const handleKPISelect = (kpi) => {
        setSelectedKPI(kpi.label);    
    };

    return (
        <Card className='kpi-grid-card'>
            <Card.Body>
                <Row className='kpi-grid'>
                    {topKPIs.map((kpi, index) => (
                        <Col key={index} xs={12} md={6} className='kpi-item-col'>
                            <Card 
                                className={`kpi-item ${selectedKPI === kpi.label ? 'selected' : ''}`}
                                onClick={() => handleKPISelect(kpi)}
                            >
                                <Card.Body>
                                    <h5>{kpi.label}</h5>
                                    <p>{kpi.value}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row className='kpi-grid'>
                    {bottomKPIs.map((kpi, index) => (
                        <Col key={index} xs={12} md={4} className='kpi-item-col'>
                            <Card 
                                className={`kpi-item ${selectedKPI === kpi.label ? 'selected' : ''}`}
                                onClick={() => handleKPISelect(kpi)}
                            >
                                <Card.Body>
                                    <h5>{kpi.label}</h5>
                                    <p>{kpi.value}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* Render chart if a KPI is selected */}
                {selectedKPI && (
                    <VBC_DynamicClientChart
                        chartData={chartData}
                        dataType={dataType}
                        selectedKPI={selectedKPI}
                        localCurrency={localCurrency}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default KPIGrid;
