import React from 'react'
import { Button } from 'react-bootstrap'

function ChartSelector({setSelectedChart}) {

    const handleChartSelection = (event) => {
        setSelectedChart(event.target.innerText);        
    };

  return (
    <div className='chart-selector'>
        <div className="button-list">
            <span className='small-card-text'>Select Chart:</span>
            <Button variant='success' className='custom-button-gold chart-selector-button' onClick={handleChartSelection}>Best Customers</Button>
            <Button variant='success' className='custom-button-gold chart-selector-button' onClick={handleChartSelection}>Best Materials</Button>
            <Button variant='danger' className='custom-button-gold chart-selector-button' onClick={handleChartSelection}>Worst Customers</Button>
            <Button variant='danger' className='custom-button-gold chart-selector-button' onClick={handleChartSelection}>Worst Materials</Button>
        </div>
    </div>
  )
}

export default ChartSelector