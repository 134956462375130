import React, {useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card'
import { faTruck } from '@fortawesome/free-solid-svg-icons'    
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

function ExcelDashCardVA05({ExcelProps}) {
    const {excelDRPConcept} = ExcelProps;
    const [VA05Data, setVA05Data] = useState(null);
    const [totalOrdersPendingDelivery, setTotalOrdersPendingDelivery] = useState(null);
    const [totalItemsPendingDelivery, setTotalItemsPendingDelivery] = useState(null);
    const [top5Materials, setTop5Materials] = useState(null);

    useEffect(() => {
        const handleDataCheck = async () => {
          try {
            if (excelDRPConcept) {
              setVA05Data(excelDRPConcept.totalOrdersByMaterial);
            } else {
              setVA05Data("error"); // Handle missing excelDRPConcept
            }
          } catch (error) {
            console.log(error);
            setVA05Data("catch error"); // Handle other errors
          }
        };
        handleDataCheck();
      }, [excelDRPConcept]);

      useEffect(() => {
        try {
          // Calculate total orders and items
          const totalOrders = VA05Data.reduce((acc, item) => acc + item.TotalOrders, 0);
          const totalItems = VA05Data.reduce((acc, item) => {
            const totalItemsForItem = item.Orders.reduce((accForItem, order) => {
              const openQuantity = order.openQuantity;
              return accForItem + (openQuantity ? openQuantity : 0);
            }, 0);
            return acc + totalItemsForItem;
          }, 0);
      
          // Extract top 5 materials based on open quantity
          const top5Materials = VA05Data
            ?.filter((material) => material.Material.startsWith("TB"))
            .sort((a, b) => {
              const aOpenQuantity = a.Orders.reduce((acc, order) => acc + (order.openQuantity ? order.openQuantity : 0), 0);
              const bOpenQuantity = b.Orders.reduce((acc, order) => acc + (order.openQuantity ? order.openQuantity : 0), 0);
              return bOpenQuantity - aOpenQuantity;
            })
            .slice(0, 5)
            .map((material) => {
              // Remove dimensions with 2 characters or less
              material.Description = material.Description = material.Description.replace(/\b\w{1,2}\b|\b\d{1,2}x\d{1,2}\b/g, '').trim();
              return material;
            });
          setTop5Materials(top5Materials);
          setTotalOrdersPendingDelivery(totalOrders);
          setTotalItemsPendingDelivery(totalItems);
        } catch (error) {
        }
      }, [VA05Data]);

    function HorizontalLine() {
    return <div style={{ borderBottom: '1px solid white', margin: '5px 0', width: "50%" }} />;
    }

  return (
    <Card className='excel-dash-card dark-card'>
        <Card.Header style={{ fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTruck} style={{marginRight: "1vw"}} />VA05 Orders
        </Card.Header>
        <Card.Body className='excel-dash-card-body' style={{fontSize: "small"}}>
            <span>
                Total Orders Pending Delivery: {totalOrdersPendingDelivery}
            </span>
            {HorizontalLine()}
            <span>
                Total items Pending Delivery: {totalItemsPendingDelivery}
            </span>
            {HorizontalLine()}
            <ResponsiveContainer width='75%' height={280} style={{marginTop: "1vh"}}>
              <BarChart data={top5Materials} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey='Description' fontSize='small' angle={-25} textAnchor='end' interval={0} tickMargin={1} height={130} />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc' }} />
                <Legend verticalAlign='top' height={36} />
                <Bar dataKey='TotalOpenQuantity' fill='#8884d8'>
                  <LabelList dataKey='TotalOpenQuantity' position='top' />
                </Bar>
              </BarChart>
          </ResponsiveContainer>
        </Card.Body>
    </Card>
  )
}

export default ExcelDashCardVA05