import './MenuButtonIcon.css';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Reusable Icon component
const MenuButtonIcon = ({ icon, text, handleSelectInsight }) => {

  const handleSelectText = () => {
    handleSelectInsight(text);
  };

  return (
    <div className={'menu-button-icon ' + text} onClick={handleSelectText}> 
        <FontAwesomeIcon className={'icon ' + text} icon={icon} size="1x" />
        <div className='menu-button-icon-text'>{text}</div>
    </div>
  );
};

export default MenuButtonIcon;