import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { faBoxes, faBoxesStacked} from '@fortawesome/free-solid-svg-icons'    
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ExcelDashCardIQ09({ ExcelProps }) {
  const { excelDRPConcept } = ExcelProps;
  const [IQ09Data, setIQ09Data] = useState([]);
  const [totalSlabs, setTotalSlabs] = useState(0);
  const [totalSlabsE, setTotalSlabsE] = useState(0);
  const [top5StockedMaterials, setTop5StockedMaterials] = useState(null);


  useEffect(() => {
    // Assuming you have logic to get and process stock data
    const handleDataCheck = async () => {
      try {
        if (excelDRPConcept) {
          setIQ09Data(excelDRPConcept.totalStockByMaterialBatchAndSerial);
        } else {
          setIQ09Data("error"); // Handle missing excelDRPConcept
        }
      } catch (error) {
        console.log(error);
        setIQ09Data("catch error"); // Handle other errors
      }
    };
    handleDataCheck();
  }, [excelDRPConcept]);

  useEffect(() => {
    try {
      if (IQ09Data && Array.isArray(IQ09Data)) {
        // Calculate total orders and items
        const totalSlabs = IQ09Data.reduce((acc, item) => {
          // Assuming Material is a property of each item in IQ09Data
          if (item.Material && item.Material.startsWith("TB")) {
            return acc + item.Quantity;
          }
          return acc;
        }, 0);
  
        const totalSlabsE = IQ09Data.reduce((acc, item) => {
          // Assuming Material is a property of each item in IQ09Data
          if (item.Material && item.Material.endsWith("E") && item.Material.startsWith("TB")) {
            return acc + item.Quantity;
          }
          return acc;
        }, 0);
  
        setTotalSlabs(totalSlabs);
        setTotalSlabsE(totalSlabsE);

        //extract top 5 stocked materials
        const top5StockedMaterials = IQ09Data
          ?.filter((material) => material.Material.startsWith("TB"))
          .sort((a, b) => {
            const aQuantity = a.Quantity;
            const bQuantity = b.Quantity;
            return bQuantity - aQuantity;
          })
          .slice(0, 5)
          setTop5StockedMaterials(top5StockedMaterials);
      }
    } catch (error) {
      console.log(error);
    }
  }, [IQ09Data]);
  
  
  
  
 

  function HorizontalLine() {
    return <div style={{ borderBottom: '1px solid white', margin: '5px 0', width: '50%' }} />;
  }

  return (
    <Card className='excel-dash-card dark-card'>
        <Card.Header style={{ fontWeight: "600" }}>
            <FontAwesomeIcon icon={faBoxes} style={{marginRight: "1vw"}} />IQ09 Stock
        </Card.Header>
        <Card.Body className='excel-dash-card-body' style={{fontSize: "medium"}}>
            {totalSlabs &&             
            <span>
                Total slabs: {totalSlabs}
            </span>}
            {HorizontalLine()}
            <span>
              Total slabs E Quality: {totalSlabsE}
            </span>
            {HorizontalLine()}
            <ResponsiveContainer width='75%' height={280} style={{marginTop: "1vh"}}>
              <BarChart data={top5StockedMaterials} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey='Description' fontSize='small' angle={-25} textAnchor='end' interval={0} tickMargin={1} height={130} />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc' }} />
                <Legend verticalAlign='top' height={36} />
                <Bar dataKey='Quantity' fill='skyblue'>
                  <LabelList dataKey='Quantity' position='top' />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
        </Card.Body>
    </Card>
  );
}

export default ExcelDashCardIQ09;
