import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {connectToDB, RemoveDataFromTable} from '../../../Utils/DBOps'

function VentasNavBar({setVentasData, setCurrentViewedComponent}) {

    const handleClearData = async () => {
        try {
            const db = await connectToDB();
            await RemoveDataFromTable(db, "VentasAnalyserData");
            setVentasData([]);
            setCurrentViewedComponent("VentasAnalyserConfig");
        } catch (error) {
            console.log('Error clearing local storage:', error.message);
        }
        setVentasData([]);
    };

  return (
    <div>
        <Navbar variant="dark" bg="dark" expand="lg" className='navbar-parent'>
            <Container fluid>
                <Navbar.Brand href="#home">Ventas Analyser</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-dark-example" />
                <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                        <NavDropdown
                        style={{fontSize: "medium"}}
                        id="functions"
                        title="Functions"
                        menuVariant="dark"
                        >
                            <NavDropdown.Item onClick={() => setCurrentViewedComponent("VentasDash")}>Dash</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => setCurrentViewedComponent("VentasByClient")} >By Client</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <Container fluid className={"nav-bar-right"}>
                    <Button variant="danger" size='sm' onClick={handleClearData}>Start Over</Button>
                </Container>
            </Container>
        </Navbar>
    </div>
  )
}

export default VentasNavBar