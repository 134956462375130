import React from 'react'
import {Nav, Navbar, NavDropdown, Button, Form} from 'react-bootstrap'
import {RemoveDataFromTable, connectToDB} from '../../../Utils/DBOps'

function DebtPortalNavBar({setDebtPortalData, setDebtDataPresent, setDebtPortalDisplayView, debtPortalFilterProps, filtersBarVisible, setFiltersBarVisible, 
    setHideCurrencyandPostingColumns, hideCurrencyandPostingColumns, overDueOnly, setOverDueOnly, handleExportData
}) {
    const {CustomerAccountShowOpenItemsOnly, setCustomerAccountShowOpenItemsOnly} = debtPortalFilterProps;
    
    const handleResetData = async () => {
        try {
            const _db = await connectToDB();
            await RemoveDataFromTable(_db, "DebtPortalData");
            setDebtPortalData(null);
            setDebtDataPresent(false);
            setDebtPortalDisplayView('Config');
            console.log("Data Reset Successfully");
        } catch (error) {
            console.log(error);
        }
    };

    const handleSetView = (e) => {
        const view = e.target.innerText;
        setDebtPortalDisplayView(view);
    };

    return (
        <Navbar className="-CUBOAnalyser-navbar" variant="dark" bg="dark" expand="lg">
            <Navbar.Brand className="navbar-brand-cubo-analyser">Debt Portal</Navbar.Brand>
            <Nav className="mr-auto">
                <NavDropdown title="Views" id="basic-nav-dropdown" style={{fontSize: "medium"}} menuVariant='dark'>
                    <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={handleSetView}>Overview</NavDropdown.Item>
                    <NavDropdown.Item  style={{ fontSize: "medium", width: "fit-content" }} onClick={handleSetView}>Customer Account</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav className="mr-auto">
                <NavDropdown title="Options" id="basic-nav-dropdown" style={{fontSize: "medium"}} menuVariant='dark'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <Form.Check 
                            type="switch" 
                            label="Show Open Items Only" 
                            style={{fontSize: "small", margin: "4px", display: "flex", justifyContent: "space-between", alignItems: 'center', fontSize: "12px"}}
                            checked={CustomerAccountShowOpenItemsOnly}
                            onChange={() => setCustomerAccountShowOpenItemsOnly(!CustomerAccountShowOpenItemsOnly)}
                        />
                        <Form.Check 
                            type="switch" 
                            label="Show Filters Bar" 
                            style={{fontSize: "small", margin: "4px", display: "flex", justifyContent: "space-between", alignItems: 'center', fontSize: "12px"}}
                            checked={filtersBarVisible}
                            onChange={() => setFiltersBarVisible(!filtersBarVisible)}
                        />
                        <Form.Check 
                            type="switch" 
                            label="Hide Posting/Currency" 
                            style={{fontSize: "small", margin: "4px", display: "flex", justifyContent: "space-between", alignItems: 'center', fontSize: "12px"}}
                            checked={hideCurrencyandPostingColumns}
                            onChange={() => setHideCurrencyandPostingColumns(!hideCurrencyandPostingColumns)}
                        />
                        <Form.Check 
                            type="switch" 
                            label="Overdue Only" 
                            style={{fontSize: "small", margin: "4px", display: "flex", justifyContent: "space-between", alignItems: 'center', fontSize: "12px"}}
                            checked={overDueOnly}
                            onChange={() => setOverDueOnly(!overDueOnly)}
                        />
                    </div>
                </NavDropdown>
            </Nav>
            <Nav className="mr-auto flex-end">
                <Button variant="success" size='sm' style={{marginRight: "15px"}} onClick={handleExportData}>Export</Button>
                <Button variant="danger" size='sm' onClick={handleResetData}>Reset Data</Button>
            </Nav>
        </Navbar>
      )
}

export default DebtPortalNavBar