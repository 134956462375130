import './DebtPortalCustomerAccountMainTable.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../../../../Utils/FormattingFunctions';

const debtTableColumns = [
  { dataField: 'Document Type', displayName: 'Document Type' },
  { dataField: 'Document Number', displayName: 'Document Number', numericSort: true },
  { dataField: 'Invoice Reference', displayName: 'Invoice Reference' },
  { dataField: 'Assignment', displayName: 'Assignment', numericSort: true },
  { dataField: 'Clearing Document', displayName: 'Clearing Document' },
  { dataField: 'Clearing Date', displayName: 'Clearing Date', dateSort: true },
  { dataField: 'Document Date', displayName: 'Document Date', dateSort: true },
  { dataField: 'Net Due Date', displayName: 'Net Due Date', dateSort: true },
  { dataField: 'Customer', displayName: 'Customer' },
  { dataField: 'Name 1', displayName: 'Name' },
  { dataField: 'Posting period', displayName: 'Posting Period', numericSort: true },
  { dataField: 'Local Currency', displayName: 'Local Currency' },
  { dataField: 'Amount in Local Currency', displayName: 'Amount', numericSort: true },
  { dataField: 'Text', displayName: 'Text' },
  { dataField: 'Terms of Payment', displayName: 'Terms of Payment' },
  { dataField: 'Reference', displayName: 'Reference', numericSort: true },
];

export function convertExcelDateToUKDate(excelDate) {
  if (!excelDate || isNaN(excelDate)) return '';
  const startDate = new Date('1900-01-01');
  const millisecondsToAdd = (excelDate - 1) * 24 * 60 * 60 * 1000;
  const targetDate = new Date(startDate.getTime() + millisecondsToAdd);
  const day = targetDate.getDate();
  const month = targetDate.getMonth() + 1;
  const year = targetDate.getFullYear();
  return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
}

// Function to parse UK date string into Date object
function parseUKDate(dateStr) {
  if (!dateStr) return null;
  const [day, month, year] = dateStr.split('/');
  return new Date(`${year}-${month}-${day}`);
}

function DebtPortalCustomerAccountMainTable({ debtPortalData, filtersBarVisible, hideCurrencyandPostingColumns, CustomerAccountShowOpenItemsOnly, setExportData }) {
  // Set initial sort to Net Due Date, oldest to newest (ascending)
  const [sortColumn, setSortColumn] = useState('Net Due Date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedFilteredData, setSortedFilteredData] = useState([]);
  const tableRef = useRef();

  const handleSort = (column) => {
    const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const applyFiltersAndSort = useCallback(() => {
    let filteredData = debtPortalData.map(item => ({
      ...item,
      'Document Date': convertExcelDateToUKDate(item['Document Date']),
      'Net Due Date': convertExcelDateToUKDate(item['Net Due Date']),
      'Clearing Date': item['Clearing Date'] ? convertExcelDateToUKDate(item['Clearing Date']) : ''
    }));

    const columnConfig = debtTableColumns.find(entry => entry.dataField === sortColumn) || {};
    const isNumericSort = columnConfig.numericSort || false;
    const isDateSort = columnConfig.dateSort || false;

    filteredData.sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (isNumericSort) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
        if (isNaN(aValue)) aValue = 0;
        if (isNaN(bValue)) bValue = 0;
        return sortDirection === 'asc' ? (aValue - bValue) : (bValue - aValue);
      } else if (isDateSort) {
        const aDate = parseUKDate(aValue);
        const bDate = parseUKDate(bValue);
        if (!aDate && !bDate) return 0;
        if (!aDate) return 1;
        if (!bDate) return -1;
        return sortDirection === 'asc' ? aDate - bDate : bDate - aDate;
      } else {
        return sortDirection === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }
    });

    setSortedFilteredData(filteredData);
  }, [debtPortalData, sortColumn, sortDirection]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [debtPortalData, sortColumn, sortDirection, applyFiltersAndSort]);

  const totalAmount = sortedFilteredData.reduce((total, item) => {
    const amount = parseFloat(item['Amount in Local Currency']);
    return total + (isNaN(amount) ? 0 : amount);
  }, 0).toFixed(2);

  const mainTableStyle = {
    ...(filtersBarVisible && { maxHeight: "72vh" })
  };

  const currencyCode = sortedFilteredData.length > 0 ? sortedFilteredData[0]['Local Currency'] : 'GBP';

  // Filter columns based on hideCurrencyandPostingColumns prop
  const visibleColumns = debtTableColumns.filter(column => 
    !hideCurrencyandPostingColumns || (column.dataField !== 'Local Currency' && column.dataField !== 'Posting period')
  );

  return (
    <div className='debt-portal-customer-account-main-table' style={mainTableStyle}>
      <Table striped bordered hover size='sm' variant='dark' ref={tableRef} className='actual-debt-table'>
        <thead>
          <tr>
            {visibleColumns.map(({ dataField, displayName }) => (
              <th
                key={dataField}
                onClick={() => handleSort(dataField)}
                style={{ cursor: 'pointer', position: 'sticky', top: 0, zIndex: 1, fontSize: 'small' }}
              >
                {displayName}
                {sortColumn === dataField && (
                  <FontAwesomeIcon
                    icon={sortDirection === 'asc' ? faSortUp : faSortDown}
                    style={{ marginLeft: '5px' }}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedFilteredData.length > 0 ? (
            sortedFilteredData.map((item, index) => {
              // Determine if the row is overdue
              const netDueDateStr = item['Net Due Date'];
              const netDueDate = parseUKDate(netDueDateStr);
              const today = new Date();
              const isOverdue = netDueDate && netDueDate < today.setHours(0, 0, 0, 0); // Compare dates without time

              return (
                <tr key={index}>
                  {visibleColumns.map(({ dataField }) => {
                    let cellStyle = { fontSize: 'small' };
                    let cellContent = item[dataField];

                    if (dataField === 'Net Due Date') {
                      if (isOverdue) {
                        cellStyle.color = 'lightcoral'; // Light red color
                      } else {
                        cellStyle.color = 'lightgreen'; // Light green color
                      }
                    }

                    if (dataField === 'Amount in Local Currency') {
                      cellContent = formatCurrency(item[dataField] || 0, 'en-GB', currencyCode);
                    }

                    return (
                      <td key={dataField} style={cellStyle}>
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={visibleColumns.length}>Account Clear</td>
            </tr>
          )}
          {sortedFilteredData.length > 0 && (
            <tr>
              {visibleColumns.map((column, idx) => (
                idx === visibleColumns.length - 5 ? (
                  <td key={idx} colSpan={1} style={{ textAlign: 'center', fontSize: "small" }}><strong>Total:</strong></td>
                ) : idx === visibleColumns.length - 4 ? (
                  <td key={idx} colSpan="1" style={{ fontSize: 'small' }}><strong>{formatCurrency(totalAmount, 'en-GB', currencyCode)}</strong></td>
                ) : (
                  <td key={idx}></td>
                )
              ))}
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default DebtPortalCustomerAccountMainTable;
