import './ExcelDashNavBar.css';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Button, Form, NavDropdown } from 'react-bootstrap';
import { exportToExcelWithOmission } from '../../../../Utils/ExcelFileOps';
import FilterDropdown from '../../../Reusables/FilterDropdown';
import SettingsDropdown from '../ExcelDashNavBar/SettingsDropdown';
import ColumnsDropdown from './ColumnsDropdown';

function ExcelDashNavBarFiltersFunctions({
  selectedDecors,
  setSelectedDecors,
  selectedFinishes,
  setSelectedFinishes,
  selectedThicknesses,
  setSelectedThicknesses,
  selectedSizes,
  setSelectedSizes,
  excelDRPData, toggleColumns, showAdditionalColumns, handleCopyToClipboard,
  excelFilteredDRPData, plant, setATPOnly, ATPOnly, ESlabsOnly, setESlabsOnly,
  setOptimalSizesOnly, optimalSizesOnly, meshVisible, setMeshVisible,
  hideZeroLines, setHideZeroLines, selectedPriceGroups, setSelectedPriceGroups,
  visibleColumns, setVisibleColumns, allColumns, toggleColumnVisibility,
}) {


    // Search inputs
    const [decorSearch, setDecorSearch] = useState('');
    const [finishSearch, setFinishSearch] = useState('');
    const [thicknessSearch, setThicknessSearch] = useState('');
    const [sizeSearch, setSizeSearch] = useState('');
    const [priceGroupSearch, setPriceGroupSearch] = useState('');

    // Multi-select state for each filter
    const [decorMultiSelect, setDecorMultiSelect] = useState(false);
    const [finishMultiSelect, setFinishMultiSelect] = useState(false);
    const [thicknessMultiSelect, setThicknessMultiSelect] = useState(false);
    const [sizeMultiSelect, setSizeMultiSelect] = useState(false);
    const [priceGroupMultiSelect, setPriceGroupMultiSelect] = useState(false);

    // Unique options
    const [uniqueDecors, setUniqueDecors] = useState([]);
    const [uniqueFinishes, setUniqueFinishes] = useState([]);
    const [uniqueThicknesses, setUniqueThicknesses] = useState([]);
    const [uniqueSizes, setUniqueSizes] = useState([]);
    const [uniquePriceGroups, setUniquePriceGroups] = useState([]);

    const today = new Date();
    const fileSafeDateString = today.toISOString().slice(0, 10).replace(/-/g, '');

    useEffect(() => {
        if (excelDRPData) {
        const uniqueDecors = [...new Set(excelDRPData.map(item => item['Decor']))].sort();
        const uniqueFinishes = [...new Set(excelDRPData.map(item => item['Finish Description']))].sort();
        const uniqueThicknesses = [...new Set(excelDRPData.map(item => item['Thickness']))].sort((a, b) => a - b);
        const uniqueSizes = [...new Set(excelDRPData.map(item => item['Size Description']))].sort();
        const uniquePriceGroups = [...new Set(excelDRPData.map(item => item['Price group']))].sort();

        setUniqueDecors(uniqueDecors);
        setUniqueFinishes(uniqueFinishes);
        setUniqueThicknesses(uniqueThicknesses);
        setUniqueSizes(uniqueSizes);
        setUniquePriceGroups(uniquePriceGroups);
        }
    }, [excelDRPData]);

    const handleSetOptimalSizesOnly = () => {
        setOptimalSizesOnly(!optimalSizesOnly);
    };

    const toggleATPOnly = () => {
        setATPOnly(!ATPOnly);
    };

    const handleClearFilters = () => {
        setSelectedDecors([]);
        setSelectedFinishes([]);
        setSelectedThicknesses([]);
        setSelectedSizes([]);
        setDecorSearch('');
        setFinishSearch('');
        setThicknessSearch('');
        setSizeSearch('');
        // Reset multi-select states if needed
        setDecorMultiSelect(false);
        setFinishMultiSelect(false);
        setThicknessMultiSelect(false);
        setSizeMultiSelect(false);
    };

    const getSelectedFiltersLabel = () => {
        let selectedFilters = [];
    
        // Helper function to safely process filters that could be a string or an array
        const processFilter = (label, filter) => {
            if (!filter || (Array.isArray(filter) && filter.length === 0)) return; // Check if filter is empty or undefined
    
            if (Array.isArray(filter)) {
                selectedFilters.push(`${label}: ${filter.join(', ')}`);
            } else if (typeof filter === 'string' && filter.trim() !== '') {
                selectedFilters.push(`${label}: ${filter}`);
            }
        };
    
        // Process each filter using the helper function
        processFilter('Decor', selectedDecors);
        processFilter('Finish', selectedFinishes);
        processFilter('Thickness', selectedThicknesses);
        processFilter('Size', selectedSizes);
        processFilter('Price Group', selectedPriceGroups);
    
        // Combine all selected filters into a single string
        return selectedFilters.length > 0 ? selectedFilters.join(' | ') : 'None';
    };
    

    return (
        <Container fluid className='navbar-container'>
        <Navbar.Brand style={{marginLeft: "25px"}}>Filters:</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
            <Nav>
            <FilterDropdown
                title="Decor"
                options={uniqueDecors.filter(value => value.toLowerCase().includes(decorSearch.toLowerCase()))}
                selectedItems={selectedDecors}
                setSelectedItems={setSelectedDecors}
                searchValue={decorSearch}
                setSearchValue={setDecorSearch}
                multiSelect={decorMultiSelect}
                setMultiSelect={setDecorMultiSelect}
                handleSelectAll={() => setSelectedDecors(uniqueDecors)}
            />
            <FilterDropdown
                title="Finish"
                options={uniqueFinishes.filter(value => value.toLowerCase().includes(finishSearch.toLowerCase()))}
                selectedItems={selectedFinishes}
                setSelectedItems={setSelectedFinishes}
                searchValue={finishSearch}
                setSearchValue={setFinishSearch}
                multiSelect={finishMultiSelect}
                setMultiSelect={setFinishMultiSelect}
                handleSelectAll={() => setSelectedFinishes(uniqueFinishes)}
            />
            <FilterDropdown
                title="Thickness"
                options={uniqueThicknesses.filter(value => value.toString().toLowerCase().includes(thicknessSearch.toLowerCase()))}
                selectedItems={selectedThicknesses}
                setSelectedItems={setSelectedThicknesses}
                searchValue={thicknessSearch}
                setSearchValue={setThicknessSearch}
                multiSelect={thicknessMultiSelect}
                setMultiSelect={setThicknessMultiSelect}
                handleSelectAll={() => setSelectedThicknesses(uniqueThicknesses)}
            />
            <FilterDropdown
                title="Size"
                options={uniqueSizes.filter(value => value.toLowerCase().includes(sizeSearch.toLowerCase()))}
                selectedItems={selectedSizes}
                setSelectedItems={setSelectedSizes}
                searchValue={sizeSearch}
                setSearchValue={setSizeSearch}
                multiSelect={sizeMultiSelect}
                setMultiSelect={setSizeMultiSelect}
                handleSelectAll={() => setSelectedSizes(uniqueSizes)}
            />
            <FilterDropdown
                title="Price Group"
                options={uniquePriceGroups.filter(value => {
                    return (
                        value !== undefined &&
                        value !== null &&
                        (priceGroupSearch === '' || value.toString() === priceGroupSearch)
                    );
                })}
                selectedItems={selectedPriceGroups}
                setSelectedItems={setSelectedPriceGroups}
                searchValue={priceGroupSearch}
                setSearchValue={setPriceGroupSearch}
                multiSelect={priceGroupMultiSelect}
                setMultiSelect={setPriceGroupMultiSelect}
                handleSelectAll={() => setSelectedPriceGroups(uniquePriceGroups)}
            />


            </Nav>
        </Navbar.Collapse>
        <div style={{ display: 'flex', width: "auto", color: "whitesmoke", fontSize: "small", flexGrow: "1", justifyContent: "center" }}>
            <span >
                Selected Filters: {getSelectedFiltersLabel()}
            </span>
        </div>
        <Container className='nav-bar-right'>
            <ColumnsDropdown
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                toggleColumnVisibility={toggleColumnVisibility}
            />
            <SettingsDropdown
                showAdditionalColumns={showAdditionalColumns}
                toggleColumns={toggleColumns}
                ATPOnly={ATPOnly}
                toggleATPOnly={toggleATPOnly}
                ESlabsOnly={ESlabsOnly}
                setESlabsOnly={setESlabsOnly}
                optimalSizesOnly={optimalSizesOnly}
                handleSetOptimalSizesOnly={handleSetOptimalSizesOnly}
                meshVisible={meshVisible}
                setMeshVisible={setMeshVisible}
                hideZeroLines={hideZeroLines}
                setHideZeroLines={setHideZeroLines}
            />
            <Container>
                <Button
                    variant="info"
                    size='sm'
                    onClick={handleCopyToClipboard}
                    style={{ color: "white", fontWeight: "700", marginLeft: "10px" }}
                >
                    Copy to Clipboard
                </Button>
                <Button
                    variant="info"
                    size='sm'
                    onClick={() => exportToExcelWithOmission(excelFilteredDRPData, `DRP Summary - ${plant} - ` + fileSafeDateString, [], [])}
                    style={{ color: "white", fontWeight: "700", marginLeft: "10px" }}
                >
                    Export To Excel
                </Button>
                <Button
                    variant="danger"
                    size='sm'
                    onClick={handleClearFilters}
                    style={{ marginLeft: "10px", fontWeight: "700" }}
                >
                    Clear Filters
                </Button>
            </Container>
        </Container>
        </Container>
    );
}

export default ExcelDashNavBarFiltersFunctions;
