import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBtnFinish({data, handleFilterFinishChange}) {
  return (
    <div className='navbtn-colors'>
        {data[0] && (
        data
            // Extract unique color values
            .map((item) => item.Finish)
            .filter((value, index, self) => self.indexOf(value) === index)
            // Sort the unique color values alphabetically
            .sort()
            // Render the dropdown items
            .map((finish) => (
            <NavDropdown.Item
                key={finish}
                className='navbtn-finish__item'
                onClick={handleFilterFinishChange}
            >
                {finish}
            </NavDropdown.Item>
            ))
        )}
    </div>
  )
}

export default NavBtnFinish