import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { faSterlingSign} from '@fortawesome/free-solid-svg-icons'    
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {totalBilledSlabsAllCustomer, totalNetValueAllCustomerLocal} from '../../../../../Utils/CUBOAnalysisOps'
import {convertExcelDateToDate} from '../../../../../Utils/DateOps'

function ExcelDashCardCUBO({ ExcelProps, setSelectedViewComponent }) {
    const { excelDRPConcept } = ExcelProps;
    const [CUBOData, setCUBOData] = useState([]);
    const [totalSalesNet, setTotalSalesNet] = useState(0);
    const [totalSalesSlabs, setTotalSalesSlabs] = useState(0);
    const [top5SoldMaterials, setTop5SoldMaterials] = useState(null);

    useEffect(() => {
        const handleDataCheck = async () => {
            if (excelDRPConcept) {
                setCUBOData(excelDRPConcept.totalSalesByMaterial);
                const salesDataRaw = excelDRPConcept.salesDataRaw;

                
                console.log(totalSalesNet);
                console.log(totalSalesSlabs);
            }
            else {
                console.log("error");
            }
        };
        handleDataCheck();
    }, [excelDRPConcept]);

    const returnBilledQty = (sale) => {
        return sale["Billed Quantity"] || sale['Cantidad Facturada'];
    }

    const returnDateValue = (sale) => {
        return sale["Invoice Document Date"] || sale['Fecha Doc. Factura'];
    };

    useEffect(() => {
        try {
            if (CUBOData && Array.isArray(CUBOData)) {
                // Calculate total sales and slabs
                const salesDataRaw = excelDRPConcept.salesDataRaw;

                //filter salesDataRaw so that its only the last 12 months of data:
                const last12Months = new Date();
                last12Months.setMonth(last12Months.getMonth() - 12);
                const filteredSalesData = salesDataRaw.filter((sale) => {
                    const dateVal = returnDateValue(sale);
                    const convertedDate = convertExcelDateToDate(dateVal);
                    return convertedDate >= last12Months;
                });

                const totalSalesNet = totalNetValueAllCustomerLocal(filteredSalesData);
                const totalSalesSlabs = totalBilledSlabsAllCustomer(filteredSalesData);
                setTotalSalesNet(totalSalesNet.toLocaleString('en-US', { style: 'currency', currency: 'GBP' }));
                setTotalSalesSlabs(totalSalesSlabs);
                
                // Calculate total Billed Quantity per material
                const materialMap = new Map();

                    filteredSalesData.forEach((sale) => {
                        const material = sale.Material;
                        const billedQty = parseFloat(returnBilledQty(sale)) || 0;

                    // Only consider materials starting with "TB" and quantities > 0
                    if (material && String(material).startsWith("TB") && billedQty > 0) {
                        if (!materialMap.has(material)) {
                            materialMap.set(material, {
                                materialCode: material,
                                materialDescription: sale["Material Description"] || sale["Descripción Material"] || '',
                                totalBilledQty: 0,
                            });
                        }
                        const materialData = materialMap.get(material);
                        materialData.totalBilledQty += billedQty;
                    }
                });

                // Convert the map to an array and sort by totalBilledQty descending
                const sortedMaterials = Array.from(materialMap.values())
                    .sort((a, b) => b.totalBilledQty - a.totalBilledQty)
                    .slice(0, 5); // Get the top 5 materials

                setTop5SoldMaterials(sortedMaterials);
            }
        } catch (error) {
            console.log(error);
        }
    }, [CUBOData, excelDRPConcept.salesDataRaw]);


    const HorizontalLine = () => {
        return <div style={{ borderBottom: '1px solid white', margin: '5px 0', width: '50%' }} />;
      }
    const currencyFormatter = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(value);

    const tooltipFormatter = (value, name, props) => {
        const { payload } = props;
      
        // Check the payload and payload[0] to see their values
        try {
            const slabName = payload.Description;
            return [`Slabs Invoiced: ${value}`];
        } catch (error) {
           console.log("couldnt access payload.Description"); 
           return [`NetBilledSum: ${currencyFormatter(value)}`];
        }

    };

    const handleNavigateCUBO = () => {
        if (excelDRPConcept.salesDataRaw) {
            setSelectedViewComponent("CUBO Analytics");
        }
        else {
            alert("No CUBO Sales data found. Please ensure CUBO sales data was uploaded into CUBO section of config and try again.");
        }
    };

    console.log(top5SoldMaterials);

  return (
    <Card className='excel-dash-card dark-card' onClick={handleNavigateCUBO}>
        <Card.Header style={{ fontWeight: "600" }}>
            <FontAwesomeIcon icon={faSterlingSign} style={{marginRight: "1vw"}} />CUBO Sales
        </Card.Header>
        <Card.Body className='excel-dash-card-body' style={{fontSize: "medium"}}>
            {CUBOData &&             
            <span>
                Total Revenue: {totalSalesNet} 
            </span>}
            {HorizontalLine()}
            <span>
                Total Slabs Billed: {totalSalesSlabs}
            </span>
            {HorizontalLine()}
            <ResponsiveContainer width='75%' height={280} style={{marginTop: "1vh"}}>
            <BarChart data={top5SoldMaterials} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="materialDescription" angle={-30} textAnchor="end" interval={0} height={150} fontSize={"x-small"} />
                <YAxis />
                <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: 'rgb(36, 36, 40)', border: '1px solid #dcdcdc' }}/>
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="totalBilledQty" fill="#37c45d" />
            </BarChart>
            </ResponsiveContainer>
        </Card.Body>
    </Card>
  )
}

export default ExcelDashCardCUBO