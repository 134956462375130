import './CUBOAnalytics.css'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RenderFirstRecordOfArrayForProperties from '../../Reusables/RenderFirstRecordOfArrayForProperties'
import {
    transformDatesInSalesData, 
    //All Customers
    totalNetValueAllCustomerLocal, totalNetValueAllCustomerEuro, 
    totalBilledSlabsAllCustomer, totalBilledSlabsValueEuroAllCustomer, 
    totalBilledSlabsValueLocalAllCustomer, totalTransportCostsBilledAllCustomer, 
    averageInvoiceValueAllCustomerEuro, averageInvoiceValueAllCustomerLocal,
    calculateAverageCreditsEuro, calculateAverageCreditsLocal, 
    calculateCreditsEuro, calculateCreditsLocal,
    calculateTurnoverEuro, calculateTurnoverLocal, 
    calculateTop10CustomersLocal, calculateTop10MaterialsLocal,
    calculateTop10WorstCustomersLocal, calculateTop10WorstMaterialsLocal,
    //By Customer
    totalNetValueByCustomerLocal, totalNetValueByCustomerEuro,
    totalBilledSlabsByCustomerLocal, totalBilledSlabsByCustomer,
    averageInvoiceValueByCustomerLocal, averageInvoiceValueByCustomerEuro,
    calculateCreditsByCustomerLocal,  calculateCreditsByCustomerEuro, 
    calculateAverageCreditsByCustomerLocal,calculateAverageCreditsByCustomerEuro,
    calculateTurnoverByCustomerLocal, calculateTurnoverByCustomerEuro,
    //others
    translateHeaders
} from '../../../Utils/CUBOAnalysisOps'
import CUBOCardRevenue from './CUBOCardRevenue'
import CUBOCardSlabsTransport from './CUBOCardSlabsTransport'
import CUBOCardSlabsCredits from './CUBOCardCredits'
import TurnOverBar from './TurnOverBar'
import Top10DynamicChartCpt from './Top10DynamicChartCpt'
import CUBOCardDynamicList from './CUBOCardDynamicList'

function CUBOAnalytics({ExcelProps}) {
    const {excelDRPConcept, budgetInfoAdded, budget} = ExcelProps;
    //initial data
    const [salesData, setSalesData] = useState([]);

    //Switch for All / Customer
    const [customerView, setCustomerView] = useState(true);
    const [selectedCustomer, setSelectedCustomer] = useState('');

    //initial calculated display data
    const [turnOverData, setTurnoverData] = useState({}); 
    const [revenueData, setRevenueData] = useState({});
    const [slabsAndTransportData, setSlabsAndTransportData] = useState({}); 
    const [creditsData, setCreditsData] = useState([]); 
    const [dynamicChartData, setDynamicChartData] = useState([]);
    const [selectedChart, setSelectedChart] = useState('');

    //CUSTOMER SELECTION Data
    // const [customerRevenueData, setCustomerRevenueData] = useState({});
    // const [customerSlabsAndTransportData, setCustomerSlabsAndTransportData] = useState({});
    // const [customerCreditsData, setCustomerCreditsData] = useState({});
    // const [customerTurnoverData, setCustomerTurnoverData] = useState({});
    // const [customerDynamicChartData, setCustomerDynamicChartData] = useState({});
    //customer Selection bar needs to change the current data rendered, so everything rendered at the moment is only good for initial view.


    useEffect(() => {
        if (excelDRPConcept !== null) 
        {
            const salesDataRaw = excelDRPConcept.salesDataRaw;
            const changedDateData = transformDatesInSalesData(salesDataRaw);
            setSalesData(changedDateData);

            //build Revenue Stats for the initial display.
            const revenueDataObj = buildRevenueData(changedDateData);
            setRevenueData(revenueDataObj);
            const slabsAndTransportDataObj = buildSlabsAndTransportData(changedDateData);
            setSlabsAndTransportData(slabsAndTransportDataObj);
            const creditsDataObj = buildCreditsData(changedDateData);
            setCreditsData(creditsDataObj);
            const turnoverDataObj = buildTurnoverData(changedDateData);
            setTurnoverData(turnoverDataObj);
            const dynamicChartDataObj = buildDynamicChartDataObj(changedDateData);
            setDynamicChartData(dynamicChartDataObj);
            console.log("revenueDataObj: ", revenueDataObj);
            console.log("slabsAndTransportDataObj: ", slabsAndTransportDataObj);
        }
        
    },
    [excelDRPConcept]); 

    //Build Data for CUBO Revenue Card
    const buildRevenueData = (salesData) => {
        const revenueDataObj = {
            totalRevenueAllLocal: totalNetValueAllCustomerLocal(salesData),
            totalRevenueAllEuro: totalNetValueAllCustomerEuro(salesData),
            averageInvoiceValueAllCustomerLocal: averageInvoiceValueAllCustomerLocal(salesData),
            averageInvoiceValueAllCustomerEuro: averageInvoiceValueAllCustomerEuro(salesData), 
        }
        return revenueDataObj;
    };

    const buildSlabsAndTransportData = (salesData) => {
        const slabsAndTransportDataObj = {
            totalRevSlabsLocal: totalBilledSlabsValueLocalAllCustomer(salesData),
            totalRevSlabsEuro: totalBilledSlabsValueEuroAllCustomer(salesData),
            totalBilledSlabsAllCustomer: totalBilledSlabsAllCustomer(salesData),
            totalTransportCosts: totalTransportCostsBilledAllCustomer(salesData),
        }
        return slabsAndTransportDataObj;
    };

    const buildCreditsData = (salesData) => {
        const creditsDataObj = {
            totalCreditsLocal: calculateCreditsLocal(salesData),
            totalCreditsEuro: calculateCreditsEuro(salesData),
            averageCreditsLocal: calculateAverageCreditsLocal(salesData),
            averageCreditsEuro: calculateAverageCreditsEuro(salesData),
        }
        return creditsDataObj;
    };

    const buildTurnoverData = (salesData) => {
        const turnoverDataObj = {
            totalTurnoverLocal: calculateTurnoverLocal(salesData),
            totalTurnoverEuro: calculateTurnoverEuro(salesData),
        }
        return turnoverDataObj;
    };

    const buildDynamicChartDataObj = (salesData) => {
        const dynamicChartDataObj = {
            top10Customers: calculateTop10CustomersLocal(salesData),
            top10Materials: calculateTop10MaterialsLocal(salesData),
            top10WorstCustomers: calculateTop10WorstCustomersLocal(salesData),
            top10WorstMaterials: calculateTop10WorstMaterialsLocal(salesData),
        }
        return dynamicChartDataObj;
    };

    const buildCustomerViewData = (salesData, selectedCustomer) => {
        const customerViewData = {
        }
    };

    console.log(excelDRPConcept.salesDataRaw);
    console.log(dynamicChartData);

  return (
    <div className='-CUBO-ANALYTICS-' style={{fontFamily: 'Trebuchet MS'}}>
        <Container fluid className='excel-dash-container-analytics'>
            <Row className='Analytics-cards-row'>
                <TurnOverBar turnOverData={turnOverData} budgetInfoAdded={budgetInfoAdded} budget={budget} />
            </Row>
            <Row className='Analytics-cards-row'>
                <CUBOCardRevenue revenueData={revenueData} />
                <CUBOCardSlabsTransport slabsAndTransportDataObj={slabsAndTransportData} />
                <CUBOCardSlabsCredits creditsData={creditsData} />
                <CUBOCardDynamicList dynamicChartData={dynamicChartData} selectedChart={selectedChart} setSelectedChart={setSelectedChart} />
            </Row>
            <Row>
                <Top10DynamicChartCpt 
                    dynamicChartData={dynamicChartData} 
                    salesData={salesData} 
                    selectedChart={selectedChart} 
                    setSelectedChart={setSelectedChart}
                />
            </Row>
        </Container>    
    </div>
  )
}

export default CUBOAnalytics