import XLSX from 'xlsx';

export const TransformExcelToDataArray = async (excelFile) => {
    try {
        let arrayBuffedFile = await excelFile.arrayBuffer();

        let ComprasBook = XLSX.readFile(arrayBuffedFile);

        let ComprasSheet = ComprasBook.Sheets["Sheet1"];
        
        let ComprasData = XLSX.utils.sheet_to_json(ComprasSheet);

        return ComprasData;

    } catch (error) {
        console.log("Something went wrong: ", error)
    }
}

// Utility function for safe parsing
const safeParseFloat = (value) => {
    if (!value) return 0;
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
};

export const CalculateTotalQtySoldSlabs = (invoiceData) => {
    let total = 0;
    invoiceData.forEach(item => {
        const value = item["Material"];
        if (typeof value === "string") {
            if (value.startsWith("TB")) {
                const quantity = safeParseFloat(item["Billed Quantity"]) || safeParseFloat(item["Cantidad Facturada"]) || 0;
                total += isNaN(quantity) ? 0 : quantity;
            }
        }
    });
    return total;
};


export const CalculateTotalSoldLocalValue = (invoiceData) => {
    let total = 0;
    invoiceData.forEach(item => {
            const quantity = parseFloat(item["Net Amount Billed"]) || parseFloat(item["Imp. Neto Facturado"]) || 0;
            total += isNaN(quantity) ? 0 : quantity;
    });
    return total;
};

export const CalculateTotalSoldGroupValue = (invoiceData) => {
    let total = 0;
    invoiceData.forEach(item => {
            const quantity = parseFloat(item["Net Amount Billed (Group Currency)"]) || parseFloat(item["Imp. Neto Facturado (Mon. Grupo)"]) || 0;
            total += isNaN(quantity) ? 0 : quantity;
    });
    return total;
};