import '../../Styles/InsightCardDial.css'
import '../../Styles/Insights.css'
import React, { useEffect } from 'react'
import { Container, Row, Col, Form, Card, CardHeader } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InsightCardBasic({title, bodyTitle, icon, colour}) {
  const cardHeader = React.useRef(null);
  const cardBody = React.useRef(null);
  useEffect(() => {
    if (colour) {
      cardHeader.current.style.backgroundColor = colour;
      cardBody.current.style.backgroundColor = colour;
    }
  }, [colour])

  return (
    <Card className='dark-insight-card' >
      <CardHeader ref={cardHeader} className='dark-insight-card-header' style={{display: "flex",justifyContent: "space-evenly", alignItems: "center"}}>
        {title}
        {icon && <FontAwesomeIcon className='icon' icon={icon} size="1x" />}
      </CardHeader>
      <Card.Body ref={cardBody} className='dark-insight-card-body'>
          <Card.Text>{bodyTitle}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default InsightCardBasic