import React, { useState, useEffect } from 'react';
import { CUBOAnalyseDRPData } from '../../../Utils/CUBOAnalyserDRP';
import { CalculateTotalQtySoldSlabs, CalculateTotalSoldGroupValue, CalculateTotalSoldLocalValue } from '../../../Utils/CUBOAnalyserFunctions';
import { connectToDB, StoreData, GetData } from '../../../Utils/DBOps';
import Loading from '../../Reusables/Loading';
import InsightCardBasic from '../../Reusables/InsightCardBasic';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

function CUBOAnalyserDash({ CUBOAnalyserProps }) {
    const { dataCompras, dataVentas, dataOrders, dataStockSubsid, dataStockHQ, setCUBODRPData, CUBODRPData, DRPDataLoaded, setDRPDataLoaded } = CUBOAnalyserProps;
    const [isLoading, setIsLoading] = useState(false);
    const [totalQtySoldSlabs, setTotalQtySoldSlabs] = useState(0);
    const [totalSoldGroupValue, setTotalSoldGroupValue] = useState(0);
    const [totalSoldLocalValue, setTotalSoldLocalValue] = useState(0);

    useEffect(() => {
        if (CUBODRPData && CUBODRPData.length > 0 && DRPDataLoaded ) {
            const totalSlabs = CalculateTotalQtySoldSlabs(dataVentas);
            const groupValue = CalculateTotalSoldGroupValue(dataVentas);
            const localValue = CalculateTotalSoldLocalValue(dataVentas);
            setTotalQtySoldSlabs(totalSlabs);
            setTotalSoldGroupValue(groupValue);
            setTotalSoldLocalValue(localValue);
        }
    }, [CUBODRPData, dataVentas, DRPDataLoaded]);

    return (
        <div className='CUBO-analyser-dash-main'>
            {isLoading && <Loading />}
            {!isLoading && CUBODRPData && CUBODRPData.length > 0 && DRPDataLoaded &&
                <InsightCardBasic title="CUBO Analyser" icon={faShoppingBasket} bodyTitle={totalQtySoldSlabs.toString()} />
            }
        </div>
    );
}

export default CUBOAnalyserDash;
