import React, { useState, useMemo, useCallback } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import ABCSalesDataConfig from './ABCSalesDataConfig';
import ClassificationSummaryTable from './Dash/ClassificationSummaryTable';
import ClassificationFilter from './Dash/ClassificationFilter';
import XYZClassificationFilter from './Dash/XYZClassificationFilter';
import ViewToggle from './Dash/ViewToggle';
import ClassifiedDataTable from './Dash/ClassifiedDataTable';
import ClassifiedDataChart from './Dash/ClassifiedDataChart';
import * as XLSX from 'xlsx';
import { 
  validateData,
  extractLastTwoYearsData,
  calculateStatistics,
  performABCClassification,
  performXYZClassification,
  exportABCXYZToExcel,
  parseExcelDate,
} from '../../../Utils/ABCXYZFunctions';
import { DynamicOpenExcelFile } from '../../../Utils/ExcelFileOps';

function ABCXYZMain() {
  const [file, setFile] = useState(null);
  const [columnOptions, setColumnOptions] = useState([]);
  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [classificationFilter, setClassificationFilter] = useState(['A', 'B', 'C']);
  const [xyzFilter, setXYZFilter] = useState(['X', 'Y', 'Z']);
  const [view, setView] = useState('table');
  const [customFilters, setCustomFilters] = useState([]);
  const [dateColumnName, setDateColumnName] = useState('');
  const [materialColumnName, setMaterialColumnName] = useState('');
  const [billedAmountColumnName, setBilledAmountColumnName] = useState('');
  const [materialDescriptionColumnName, setMaterialDescriptionColumnName] = useState('');
  const [isXYZAvailable, setIsXYZAvailable] = useState(false);
  const [abcThresholds, setABCThresholds] = useState({ A: 80, B: 95 });
  const [xyzThresholds, setXYZThresholds] = useState({ X: 0.1, Y: 0.25 });

  // Memoized color mappings
  const classificationColors = useMemo(() => ({
    A: '#66b3ff',
    B: '#ffcc66',
    C: '#ff6666',
    X: '#ffff66',
    Y: '#ff66ff',
    Z: '#66ff66',
  }), []);

  // Memoized filtered stats calculation
const filteredStats = useMemo(() => {
  // Step 1: Apply classification and XYZ filters
  let filtered = stats.filter(item => {
    const abcMatch = classificationFilter.includes(item.classification);
    const xyzMatch = isXYZAvailable ? xyzFilter.includes(item.xyzClassification) : true;
    return abcMatch && xyzMatch;
  });

  // Step 2: Apply custom filters
  customFilters.forEach(({ type, value, column }) => {
    filtered = filtered.filter(item => {
      const columnValue = item[column.toLowerCase()] ? item[column.toLowerCase()].toString().toLowerCase() : ''; // Convert to string and lowercase for comparison

      switch (type) {
        case 'startsWith':
          return columnValue.startsWith(value.toLowerCase());
        case 'endsWith':
          return columnValue.endsWith(value.toLowerCase());
        case 'contains':
          return columnValue.includes(value.toLowerCase());
        case 'doesNotContain':
          return !columnValue.includes(value.toLowerCase());
        default:
          return true; // Ignore if filter type is unrecognized
      }
    });
  });

  // Step 3: Sort and calculate cumulative percentage
  const sortedFiltered = filtered.sort((a, b) => b.totalSales - a.totalSales);
  const totalSalesFiltered = sortedFiltered.reduce((sum, item) => sum + item.totalSales, 0);
  let cumulativeSales = 0;

  return sortedFiltered.map(item => {
    cumulativeSales += item.totalSales;
    const cumulativePercentage = (cumulativeSales / totalSalesFiltered) * 100;
    return { ...item, cumulativePercentage };
  });
}, [stats, classificationFilter, xyzFilter, isXYZAvailable, customFilters]);

  // Handle file selection and extract column headers
  const handleFileSelect = useCallback(async (selectedFile) => {
    setFile(selectedFile);
    const data = await DynamicOpenExcelFile(selectedFile, 'Sheet1');
    if (data && data.length > 0) {
      setColumnOptions(Object.keys(data[0]));
    } else {
      setColumnOptions([]);
    }
  }, []);

  // Handle data processing
  const handleData = useCallback(async () => {
    const data = await DynamicOpenExcelFile(file, 'Sheet1');
    const dates = data.map(row => parseExcelDate(row[dateColumnName])).filter(date => date instanceof Date && !isNaN(date));

    if (dates.length === 0) {
      alert('The selected Date column is not parsable to dates.');
      return;
    }

    const hasTwoYearsData = validateData(data, dateColumnName);
    let filteredData;
    let xyzAvailable = false;

    if (hasTwoYearsData) {
      filteredData = extractLastTwoYearsData(data, dateColumnName);
      xyzAvailable = true;
    } else {
      filteredData = data;
    }

    setIsXYZAvailable(xyzAvailable);
    setData(filteredData);

    let stats = calculateStatistics(filteredData, billedAmountColumnName, materialColumnName, materialDescriptionColumnName, dateColumnName);
    stats = performABCClassification(stats, abcThresholds);

    if (xyzAvailable) {
      stats = performXYZClassification(stats, xyzThresholds);
    }
    setStats(stats);
  }, [file, dateColumnName, billedAmountColumnName, materialColumnName, materialDescriptionColumnName, abcThresholds, xyzThresholds]);

  // Clear data function memoized to avoid re-creation on each render
  const clearData = useCallback(() => {
    setData([]);
    setStats([]);
    setFile(null);
    setClassificationFilter(['A', 'B', 'C']);
    setXYZFilter(['X', 'Y', 'Z']);
    setIsXYZAvailable(false);
    setDateColumnName('');
    setMaterialColumnName('');
    setBilledAmountColumnName('');
    setMaterialDescriptionColumnName('');
    setColumnOptions([]);
  }, []);

  return (
    <Container className='-ABCXYZ-Main-'>
      {stats.length === 0 ? (
        <ABCSalesDataConfig
          file={file}
          handleFileSelect={handleFileSelect}
          buttonFunction={handleData}
          abcThresholds={abcThresholds}
          setABCThresholds={setABCThresholds}
          xyzThresholds={xyzThresholds}
          setXYZThresholds={setXYZThresholds}
          setCustomFilters={setCustomFilters}
          customFilters={customFilters}
          columnOptions={columnOptions}
          dateColumnName={dateColumnName}
          setDateColumnName={setDateColumnName}
          materialColumnName={materialColumnName}
          setMaterialColumnName={setMaterialColumnName}
          billedAmountColumnName={billedAmountColumnName}
          setBilledAmountColumnName={setBilledAmountColumnName}
          materialDescriptionColumnName={materialDescriptionColumnName}
          setMaterialDescriptionColumnName={setMaterialDescriptionColumnName}
        />
      ) : (
        <>
          <Row className='my-3' style={{ alignItems: 'center' }}>
            <Col style={{ position: 'relative', top: '10px', margin: 'auto' }}>
              <ClassificationFilter
                classificationFilter={classificationFilter}
                setClassificationFilter={setClassificationFilter}
              />
              {isXYZAvailable && (
                <XYZClassificationFilter
                  xyzFilter={xyzFilter}
                  setXYZFilter={setXYZFilter}
                />
              )}
              <ViewToggle view={view} setView={setView} />
            </Col>
            <Col className='text-end'>
              <Button variant='success' onClick={() => exportABCXYZToExcel(filteredStats)} className='me-2'>
                Export to Excel
              </Button>
              <Button variant='danger' onClick={clearData}>
                Clear Data
              </Button>
            </Col>
          </Row>
          <ClassificationSummaryTable
            stats={stats}
            classificationColors={classificationColors}
            isXYZAvailable={isXYZAvailable}
          />
          <div style={{height: "76vh", overflowY: "scroll", marginBottom: "5px"}}>
            {view === 'table' ? (
              <ClassifiedDataTable
                data={filteredStats}
                classificationColors={classificationColors}
                isXYZAvailable={isXYZAvailable}
              />
            ) : (
              <ClassifiedDataChart
                data={filteredStats}
                classificationColors={classificationColors}
                isXYZAvailable={isXYZAvailable}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
}

export default React.memo(ABCXYZMain);
