import React, {useEffect, useState} from 'react'
import CUBOAnalyserDemandTableSubsidiary from './CUBOAnalyserDemandTableSubsidiary';

function CUBOAnalyserDemandSubsidaryMainView({CUBOAnalyserProps, demandDataProps, demandFilters, setFilteredDataCopy, selectedCenter} ) {
  const { CUBODRPData } = CUBOAnalyserProps;

  return (
    <div className='CUBO-analyser-main-view'>
      {<CUBOAnalyserDemandTableSubsidiary CUBODRPData={CUBODRPData} demandDataProps={demandDataProps} CUBOAnalyserProps={CUBOAnalyserProps} demandFilters={demandFilters} setFilteredDataCopy={setFilteredDataCopy} selectedCenter={selectedCenter}/>}
    </div>
  )
}

export default CUBOAnalyserDemandSubsidaryMainView