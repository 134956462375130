import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Rectangle } from 'recharts';

const VBC_TopMaterialsBarChart = ({ topMaterials }) => {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { material, totalQuantity } = payload[0].payload;
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'black', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label"><strong>{material}</strong></p>
                    <p>{`Total Quantity: ${totalQuantity}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={topMaterials}
                margin={{
                    top: 20,
                    right: 20,
                    left: 100,
                    bottom: 220, // Increased bottom margin to accommodate rotated labels
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="material" 
                    tick={{ fontSize: 12 }} 
                    angle={-25} 
                    textAnchor="end" 
                    interval={0} // Show all labels
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar 
                    dataKey="totalQuantity" 
                    fill="lightblue" 
                    barSize={40} // Adjust bar size for better appearance
                    label={{ position: 'top', fill: '#333' }} // Display quantity on top of bars
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default VBC_TopMaterialsBarChart;
