import './CalculativeSummary.css'
import React from 'react'
import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'


function CalculativeSummary({ExcelProps, excelDRPData}) {
  const {budgetAnalysisData, transitDays } = ExcelProps;
  const [slabsXDiffference, setSlabsXDifference] = useState();
  const [topRecord, setTopRecord] = useState();
  
  const currencyFormatter = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(value);

  function getMostSoldMaterial(records) {
    if (!records || records.length === 0) {
      return null; // Return null if the array is empty or not provided
    }
  
    let mostSoldRecord = records[0]; // Initialize with the first record
    let maxSold = records[0].MaterialSold; // Initialize with the first MaterialSold value
  
    for (let i = 1; i < records.length; i++) {
      if (records[i].MaterialSold > maxSold) {
        mostSoldRecord = records[i];
        maxSold = records[i].MaterialSold;
      }
    }
  
    return mostSoldRecord;
  }

  useEffect(() => {
    console.log(budgetAnalysisData)
    let topRecord;
    if (excelDRPData[0]) {
      const _rec = getMostSoldMaterial(excelDRPData);
      console.log(_rec);
      setTopRecord(_rec); 
    }
    console.log("Logged from CalculativeSummary.jsx")
    let slabsTimesDifference = budgetAnalysisData.totalBilledSlabsAllCustomer * (1 + budgetAnalysisData.percentageDifference);
    slabsTimesDifference = slabsTimesDifference.toFixed(0);
    setSlabsXDifference(slabsTimesDifference);
  }, [budgetAnalysisData]);

  return (
    <Card className='dark-card MarginTop'>
      <Card.Header>
        <Card.Title>Calculative Summary</Card.Title>
      </Card.Header>
      <Card.Body className='calculative-summary-card-body'>
        <Card.Text>
          <span>This breaks down sales figures and desired projections to show a summary of whats required to reach desired projection</span>
        </Card.Text>
        <div className='tables-section-calc-sum'>
          <Table className='turnover-section' striped variant='dark'>
            <thead>
              <tr>
                <th>Turnover</th>
                <th>Desiried Budget:</th>
                <th>Percentage Difference:</th>
                <th>Percentage Increase:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currencyFormatter(budgetAnalysisData.salesTotalValueLocal)}</td>
                <td>{currencyFormatter(budgetAnalysisData.budget)}</td>
                <td>{budgetAnalysisData.percentageDifferenceRoundedString}%</td>
                <td>{budgetAnalysisData.percentageIncreaseInTurnoverRoundedString}%</td>
              </tr>
            </tbody>
          </Table>
          <Table className='slabs-section' striped variant='dark'>
            <thead>
              <tr>
                <th>Total Slabs Sold</th>
                <th>Average Invoice Value W/o Any Credits:</th>
                <th>Average Invoice Value W/o Display or quality Credits:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{budgetAnalysisData.totalBilledSlabsAllCustomer}</td>
                <td>{currencyFormatter(budgetAnalysisData.averageInvoiceValueAllCustomerLocalNoCredits)}</td>
                <td>{currencyFormatter(budgetAnalysisData.averageInvoiceValueWithoutDisplaysOrCredits)}</td>
              </tr>
            </tbody>
          </Table>
          <Table className='projected-slabs-section' striped variant='dark'>
            <thead>
              <tr>
                <th>Total Slabs Sold X % Difference</th>
                <th>No D/Q Credits / Budget: </th>
                <th>Increase in Stock By Slabs With Difference:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{slabsXDiffference}</td>
                <td>{(budgetAnalysisData.budget / budgetAnalysisData.averageInvoiceValueWithoutDisplaysOrCredits).toFixed(0)}</td>
                <td>{slabsXDiffference - budgetAnalysisData.totalBilledSlabsAllCustomer}</td>
              </tr>
            </tbody>
          </Table>
          {topRecord && 
            <div className='calc-sum-text-section'>
              <p>Based on the above data, the projected slabs sold with the difference in percentage is {slabsXDiffference}. This means that the stock needs to be increased by {slabsXDiffference - budgetAnalysisData.totalBilledSlabsAllCustomer} slabs to reach the desired budget.</p>
              <p>This represents a {budgetAnalysisData.percentageDifferenceRoundedString}% increase in stock held to attain budget. </p>
              <p>Conservate approach suggests usings difference indicators and not increase directly to avoid capcity issues in production.</p>
              <p>Taking the above figures to an example of stock:</p>
              <Table striped variant='dark'>
                <thead>
                  <tr>
                    <th>Material Description</th>
                    <th>Sold Year</th>
                    <th>Daily Rate ( <> Year / 365</> )</th>
                    <th>Daily Rate X % Diff (New Daily Rate)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{topRecord["Material Description"]}</td>
                    <td>{topRecord.MaterialSold}</td>
                    <td>{topRecord.DailyRate}</td>
                    <td>{(topRecord.DailyRate * (1 +budgetAnalysisData.percentageDifference)).toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
              <p>To work out the new required stock, first take the previous 90 day holding figure {topRecord.MaterialSoldPer3Month} and multiply by new daily rate {(topRecord.DailyRate * (1 +budgetAnalysisData.percentageDifference)).toFixed(2)}, Which is Daily Rate increased by % difference of {(budgetAnalysisData.percentageDifferenceRoundedString)}% </p>
              <p> this gives us {(topRecord.MaterialSoldPer3Month * (1 + budgetAnalysisData.percentageDifference)).toFixed(2)}, Then we add the Stock Need (Transit days X New Daily Rate = {(topRecord.DailyRate * (1 +budgetAnalysisData.percentageDifference)).toFixed(2) * transitDays}) and round up to the nearest slab </p>
              <p>We reach a final target stock of {topRecord.TargetStock}</p>
              <Table striped variant='dark' style={{marginTop: "30px"}}> 
                <thead>
                  <tr>
                    <th>Transit Days:</th>
                    <th>Stock Need During Transit ( <> Transit Days * DiffDayRate </> )</th>
                    <th>Sold 3 Month</th>
                    <th>Target Stock ( <> Sold 3 Mth * DiffDayRate + Stock Need Transit</> )</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{transitDays}</td>
                    <td>{(topRecord.DailyRate * (1 +budgetAnalysisData.percentageDifference)).toFixed(2) * transitDays}</td>
                    <td>{topRecord.MaterialSoldPer3Month}</td>
                    <td>{topRecord.TargetStock}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          }
        </div>
      </Card.Body>
    </Card>
  )
}

export default CalculativeSummary