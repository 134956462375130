import React from 'react';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const FilterToolbar = ({ onFilterChange, toggleView }) => {
    return (
        <Navbar variant="dark" bg="dark" expand="lg" className='sales-orders-navbar'>
            <Form className='sales-orders-filter-toolbar'>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <NavDropdown
                    style={{fontSize: "medium"}}
                    id="Views"
                    title="Sales Orders Views"
                    menuVariant="dark"
                    >
                        <NavDropdown.Item onClick={toggleView}>Main Table</NavDropdown.Item>
                        <NavDropdown.Item>More In development</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                </Navbar.Collapse>  
                <Form.Group className='sales-orders-fields-holder'>
                    <Form.Label htmlFor="filter-by-customer" className='txt-med'>Filter by customer:</Form.Label>
                    <FormControl id="filter-by-customer" type="text" placeholder="Customer" onChange={e => onFilterChange('customer', e.target.value)} />
                </Form.Group>
                <Form.Group className='sales-orders-fields-holder'>
                    <Form.Label htmlFor="filter-by-material" className='txt-med'>Filter by material:</Form.Label>
                    <FormControl id="filter-by-material" type="text" placeholder="Material" onChange={e => onFilterChange('material', e.target.value)} />
                </Form.Group>
                <FormGroup className='sales-orders-fields-holder'>
                    <Form.Label htmlFor="filter-by-reference" className='txt-med'>Filter by reference:</Form.Label>
                    <FormControl id="filter-by-reference" type="text" placeholder="Reference" onChange={e => onFilterChange('reference', e.target.value)} />
                </FormGroup>
            </Form>
        </Navbar>
    );
};

export default FilterToolbar;
