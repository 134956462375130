import './InsightBrowser.css'
import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import InsightsStock from './InsightsStock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'
import MenuButtonIcon from '../Reusables/MenuButtonIcon'
import InsightsSales from './InsightsSales'

function InsightBrowser({AppProps, DataProps, setSelectedComponent, SelectedComponent}) {
    const { DIOData, SalesData, StockData, OrderData, POwithHQData } = DataProps;
    const { token } = AppProps;

    const handleSelectInsight = (text) => {
        setSelectedComponent(componentMap[text]);
    };

    useEffect(() => {
        setSelectedComponent(componentMap['Stock']);
    }, []);
    
    const componentMap = {
        Sales: <InsightsSales AppProps={AppProps} DataProps={DataProps} />,
        Stock: <InsightsStock AppProps={AppProps} DataProps={DataProps} />,
        // Add other components as needed
      };

  return (
    <div className='/INSIGHT_BROWSER'>
        <Row className="placeholder-row-fullheight">
            <Col className='insight-menu'>
                <MenuButtonIcon icon={faBoxesStacked} text={"Stock"} handleSelectInsight={handleSelectInsight}/>
                <MenuButtonIcon icon={faMoneyBillTrendUp} text={"Sales"} handleSelectInsight={handleSelectInsight}  />
            </Col>
            <Col className='insight-page'>
                {SelectedComponent}
            </Col>    
        </Row>
    </div>
  )
}

export default InsightBrowser