// ExcelDashComponentCycler.jsx
import React from 'react';
import ExcelStockChecker from './ExcelStockChecker/ExcelStockChecker';
import ExcelDRP from '../ExcelDRP/ExcelDRP';
import ExcelPurchaseOrders from './ExcelPurchaseOrders/ExcelPurchaseOrders';
import ExcelSalesOrders from '../ExcelDRP/ExcelSalesOrders/ExcelSalesOrders';
import FourCardGrid from '../ExcelDRP/DashCards/FourCardGrid';
import CUBOAnalytics from '../Analytics/CUBOAnalytics';
import CalculativeSummary from '../CalculativeSummary/CalculativeSummary';
import { useNavigate } from 'react-router-dom';
import ExcelDRPInsights from '../ExcelDRP/ExcelDRPInsights';

const ExcelDashComponentCycler = ({
  selectedViewComponent,
  ExcelProps, AppProps,
  decorFilter, finishFilter,thicknessFilter,sizeFilter,
  excelDRPData, setExcelDRPData,
  excelFilteredDRPData, setExcelFilteredDRPData,
  setFinishFilter,
  setThicknessFilter,
  setSizeFilter,
  setFilterStatusLabelText,
  filterStatusLabelText,
  showAdditionalColumns,
  setSelectedViewComponent,
  setPlant,
  ATPOnly,
  ESlabsOnly,
  optimalSizesOnly,
  DRPLoaded, setDRPLoaded,
  meshVisible,
  hideZeroLines,
  visibleColumns,
  allColumns
}) => {
  const Navigate = useNavigate();
  switch (selectedViewComponent) {
    case 'Check Stock':
      return <ExcelStockChecker ExcelProps={ExcelProps} AppProps={AppProps} />;
      case 'DRP Summary':
        if (!ExcelProps.excelDRPConcept || !ExcelProps.excelDRPConcept.salesDataRaw || ExcelProps.excelDRPConcept.salesDataRaw.length === 0) {
          // Data is invalid, navigate to '/Excel'
          Navigate('/Excel');
          return null;
        } else {
          return (
        <ExcelDRP
          ExcelProps={ExcelProps}
          AppProps={AppProps}
          decorFilter={decorFilter}
          finishFilter={finishFilter}
          thicknessFilter={thicknessFilter}
          sizeFilter={sizeFilter}
          setExcelDRPData={setExcelDRPData}
          excelDRPData={excelDRPData}
          excelFilteredDRPData={excelFilteredDRPData}
          setExcelFilteredDRPData={setExcelFilteredDRPData}
          setFinishFilter={setFinishFilter}
          setThicknessFilter={setThicknessFilter}
          setSizeFilter={setSizeFilter}
          filterStatusLabelText={filterStatusLabelText}
          setFilterStatusLabelText={setFilterStatusLabelText}
          showAdditionalColumns={showAdditionalColumns}
          setPlant={setPlant}
          ATPOnly={ATPOnly}
          ESlabsOnly={ESlabsOnly}
          optimalSizesOnly={optimalSizesOnly}
          DRPLoaded={DRPLoaded}
          setDRPLoaded={setDRPLoaded}
          meshVisible={meshVisible}
          hideZeroLines={hideZeroLines}
          visibleColumns={visibleColumns}
          allColumns={allColumns}
        />
      );
    }
    case 'Full Config':
      // Handle navigation or rendering logic for 'Full Config'
      Navigate("/Excel");
      return null;
    case 'Purchase Order Proposal':
      return <ExcelPurchaseOrders ExcelProps={ExcelProps} excelDRPData={excelDRPData} />;
    case 'Calculative Summaray':
      return <CalculativeSummary ExcelProps={ExcelProps} excelDRPData={excelDRPData} />;
    case 'Delivery Schedule':
      return (
        <ExcelSalesOrders
          ExcelProps={ExcelProps}
          AppProps={AppProps}
          excelDRPConcept={ExcelProps.excelDRPConcept}
        />
      );
    case 'Analytics':
      return <FourCardGrid ExcelProps={ExcelProps} AppProps={AppProps} setSelectedViewComponent={setSelectedViewComponent} />;
    case 'CUBO Analytics':
      return <CUBOAnalytics ExcelProps={ExcelProps} AppProps={AppProps} setSelectedViewComponent={setSelectedViewComponent} />;
    case 'Insights':
      return <ExcelDRPInsights ExcelProps={ExcelProps} AppProps={AppProps} excelFilteredDRPData={excelFilteredDRPData} excelDRPData={excelDRPData} />;
    default:
      return <FourCardGrid ExcelProps={ExcelProps} AppProps={AppProps} setSelectedViewComponent={setSelectedViewComponent} />;
  }
};

export default ExcelDashComponentCycler;
