import React, { useEffect } from 'react'
import FileInputContainer from '../../Reusables/FileInputContainer'
import { Button, Container } from 'react-bootstrap'
import { SaveDebtPortalData, LoadDebtPortalData } from '../../../Utils/DebtPortalOps'

function DebtPortalConfig({ debtPortalPath, setDebtPortalPath, setDebtPortalData, setDebtDataPresent }) {

  const handleDebtPortalDataSave = async () => {
    const data = await SaveDebtPortalData(debtPortalPath);
    if (data && data.length > 0) {
      setDebtPortalData(data[0]);
      setDebtDataPresent(true);
    }
  };

  const handleDebtPortalDataLoad = async () => {
    const debtPortalData = await LoadDebtPortalData();
    if (debtPortalData && debtPortalData.length > 0) {
      setDebtPortalData(debtPortalData[0]);
      setDebtDataPresent(true);
      setDebtPortalPath(null);
    } else {
      alert("No Debt Portal Data Found, please extract society FBL5N and upload.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      //retrieves data from DebtPortalData table in IndexedDB
      await handleDebtPortalDataLoad();
    };
    fetchData();
  }, [SaveDebtPortalData, LoadDebtPortalData, setDebtPortalData, setDebtDataPresent]);

  return (
    <Container className='data-browser-card'>
      <span>Upload the Excel data from each file</span>
      <FileInputContainer labelTitle={"FBL5N Data"} selectedFilePath={debtPortalPath} setSelectedFile={setDebtPortalPath} />
      <Button className='custom-button-gold' variant='warning' style={{ marginBottom: "10px" }} onClick={handleDebtPortalDataSave}> Go!</Button>
    </Container>
  );
}

export default DebtPortalConfig
