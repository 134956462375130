import React from 'react'
import InsightCardBasic from '../Reusables/InsightCardBasic'
import { Container, Row, Col } from 'react-bootstrap'
import {CalculateSlabsOnPO, CalculateStockSubtotaIQ09, CalculateSalesDemandTotal, CalculateATPTotal, groupByMaterialAndBatchSorted, groupByMaterialAndBatchAndSerial} from '../../Utils/InsightFunctions'
import InsightStockBrowser from './InsightStockBrowser'
import './InsightComponentStyles.css'
import { faBoxesStacked, faCartShopping, faPoundSign, faHand } from '@fortawesome/free-solid-svg-icons'


function InsightsStock({AppProps, DataProps}) {
    const {canUseInsights} = AppProps;
    const { DIOData, SalesData, StockData, OrderData, POwithHQData } = DataProps;

    const slabsOnPO = CalculateSlabsOnPO(POwithHQData);
    const slabsOnStock = CalculateStockSubtotaIQ09(StockData);
    const salesDemand = CalculateSalesDemandTotal(OrderData);
    const ATPNow = slabsOnStock - salesDemand;
    const GroupedTop10StockData = groupByMaterialAndBatchAndSerial(StockData);

    
  return (
    <div className='/INSIGHT_STOCK'>
        <Container className='insight-row-cards-holder top-cards'>
            <InsightCardBasic title="Slabs on Stock" bodyTitle={"Total Slabs: " + slabsOnStock} icon={faBoxesStacked} colour={"rgb(26, 79, 7)"}/>
            <InsightCardBasic title="Total in PO (HQ)" bodyTitle={"Slabs pending: " + slabsOnPO} icon={faCartShopping} colour={"rgb(91, 67, 11)"}/>
            <InsightCardBasic title="Sales Demand" bodyTitle={"Slabs pending in SO: " + salesDemand} icon={faPoundSign} colour={"rgb(11, 43, 91)"}/>
            <InsightCardBasic title="Slabs ATP" bodyTitle={"Slabs Available: " + ATPNow} icon={faHand} colour={"rgb(91, 11, 18)"}/>
        </Container>
        <Container className='insight-row-cards-holder stockbrowser'>
          <InsightStockBrowser title={"Stock Browser:"} data={GroupedTop10StockData} dataProperty1={"Quantity"} dataProperty2={"Description"} />
        </Container>
    </div>
  )
}

export default InsightsStock