import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import columnsConfig from '../../../../Utils/columnsConfigSalesOrders.json'; // Adjust the import path accordingly
import {convertExcelDateToUKDateString} from '../../../../Utils/SalesOrderFunctions'


function MainTable({ filterState, rawData }) {
    const [columnSet, setColumnSet] = useState('ENG');
    const [filteredData, setFilteredData] = useState([]);

    // Detecting the language based on the data
    useEffect(() => {
        const detectLanguage = () => {
            const topRow = rawData && rawData[0];
            if (topRow) {
                const headers = Object.keys(topRow);
                const isSpanish = headers.some((key) => key.includes('Centro'));
                setColumnSet(isSpanish ? 'ESP' : 'ENG');
            }
        };

        if (rawData && rawData.length > 0) {
            detectLanguage();
        }
    }, [rawData]);

    // Filtering data based on filter state
// Filtering data based on filter state
useEffect(() => {
    const applyFilters = () => {
        console.log(rawData[0]);
        console.log(rawData[0][columnsConfig[columnSet].headers['Customer Name']]);
        const data = rawData.filter(item => (
            (filterState.customer === '' || (item[columnsConfig[columnSet].headers['Customer Name']] && item[columnsConfig[columnSet].headers['Customer Name']].toLowerCase().includes(filterState.customer.toLowerCase()))) &&
            (filterState.material === '' || (item[columnsConfig[columnSet].headers['Material Description']] && item[columnsConfig[columnSet].headers['Material Description']].toLowerCase().includes(filterState.material.toLowerCase()))) &&
            (filterState.reference === '' || (item[columnsConfig[columnSet].headers['Customer Reference']] && item[columnsConfig[columnSet].headers['Customer Reference']].toLowerCase().includes(filterState.reference.toLowerCase())))
        ));
        setFilteredData(data);
    };

    if (rawData && rawData.length > 0) {
        applyFilters();
    }
}, [filterState, rawData, columnSet]);

    const headers = columnsConfig[columnSet].headers;

    return (
        <Table striped bordered hover size="sm" variant="dark" className='sales-orders-main-table'>
            <thead>
                <tr>
                    {Object.keys(headers).map(header => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {filteredData.map((order, index) => (
                    <tr key={index}>
                        {Object.entries(headers).map(([header, columnKey]) => (
                            <td key={header}>
                                {header.toLowerCase().includes("date") || header.toLowerCase().includes("fecha") ?
                                    convertExcelDateToUKDateString(order[columnKey]) :
                                    order[columnKey]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default MainTable;
