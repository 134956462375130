import React, { useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faCopy, faPalette } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';

const defaultGroupColors = {
  'Characteristics': '#2e4053', // Dark Blue-Gray
  'Invoicing': '#4caf50',       // Dark Lime Green
  'Stock Parameters': '#1abc9c', // Green
  'Current Stock': '#c0392b',    // Crimson
  'Metrics': '#d4af37',          // Darker Gold
  'DIO': '#2980b9',              // Blue
};

function JJDRPTable({
  JJDRPData, setJJDRPData, JJDRPDisplayView, setJJDRPDisplayView, setJJDRPDataValid,
  columns, visibleColumns, setVisibleColumns, recordsToShow, customGroupColors = {}
}) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [groupColors, setGroupColors] = useState({ ...defaultGroupColors, ...customGroupColors });
  const [showColorModal, setShowColorModal] = useState(false);

  // Column definitions and mappings to data fields
  const columnMap = {
    'Center': 'Center',
    'SKU': 'SKU',
    'Descrp. Modelo': 'Descrp. Modelo',
    'Acabado': 'Acabado',
    'Espesor': 'Espesor',
    'Medida': 'Medida',
    'Segmento': 'Segmento',
    'Coverage (Months)': 'Coverage (Months)',
    'Transit Days': 'Transit Days',
    'Invoiced 12M<': 'Invoiced 12M<',
    'Invoiced 3M<': 'Invoiced 3M<',
    'Daily Rate': 'Daily Rate',
    'Transit Buffer': 'Transit Buffer',
    'Target Stock B4 Grwth': 'Target Stock B4 Grwth',
    'Growth Expectation?': 'Growth Expectation?',
    'Target Stock After Growth': 'Target Stock After Growth',
    'Mimimum Stock (RO Trigger)': 'Mimimum Stock (RO Trigger)',
    'MSIB (Min Slabs in 1 Batch)': 'MSIB (Min Slabs in 1 Batch)',
    'Stock Max': 'Stock Max',
    'Current Available Stock (In Plant)': 'Current Available Stock (In Plant)',
    'Current Stock in Transit': 'Current Stock in Transit',
    'Current Purchase Orders': 'Current Purchase Orders',
    'Current Stock in HQ': 'Current Stock in HQ',
    'Supplemented ATP': 'Supplemented ATP',
    'N2O Raw': 'N2O Raw',
    'Real Need': 'Real Need',
    'Bookmatch SKU': 'Bookmatch SKU',
    'Bookmatch Preference': 'Bookmatch Preference',
    'Sister Code': 'Sister Code',
    'Final Orders to Place': 'Final Orders to Place',
  };

  const columnGroups = {
    'Center': 'Characteristics',
    'SKU': 'Characteristics',
    'Descrp. Modelo': 'Characteristics',
    'Acabado': 'Characteristics',
    'Espesor': 'Characteristics',
    'Medida': 'Characteristics',
    'Segmento': 'Characteristics',
    'Coverage (Months)': 'Invoicing',
    'Transit Days': 'Invoicing',
    'Invoiced 12M<': 'Invoicing',
    'Invoiced 3M<': 'Invoicing',
    'Daily Rate': 'Invoicing',
    'Transit Buffer': 'Invoicing',
    'Target Stock B4 Grwth': 'Stock Parameters',
    'Growth Expectation?': 'Stock Parameters',
    'Target Stock After Growth': 'Stock Parameters',
    'Mimimum Stock (RO Trigger)': 'Stock Parameters',
    'MSIB (Min Slabs in 1 Batch)': 'Stock Parameters',
    'Stock Max': 'Stock Parameters',
    'Current Available Stock (In Plant)': 'Current Stock',
    'Current Stock in Transit': 'Current Stock',
    'Current Purchase Orders': 'Current Stock',
    'Current Stock in HQ': 'Current Stock',
    'Supplemented ATP': 'Current Stock',
    'N2O Raw': 'Metrics',
    'Real Need': 'Metrics',
    'Bookmatch SKU': 'Metrics',
    'Bookmatch Preference': 'Metrics',
    'Sister Code': 'Metrics',
    'Final Orders to Place': 'Metrics',
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const renderSortIcon = (column) => {
    return sortColumn === column ? (
      sortDirection === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />
    ) : null;
  };

  const handleCopyColumn = (column) => {
    const columnData = limitedData.map(item => item[columnMap[column]]).join('\n');
    navigator.clipboard.writeText(columnData)
      .then(() => {
        alert(`Copied ${limitedData.length} values from column "${column}" to clipboard.`);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const formatValue = (value) => {
    if (typeof value === 'number') {
      const decimalPart = value.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 2) {
        return value.toFixed(2);
      }
      return value;
    }
    return value;
  };

  const sortedData = JJDRPData.slice().sort((a, b) => {
    const aValue = sortColumn ? a[columnMap[sortColumn]] : null;
    const bValue = sortColumn ? b[columnMap[sortColumn]] : null;

    if (sortDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const limitedData = sortedData.slice(0, recordsToShow);

  const generateRows = () => {
    return limitedData.map((item, index) => (
      <tr key={index}>
        {columns.filter(column => visibleColumns.includes(column)).map((column, idx) => (
          <td key={idx} style={{ padding: '8px', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatValue(item[columnMap[column]])}
          </td>
        ))}
      </tr>
    ));
  };

  const handleColorChange = (group, color) => {
    setGroupColors(prevColors => ({
      ...prevColors,
      [group]: color
    }));
  };

  return (
    <div style={{ maxHeight: '78vh', overflowY: 'auto', margin: 'auto', maxWidth: '98%' }}>
        <Table striped bordered hover size='sm' variant='dark' style={{ minWidth: '1200px' }}>
          <thead>
            <tr>
              {columns.filter(column => visibleColumns.includes(column)).map((column, index) => (
                <th
                  key={index}
                  style={{
                    backgroundColor: groupColors[columnGroups[column]],
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    padding: '0px',
                    textAlign: 'center',
                    fontSize: '14px',
                  }}
                >
                  {column === 'Center' && (
                    <FontAwesomeIcon
                      icon={faPalette}
                      style={{ cursor: 'pointer', fontSize: '1.2em', position: 'absolute', left: '4px', top: '4px' }}
                      onClick={() => setShowColorModal(true)}
                    />
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '1.5em' }}>
                    <span onClick={() => handleSort(column)} style={{ cursor: 'pointer' }}>
                      {column} {renderSortIcon(column)}
                    </span>
                    {(column === 'SKU' || column === 'Orders to Place' || column === 'Center') && (
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ cursor: 'pointer', fontSize: '1em', marginLeft: '4px' }}
                        onClick={() => handleCopyColumn(column)}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {generateRows()}
          </tbody>
        </Table>

      <Modal show={showColorModal} onHide={() => setShowColorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'lightgrey' }}>Customize Group Colors</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: 'lightgrey' }}>
          {Object.keys(defaultGroupColors).map((group, index) => (
            <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <label style={{ minWidth: '150px' }}>{group}</label>
              <input
                type="color"
                value={groupColors[group]}
                onChange={(e) => handleColorChange(group, e.target.value)}
                style={{ marginLeft: '10px' }}
              />
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setGroupColors(defaultGroupColors)}>
            Reset to Default
          </Button>
          <Button variant="primary" onClick={() => setShowColorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default JJDRPTable;
