import React from 'react';
import { PieChart, Pie, Cell, Tooltip, LabelLine, ResponsiveContainer } from 'recharts';
import { Table, Row } from 'react-bootstrap';
import { formatCurrency } from '../../../../Utils/FormattingFunctions';

const CreditAnalysisPieChart = ({
  creditData,
  selectedAggregation,
  selectedGroup,
  setSelectedGroup,
  colorMapping,
  maxItems = 7,
  showAll,
  setShowAll,
}) => {
  const aggregationKeyMapping = {
    'Order Reason': 'Order Reason',
    'Client Description': 'Client Description',
    'Material': 'Material Description',
  };

  const groupKeyName = aggregationKeyMapping[selectedAggregation];

  const groupedData = creditData.reduce((acc, item) => {
    let groupKey = item[groupKeyName] || 'Unassigned';

    const amount = Math.abs(item['Net Billed Amount']); // Use absolute value for the chart

    if (!acc[groupKey]) {
      acc[groupKey] = { total: 0, data: [] };
    }
    acc[groupKey].total += amount; // Sum absolute amounts
    acc[groupKey].data.push(item);
    return acc;
  }, {});

  let pieData = Object.keys(groupedData).map((key) => ({
    name: key,
    value: groupedData[key].total, // Keep values positive for visualization
  }));

  pieData.sort((a, b) => b.value - a.value);

  const maxItemsToShow = selectedAggregation === 'Material' ? 10 : maxItems;
  let limitedPieData = pieData;
  if (!showAll) {
    limitedPieData = pieData.slice(0, maxItemsToShow);
  }

  const handleCellClick = (data) => {
    if (selectedGroup === data.name) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(data.name);
    }
  };

  if (pieData.length === 0) {
    return <div>No data available for the PieChart</div>;
  }

  // Function to dynamically position labels outside the pie chart
  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, name, value, percent } = props;
    const radius = outerRadius + 30; // Position labels slightly outside the pie chart
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent < 0.05) return null; // Skip labels for very small slices

    return (
      <text
        x={x}
        y={y}
        fill={colorMapping[name]}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize="12px"
        fontWeight="bold"
      >
        {`${name} (${formatCurrency(value)})`}
      </text>
    );
  };

  const CustomLegend = ({ data }) => {
    return (
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        <Table bordered variant="dark" size="sm" style={{ fontSize: "12px", width: "90%", margin: "auto" }}>
          <thead>
            <tr>
              <th style={{ color: '#FFFFFF' }}>Legend</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {data.map((item, index) => (
                <td key={index} style={{ padding: '5px', color: '#FFFFFF' }}>
                  <span style={{ color: colorMapping[item.name], fontWeight: 'bold' }}>■ </span>
                  {item.name} ({formatCurrency(item.value)})
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <ResponsiveContainer width="95%" height={400}>
          <PieChart>
            <Pie
              data={limitedPieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              innerRadius={60}
              label={renderCustomizedLabel} // Custom label function
              labelLine={true} // Enable label lines for small slices
            >
              {limitedPieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorMapping[entry.name]}
                  stroke={selectedGroup === entry.name ? '#000000' : null}
                  strokeWidth={selectedGroup === entry.name ? 2 : 1}
                  onClick={() => handleCellClick(entry)}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value) => formatCurrency(value)} />
          </PieChart>
        </ResponsiveContainer>
      </Row>
      <div>
        <CustomLegend data={limitedPieData} />
      </div>
    </div>
  );
};

export default CreditAnalysisPieChart;
