import React from 'react'
import '../Styles/Home.css'

function Home() {
  return (
    <div className='/HOME'>
        <div className='home-main-section'>
        <div className='home-first-section'>
            Touch. Feel. Live.
        </div>
        <div className='home-second-section'>
            click the bars menu top-left to get started.
        </div>
        </div>
    </div>
  )
}

export default Home