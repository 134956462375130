import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBtnColours({data, handleFilterColourChange}) {
  return (
    <div className='navbtn-colors'>
        {data[0] && (
        data
            // Extract unique color values
            .map((item) => item.Colour)
            .filter((value, index, self) => self.indexOf(value) === index)
            // Sort the unique color values alphabetically
            .sort()
            // Render the dropdown items
            .map((color) => (
            <NavDropdown.Item
                key={color}
                className='navbtn-colors__item'
                onClick={handleFilterColourChange}
            >
                {color}
            </NavDropdown.Item>
            ))
        )}
    </div>
  )
}

export default NavBtnColours