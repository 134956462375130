// Define your header translations
import { convertExcelDateToDate } from "./DateOps";

const headerTranslations = {
    "Sociedad Facturadora": "Billing Company",
    "Centro": "Center",
    "Ejercicio/Periodo Factura": "Fiscal Year/Invoice Period",
    "Fecha Doc. Oferta": "Doc/ Offer Date",
    "Documento Oferta": "Offer Document",
    "Fecha Doc. Ventas": "Sales Doc Date",
    "Documento Ventas": "Sales Document",
    "Posición Doc. Ventas": "Sales Document Position",
    "Fecha Doc. Entrega": "Delivery Doc Date",
    "Documento Entrega": "Delivery Document",
    "Posición Doc. Entrega": "Delivery Document Position",
    "Documento Factura": "Invoice Document",
    "Fecha Doc. Factura": "Invoice Doc Date",
    "Posición Doc. Factura": "Invoice Document Position",
    "Documento Contable": "Accounting Document",
    "Responsable de Pago": "Payment Responsible",
    "": "Client Description",  // Special case for blank header
    "Material": "Material",
    "Descripción Material": "Material Description",
    "Motivo Pedido": "Order Reason",
    "Cantidad Facturada": "Billed Quantity",
    "Imp. Neto Facturado": "Net Amount Billed",
    "Imp. Neto Facturado (Mon. Sociedad)": "Net Amount Billed (Company Currency)",
    "Imp. Neto Facturado (Mon. Grupo)": "Net Amount Billed (Group Currency)",
    "Área Total Real Tabla Factura (M2)": "Total Real Area Invoice Table (M2)"
};

// Function to translate headers and handle special cases
export const translateHeaders = (dataArray) => {
    if (dataArray.length === 0) return [];

    // Extract headers
    const headers = dataArray[0];

    // Translate headers
    const translatedHeaders = headers.map((header, index) => {
        if (header === '' && headers[index + 1] === 'Material') {
            return 'Client Description'; // Handle special case
        }
        return headerTranslations[header] || header;
    });

    // Process the rest of the rows
    const processedData = dataArray.map((row, rowIndex) => {
        if (rowIndex === 0) return translatedHeaders; // Replace header row

        const rowData = {};
        translatedHeaders.forEach((header, index) => {
            rowData[header] = row[index] || ''; // Handle empty cells
        });
        return rowData;
    });

    return processedData;
};

const returnSocietySaleValue = (sale) => {
    return sale["Net Amount Billed (Soc. Currency)"] || sale['Imp. Neto Facturado'];
};

const returnGroupSaleValue = (sale) => {
    return sale['Net Amount Billed (Group Currency)'] || sale['Imp. Neto Facturado (Mon. Grupo)'];
};

const returnDateValue = (sale) => {
    return sale["Invoice Document Date"] || sale['Fecha Doc. Factura'];
};

const returnBilledQty = (sale) => {
    return sale["Billed Quantity"] || sale['Cantidad Facturada'];
}

const returnOrderReason = (sale) => {
    return sale["Purchase Order Reason"] || sale['Motivo Pedido'];
};

const returnClientDescription = (sale) => {
    return sale["Client Description"] || sale['Payment Responsible Name'];
};


//In CUBO, data is stored as dates, this function will convert them to the format we need, and sort in date order.
export const transformDatesInSalesData = (salesData)  => {
    // Iterate through each record in the array
    for (let i = 0; i < salesData.length; i++) {
      // Extract the Invoice Document Date from the record
        const invoiceDocumentDate = salesData[i]["Invoice Document Date"] || salesData[i]['Fecha Doc. Factura'];
        convertExcelDateToDate(invoiceDocumentDate);  
        // Check if the date is in the expected format (DD.MM.YYYY)
        
        // Sort the array by the transformed dates (oldest to newest)
        salesData.sort((a, b) => {
            const dteValA = returnDateValue(a);
            const dteValB = returnDateValue(b);
        const dateA = new Date(dteValA);
        const dateB = new Date(dteValB);
        return dateA - dateB;
        });
    
        return salesData;
    }
}

//All Customers - Revenue local Currency
export const totalNetValueAllCustomerLocal = (salesData) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        const value = returnSocietySaleValue(sale);
        totalNetValue += value || 0;
    });
    return totalNetValue;
};

//All Customers - Revenue Euro
export const totalNetValueAllCustomerEuro = (salesData) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        const value = returnGroupSaleValue(sale);
        totalNetValue += value || 0;
    });
    return totalNetValue;
};

//All Customers - Total Billed Slabs for All Sales & Customers
export const totalBilledSlabsAllCustomer = (salesData) => {
    let totalBilledSlabs = 0;
    salesData.forEach(sale => {

        if (sale.Material) {
            if (String(sale.Material).startsWith("TB")) {
                totalBilledSlabs += returnBilledQty(sale);
            }
        }
    });
    return totalBilledSlabs;
};

//All Customers - Total Billed Value for Slabs Only, in local currency
export const totalBilledSlabsValueLocalAllCustomer = (salesData) => {
    let totalBilledValueLocal = 0;
    salesData.forEach(sale => {

        if (sale.Material) {
            if (String(sale.Material).startsWith("TB")) {
                totalBilledValueLocal += returnSocietySaleValue(sale);
            }
        }
    });
    return totalBilledValueLocal;
};
//All Customers - Total Billed Value for Slabs Only, in Euro
export const totalBilledSlabsValueEuroAllCustomer = (salesData) => {
    let totalBilledValueLocal = 0;
    salesData.forEach(sale => {

        if (sale.Material) {
            if (String(sale.Material).startsWith("TB")) {
                totalBilledValueLocal += returnGroupSaleValue(sale);
            }
        }
    });
    return totalBilledValueLocal;
};

//All Customers - Total Transport costs billed to all customers.
export const totalTransportCostsBilledAllCustomer = (salesData) => {
    let totalTransportCosts = 0;
    salesData.forEach(sale => {
        if (sale.Material) {
            if (String(sale.Material) === "400000014") {
                totalTransportCosts += returnSocietySaleValue(sale);
            }
        }
    });
    return totalTransportCosts;
};

//All Customers - average invoice value all customers Local
export const averageInvoiceValueAllCustomerLocal = (salesData) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        try {
            const value = returnSocietySaleValue(sale);
            totalNetValue += value || 0;
        } catch (error) {
            console.log(error);
        }
    });
    return totalNetValue / salesData.length;
};

export const averageInvoiceValueAllCustomerLocaWithoutDisplaysOrQualityCredits = (salesData) => {
    let totalNetValue = 0;
    let filterLength = 0;
    salesData.forEach(sale => {
        try {
            const value = returnSocietySaleValue(sale);
            const reason = returnOrderReason(sale);
            if (!reason.includes('EXPOSI') && !reason.includes('calidad') && !reason.includes('deteriora')) {
                totalNetValue += value || 0;
                filterLength++;
            }
        } catch (error) {
            console.log(error);
        }
    });
    return totalNetValue / filterLength;
};

export const averageInvoiceValueAllCustomerLocalNoCredits = (salesData) => {
    let totalNetValue = 0;
    let filterLength = 0;
    salesData.forEach(sale => {
        try {
            const value = returnSocietySaleValue(sale);
            if (value > 0) {
                totalNetValue += value || 0;
                filterLength++;
            }
        } catch (error) {
            console.log(error);
        }
    });
    return totalNetValue / filterLength;
};



// All Customers - average invoice value all customers Euro
export const averageInvoiceValueAllCustomerEuro = (salesData) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        const value = returnGroupSaleValue(sale);
        totalNetValue += value || 0;
    });
    return totalNetValue / salesData.length;
}

//All Customers - Credits Local
export const calculateCreditsLocal = (salesData) => {
    let totalCredits = 0;
    salesData.forEach(sale => {
        if (returnSocietySaleValue(sale) < 0) {
            totalCredits += returnSocietySaleValue(sale);
        }
    });
    return totalCredits;
};

//All Customers - Credits Euro
export const calculateCreditsEuro = (salesData) => {
    let totalCredits = 0;
    salesData.forEach(sale => {
        if (returnGroupSaleValue(sale) < 0) {
            totalCredits += returnGroupSaleValue(sale);
        }
    });
    return totalCredits;
};

//All Customers - average credit Local
export const calculateAverageCreditsLocal = (salesData) => {
    let totalCredits = 0;
    let avgCounter = 0;
    salesData.forEach(sale => {
        if (returnSocietySaleValue(sale) < 0) {
            totalCredits += returnSocietySaleValue(sale);
            avgCounter++;
        }
    });
    return totalCredits / avgCounter;
};

//All Customers - average credit Euro
export const calculateAverageCreditsEuro = (salesData) => {
    let totalCredits = 0;
    let avgCounter = 0;
    salesData.forEach(sale => {
        if (returnGroupSaleValue(sale) < 0) {
            totalCredits += returnGroupSaleValue(sale);
            avgCounter++;
        }
    });
    return totalCredits / avgCounter;
}

//All Customers - Calculate turnover Local
export const calculateTurnoverLocal = (salesData) => {
    let totalTurnover = 0;
    salesData.forEach(sale => {
        if (returnSocietySaleValue(sale) > 0) {
            totalTurnover += returnSocietySaleValue(sale);
        }
    });
    return totalTurnover;
};

//All Customers - Calculate turnover Euro
export const calculateTurnoverEuro = (salesData) => {
    let totalTurnover = 0;
    salesData.forEach(sale => {
        if (returnGroupSaleValue(sale) > 0) {
            totalTurnover += returnGroupSaleValue(sale);
        }
    });
    return totalTurnover;
};


//CHARTS
//All Customers - Calculate top 10 customers
export const calculateTop10CustomersLocal = (salesData) => {
    const customerMap = new Map();

    salesData.forEach(sale => {
        const customer = returnClientDescription(sale);
        const netAmount = returnSocietySaleValue(sale);

        if (customer && netAmount > 0) {
            if (!customerMap.has(customer)) {
                customerMap.set(customer, 0);
            }

            customerMap.set(customer, customerMap.get(customer) + netAmount);
        }
    });

    const sortedCustomers = Array.from(customerMap.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(entry => ({ name: entry[0], totalValue: entry[1] }));

    return sortedCustomers;
};

//All Customers - Calculate top 10 Materials
export const calculateTop10MaterialsLocal = (salesData) => {
    const materialMap = new Map();

    salesData.forEach(sale => {
        const material = String(sale.Material);
        const materialDescription = sale["Material Description"];
        const netAmount = returnSocietySaleValue(sale);
        const billedQty = returnBilledQty(sale);

        if (material.startsWith('TB') && netAmount > 0) {
            if (!materialMap.has(material)) {
                materialMap.set(material, {
                    totalValue: 0,
                    totalBilledQty: 0,
                    materialDescription: materialDescription
                });
            }

            const materialData = materialMap.get(material);
            materialMap.set(material, {
                ...materialData,
                totalValue: materialData.totalValue + netAmount,
                totalBilledQty: materialData.totalBilledQty + billedQty,
            });
        }
    });

    const sortedMaterials = Array.from(materialMap.entries())
        .sort((a, b) => b[1].totalValue - a[1].totalValue)
        .slice(0, 10)
        .map(entry => ({
            material: entry[0],
            materialDescription: entry[1].materialDescription,
            totalValue: entry[1].totalValue,
            totalBilledQty: entry[1].totalBilledQty,
            totalSlabsValueLocal: entry[1].totalSlabsValueLocal
        }));

    return sortedMaterials;
};

//All Customers - Calculate top 10 WORST Materials
export const calculateTop10WorstMaterialsLocal = (salesData) => {
    const materialMap = new Map();

    salesData.forEach(sale => {
        const material = String(sale.Material);
        const materialDescription = sale["Material Description"];
        const netAmount = returnSocietySaleValue(sale);
        const billedQty = returnBilledQty(sale);

        if (material.startsWith('TB') && netAmount > 0) {
            if (!materialMap.has(material)) {
                materialMap.set(material, {
                    totalValue: 0,
                    totalBilledQty: 0,
                    materialDescription: materialDescription
                });
            }

            const materialData = materialMap.get(material);
            materialMap.set(material, {
                ...materialData,
                totalValue: materialData.totalValue + netAmount,
                totalBilledQty: materialData.totalBilledQty + billedQty,
            });
        }
    });

    const sortedMaterials = Array.from(materialMap.entries())
        .sort((a, b) => a[1].totalValue - b[1].totalValue) // Sort ascending for worst performance
        .slice(0, 10)
        .map(entry => ({
            material: entry[0],
            materialDescription: entry[1].materialDescription,
            totalValue: entry[1].totalValue,
            totalBilledQty: entry[1].totalBilledQty,
        }));

    return sortedMaterials;
};

//All Customers - Calculate top 10 WORST Customers
export const calculateTop10WorstCustomersLocal = (salesData) => {
    const customerMap = new Map();

    salesData.forEach(sale => {
        const customer = returnClientDescription(sale);
        const netAmount = returnSocietySaleValue(sale);

        if (customer && netAmount > 0) {
            if (!customerMap.has(customer)) {
                customerMap.set(customer, 0);
            }

            customerMap.set(customer, customerMap.get(customer) + netAmount);
        }
    });

    const sortedCustomers = Array.from(customerMap.entries())
        .sort((a, b) => a[1] - b[1]) // Sort ascending for worst performance
        .slice(0, 10)
        .map(entry => ({ name: entry[0], totalValue: entry[1] }));

    return sortedCustomers;
};


//Revenue Euro by specific customer:
export const totalNetValueBySelectedCustomerEuro = (salesData, custonerName) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === custonerName) {
            const value = returnGroupSaleValue(sale);
            totalNetValue += value || 0;
        }
    });
    return totalNetValue;
};

// By Customer - Revenue local Currency
export const totalNetValueByCustomerLocal = (salesData, customerName) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName) {
            const value = returnSocietySaleValue(sale);
            totalNetValue += value || 0;
        }
    });
    return totalNetValue;
};

// By Customer - Revenue Euro
export const totalNetValueByCustomerEuro = (salesData, customerName) => {
    let totalNetValue = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName) {
            const value = returnGroupSaleValue(sale);
            totalNetValue += value || 0;
        }
    });
    return totalNetValue;
};

// By Customer - Total Billed Slabs for All Sales & Customers
export const totalBilledSlabsByCustomer = (salesData, customerName) => {
    let totalBilledSlabs = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material).startsWith("TB")) {
            totalBilledSlabs += returnBilledQty(sale);
        }
    });
    return totalBilledSlabs;
};

// By Customer - Total Billed Value for Slabs Only, in local currency
export const totalBilledSlabsValueLocalByCustomer = (salesData, customerName) => {
    let totalBilledValueLocal = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material).startsWith("TB")) {
            totalBilledValueLocal += returnSocietySaleValue(sale);
        }
    });
    return totalBilledValueLocal;
};

// By Customer - Total Billed Value for Slabs Only, in Euro
export const totalBilledSlabsValueEuroByCustomer = (salesData, customerName) => {
    let totalBilledValueEuro = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material).startsWith("TB")) {
            totalBilledValueEuro += returnGroupSaleValue(sale);
        }
    });
    return totalBilledValueEuro;
};

// By Customer - Total Transport costs billed to a specific customer.
export const totalTransportCostsBilledByCustomer = (salesData, customerName) => {
    let totalTransportCosts = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material) === "400000014") {
            totalTransportCosts += returnSocietySaleValue(sale);
        }
    });
    return totalTransportCosts;
};

// By Customer - average invoice value for a specific customer Local
export const averageInvoiceValueByCustomerLocal = (salesData, customerName) => {
    let totalNetValue = 0;
    let salesCount = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName) {
            const value = returnSocietySaleValue(sale);
            totalNetValue += value || 0;
            salesCount++;
        }
    });
    return salesCount === 0 ? 0 : totalNetValue / salesCount;
};

// By Customer - average invoice value for a specific customer Euro
export const averageInvoiceValueByCustomerEuro = (salesData, customerName) => {
    let totalNetValue = 0;
    let salesCount = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName) {
            const value = returnGroupSaleValue(sale);
            totalNetValue += value || 0;
            salesCount++;
        }
    });
    return salesCount === 0 ? 0 : totalNetValue / salesCount;
};

// By Customer - Credits Local
export const calculateCreditsByCustomerLocal = (salesData, customerName) => {
    let totalCredits = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnSocietySaleValue(sale) < 0) {
            totalCredits += returnSocietySaleValue(sale);
        }
    });
    return totalCredits;
};

// By Customer - Credits Euro
export const calculateCreditsByCustomerEuro = (salesData, customerName) => {
    let totalCredits = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnGroupSaleValue(sale) < 0) {
            totalCredits += returnGroupSaleValue(sale);
        }
    });
    return totalCredits;
};

// By Customer - average credit Local
export const calculateAverageCreditsByCustomerLocal = (salesData, customerName) => {
    let totalCredits = 0;
    let avgCounter = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnSocietySaleValue(sale) < 0) {
            totalCredits += returnSocietySaleValue(sale);
            avgCounter++;
        }
    });
    return avgCounter === 0 ? 0 : totalCredits / avgCounter;
};

// By Customer - average credit Euro
export const calculateAverageCreditsByCustomerEuro = (salesData, customerName) => {
    let totalCredits = 0;
    let avgCounter = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnGroupSaleValue(sale) < 0) {
            totalCredits += returnGroupSaleValue(sale);
            avgCounter++;
        }
    });
    return avgCounter === 0 ? 0 : totalCredits / avgCounter;
};

// By Customer - Calculate turnover Local
export const calculateTurnoverByCustomerLocal = (salesData, customerName) => {
    let totalTurnover = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnSocietySaleValue(sale) > 0) {
            totalTurnover += returnSocietySaleValue(sale);
        }
    });
    return totalTurnover;
};

// By Customer - Calculate turnover Euro
export const calculateTurnoverByCustomerEuro = (salesData, customerName) => {
    let totalTurnover = 0;
    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && returnGroupSaleValue(sale) > 0) {
            totalTurnover += returnGroupSaleValue(sale);
        }
    });
    return totalTurnover;
};

// By Customer - Calculate top 10 Materials for a specific customer
export const calculateTop10MaterialsByCustomerLocal = (salesData, customerName) => {
    const materialMap = new Map();

    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material).startsWith('TB')) {
            const material = String(sale.Material);
            const materialDescription = sale["Material Description"];
            const netAmount = returnSocietySaleValue(sale);
            const billedQty = returnBilledQty(sale);

            if (!materialMap.has(material)) {
                materialMap.set(material, {
                    totalValue: 0,
                    totalBilledQty: 0,
                    materialDescription: materialDescription
                });
            }

            const materialData = materialMap.get(material);
            materialMap.set(material, {
                ...materialData,
                totalValue: materialData.totalValue + netAmount,
                totalBilledQty: materialData.totalBilledQty + billedQty,
            });
        }
    });

    const sortedMaterials = Array.from(materialMap.entries())
        .sort((a, b) => b[1].totalValue - a[1].totalValue)
        .slice(0, 10)
        .map(entry => ({
            material: entry[0],
            materialDescription: entry[1].materialDescription,
            totalValue: entry[1].totalValue,
            totalBilledQty: entry[1].totalBilledQty,
        }));

    return sortedMaterials;
};

// By Customer - Calculate top 10 WORST Materials for a specific customer
export const calculateTop10WorstMaterialsByCustomerLocal = (salesData, customerName) => {
    const materialMap = new Map();

    salesData.forEach(sale => {
        if (returnClientDescription(sale) === customerName && sale.Material && String(sale.Material).startsWith('TB')) {
            const material = String(sale.Material);
            const materialDescription = sale["Material Description"];
            const netAmount = returnSocietySaleValue(sale);
            const billedQty = returnBilledQty(sale);

            if (!materialMap.has(material)) {
                materialMap.set(material, {
                    totalValue: 0,
                    totalBilledQty: 0,
                    materialDescription: materialDescription
                });
            }

            const materialData = materialMap.get(material);
            materialMap.set(material, {
                ...materialData,
                totalValue: materialData.totalValue + netAmount,
                totalBilledQty: materialData.totalBilledQty + billedQty,
            });
        }
    });

    const sortedMaterials = Array.from(materialMap.entries())
        .sort((a, b) => a[1].totalValue - b[1].totalValue) // Sort ascending for worst performance
        .slice(0, 10)
        .map(entry => ({
            material: entry[0],
            materialDescription: entry[1].materialDescription,
            totalValue: entry[1].totalValue,
            totalBilledQty: entry[1].totalBilledQty,
        }));

    return sortedMaterials;
};

