import React, { useEffect, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import StockCellViewCard from './ExcelDRPCellViewCards/StockCellViewCard';
import PurchaseOrderCellViewCard from './ExcelDRPCellViewCards/PurchaseOrderCellViewCard';
import SalesOrderCellViewCard from './ExcelDRPCellViewCards/SalesOrderCellViewCard';

function ExcelDRPMultiFunctionTable({
  data,
  visibleColumns = [],
  recordsToShow,
  IQ09RawData,
  allColumns,
  ATPOnly
}) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const tableRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [purchaseOrderModalShow, setPurchaseOrderModalShow] = useState(false);
  const [salesOrderModalShow, setSalesOrderModalShow] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedPurchaseOrderMaterial, setSelectedPurchaseOrderMaterial] = useState(null);
  const [selectedSalesOrderMaterial, setSelectedSalesOrderMaterial] = useState(null);

  // Ensure visibleColumns is not undefined
  const columnsToDisplay = ATPOnly
  ? allColumns.filter(column => ['Material', 'Material Description', 'Decor', 'Finish Description', 'Thickness', 'Size', 'ATP'].includes(column.label))
  : allColumns.filter(column => visibleColumns.includes(column.key));


  const parseDailyRate = (dailyRate) => {
    try {
      return dailyRate;
    } catch (error) {
      return '-';
    }
  };

  const parseDIO = (DIO) => {
    try {
      return Number.isInteger(DIO) ? DIO : DIO.toFixed(2);
    } catch (error) {
      return '-';
    }
  };

  const headerColors = ['rgb(14, 81, 99)', 'rgb(9, 100, 125)'];
  console.log(data[0]);

  const generateColourfulHeaderCells = (columns, headerColors) => {
    return columns.map((column, index) => {
      let backgroundColor;
  
      // Check if the column key is one of the specified columns
      if (["Price Group", "Price Code", "Portfolio"].includes(column.label)) {
        backgroundColor = '#f5c658'; // Pale yellow/gold color
      } else {
        // Use the existing logic for other columns
        backgroundColor =
          index < 6
            ? headerColors[index % 2]
            : index % 2 === 0
            ? 'rgb(19, 87, 64)'
            : 'rgb(18, 128, 90)';
      }
  
      return (
        <th
          key={column.key}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: backgroundColor,
            color: 'white',
            cursor: 'pointer',
          }}
          onClick={() => handleSort(column.key)}
        >
          {column.label} {sortColumn === column.key && renderSortIcon()}
        </th>
      );
    });
  };
  

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const renderSortIcon = () => {
    return sortDirection === 'asc' ? (
      <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: '5px' }} />
    ) : (
      <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: '5px' }} />
    );
  };

  const sortedData = data.slice().sort((a, b) => {
    const aValue = sortColumn ? a[sortColumn] : null;
    const bValue = sortColumn ? b[sortColumn] : null;

    if (sortDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const generateRows = (sortedData) => {
    const limitedData = sortedData.slice(0, recordsToShow);
    return limitedData.map((item, index) => (
      <tr key={index}>
        {columnsToDisplay.map((column) => {
          const cellValue = item[column.key];
          // Handle special cases for cell rendering
          if (column.key === 'MaterialInStock') {
            return (
              <td
                key={column.key}
                onClick={() => { setSelectedMaterial(item.Material); setModalShow(true); }}
              >
                {cellValue}
              </td>
            );
          } else if (column.key === 'MaterialOrdererdByClient') {
            return (
              <td
                key={column.key}
                onClick={() => { setSalesOrderModalShow(true); setSelectedSalesOrderMaterial(item.Material); }}
              >
                {cellValue}
              </td>
            );
          } else if (column.key === 'MaterialOnPO') {
            return (
              <td
                key={column.key}
                onClick={() => { setSelectedPurchaseOrderMaterial(item.Material); setPurchaseOrderModalShow(true); }}
              >
                {cellValue}
              </td>
            );
          } else if (column.key === 'DailyRate') {
            return <td key={column.key}>{parseDailyRate(cellValue)}</td>;
          } else if (column.key === 'DaysOnInventory') {
            return <td key={column.key}>{parseDIO(cellValue)}</td>;
          } else {
            return <td key={column.key}>{cellValue}</td>;
          }
        })}
      </tr>
    ));
  };

  return (
    <div className='-EXCEL-DRP-SUMMARY-TABLE-'>
      <Table
        striped
        bordered
        hover
        size='sm'
        variant='dark'
        style={{ fontSize: 'small' }}
        className='stock-table'
        ref={tableRef}
      >
        <thead>
          <tr style={{ position: 'sticky' }}>
            {generateColourfulHeaderCells(columnsToDisplay, headerColors)}
            {/* Include additional columns if necessary */}
          </tr>
        </thead>
        <tbody>{generateRows(sortedData)}</tbody>
      </Table>
      {/* Modals for cell view cards */}
      {selectedMaterial && (
        <StockCellViewCard
          selectedMaterial={selectedMaterial}
          cardHeaderText={"Available Stock"}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {selectedPurchaseOrderMaterial && (
        <PurchaseOrderCellViewCard
          selectedPurchaseOrderMaterial={selectedPurchaseOrderMaterial}
          cardHeaderText={"Purchase Orders"}
          show={purchaseOrderModalShow}
          onHide={() => setPurchaseOrderModalShow(false)}
        />
      )}
      {selectedSalesOrderMaterial && (
        <SalesOrderCellViewCard
          cardCSSClassName={"sales-order-card"}
          cardCSSHeaderClassName={"sales-order-header"}
          cardCSSBodyClassName={"sales-order-body"}
          cardHeaderText={"Sales Orders"}
          selectedMaterial={selectedSalesOrderMaterial}
          show={salesOrderModalShow}
          onHide={() => setSalesOrderModalShow(false)}
        />
      )}
    </div>
  );
}

export default ExcelDRPMultiFunctionTable;
