import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import VBC_SalesBrowserTable from './VBC_SalesBrowserTable';
import VBC_SalesBrowserDecorSelectionBar from './VBC_SalesBrowserDecorSelectionBar';
import Loading from '../../../Reusables/Loading'

const VBC_SalesBrowserParent = ({ filteredData, dataType, localCurrency }) => {
    const [selectedHalfCode, setSelectedHalfCode] = useState('');

    // Function to filter data based on the selected Half Code and dataType
    const decorFilteredData = useMemo(() => {
        let data = filteredData;

        // Apply dataType filter
        if (dataType === 'Invoiced') {
            data = data.filter(item => item['Net Billed Amount'] > 0);
        } else if (dataType === 'Credited') {
            data = data.filter(item => item['Net Billed Amount'] < 0);
        }

        // Apply Half Code filter
        if (selectedHalfCode) {
            data = data.filter(item => item['Material'].substring(0, 7) === selectedHalfCode);
        }
        else {
            //sort data by date descending and then slice the first 250 records:
            data = data.sort((a, b) => new Date(b['Invoice Document Date']) - new Date(a['Invoice Document Date'])).slice(0, 250);
        }

        return data;
    }, [filteredData, selectedHalfCode, dataType]);

    return (
        <Card className="dark-card-2 sales-browser-card">
            <Card.Body className='sales-browser-body'>
                <VBC_SalesBrowserDecorSelectionBar 
                    setSelectedHalfCode={setSelectedHalfCode}
                    selectedHalfCode={selectedHalfCode}
                />
                {decorFilteredData && decorFilteredData.length > 0 ? <VBC_SalesBrowserTable data={decorFilteredData} localCurrency={localCurrency}/> : <Loading />}
            </Card.Body>
        </Card>
    );
};

export default VBC_SalesBrowserParent;
