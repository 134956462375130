import React from 'react'
import { Form } from 'react-bootstrap'

function FromGroupTextArea({controlID, label, placeholder, setTextAreaValue, textAreaValue}) {
  return (
    <div className="form-row-comments">
    <Form.Group controlId={controlID} className='formGroup-comments'>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={6}
        placeholder={placeholder}
        value={textAreaValue}
        onChange={(e) => setTextAreaValue(e.target.value)}
      />
      </Form.Group>
    </div>
  )
}

export default FromGroupTextArea