import './ExcelDash.css';
import React, { useEffect, useState } from 'react';
import ExcelDashNavBar from '../ExcelDashNavBar/ExcelDashNavBar';
import ExcelDashComponentCycler from '../ExcelDashComponentCycler';

const allColumns = [
  { key: 'Material', label: 'Material' },
  { key: 'Material Description', label: 'Material Description' },
  { key: 'Decor', label: 'Decor' },
  { key: 'Finish Description', label: 'Finish' },
  { key: 'Thickness', label: 'Thickness' },
  { key: 'Plant', label: 'Plant' },
  { key: 'Price group', label: 'Price Group' },
  { key: 'Price Code', label: 'Price Code' },
  { key: 'Portfolio', label: 'Portfolio' },
  { key: 'MaterialInStock', label: 'In Stock' },
  { key: 'MaterialOrdererdByClient', label: 'On Order' },
  { key: 'MaterialOnPO', label: 'Order to HQ' },
  { key: 'MaterialSold', label: 'Sold 12M' },
  { key: 'MaterialSoldPer3Month', label: 'Sold 3M' },
  { key: 'DailyRate', label: 'Daily Rate' },
  { key: 'TargetStock', label: 'Target Stock' },
  { key: 'DaysOnInventory', label: 'Days Inventory' },
  { key: 'NeedToOrder', label: 'Need To Order' },
  { key: 'ATP', label: 'ATP' },
  { key: 'SupplementedATP', label: 'SATP Total' },
];

function ExcelDash({ ExcelProps, AppProps }) {
  // DRP States
  const [selectedViewComponent, setSelectedViewComponent] = useState("DRP Summary");
  const { excelDRPConcept } = ExcelProps;
  const [excelDRPData, setExcelDRPData] = useState([]);
  const [plant, setPlant] = useState();

  // Filters Stuff
  const [selectedDecors, setSelectedDecors] = useState([]);
  const [selectedFinishes, setSelectedFinishes] = useState([]);
  const [selectedThicknesses, setSelectedThicknesses] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [SelectedPriceGroups, setSelectedPriceGroups] = useState([]);
  const [excelFilteredDRPData, setExcelFilteredDRPData] = useState([]);
  const [filterStatusLabelText, setFilterStatusLabelText] = useState('');
  const [showAdditionalColumns, setShowAdditionalColumns] = useState(false);
  const [ATPOnly, setATPOnly] = useState(false);
  const [ESlabsOnly, setESlabsOnly] = useState(true); // Set to false by default
  const [optimalSizesOnly, setOptimalSizesOnly] = useState(true); // Set to false by default
  const [DRPLoaded, setDRPLoaded] = useState(false);
  const [meshVisible, setMeshVisible] = useState(true); // Keep as true if you want mesh visible
  const [hideZeroLines, setHideZeroLines] = useState(false); // Set to false by default

  // State for visible columns, initialized from local storage
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns ? JSON.parse(savedColumns) : allColumns.map(column => column.key);
});


  const toggleLogicColumns = () => {
    setShowAdditionalColumns(!showAdditionalColumns);
  };

  // Function to handle column visibility toggle
  const toggleColumnVisibility = (columnKey, isChecked) => {
    setVisibleColumns((prevVisibleColumns) => {
      const newVisibleColumns = isChecked
        ? [...prevVisibleColumns, columnKey] // Add the column
        : prevVisibleColumns.filter((key) => key !== columnKey); // Remove the column
  
      // Update localStorage to persist the visibility state
      localStorage.setItem('visibleColumns', JSON.stringify(newVisibleColumns));
  
      return newVisibleColumns; // Return the new state for immediate UI update
    });
  };

  useEffect(() => {
    if (plant) {
      if (document.title.includes('Plant')) {
        return;
      }
      document.title = document.title + ` Plant - ${plant}`;
    }
  }, [plant]);

  useEffect(() => {
    if (excelDRPConcept) {
      let stockByMaterial = excelDRPConcept.totalStockByMaterialBatchAndSerial;
      if (stockByMaterial) {
        console.log(stockByMaterial[0].Plant);
        setPlant(stockByMaterial[0].Plant);
      }
    }
  }, [excelDRPData, excelDRPConcept]);

  useEffect(() => {
    let filteredData = excelDRPData;

    if (selectedDecors.length > 0) {
      filteredData = filteredData.filter(item => selectedDecors.includes(item.Decor));
    }
    if (selectedFinishes.length > 0) {
      filteredData = filteredData.filter(item => selectedFinishes.includes(item['Finish Description']));
    }
    if (selectedThicknesses.length > 0) {
      filteredData = filteredData.filter(item => selectedThicknesses.includes(item.Thickness));
    }
    if (selectedSizes.length > 0) {
      filteredData = filteredData.filter(item => selectedSizes.includes(item['Size Description']));
    }
    if (SelectedPriceGroups.length > 0) {
      filteredData = filteredData.filter(item => SelectedPriceGroups.includes(item['Price group']));
    } 


    // **Apply additional filters**
    if (ESlabsOnly) {
      filteredData = filteredData.filter(item => item['Material Description'].endsWith('E'));
    }

    if (optimalSizesOnly) {
      filteredData = filteredData.filter(item =>
        item['Material Description'].includes('32x16') || item['Material Description'].includes('32x15')
      );
    }

    if (hideZeroLines) {
      filteredData = filteredData.filter(item => {
        // Check if any of the NoData fields are false
        return !(item.NoDataSales && item.NoDataStock && item.NoDataOrders && item.NoDataPOs);
      });
    }

    if (!meshVisible) {
      filteredData = filteredData.filter(item => {
        const lastFour = item.Material.slice(-4);
        return !lastFour.startsWith('P');
      });
    }

    setExcelFilteredDRPData(filteredData);
    setFilterStatusLabelText(`Filters Applied: ${filteredData.length} records`);
  }, [
    selectedDecors, selectedFinishes, selectedThicknesses, selectedSizes,
    ESlabsOnly, optimalSizesOnly, hideZeroLines, meshVisible, excelDRPData, SelectedPriceGroups,
  ]);

  return (
    <div className='-Excel-Dash-'>
      <ExcelDashNavBar
        ExcelProps={ExcelProps}
        AppProps={AppProps}
        setSelectedViewComponent={setSelectedViewComponent}
        selectedViewComponent={selectedViewComponent}
        selectedDecors={selectedDecors}
        setSelectedDecors={setSelectedDecors}
        selectedFinishes={selectedFinishes}
        setSelectedFinishes={setSelectedFinishes}
        selectedThicknesses={selectedThicknesses}
        setSelectedThicknesses={setSelectedThicknesses}
        selectedSizes={selectedSizes}
        setSelectedSizes={setSelectedSizes}
        SelectedPriceGroups={SelectedPriceGroups}
        setSelectedPriceGroups={setSelectedPriceGroups}
        setExcelDRPData={setExcelDRPData}
        excelDRPData={excelDRPData}
        excelFilteredDRPData={excelFilteredDRPData}
        setExcelFilteredDRPData={setExcelFilteredDRPData}
        setShowAdditionalColumns={setShowAdditionalColumns}
        showAdditionalColumns={showAdditionalColumns}
        toggleColumns={toggleLogicColumns}
        plant={plant}
        setATPOnly={setATPOnly}
        ATPOnly={ATPOnly}
        setESlabsOnly={setESlabsOnly}
        ESlabsOnly={ESlabsOnly}
        optimalSizesOnly={optimalSizesOnly}
        setOptimalSizesOnly={setOptimalSizesOnly}
        setDRPLoaded={setDRPLoaded}
        meshVisible={meshVisible}
        setMeshVisible={setMeshVisible}
        hideZeroLines={hideZeroLines}
        setHideZeroLines={setHideZeroLines}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        allColumns={allColumns}
        toggleColumnVisibility={toggleColumnVisibility} // Pass the toggle function
      />
      {/* Render the selected component dynamically from selected view */}
      <ExcelDashComponentCycler
        selectedViewComponent={selectedViewComponent}
        setSelectedViewComponent={setSelectedViewComponent}
        ExcelProps={ExcelProps}
        AppProps={AppProps}
        setExcelDRPData={setExcelDRPData}
        excelDRPData={excelDRPData}
        excelFilteredDRPData={excelFilteredDRPData}
        setExcelFilteredDRPData={setExcelFilteredDRPData}
        setFilterStatusLabelText={setFilterStatusLabelText}
        filterStatusLabelText={filterStatusLabelText}
        showAdditionalColumns={showAdditionalColumns}
        setPlant={setPlant}
        ATPOnly={ATPOnly}
        ESlabsOnly={ESlabsOnly}
        optimalSizesOnly={optimalSizesOnly}
        DRPLoaded={DRPLoaded}
        setDRPLoaded={setDRPLoaded}
        meshVisible={meshVisible}
        hideZeroLines={hideZeroLines}
        visibleColumns={visibleColumns}
        allColumns={allColumns}
      />
    </div>
  );
}

export default ExcelDash;
