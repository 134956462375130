import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faSterlingSign, faEuroSign } from '@fortawesome/free-solid-svg-icons';

function CUBOCardRevenue({ revenueData }) {
  const [revenueDataValid, setRevenueDataValid] = useState(false);

  const formatCurrencyLocal = (value) => {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
    });
  };

  const formatCurrencyEuro = (value) => {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });
  };

  useEffect(() => {
    if (revenueData !== null) {
      setRevenueDataValid(true);
    }
  }, [revenueData]);

  return (
    <Card className='cubo-card-sales-data dark-card font-springgreen'>
      <Card.Header style={{ fontWeight: '600', display: 'flex', alignItems: 'center'}} className='medium-card-text'>
        <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginRight: '12.5px' }} />
        <FontAwesomeIcon icon={faSterlingSign} style={{ marginRight: '12.5px' }} />
        <span>Revenue:</span>
        <FontAwesomeIcon icon={faEuroSign} style={{ marginLeft: '12.5px' }} />
        <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginLeft: '12.5px' }} />
      </Card.Header>
      <Card.Body className='cubo-card-body-transport'>
        {revenueDataValid && (
            <>
                <Row className='cubo-card-transport-data-text'>
                <Col className='custom-col bord-right bord-bottom'>
                    <span style={{fontWeight: "600"}}>Revenue Local:</span>
                    <span className='revenue-result'>{formatCurrencyLocal(revenueData.totalRevenueAllLocal)}</span>
                </Col>
                <Col className='custom-col bord-bottom'>
                    <span style={{fontWeight: "600"}}>Revenue Euro:</span>
                    <span className='revenue-result'>{formatCurrencyEuro(revenueData.totalRevenueAllEuro)}</span>
                </Col>
                </Row>
                <Row className='cubo-card-transport-data-text'>
                <Col className='custom-col bord-right'>
                    <span style={{fontWeight: "600"}}>Avg Invoice £:</span>
                    <span className='revenue-result'>{formatCurrencyLocal(revenueData.averageInvoiceValueAllCustomerLocal)}</span>
                </Col>
                <Col className='custom-col'>
                    <span style={{fontWeight: "600"}}>Avg Invoice Eur: </span>
                    <span className='revenue-result'>{formatCurrencyEuro(revenueData.averageInvoiceValueAllCustomerEuro)}</span>
                </Col>
                </Row>
            </>
        )}
      </Card.Body>
    </Card>
  );
}

export default CUBOCardRevenue;
