import React, { useState } from 'react';
import CUBOAnalyserDemandNavbar from './CUBOAnalyserDemandNavbar/CUBOAnalyserDemandNavbar';
import CUBOAnalyserDemandTable from './CUBOAnalyserDemandTable';  
import CUBOAnalyserDemandSubsidiary from './CUBOAnalyserDemandSubsidiary/CUBOAnalyserDemandSubsidiary';

function CUBOAnalyserDemand({ CUBOAnalyserProps, demandDataProps}) {
    const { CUBODRPData, setFilteredDataCopy, filteredDataCopy } = CUBOAnalyserProps;
    const { ESlabsOnly, OptimalSizesOnly, showDailyRates, setESlabsOnly, setOptimalSizesOnly, setShowDailyRates } = demandDataProps;
    const [analyserDemandView, setAnalyserDemandView] = useState("HQ");
    const [demandFilters, setDemandFilters] = useState({ decor: "", finish: "", size: "", thickness: "" });
    const [selectedCenter, setSelectedCenter] = useState('131');

    return (
        <div className='CUBO-analyser-demand-main'>
            <CUBOAnalyserDemandNavbar
                CUBOAnalyserProps={CUBOAnalyserProps}
                setAnalyserDemandView={setAnalyserDemandView}
                demandFilters={demandFilters}
                setDemandFilters={setDemandFilters}
                filteredDataCopyForExport={filteredDataCopy}
                demandDataProps={demandDataProps}
                analyserDemandView={analyserDemandView}
                selectedCenter={selectedCenter}
            />
            {analyserDemandView === "HQ" && <CUBOAnalyserDemandTable CUBODRPData={CUBODRPData} demandFilters={demandFilters} setFilteredDataCopy={setFilteredDataCopy} demandDataProps={demandDataProps} />}
            {analyserDemandView === "Subsidiary" && <CUBOAnalyserDemandSubsidiary CUBOAnalyserProps={CUBOAnalyserProps} demandDataProps={demandDataProps} demandFilters={demandFilters} selectedCenter={selectedCenter} setSelectedCenter={setSelectedCenter} />}
        </div>
    );
}

export default CUBOAnalyserDemand;
