import React from 'react';
import Table from 'react-bootstrap/Table';

function WorstDIOWidget({ data }) {
  const sortedData = data
    .filter(item => item.DaysOnInventory) // Filter items with valid DIO values
    .sort((a, b) => b.DaysOnInventory - a.DaysOnInventory)
    .slice(0, 10); // Get worst 10

  return (
    <div className="widget-container">
      <h5>Worst 10 DIO</h5>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Material</th>
            <th>Days Inventory</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{item.Material}</td>
              <td>{item.DaysOnInventory}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default WorstDIOWidget;
