import React from 'react'
import FileInputContainer from '../../Reusables/FileInputContainer'
import * as XLSX from "xlsx";
import {VentasAnalyserDataHandler} from '../../../Utils/VentasAnalyserOps'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-bootstrap';
import { tree } from 'd3';

function VentasAnalyserConfig({ventasData, setVentasData,  setDataJustStoredOrRemoved, set}) {

const handleLoadVentasData = async (e) => {
   await VentasAnalyserDataHandler(ventasData, null, setVentasData, true);
   setDataJustStoredOrRemoved(v => !v);
};

  return (
    <div className='-VentasAnalyserConfig'>
        <Card className="data-browser-card">
            <Card.Header style={{fontWeight: "600"}}>Ventas Analyser Configuration:</Card.Header>
            <Card.Body>
                    <FileInputContainer labelTitle={"CUBO Ventas"} selectedFilePath={ventasData} setSelectedFile={setVentasData} />
                    <Button variant="warning" className='custom-button-gold' onClick={handleLoadVentasData}>Load...</Button>
            </Card.Body>
        </Card>
    </div>
  )
}

export default VentasAnalyserConfig