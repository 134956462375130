import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import KPIGrid from '../../Reusables/KPIGrid'; // Import the KPIGrid component
import '../VentasAnalyser/VentasByClient/KPIGrid.css'; // Import custom styles for KPI cards

// Helper function to aggregate data by a dynamic key
const aggregateData = (data, groupByField, valueFields) => {
  const aggregation = {};

  data.forEach(item => {
    const key = item[groupByField]; // E.g., Center, Decor, etc.
    if (!aggregation[key]) {
      aggregation[key] = valueFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {});
    }

    // Sum up values for the valueFields
    valueFields.forEach(field => {
      aggregation[key][field] += parseFloat(item[field]) || 0;
    });
  });

  return Object.keys(aggregation).map(key => ({
    [groupByField]: key,
    ...aggregation[key],
  }));
};

const Insights = ({ JJDRPData }) => {
  // State to store the selected KPI and grouping field
  const [selectedKPI, setSelectedKPI] = useState('Invoiced 12M<');
  const [groupByField, setGroupByField] = useState('Center'); // Default grouping by Center
  const [maxCategories, setMaxCategories] = useState(10); // Limit for X-axis categories

  // KPI calculations
  const calculateTotal = (field) => JJDRPData.reduce((total, item) => total + (parseFloat(item[field]) || 0), 0);

  // List of KPIs
  const kpis = [
    { label: 'Total Invoiced (12M)', value: calculateTotal('Invoiced 12M<'), dataKey: 'Invoiced 12M<' },
    { label: 'Total Invoiced (3M)', value: calculateTotal('Invoiced 3M<'), dataKey: 'Invoiced 3M<' },
    { label: 'Total Available Stock', value: calculateTotal('Current Available Stock (In Plant)'), dataKey: 'Current Available Stock (In Plant)' },
    { label: 'Total Purchase Orders', value: calculateTotal('Current Purchase Orders'), dataKey: 'Current Purchase Orders' },
    { label: 'Total Stock in Transit', value: calculateTotal('Current Stock in Transit'), dataKey: 'Current Stock in Transit' },
    { label: 'Total Target Stock Before Growth', value: calculateTotal('Target Stock B4 Grwth'), dataKey: 'Target Stock B4 Grwth' },
    { label: 'Total Target Stock After Growth', value: calculateTotal('Target Stock After Growth'), dataKey: 'Target Stock After Growth' },
    { label: 'Total Stock Max', value: calculateTotal('Stock Max'), dataKey: 'Stock Max' },
    { label: 'Total Orders to Place', value: calculateTotal('Orders to Place'), dataKey: 'Orders to Place' },
  ];

  // Aggregate data by the selected field (e.g., Center, Decor)
  const aggregatedData = aggregateData(JJDRPData, groupByField, kpis.map(kpi => kpi.dataKey));

  // Prepare chart data for the selected KPI
  let chartData = aggregatedData.map(item => ({
    [groupByField]: item[groupByField],
    amount: parseFloat(item[selectedKPI]) || 0,
  }));

  // Sort data by the selected KPI and limit the number of categories displayed on the X-axis
  chartData = chartData
    .sort((a, b) => b.amount - a.amount)
    .slice(0, maxCategories);

  return (
    <div className="insights-page" style={{ padding: '10px', backgroundColor: '#1f1f21', color: '#fff' }}>
      <div style={{ display: "flex", justifyContent: "center", width: "50%", margin: "auto" }}>
        <span className="insights-header" style={{ color: '#fff', textAlign: 'center', marginRight: "10px", fontSize: "14px" }}>
          Group By:
        </span>
        <select value={groupByField} onChange={(e) => setGroupByField(e.target.value)} style={{ fontSize: "small", marginRight: '10px' }}>
          <option value="Center">Center</option>
          <option value="Descrp. Modelo">Decor</option>
          <option value="Acabado">Finish</option>
          <option value="Espesor">Thickness</option>
          <option value="Medida">Format</option>
          <option value="Segmento">Segment</option>
        </select>
        <label style={{ color: '#fff', marginRight: '10px', fontSize: "small" }}>Max Categories on X-axis:</label>
        <input
          type="number"
          value={maxCategories}
          onChange={(e) => setMaxCategories(Number(e.target.value))}
          style={{ width: '50px', fontSize: "small", marginRight: '10px' }}
        />
      </div>
      <Row>
        <Col md={12}>
          {/* KPI Grid */}
          <KPIGrid
            kpis={kpis}
            selectedKPI={selectedKPI}
            setSelectedKPI={setSelectedKPI}
            chartData={chartData} // Pass aggregated chart data
            localCurrency={false} // Assuming no currency for now
            groupByField={groupByField} // Pass the selected grouping field
          />
        </Col>
      </Row>
    </div>
  );
};

export default Insights;
