import React, { useState, useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

function CUBOCardDynamicList({ dynamicChartData, selectedChart, setSelectedChart }) {
  const [dataList, setDataList] = useState([]);
  const [chartTitle, setChartTitle] = useState('');
  const [isValid, setIsValid] = useState(false);

  const formatCurrencyLocal = (value) => {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
    });
  };

  useEffect(() => {
    if (dynamicChartData !== null) {
      setDynamicData(selectedChart);
    }
  }, [dynamicChartData, selectedChart]);

  const setDynamicData = (selectedChart) => {
    switch (selectedChart) {
      case 'Best Customers':
        setDataList(dynamicChartData.top10Customers);
        setChartTitle('Top 10 Customers');
        setIsValid(true);
        break;
      case 'Worst Customers':
        setDataList(dynamicChartData.top10WorstCustomers);
        setChartTitle('Top 10 Worst Customers');
        setIsValid(true);
        break;
      case 'Best Materials':
        setDataList(dynamicChartData.top10Materials);
        setChartTitle('Top 10 Materials');
        setIsValid(true);
        break;
      case 'Worst Materials':
        setDataList(dynamicChartData.top10WorstMaterials);
        setChartTitle('Top 10 Worst Materials');
        setIsValid(true);
        break;
      default:
        setDataList(dynamicChartData.top10Customers);
        setSelectedChart("Best Customers")
        setChartTitle('Top 10 Customers');
        setIsValid(true);
        break;
    }
  };

  const renderTable = (data, title) => {
    return (
      <Table striped bordered hover responsive variant="dark" size="sm" style={{ fontSize: 'small' }}>
        <thead>
          <tr>
            <th>{title}</th>
            <th>Total Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={index}>
              <td style={{ fontSize: '0.8rem' }}>{entry.name || entry.materialDescription}</td>
              <td style={{ fontSize: '0.8rem' }}>{formatCurrencyLocal(entry.totalValue)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Card className='cubo-card-sales-data dark-card font-blue'>
      {isValid && dataList && (
        <>
          {renderTable(dataList, chartTitle)}
        </>
      )}
    </Card>
  );
}

export default CUBOCardDynamicList;
