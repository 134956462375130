import React, { useEffect, useState } from 'react'
import { DRPConceptFromLocal } from '../../../Utils/ExcelFileOps';
import { useNavigate } from 'react-router-dom';

function ExcelDRPStateManager({ExcelProps, DataProps}) {
    const [loadedFromLocalStorage, setLoadedFromLocalStorage] = useState(false);
    const {excelDataValid, setExcelDataValid, excelDRPConcept, setExcelDRPConcept} = ExcelProps;
    const Navigate = useNavigate();

    useEffect(() => {
        try {
            const excelDRPConcept = DRPConceptFromLocal(setExcelDataValid, setExcelDRPConcept);
            if (excelDRPConcept) {
                setLoadedFromLocalStorage(true);
                console.log('DRP Concept loaded from local storage:');                
            }
            else {
                console.log('No DRP Concept found in local storage');
            }
        } catch (error) {
            console.log('Error loading DRP Concept from local storage:', error.message);
        }
    }, []);


  return (
    <div></div>
  )
}

export default ExcelDRPStateManager