import '../../../../Styles/Excels/ExcelSalesOrders.css';
import React, { useEffect, useState } from 'react';
import SalesOrdersComponentCycler from './SalesOrdersComponentCycler';
import { GetDeliveryScheduleMainData, GroupDataByMonth } from '../../../../Utils/DeliveryScheduleOps';

//GetDeliveryScheduleMainData just pulls the data from Dexie context.

function ExcelSalesOrders({ ExcelProps }) {
    const {excelDataValid, excelDRPConcept} = ExcelProps;
    const [groupedDataByYearMonth, setGroupedDataByYearMonth] = useState([]);
    const [selectedScheduleView, setSelectedScheduleView] = useState('MainTable');
    const [selectedMonth, setSelectedMonth] = useState();

    useEffect(() => {
        if (excelDataValid && excelDRPConcept) {
            const data = GetDeliveryScheduleMainData(excelDRPConcept);
            const groupedData = GroupDataByMonth(data);
            setGroupedDataByYearMonth(groupedData);
            console.log('groupedData:', groupedData);
        }
    }, [excelDataValid, excelDRPConcept]);

    const handleMonthClick = (month) => {
        setSelectedMonth(month);
        setSelectedScheduleView('MonthView');
    };

    const DeliveryScheduleProps = {
        groupedDataByYearMonth: groupedDataByYearMonth,
        setSelectedScheduleView: setSelectedScheduleView,
        selectedScheduleView: selectedScheduleView,
        handleMonthClick: handleMonthClick,
        selectedMonth: selectedMonth,
        rawData: GetDeliveryScheduleMainData(excelDRPConcept),
    };

    return (
        <div className='-Excel-Sales-Orders-'>
            {DeliveryScheduleProps.rawData && 
                <SalesOrdersComponentCycler
                    DeliveryScheduleProps={DeliveryScheduleProps}
                />
            }
        </div>
    );
}

export default ExcelSalesOrders;

  