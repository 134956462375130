import '../Styles/ClaimForBreakageForm.css';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import EmailSucessCard from '../Components/ClaimForBreakage/EmailSucessCard';
import Loading from '../Components/Reusables/Loading';
import FormGroupTwoTextInputsInsideRow from '../Components/ClaimForBreakage/FormGroupTwoTextInputsInsideRow';
import FormGroupTwoInputsOneTextOneDate from '../Components/ClaimForBreakage/FormGroupTwoInputsOneTextOneDate';
import FromGroupTextArea from '../Components/ClaimForBreakage/FromGroupTextArea';
import SlabsBox from '../Components/ClaimForBreakage/SlabsBox';
import { SubmitClaimForBreakage, validateClaimBreakageFormData } from '../Utils/APIRoutes_ClaimBreakage';

function ClaimForBreakageForm() {
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [InvoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState([]);
  const [slabs, setSlabs] = useState([]);  
  const [filesUploaded, setFilesUploaded] = useState(Array(slabs.length).fill(false));
  const [emailStatus, setEmailStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e, slabIndex) => {
    const fileList = Array.from(e.target.files);
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[slabIndex] = fileList;
      return updatedFiles;
    });

    // Mark the corresponding slab as having files uploaded
      setFilesUploaded((prevFilesUploaded) => {
      const updatedFilesUploaded = [...prevFilesUploaded];
      updatedFilesUploaded[slabIndex] = true;
      return updatedFilesUploaded;
    });

  };

  const addSlab = () => {
    setSlabs((prevSlabs) => [
      ...prevSlabs,
      { materialCode: '', serialNumber: '', reason: 'broken-during-cutting' }, // Set default reason here
    ]);
  };

  const handleSlabChange = (index, field, value) => {
    setSlabs((prevSlabs) => {
      const updatedSlabs = [...prevSlabs];
      updatedSlabs[index][field] = value;
      return updatedSlabs;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      // Validate required fields
    if (!validateClaimBreakageFormData(accountNumber, InvoiceNumber, invoiceDate, slabs)) {
      return;
    }
  
    setIsLoading(true);
    await SubmitClaimForBreakage(accountNumber, accountName, InvoiceNumber, invoiceDate, comment, slabs, files, setIsLoading, setEmailStatus);
  };


  if (isLoading) {
    // Render loading state
    return (
      <Loading />
    );
  }
  
    if (!emailStatus && !isLoading) {
      return (
        <div className="feedback-form-container">
          <Form onSubmit={handleSubmit} className='the-form'>
            <div className="form-row">
              <p style={{ margin: "auto", fontWeight: "700" }}>Claim for Breakage</p>
            </div>
            <div className="form-row">
              <p style={{ margin: "auto", fontWeight: "600", fontSize: "medium" }}>Please report all material faults here.</p>
            </div>
            <FormGroupTwoTextInputsInsideRow controlID1="accountNumber" label1="Account Number" placeholder1="Enter Account Number" 
              value1={accountNumber} setTextFieldValue1={setAccountNumber} label2="Account Name" controlID2="accountName" 
              placeholder2="Enter Account Name" value2={accountName} setTextFieldValue2={setAccountName} 
            />
            <FormGroupTwoInputsOneTextOneDate controlID1="InvoiceNumber" label1="Invoice Number" placeholder1="Enter Invoice Number"
              value1={InvoiceNumber} setTextFieldValue1={setInvoiceNumber} label2="Invoice Date" controlID2="invoiceDate"
              placeholder2="Enter Invoice Date" value2={invoiceDate} setTextFieldValue2={setInvoiceDate}
            />
            <FromGroupTextArea controlID="comment" label="Comments" placeholder="Enter Comments" value={comment} setTextAreaValue={setComment} />    

            {/* Add the slabs box content here */}
            <SlabsBox slabs={slabs} handleSlabChange={handleSlabChange} handleFileChange={handleFileChange} filesUploaded={filesUploaded} addSlab={addSlab} />
    
            <Button variant="warning" type="submit" className="custom-button-gold" style={{marginTop: "2vh"}}>
              Submit
            </Button>
          </Form>
        </div>
      );
    } 

    if (emailStatus) {
      return (
        <EmailSucessCard setEmailStatus={setEmailStatus} />
      )
    }

  }
  
export default ClaimForBreakageForm;
