import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

function FileInputContainer({ selectedFilePath, setSelectedFile, labelTitle }) {
  const fileInputRef = useRef(null);
  const [displayPath, setDisplayPath] = useState('No File Picked Yet');
  const [isDragging, setIsDragging] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSelectedFile = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedFile(selectedFile);
      setDisplayPath(selectedFile.name);
    } else {
      setSelectedFile(null);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setSelectedFile(droppedFile);
      setDisplayPath(droppedFile.name);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: isDragging ? '2px dashed #007bff' : '2px solid transparent',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: isDragging ? '#f0f8ff' : '#242428',
      }}
    >
      <Container className='file-input-container' style={{backgroundColor: ""}}>
        <InputGroup className="mb-1" style={{backgroundColor: "#242428" }}>
          <InputGroup.Text style={{ width: "175px" }} id="inputGroup-sizing-default">{labelTitle}</InputGroup.Text>
          <input
            type="text"
            style={{ flexGrow: "1", backgroundColor: "#f8f9fa" }}
            className="file-path"
            placeholder="Selected File Path"
            readOnly
            value={displayPath}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleSelectedFile}
            className={labelTitle}
          />
          <Button
            variant="warning"
            className='file-input-button'
            style={{ position: "relative", left: "-50px", top: "2px", textAlign: "center" }}
            onClick={handleButtonClick}
          >
            ...
          </Button>
        </InputGroup>
        {isDragging && <p style={{ textAlign: 'center', color: '#007bff' }}>Drop file here...</p>}
      </Container>
    </div>
  );
}

export default FileInputContainer;
