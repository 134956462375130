import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import React from 'react';

function NavBtnTools({AppProps, DataProps}) {
  const navigate = useNavigate();

  
  const handleClickInsights = async () => { 
    navigate('/INSIGHTS');
  };
  
    return (
      <>
        <Dropdown data-bs-theme="dark">
          <Dropdown.Toggle as={Button} id="dropdown-button-dark-example1" variant="warning" className='custom-button-gold btn-tools'>
            Tools
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleClickInsights}>Insights</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item >Home</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }


export default NavBtnTools;