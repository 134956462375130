import React from 'react'
import PlanItDash from './PlanItDash/PlanItDash';

function PlanItViewCycler({planItProps, AppProps, selectedView}) {

  return (
    <div className='-plan-it-view-cycler-'>
        {selectedView === 'PlanIt Dash' && <PlanItDash planItProps={planItProps} AppProps={AppProps}/>}
    </div>
  )
}

export default PlanItViewCycler