// StockManager.js
import React from 'react';
import StockFilter from './StockFilter';
import StockTable from './StockTable';
import { Container, Row} from 'react-bootstrap';

const StockManager = ({filteredData, setFilteredData, AppProps, DataProps}) => {


  const updateFilteredData = (data) => {
    setFilteredData(data);
  };

  return (
    <Container className="mt-3" >
      <Row>
        <StockFilter updateFilteredData={updateFilteredData} AppProps={AppProps} DataProps={DataProps}/>
      </Row>
      <Row>
        <StockTable filteredData={filteredData} />
      </Row>
    </Container>
  );
};

export default StockManager;