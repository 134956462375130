import './CUBOAnalyserDemandNavbar.css'
import React, { useEffect } from 'react'
import { Nav, Navbar, NavDropdown, Button, Form } from 'react-bootstrap'
import {exportCUBODRPToExcel, exportSubsidiaryToExce, exportSubsidiaryToExcel} from '../../../../../Utils/ExcelExportUtils'

function CUBOAnalyserDemandNavbar({ CUBOAnalyserProps, demandDataProps, setAnalyserDemandView, demandFilters, setDemandFilters, analyserDemandView, selectedCenter }) {
    const { selectedView, dataCompras, dataVentas, dataOrders, dataStockSubsid, dataStockHQ, CUBODRPData, filteredDataCopy } = CUBOAnalyserProps;
    const { ESlabsOnly, OptimalSizesOnly, showDailyRates, setESlabsOnly, setOptimalSizesOnly, setShowDailyRates } = demandDataProps;
    const [uniqueDecors, setUniqueDecors] = React.useState([]);
    const [uniqueFinishes, setUniqueFinishes] = React.useState([]);
    const [uniqueSizes, setUniqueSizes] = React.useState([]);
    const [uniqueThicknesses, setUniqueThicknesses] = React.useState([]);

    useEffect(() => {
        if (CUBODRPData) {
            const _uniqueDecors = [...new Set(CUBODRPData.map(item => item.DecorName))];
            const _uniqueFinishes = [...new Set(CUBODRPData.map(item => item.Finish))];
            const _uniqueSizes = [...new Set(CUBODRPData.map(item => item.Format))];
            const _uniqueThicknesses = [...new Set(CUBODRPData.map(item => item.Thickness))];

            _uniqueDecors.sort();
            _uniqueFinishes.sort();
            _uniqueSizes.sort();
            _uniqueThicknesses.sort();

            setUniqueDecors(_uniqueDecors);
            setUniqueFinishes(_uniqueFinishes);
            setUniqueSizes(_uniqueSizes);
            setUniqueThicknesses(_uniqueThicknesses);
        }
    }, [CUBODRPData])

    const handleSelectedbDemandView = (e) => {
        setAnalyserDemandView(e.target.innerText)
    }

    const setFilter = (filterName, value) => {
        setDemandFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const handleResetFilters = () => {
        setDemandFilters({ decor: "", finish: "", size: "", thickness: "" });
    };

    const handleExportCUBODRPToExcel = () => {
        let exportData;
        
        if (analyserDemandView === "HQ") {
            if (!Object.values(demandFilters).some(value => value !== '')) {
                exportData = CUBODRPData;
            }
            else {
                exportData = filteredDataCopy;
            }
            exportCUBODRPToExcel(exportData);
        }
        if (analyserDemandView === "Subsidiary") {
            try {
                exportSubsidiaryToExcel(CUBODRPData, selectedCenter);
            } catch (error) {
                
            }
            
        }

    };

    return (
        <Navbar className="-CUBOAnalyerDemand-navbar" variant="dark" bg="dark" expand="lg" style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <Navbar.Brand className="navbar-brand-cubo-analyser-demand" style={{ marginLeft: "20px" }}>CUBOA - Demand</Navbar.Brand>
            <Nav className="mr-auto">
                <NavDropdown title="Mode" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={handleSelectedbDemandView}>HQ</NavDropdown.Item>
                    <NavDropdown.Item style={{ fontSize: "medium", width: "fit-content" }} onClick={handleSelectedbDemandView}>Subsidiary</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Options" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    <div className='demand-options-dropdown'>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch-1"
                            label="Show daily/monthly rates"
                            style={{ marginLeft: "25px", fontSize: "smaller" }}
                            checked={showDailyRates}
                            onChange={() => setShowDailyRates(!showDailyRates)}
                        />
                        <Form.Check
                            type="switch"
                            id="custom-switch-2"
                            label="E Slabs Only"
                            style={{ marginLeft: "25px", fontSize: "smaller" }}
                            checked={ESlabsOnly}
                            onChange={() => setESlabsOnly(!ESlabsOnly)}
                        />
                        <Form.Check
                            type="switch"
                            id="custom-switch-3"
                            label="Optimal Sizes Only"
                            style={{ marginLeft: "25px", fontSize: "smaller" }}
                            checked={OptimalSizesOnly}
                            onChange={() => setOptimalSizesOnly(!OptimalSizesOnly)}
                        />
                        </Form>
                    </div>
                </NavDropdown>
            </Nav>
            <Navbar.Brand className="navbar-brand-cubo-analyser-demand" style={{ marginLeft: "20px" }}>CUBOA - Filters</Navbar.Brand>
            <Nav className="mr-auto">
                <NavDropdown title="Decor" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    {uniqueDecors.map((decor, index) => (
                        <NavDropdown.Item key={index} onClick={() => setFilter("decor", decor)}>
                            {decor}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
                <NavDropdown title="Thickness" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    {uniqueThicknesses.map((thickness, index) => (
                        <NavDropdown.Item key={index} onClick={() => setFilter("thickness", thickness)}>
                            {thickness}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
                <NavDropdown title="Finish" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    {uniqueFinishes.map((finish, index) => (
                        <NavDropdown.Item key={index} onClick={() => setFilter("finish", finish)}>
                            {finish}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
                <NavDropdown title="Size" id="basic-nav-dropdown" className='custom-drop-down-toggle' style={{ fontSize: "medium" }} menuVariant='dark'>
                    {uniqueSizes.map((size, index) => (
                        <NavDropdown.Item key={index} onClick={() => setFilter("size", size)}>
                            {size}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            </Nav>
            <Nav className="mr-auto flex-end">
                <Button variant="success" size='sm' onClick={handleExportCUBODRPToExcel}>Export to Excel</Button>
                <Button variant="danger" size='sm' onClick={handleResetFilters}>Reset Filters</Button>
            </Nav>
        </Navbar>
    )
}

export default CUBOAnalyserDemandNavbar
