import React, { useEffect, useState } from 'react';
import { Card, Table, Modal } from 'react-bootstrap';
import { connectToDB, GetData } from '../../../../Utils/DBOps';
import './StockCellViewCard.css';

// Your grouping function
const groupByMaterialAndBatchAndSerial = (stockItems) => {
  const groupedMaterials = {};

  stockItems.forEach((item) => {
    const material = item.Material;
    const batch = item.Batch || 'N/A';
    const plant = item['Plant'];
    const anchoCommercial = item['ANCHO_COMERCIAL'];
    const largoCommercial = item['LARGO_COMERCIAL'];

    if (!groupedMaterials[material]) {
      groupedMaterials[material] = {
        Description: item['Material Description'],
        Quantity: 0, // Initialize with 0
        Plant: plant,
        Batches: {},
      };
    }

    if (!groupedMaterials[material].Batches[batch]) {
      groupedMaterials[material].Batches[batch] = {
        Quantity: 0, // Initialize with 0
        SerialNumbers: [],
      };
    }

    // Increment quantity for material and batch
    groupedMaterials[material].Quantity += 1;
    groupedMaterials[material].Batches[batch].Quantity += 1;
    groupedMaterials[material].Batches[batch].SerialNumbers.push({
      Material: item.Material,
      Batch: item.Batch,
      SerialNumber: item['Serial Number'],
      Tono: item.TONO_TABLA,
      AreaComercial: item.AREA_COMERCIAL,
      StorageLocation: item['Storage Location'],
      AnchoCommercial: anchoCommercial,
      LargoCommercial: largoCommercial,
    });
  });

  return Object.keys(groupedMaterials).map((material) => ({
    Material: material,
    Description: groupedMaterials[material].Description,
    Quantity: groupedMaterials[material].Quantity,
    Plant: groupedMaterials[material].Plant,
    Batches: Object.keys(groupedMaterials[material].Batches).map((batch) => ({
      Batch: batch,
      Quantity: groupedMaterials[material].Batches[batch].Quantity,
      SerialNumbers: groupedMaterials[material].Batches[batch].SerialNumbers,
    })),
  }));
};

function StockCellViewCard({ cardCSSClassName, cardCSSHeaderClassName, cardCSSBodyClassName, cardHeaderText, selectedMaterial, show, onHide }) {
  const [IQ09Data, setIQ09Data] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const getIQ09Data = async () => {
      if (selectedMaterial) {
        const db = await connectToDB();
        const localIQ09Data = await GetData(db, 'IQ09');
        if (localIQ09Data) {
          const materialData = localIQ09Data[0].filter(item => item.Material === selectedMaterial);
          const groupedData = groupByMaterialAndBatchAndSerial(materialData);
          setIQ09Data(groupedData);
        }
      }
    };
    getIQ09Data();
  }, [selectedMaterial]);

  const handleRowToggle = (rowIndex) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(rowIndex) ? prevRows.filter((row) => row !== rowIndex) : [...prevRows, rowIndex]
    );
  };

  const tableStyle = {
    fontSize: 'small',
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg"> 
      <Card className={cardCSSClassName + " dark-card"} style={{width: "100%"}}>
        <Card.Header className={cardCSSHeaderClassName}>{cardHeaderText}</Card.Header>
        <Card.Body className={cardCSSBodyClassName}>
          {IQ09Data.length > 0 ? (
            <Table striped bordered hover size="sm" style={tableStyle} variant='dark'>
              <thead>
                <tr>
                  <th>Batch</th>
                  <th>Quantity</th>
                  <th>Plant</th>
                </tr>
              </thead>
              <tbody>
                {IQ09Data.flatMap((material, materialIndex) =>
                  material.Batches.map((batch, batchIndex) => (
                    <React.Fragment key={`${materialIndex}-${batchIndex}`}>
                      <tr onClick={() => handleRowToggle(`${materialIndex}-${batchIndex}`)} style={{ cursor: 'pointer' }}>
                        <td>{batch.Batch}</td>
                        <td>{batch.Quantity}</td>
                        <td>{material.Plant}</td>
                      </tr>
                      {expandedRows.includes(`${materialIndex}-${batchIndex}`) && (
                        <tr>
                          <td colSpan="3">
                            <Table striped bordered hover size="sm" style={tableStyle} variant='dark'>
                              <thead>
                                <tr>
                                  <th>Serial Number</th>
                                  <th>Tone</th>
                                  <th>Length</th>
                                  <th>Width</th>
                                  <th>Area</th>
                                </tr>
                              </thead>
                              <tbody>
                                {batch.SerialNumbers.map((serial, serialIndex) => (
                                  <tr key={serialIndex}>
                                    <td>{serial.SerialNumber}</td>
                                    <td>{serial.Tono}</td>
                                    <td>{serial.LargoCommercial}</td>
                                    <td>{serial.AnchoCommercial}</td>
                                    <td>{serial.AreaComercial}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </Table>
          ) : (
            <p>No data available for the selected material.</p>
          )}
        </Card.Body>
      </Card>
    </Modal>
  );
}

export default StockCellViewCard;
