import '../../../Styles/Excels/ExcelFileConfig.css';
import React, {useEffect, useState} from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FileInputContainer from '../../Reusables/FileInputContainer';
import { JustStock } from '../../../Utils/ExcelFileOps';
import { Button } from 'react-bootstrap';

function JustStockFileConfig({AppProps, DataProps, ExcelProps, setDefaultView}) {

    const {
        excelIQ09Path,
        setExcelIQ09Path,
        excelDataValid,
        setExcelDataValid,
        setExcelDRPConcept,
        dynamicMode,
        setDynamicMode,
    } = ExcelProps;
  
    const handleJustStock = () => {
        JustStock(excelIQ09Path, setExcelDataValid, setExcelDRPConcept);
        setExcelIQ09Path(null);
        setDynamicMode("Just Stock"); //This will be checked from NavBar cpt, and if true, when Full config is clicked, reset all paths to allow upload.
    }

    useEffect(() => {
        if (excelDataValid) {
            setDefaultView("Analytics");
        }
    }, [excelDataValid])
      

  return (
    <div>
      {excelDataValid ?
        null
        :
        <Card className='data-browser-card'>
        <Card.Header style={{fontWeight: "600"}}>Configuration:</Card.Header>
        <Card.Body>
            <Card.Text>
                In order for Stock Checker to work, you need to add the dataset for it to work on. Everything is processed in the browser for maximum security, to prevent any data from being transmitted on the web. 
            </Card.Text>
            <Card.Text>Once files are present, feel free to use the available functions</Card.Text>
            <FileInputContainer selectedFilePath={excelIQ09Path} setSelectedFile={setExcelIQ09Path} labelTitle={"IQ09"}/>
            <Container>
              <Button variant="primary" onClick={handleJustStock}>Go</Button>
            </Container>
        </Card.Body>
      </Card>
      }
    </div>
  )
}

export default JustStockFileConfig